import React, { useState } from 'react';
import { Form, Radio, Col, Row, Input, Button, Card, Select } from 'antd';

export function RespiratorConfig() {
  const [pesoIdeal, setPesoIdeal] = useState();
  const [pco2, setPco2] = useState();
  const [frCalculada, setFrCalculada] = useState();

  const [volumeForm] = Form.useForm();
  const [pco2Form] = Form.useForm();

  const calculateVolume = (values) => {
    values.sex
      ? setPesoIdeal(50 + 0.91 * (values.heigthcm - 152.4))
      : setPesoIdeal(45.5 + 0.91 * (values.heigthcm - 152.4));
  };

  const calculatePco2 = (values) => {
    const inf = values.bicarbonato * 1.5 + 8 - 2;
    const sup = values.bicarbonato * 1.5 + 8 + 2;
    setPco2({ inf, sup });
    values.co2 && setFrCalculada((values.co2 * values.fr) / (inf + 2));
  };

  return (
    <div>
      <Row gutter={24}>
        <Col lg={12}>
          <Form form={volumeForm} onFinish={calculateVolume}>
            <Card
              title="Calculo de Volumen Tidal según peso corporal ideal"
              headStyle={{ textAlign: 'center' }}
            >
              <Col span={24}>
                <Form.Item
                  name="sex"
                  rules={[
                    {
                      required: true,
                      message: 'Debe elegir el sexo.',
                    },
                  ]}
                  label="Sexo"
                  labelCol={{ span: 24 }}
                >
                  <Radio.Group>
                    <Radio value={true}>Masculino</Radio>
                    <Radio value={false}>Femenino</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="heigthcm"
                  label="Talla"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar la talla en cm.',
                    },
                  ]}
                >
                  <Input suffix="cm" type="number" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="volume"
                  label="Volumen"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe seleccionar volumen.',
                    },
                  ]}
                >
                  <Select placeholder="Seleccione volumen">
                    <Select.Option value={4}>4 ml/kg</Select.Option>
                    <Select.Option value={6}>6 ml/kg</Select.Option>
                    <Select.Option value={8}>8 ml/kg</Select.Option>
                    <Select.Option value={10}>10 ml/kg</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="default"
                    htmlType="submit"
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    Calcular
                  </Button>
                </Form.Item>
              </Col>
              {pesoIdeal && (
                <>
                  <h1>Peso ideal: {pesoIdeal.toFixed(2)} kgs</h1>
                  <h1>
                    Volumen calculado:{' '}
                    {(volumeForm.getFieldValue('volume') * pesoIdeal).toFixed(
                      2
                    )}{' '}
                    mls
                  </h1>
                </>
              )}
            </Card>
          </Form>
        </Col>
        <Col lg={12}>
          <Form form={pco2Form} onFinish={calculatePco2}>
            <Card
              title="Calculo de Frecuencia Respiratoria y CO2 Esperada"
              headStyle={{ textAlign: 'center' }}
            >
              <Col span={24}>
                <Form.Item
                  name="bicarbonato"
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar cantidad bicarbonato.',
                    },
                  ]}
                  label="Bicarbonato"
                  labelCol={{ span: 24 }}
                >
                  <Input suffix="mEq/L" type="number" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="co2"
                  label="CO2 Actual"
                  labelCol={{ span: 24 }}
                >
                  <Input x type="number" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="fr"
                  label="FR del Ventilador"
                  labelCol={{ span: 24 }}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="default"
                    htmlType="submit"
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    Calcular
                  </Button>
                </Form.Item>
              </Col>
              {pco2 && (
                <>
                  <h1>
                    pCO2 esperado entre: {pco2.inf} y {pco2.sup} mm Hg
                  </h1>
                  {frCalculada && (
                    <h1>FR calculada: {frCalculada.toFixed(2)}</h1>
                  )}
                </>
              )}
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
