import React, { useEffect } from 'react';
import IncomesList from '../../components/IncomesList';
import { useDispatch, useSelector } from 'react-redux';
import {
  onIncomeFilterHistoryRead,
  onIncomeHistoryRead,
  onIncomeValueChange,
  onSocialWorkRead,
} from '../../actions';
import {
  Input,
  Select,
  PageHeader,
  Popover,
  Button,
  DatePicker,
  Row,
  Badge,
  message,
} from 'antd';
import './IncomesHistory.scss';
import { ROLES } from '../../utils/constants';
import { FilterOutlined } from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import { PrinterOutlined } from '@ant-design/icons';
import { pdf, Document, Page } from '@react-pdf/renderer';
import {
  PdfFooter,
  PdfReportIncome,
  PdfReportIncomeTableHeader,
} from '../../components/PdfReports';
import { styleBody } from '../../components/PdfReports/styles';
import { saveAs } from 'file-saver';

export default function IncomesHistory(props) {
  const {
    count,
    search,
    searchType,
    searchSocialWork,
    searchDateStart,
    searchDateEnd,
    loadingSearch,
    loadingHistory,
    incomesHistory,
  } = useSelector((store) => store.incomeData);
  const { dni, name } = props.match.params;
  const { path } = props.match;
  const dispatch = useDispatch();
  const { role, type } = useSelector((store) => store.userData?.user);
  const { floors } = useSelector((store) => store.tenantData);
  const { socialWorks } = useSelector((store) => store.socialWorkData);

  useEffect(() => {
    if (
      role === ROLES.EMPLOYEE.value ||
      (type === 'Enfermero' && role === ROLES.INFARMARY.value)
    )
      props.history.push('/');

    (search ||
      searchType ||
      searchSocialWork ||
      searchDateStart ||
      searchDateEnd) &&
    path === '/incomes-history'
      ? dispatch(
          onIncomeFilterHistoryRead({
            search,
            typeIncome: searchType,
            socialWork: searchSocialWork,
            dateStart: searchDateStart,
            dateEnd: searchDateEnd,
          })
        )
      : path === '/incomes-history-patient/:dni/:name'
      ? dispatch(
          onIncomeFilterHistoryRead({
            search: '',
            dni: dni,
            typeIncome: '',
          })
        )
      : dispatch(onIncomeHistoryRead(count || 10));
  }, [search, searchType, searchSocialWork, searchDateStart, searchDateEnd]);

  const printIncomeReport = async () => {
    try {
      message.loading('Generando informe. Por favor espere...', 0);
      const doc = (
        <Document>
          <Page style={styleBody.body}>
            <PdfReportIncomeTableHeader />
            {incomesHistory?.map((income, index) => (
              <>
                <PdfReportIncome
                  key={index}
                  index={index + 1}
                  income={income}
                />
              </>
            ))}
            <PdfFooter />
          </Page>
        </Document>
      );

      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(
        blob,
        `Reporte ${searchType ? searchType : ''} - ${moment(
          searchDateStart
        ).format('DD/MM/YY')} al ${moment(searchDateEnd).format(
          'DD/MM/YY'
        )} - ${searchSocialWork ? searchSocialWork : ''}`
      );
      message
        .success('El documento se ha generado con exito.', 1)
        .then(() => message.destroy());
    } catch {
      message
        .error('Error, intente nuevamente', 1)
        .then(() => message.destroy());
    }
  };

  return (
    <div className="income-history">
      {path === '/incomes-history-patient/:dni/:name' ? (
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={name}
          style={{ textTransform: 'capitalize' }}
        />
      ) : (
        <div className="income-history-header">
          <Input.Search
            placeholder="Buscar pacientes.."
            onSearch={(value) =>
              dispatch(onIncomeValueChange({ search: value }))
            }
            defaultValue={search}
            enterButton
            allowClear
            loading={loadingSearch || loadingHistory}
            suffix={
              <Popover
                content={
                  <div className="income-history-header_filter">
                    <Row gutter={24}>
                      <Select
                        placeholder="Seleccione un piso..."
                        showSearch
                        size="small"
                        optionFilterProp="children"
                        value={searchType}
                        onChange={(value) => {
                          dispatch(onIncomeValueChange({ searchType: value }));
                        }}
                        style={{ width: '100%', marginLeft: '1%' }}
                      >
                        <Select.Option value="">General</Select.Option>
                        {floors?.map((floor, index) => (
                          <Select.Option key={index} value={floor.id}>
                            {floor.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                    <Row gutter={24}>
                      <Select
                        placeholder="Obra Social"
                        showSearch
                        allowClear
                        size="small"
                        optionFilterProp="children"
                        value={searchSocialWork}
                        onChange={(value) => {
                          dispatch(
                            onIncomeValueChange({
                              searchSocialWork:
                                value === '' ? undefined : value,
                            })
                          );
                        }}
                        style={{ width: '100%', marginLeft: '1%' }}
                      >
                        {socialWorks &&
                          socialWorks.map((socialWork, index) => (
                            <Select.Option
                              key={index}
                              value={socialWork.name}
                              style={{ textTransform: 'uppercase' }}
                            >
                              {socialWork.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Row>
                    <Row gutter={24}>
                      <DatePicker.RangePicker
                        placeholder={['Fecha inicio ', 'Fecha fin ']}
                        style={{ width: '100%' }}
                        size="small"
                        format="DD-MM-YYYY"
                        value={[
                          searchDateStart && moment(searchDateStart),
                          searchDateEnd && moment(searchDateEnd),
                        ]}
                        onChange={(value) => [
                          value !== null
                            ? dispatch(
                                onIncomeValueChange({
                                  searchDateStart: moment(value[0])
                                    .startOf('day')
                                    .toISOString(),
                                  searchDateEnd: moment(value[1])
                                    .startOf('day')
                                    .toISOString(),
                                })
                              )
                            : dispatch(
                                onIncomeValueChange({
                                  searchDateStart: undefined,
                                  searchDateEnd: undefined,
                                })
                              ),
                        ]}
                        locale={locale}
                        showToday={false}
                      />
                    </Row>
                    <Row>
                      <Button
                        type="link"
                        size="small"
                        style={{ textAlign: 'center' }}
                        disabled={!searchDateStart && !searchDateEnd}
                        onClick={() => printIncomeReport()}
                      >
                        Informe <PrinterOutlined />
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        type="link"
                        size="small"
                        style={{ textAlign: 'center' }}
                        onClick={() => {
                          dispatch(
                            onIncomeValueChange({
                              searchType: '',
                              searchSocialWork: undefined,
                              searchDateStart: undefined,
                              searchDateEnd: undefined,
                            })
                          );
                        }}
                      >
                        Borrar Filtros
                      </Button>
                    </Row>
                  </div>
                }
                title="Filtros"
                trigger="click"
                placement="bottom"
              >
                <Badge
                  count={
                    (searchType != '' && 1) +
                    (searchSocialWork != undefined && 1) +
                    (searchDateStart != undefined && 1)
                  }
                  size="small"
                  title="Cantidad de Filtros Aplicados"
                >
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      socialWorks?.length === 0 && dispatch(onSocialWorkRead());
                    }}
                  >
                    Filtros <FilterOutlined />
                  </Button>
                </Badge>
              </Popover>
            }
          />
        </div>
      )}
      <IncomesList path={path} />
    </div>
  );
}
