import React from 'react';
import { Text, Image, View } from '@react-pdf/renderer';
import { styleBody } from './styles';

export function PdfSignature({
  signature,
  name,
  lastname,
  medicalSpeciality,
  mp,
}) {
  return (
    <View wrap={false}>
      <View
        render={() =>
          signature && (
            <Image src={signature} style={styleBody.signatureImage} />
          )
        }
      />
      <Text style={styleBody.signature}>
        {name} {lastname}
      </Text>
      <View
        render={() => (
          <View>
            <Text style={styleBody.signature}>
              {medicalSpeciality && medicalSpeciality}
              {mp && ` - MP: ${mp}`}
            </Text>
          </View>
        )}
      />
    </View>
  );
}
