import React, { useEffect, useState } from 'react';
import { Button, Row, Col, PageHeader, Tooltip } from 'antd';
import Modal from '../../../components/Modal';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  BalanceView,
  ListDateBalances,
  BalanceForm,
  BalanceCalculate,
} from '../../../components/Infarmary/Balances';
import { getBalanceApi } from '../../../api/balance';
import { getAccessTokenApi } from '../../../api/auth';
import PermissionsAsigner from '../../../components/PermissionsAsigner';
import { ControlOutlined, DiffOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export default function Balances(props) {
  const { id, name } = props.match.params;
  const [balances, setBalances] = useState();
  const [balanceData, setBalanceData] = useState({
    id: '',
    date: '',
    ingresos: [],
    egresos: [],
  });
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [reload, setReload] = useState(false);

  const { incomesActive } = useSelector((store) => store.incomeData);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getBalanceApi(id, token)
        .then((response) => {
          setBalances(response.balances);
          setBalanceData({
            id: response.balances[0]._id,
            date: response.balances[0].date,
            ingresos: response.balances[0].ingreso,
            egresos: response.balances[0].egreso,
          });
        })
        .catch((err) => {});

      setReload(false);
    };
    getData();
  }, [id, reload]);

  let income;
  incomesActive.find((i) => {
    if (i._id === id) {
      income = i;
    }
  });

  const addBalance = (income, balanceId) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar control a ${income.name}`);
    setModalContent(
      <BalanceForm
        incomeId={id}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        balanceId={balanceId}
      />
    );
  };

  const calculateBalance = () => {
    setIsVisibleModal(true);
    setModalTitle(`Calcular balance a ${income.name}`);
    setModalContent(
      <BalanceCalculate
        balances={balances}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        date={income.date}
      />
    );
  };

  const viewBalance = (balance) => {
    setBalanceData({
      id: balance._id,
      date: balance.date,
      ingresos: balance.ingreso,
      egresos: balance.egreso,
    });
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  return (
    <div className="evolution">
      {income ? (
        <div className="evolution-header">
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={`Cama: ${income.bed.name}`}
            subTitle={
              <Tooltip
                title={`Cama: ${income.bed.name} ${income.name} - ${
                  income.age
                } años | Fecha Ingreso: ${moment(income.date).format(
                  'DD/MM/YYYY'
                )}`}
              >
                {income.name} - {income.age} años | Fecha Ingreso:{' '}
                {moment(income.date).format('DD/MM/YYYY')}
              </Tooltip>
            }
            extra={[
              <Tooltip title="Notas">
                <Link to={`/notes/${income._id}`}>
                  <Button type="link">
                    <EditOutlined />
                  </Button>
                </Link>
              </Tooltip>,
              <Tooltip title="Controles">
                <Link to={`/controls/${income._id}`}>
                  <Button type="link">
                    <ControlOutlined />
                  </Button>
                </Link>
              </Tooltip>,
              <Tooltip title="Calcular Balance">
                <Button type="link" onClick={() => calculateBalance()}>
                  <DiffOutlined />
                </Button>
              </Tooltip>,
              <PermissionsAsigner type="Enfermero">
                <Button
                  type="primary"
                  onClick={() =>
                    moment()
                      .startOf('day')
                      .isSame(moment(balanceData.date).startOf('day'), 'day')
                      ? addBalance(income, balanceData.id)
                      : addBalance(income)
                  }
                >
                  Agregar Balance
                </Button>
              </PermissionsAsigner>,
            ]}
          />
        </div>
      ) : (
        <div className="evolution-header">
          <PageHeader
            ghost={false}
            title={name}
            onBack={() => window.history.back()}
          />
        </div>
      )}
      <div>
        <Row gutter={24}>
          <Col md={6} sm={24} xs={24}>
            <ListDateBalances balances={balances} viewBalance={viewBalance} />
          </Col>
          <Col md={18} sm={24} xs={24}>
            <BalanceView balanceData={balanceData} setReload={setReload} />
          </Col>
        </Row>
      </div>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={window.screen.width < 500 ? '80%' : '50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
