import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleTable } from './styles';

export function PdfReportIncomeTableHeader() {
  return (
    <View style={[styleTable.row, styleTable.header]} fixed>
      <Text style={styleTable.column8}>#</Text>
      <Text style={styleTable.column6}>Fecha</Text>
      <Text style={styleTable.column6}>Cama</Text>
      <Text style={styleTable.column5}>Paciente</Text>
      <Text style={styleTable.column6}>Obra social</Text>
      <Text style={styleTable.column6}>Pisos</Text>
    </View>
  );
}
