import React from 'react';
import { Text, Image, View } from '@react-pdf/renderer';
import { styleHeader, styleBody } from './styles';

export function PdfHeader({
  name,
  socialWork,
  type,
  organization,
  tenant,
  affiliate,
  period,
  tenantLogo,
}) {
  const image =
    type === 'cardio'
      ? `https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo1.png`
      : `https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/${
          tenantLogo || 'logo'
        }.png`;
  return (
    <View style={styleHeader.header} fixed>
      <Text style={styleHeader.headerText}>
        {organization} - Historia Clinica
      </Text>
      <Text style={styleHeader.headerText}>
        <Text style={styleBody.textCapitalize}>{name}</Text> -{' '}
        <Text style={styleBody.textUppercase}>{socialWork}</Text>
        {affiliate && (
          <Text style={styleBody.textUppercase}> - {affiliate}</Text>
        )}
      </Text>
      <Text style={styleHeader.headerText}>
        {period && (
          <Text style={styleBody.textCapitalize}>Periodo: {period}</Text>
        )}
      </Text>

      <Image style={styleHeader.imageHeader} src={image} />
    </View>
  );
}
