import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { onSocialWorkAddNomenclator, onSocialWorkCreate } from '../../actions';
import { getNomenclators } from '../../api/billing';
import { getAccessTokenApi } from '../../api/auth';

export default function SocialWorkForm(props) {
  const { setIsVisibleModal, data } = props;
  const dispatch = useDispatch();
  const [socialWorkForm] = Form.useForm();
  const [nomenclators, setNomenclators] = useState([]);

  useEffect(() => {
    socialWorkForm.resetFields();
    socialWorkForm.setFieldsValue(data);

    let getDataNomenclator = async () => {
      let token = await getAccessTokenApi();

      getNomenclators(token).then((response) => {
        if (response?.code === 200) {
          setNomenclators(response.nomenclators);
        }
      });
    };

    getDataNomenclator();
  }, [data]);

  const onFinish = (values) => {
    socialWorkForm.resetFields();
    data
      ? dispatch(
          onSocialWorkAddNomenclator({
            socialWorkData: values,
            setIsVisibleModal,
            id: data._id,
          })
        )
      : dispatch(onSocialWorkCreate(values, setIsVisibleModal));
  };

  return (
    <div>
      <Form form={socialWorkForm} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              name="name"
              initialValue=""
              rules={[
                { required: true, message: 'Debe cargar una obra social.' },
              ]}
            >
              <Input placeholder="Nombre (*)" disabled={data} />
            </Form.Item>
          </Col>
          {data && (
            <Col xs={24} md={12}>
              <Form.Item name="nomenclator">
                <Select
                  placeholder="Seleccione el nomenclador"
                  showSearch
                  allowClear
                >
                  {nomenclators.map((n) => (
                    <Select.Option value={n._id}>{n._id}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
