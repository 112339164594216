import { ON_DIAGNOSTIC_READ, ON_DIAGNOSTIC_READING } from '../actions/types';

const INITIAL_STATE = { diagnostics: [], loading: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_DIAGNOSTIC_READING:
      return { ...state, loading: true };
    case ON_DIAGNOSTIC_READ:
      return { ...state, diagnostics: action.payload, loading: false };
    default:
      return state;
  }
};
