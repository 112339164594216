import React, { useEffect, useState } from 'react';
import {
  Form,
  Col,
  Row,
  Button,
  DatePicker,
  notification,
  Radio,
  Select,
  Input,
} from 'antd';
import moment from 'moment';
import { addBalanceApi, updateBalanceApi } from '../../../api/balance';
import { getAccessTokenApi } from '../../../api/auth';
import { incomesInfarmary, expensesInfarmary } from '../../../utils/constants';

export function BalanceForm(props) {
  const { incomeId, setIsVisibleModal, setReload, balanceId } = props;
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);

  const [balanceForm] = Form.useForm();

  useEffect(() => {
    balanceForm.resetFields();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    let token = await getAccessTokenApi();

    values.income = incomeId;

    balanceId
      ? updateBalanceApi(balanceId, values, token)
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
              setLoading(false);
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              setReload(true);
              balanceForm.resetFields();
              setLoading(false);
              setType(null);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoading(false);
          })
      : addBalanceApi(values, token)
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
              setLoading(false);
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              setReload(true);
              balanceForm.resetFields();
              setLoading(false);
              setType(null);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoading(false);
          });
  };

  return (
    <div>
      <Form form={balanceForm} onFinish={onFinish}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Form.Item
              name="type"
              rules={[
                { required: true, message: 'Debe elegir ingreso o egreso' },
              ]}
              labelCol={{ span: 24 }}
            >
              <Radio.Group onChange={(value) => setType(value.target.value)}>
                <Radio value={true}>Ingreso</Radio>
                <Radio value={false}>Egreso</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Form.Item
              name="hour"
              rules={[{ required: true, message: 'Debe cargar una hora' }]}
              label="Hora"
              labelCol={{ span: 24 }}
              initialValue={moment()}
            >
              <DatePicker.TimePicker
                placeholder="Seleccione hora del balance (*)"
                style={{ width: '100%' }}
                inputReadOnly
                format="HH:mm"
                showTime={{ format: 'HH:mm' }}
                disabled={type === null}
                onChange={() => {
                  if (
                    moment().isSameOrBefore(balanceForm.getFieldValue('hour'))
                  ) {
                    notification['warning']({
                      message:
                        'No se puede elegir una fecha/hora mayor a la actual.',
                    });
                    balanceForm.setFieldsValue({ hour: moment() });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Form.Item
              name="name"
              label="Tipo"
              rules={[{ required: true, message: 'Debe cargar una hora' }]}
              labelCol={{ span: 24 }}
            >
              <Select
                placeholder="Seleccione el tipo"
                showSearch
                optionFilterProp="children"
                disabled={type === null}
              >
                {type
                  ? incomesInfarmary.map((income, index) => (
                      <Select.Option key={index} value={income.name}>
                        {income.name}
                      </Select.Option>
                    ))
                  : expensesInfarmary.map((expense, index) => (
                      <Select.Option key={index} value={expense.name}>
                        {expense.name}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Form.Item
              name="quantity"
              label="Cantidad"
              rules={[{ required: true, message: 'Debe cargar la cantidad' }]}
              labelCol={{ span: 24 }}
            >
              <Input
                type="number"
                inputMode="numeric"
                style={{ width: '100%' }}
                suffix="ml"
                min={0}
                disabled={type === null}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
                loading={loading}
                disabled={loading || type == null}
              >
                Agregar Balance
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
