import React from 'react';
import {
  Row,
  Col,
  Card,
  List,
  Tag,
  Button,
  Dropdown,
  Menu,
  Modal as ModalAntd,
  notification,
} from 'antd';
import {
  LoadingOutlined,
  DeleteOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { deleteBalanceApi } from '../../../api/balance';
import PermissionsAsigner from '../../PermissionsAsigner';
import { Link } from 'react-router-dom';
import { getAccessTokenApi } from '../../../api/auth';

export function BalanceView(props) {
  const { balanceData, setReload, id } = props;

  var totalIngresos = 0;
  var totalEgresos = 0;

  {
    balanceData.ingresos.map((ingreso) => {
      totalIngresos += ingreso.quantity;
    });
    balanceData.egresos.map((egreso) => {
      totalEgresos += egreso.quantity;
    });
  }

  var total = totalIngresos - totalEgresos;

  const deleteBalance = async (data, type) => {
    data.type = type;
    ModalAntd.confirm({
      title: 'Eliminar balance',
      content: `¿ Estas seguro que quieres eliminar el ${
        type ? 'ingreso' : 'egreso'
      }  ${data.name} | ${data.quantity} de las ${moment(data.hour).format(
        'HH:mm'
      )}?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        let token = await getAccessTokenApi();
        deleteBalanceApi(balanceData.id, data, token)
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setReload(true);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  return (
    <div>
      <Card
        title={`Detalle ${
          balanceData.date && moment(balanceData.date).format('DD/MM')
        }`}
        headStyle={{ textAlign: 'center' }}
        style={{ borderColor: '#002a52', height: '80vh', overflowY: 'scroll' }}
        extra={
          id && (
            <Link to={`/balances/${id}`}>
              <Button
                type="link"
                style={{
                  color: '#1764ab',
                }}
              >
                Ver Detalle
              </Button>
            </Link>
          )
        }
      >
        <div style={{ textAlign: 'center', marginBottom: '2%' }}>
          <Tag color={total > 0 ? 'blue' : 'gold'}>
            Balance: {total > 0 ? `+${total}` : total}
          </Tag>
        </div>

        <Row gutter={24}>
          <Col md={12} sm={24} xs={24}>
            <Card
              title="Ingresos"
              headStyle={{ textAlign: 'center' }}
              extra={<Tag color="blue">+{totalIngresos}</Tag>}
              style={{ overflowY: 'scroll', height: '60vh' }}
            >
              <List
                itemLayout="horizontal"
                dataSource={balanceData.ingresos}
                renderItem={(data) => (
                  <Item data={data} type={true} deleteBalance={deleteBalance} />
                )}
                locale={{ emptyText: 'No hay balances' }}
                loading={
                  !balanceData && {
                    style: { width: '100%', padding: '200px 0' },
                    size: 'large',
                    indicator: <LoadingOutlined />,
                  }
                }
              />
            </Card>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Card
              title="Egresos"
              headStyle={{ textAlign: 'center' }}
              extra={<Tag color="gold">-{totalEgresos}</Tag>}
              style={{ overflowY: 'scroll', height: '60vh' }}
            >
              <List
                itemLayout="horizontal"
                dataSource={balanceData.egresos}
                renderItem={(data) => (
                  <Item
                    data={data}
                    type={false}
                    deleteBalance={deleteBalance}
                  />
                )}
                locale={{ emptyText: 'No hay balances' }}
                loading={
                  !balanceData && {
                    style: { width: '100%', padding: '200px 0' },
                    size: 'large',
                    indicator: <LoadingOutlined />,
                  }
                }
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

function Item(props) {
  const { data, type, deleteBalance } = props;

  return (
    <List.Item
      actions={[
        <PermissionsAsigner type="Enfermero">
          <Dropdown.Button
            trigger={['click']}
            type="link"
            size="large"
            icon={<MoreOutlined style={{ color: '#1764ab' }} />}
            overlay={
              <Menu>
                <Menu.Item>
                  <Button type="link" onClick={() => deleteBalance(data, type)}>
                    <span style={{ color: 'red' }}>Eliminar</span>
                    <DeleteOutlined style={{ color: 'red' }} />
                  </Button>
                </Menu.Item>
              </Menu>
            }
          />
        </PermissionsAsigner>,
      ]}
    >
      <List.Item.Meta
        title={`${data.name} | ${data.quantity} ml`}
        description={moment(data.hour).format('HH:mm')}
      />
    </List.Item>
  );
}
