import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleBody } from './styles';

export function PdfInfarmaryAction({ action, index }) {
  return (
    <View style={styleBody.dataEvolution}>
      <Text style={action.suspend && styleBody.textSuspend}>
        <Text style={styleBody.text}>{index}. </Text>
        <Text
          style={styleBody.text}
          render={() =>
            action.suspend
              ? `SUSPENDIDA - ${action.action.name}`
              : `${action.action.name}`
          }
        ></Text>
        <Text
          style={styleBody.text}
          render={() => action.time && ` - Cada ${action.time} hs`}
        />
        <Text
          style={styleBody.text}
          render={() => action.hour && ` (${action.hour})`}
        />
        <Text
          style={styleBody.text}
          render={() => action.extra !== 'Habitual' && `- ${action.extra}`}
        />
        <Text
          style={styleBody.text}
          render={() => action.description && ` - ${action.description}`}
        />
      </Text>
    </View>
  );
}
