import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleTable } from './styles';
import moment from 'moment';

export function PdfControl({ control }) {
  return (
    <View wrap={false}>
      <View style={styleTable.row}>
        <Text style={styleTable.column1}>
          {moment(control.date).format('DD/MM HH:mm')}
        </Text>
        <Text style={styleTable.column1}>{control.heartRate}</Text>
        <Text style={styleTable.column1}>{control.bloodPresure}</Text>
        <Text style={styleTable.column1}>{control.o2Saturation} %</Text>
        <Text style={styleTable.column1}>{control.temperature} °C</Text>
        <Text style={styleTable.column1}>{control.breathingFrequency}</Text>
        <Text style={styleTable.column2}>{control.observations} </Text>
      </View>
    </View>
  );
}
