import React, { useEffect, useState } from 'react';
import {
  addPaidPharmacyApi,
  deletePharmacySendApi,
  getPharmacyStockApi,
} from '../../api/pharmacy';
import { getAccessTokenApi } from '../../api/auth';
import {
  Button,
  Card,
  Checkbox,
  Descriptions,
  Input,
  Select,
  Modal as ModalAntd,
  Tag,
  message,
} from 'antd';
import StockTable from '../../components/StockTable';
import BackButton from '../../components/BackButton';
import Spin from '../../components/Spin';
import './Pharmacy.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { deletePatientApi } from '../../api/patient';

export default function PharmacyStock(props) {
  const { id } = props.match.params;
  const type = props.location.hash;
  const [stock, setStock] = useState([]);
  console.log('🚀 ~ PharmacyStock ~ stock:', stock);
  const [store, setStore] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [expire, setExpire] = useState(false);

  const { organization, drugStores } = useSelector((store) => store.tenantData);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let token = await getAccessTokenApi();
      await getPharmacyStockApi({
        token,
        store,
        id,
        expire,
        extra: type === '#buy' ? 'buy' : 'send',
      })
        .then((response) => {
          if (response.code === 200) {
            setStock(response.dataStock);
          } else {
            message.error(response.message);
          }
          setLoading(false);
        })
        .catch(() => {
          message.error('Error del servidor, intente nuevamente mas tarde.');
        });
    };

    getData();
  }, [store, expire]);

  if (loading) return <Spin />;

  const stockHeader = () => {
    return (
      <div className="income-header">
        <BackButton />
        <div>
          <Checkbox
            onChange={(e) => setExpire(e.target.checked)}
            checked={expire}
          >
            Agrupar por vencimiento
          </Checkbox>
        </div>
        <Select
          placeholder="Ver por deposito.."
          showSearch
          optionFilterProp="children"
          onChange={(e) => setStore(e)}
          value={store}
          allowClear
        >
          {drugStores.map((store) => (
            <Select.Option value={store}>{store}</Select.Option>
          ))}
        </Select>
        <div className="income-header_search">
          <Input.Search
            enterButton
            placeholder="Buscar productos.."
            onSearch={(value) => setSearch(value)}
            defaultValue={search}
            onChange={(e) =>
              (e.target.value.length === 0 || e.target.value.length >= 3) &&
              setSearch(e.target.value)
            }
            allowClear
          />
        </div>
      </div>
    );
  };

  const paid = async (value) => {
    const token = await getAccessTokenApi();

    addPaidPharmacyApi({ data: { paid: value }, token, id })
      .then(async (response) => {
        if (response?.code !== 200) {
          message.error(response.message);
        } else {
          message.success(response.message);
          await getPharmacyStockApi({
            token,
            store,
            id,
            expire,
            extra: type === '#buy' ? 'buy' : 'send',
          })
            .then((response) => {
              if (response.code === 200) {
                setStock(response.dataStock);
              } else {
                message.error(response.message);
              }
              setLoading(false);
            })
            .catch(() => {
              message.error(
                'Error del servidor, intente nuevamente mas tarde.'
              );
            });
        }
      })
      .catch(() => {
        message.error('Error del servidor, intente mas tarde.');
      });
  };

  const deleteSendPharmacy = async () => {
    const token = await getAccessTokenApi();

    ModalAntd.confirm({
      title: 'Eliminar envio de farmacia',
      content: `¿ Estas seguro que quieres eliminar el envio ? Esta acción no se puede deshacer`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        deletePharmacySendApi({ id, token })
          .then((response) => {
            if (response?.code !== 200) {
              message.error(response.message);
            } else {
              message.success(response.message);
              window.history.back();
            }
          })
          .catch(() => {
            message.error('Error del servidor, intente mas tarde.');
          });
      },
    });
  };

  return (
    <div>
      {id && (
        <Card
          title={
            <>
              <BackButton />
              {type === '#buy' ? 'Compra' : 'Envio'}
            </>
          }
          extra={
            type === '#buy' ? (
              <div className="cards-switch">
                <Tag
                  color={stock[0]?.paid ? 'success' : 'default'}
                  icon={
                    stock[0]?.paid ? <CheckCircleOutlined /> : <SyncOutlined />
                  }
                >
                  {stock[0]?.paid ? 'Pagado' : 'Pendiente'}
                </Tag>
                {!stock[0]?.paid && (
                  <Checkbox onChange={(value) => paid(value.target.checked)} />
                )}
              </div>
            ) : (
              type === '#send' && (
                <Button
                  type="link"
                  style={{ color: 'red' }}
                  onClick={() => deleteSendPharmacy()}
                >
                  <MinusCircleOutlined /> Anular Envio
                </Button>
              )
            )
          }
        >
          <Descriptions column={{ xs: 1, sm: 1, md: 2 }} title={organization}>
            <Descriptions.Item label="Fecha de Factura">
              {moment(stock[0]?.invoice.date).format('DD/MM/YYYY')}
            </Descriptions.Item>
            {type === '#buy' && (
              <Descriptions.Item label="Nro de Factura">
                {stock[0]?.invoice.number}
              </Descriptions.Item>
            )}
            <Descriptions.Item label={type === '#buy' ? 'Destino' : 'Origen'}>
              {stock[0]?.store}
            </Descriptions.Item>
            {type === '#buy' && (
              <Descriptions.Item label="Proveedor">
                {stock[0]?.provider}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Concepto">
              {stock[0]?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Responsable">
              {stock[0]?.user}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}

      <StockTable
        items={stock}
        id={id}
        type={type}
        header={!id && stockHeader}
        search={search}
        expire={expire}
      />
    </div>
  );
}
