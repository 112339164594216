import React from 'react';
import moment from 'moment';
import './ViewInfo.scss';
import { Tag } from 'antd';

export function ViewIndicationInsumos(props) {
  const { data, month, filter, type } = props;

  if (!data) {
    return null;
  }

  return (
    <div className="view-info">
      <h4>
        {type === 'total'
          ? moment(month).format('MM / YY')
          : moment(data[0]?.insumo.date).format('DD/MM/YYYY HH:mm')}
      </h4>
      <h5>{month ? 'Filtros' : 'Filtro'}</h5>
      {type === 'total' ? (
        <ul>
          {filter?.map((i) => (
            <li>
              {i.filter?.name} - Usos: {i.filter?.uses}{' '}
            </li>
          ))}
        </ul>
      ) : (
        <ul>
          <li>
            {filter?.name} - Uso nro: {filter?.use}{' '}
            {filter?.change && <Tag color="error">Cambio filtro</Tag>}
          </li>
        </ul>
      )}

      <h5>Insumos</h5>
      <ul>
        {data?.map((item, index) => (
          <li>
            {item.insumo.name} - Cantidad consumida: {item.insumo.quantity}
          </li>
        ))}
      </ul>
    </div>
  );
}
