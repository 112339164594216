import React, { useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Descriptions,
  InputNumber,
  List,
  Popover,
  Radio,
  Row,
  Select,
  Tag,
} from 'antd';
import BackButton from '../../components/BackButton';
import { Link } from 'react-router-dom';
import {
  FilterOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Modal from '../Modal';
import BillingChargedForm from './BillingChargedForm';
import './Billing.scss';
import { useDispatch, useSelector } from 'react-redux';
import { onSearchValueChange } from '../../actions/BillingSearchActions';

export default function BillingList({ billingData, loading, type, setReload }) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [filter, setFilter] = useState();

  const dispatch = useDispatch();

  const { search, searchYear } = useSelector((store) => store.searchData);
  const { viewBillingOrganizations } = useSelector(
    (store) => store.userData?.user
  );

  const billingChargedModal = (billing) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar cobro al lote ${billing.description}`);
    setModalContent(
      <BillingChargedForm
        totalPrice={billing?.totalPrice - billing?.totalValueCharged}
        setIsVisibleModal={setIsVisibleModal}
        id={billing._id}
        quantityPractices={
          billing?.quantityPractices - billing?.totalQuantityCharged
        }
        setReload={setReload}
      />
    );
  };

  return (
    <div>
      <List
        header={
          <div>
            <Row>
              <BackButton />
              <span
                style={{
                  fontWeight: 600,
                  fontSize: '12pt',
                  marginRight: '5px',
                }}
              >
                {type === 'month' ? (
                  <div>
                    <span>Liquidaciones mensuales ambulatorias</span>{' '}
                    <Popover
                      content={
                        <div className="income-history-header_filter">
                          <Row gutter={24}>
                            <Select
                              placeholder="Seleccione un prestador..."
                              showSearch
                              size="small"
                              optionFilterProp="children"
                              value={search !== '' && search}
                              mode="multiple"
                              onChange={(value) => {
                                dispatch(
                                  onSearchValueChange({ search: value })
                                );
                              }}
                              style={{ width: '100%' }}
                              allowClear
                            >
                              {viewBillingOrganizations?.map((bo, index) => (
                                <Select.Option key={index} value={bo}>
                                  {bo}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                          <Row gutter={24}>
                            <InputNumber
                              style={{ width: '100%' }}
                              defaultValue={moment().format('YYYY')}
                              value={searchYear}
                              onChange={(value) => {
                                dispatch(
                                  onSearchValueChange({ searchYear: value })
                                );
                              }}
                            />
                          </Row>
                        </div>
                      }
                      title="Filtros"
                      trigger="click"
                      placement="bottom"
                    >
                      <Badge
                        count={
                          (search != '' && 1) + (searchYear != undefined && 1)
                        }
                        size="small"
                        title="Cantidad de Filtros Aplicados"
                      >
                        <Button type="link" size="small">
                          Filtros <FilterOutlined />
                        </Button>
                      </Badge>
                    </Popover>
                  </div>
                ) : (
                  `Detalle ${
                    type === 'socialWork'
                      ? 'por financiadores'
                      : `${billingData[0]?.socialWork}`
                  }  - Periodo ${billingData[0]?.month}/${billingData[0]?.year}`
                )}
              </span>
              {type !== 'month' && (
                <Radio.Group
                  onChange={async (e) => {
                    if (e.target.value === '') {
                      setFilter();
                    } else {
                      let billinFilter = await billingData.filter(
                        (billing) =>
                          e.target.value === billing?.realStatus?.status
                      );

                      setFilter(billinFilter);
                    }
                  }}
                  defaultValue={''}
                >
                  <Radio value="">Todos</Radio>
                  <Radio value="Cobrado">Cobrado</Radio>
                  <Radio value="Cobrado parcial">Cobrado parcial</Radio>
                  <Radio value="Pendiente">Pendiente</Radio>
                </Radio.Group>
              )}
            </Row>
          </div>
        }
        className="list"
        itemLayout={'vertical'}
        dataSource={filter ? filter : billingData}
        grid={{ gutter: 16, column: 3, xs: 1, sm: 1, md: 2 }}
        renderItem={(data) => (
          <Item
            data={data}
            type={type}
            billingChargedModal={billingChargedModal}
          />
        )}
        locale={{
          emptyText: 'No hay datos',
        }}
        loading={
          loading && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
        pagination={
          billingData?.length > 50 && {
            pageSize: 50,
            showSizeChanger: false,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            // current: page,
            onChange: (page) => {
              //   dispatch(onPharmacyValueChange({ page }));
            },
            position: 'top',
          }
        }
      />
      <div style={{ textAlign: 'center', margin: 5 }}></div>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function Item({ data, type, billingChargedModal }) {
  let actions =
    type === 'month' || type === 'socialWork'
      ? [
          <Link
            to={{
              pathname: `/billing-batch-details/${
                type === 'month' ? 'socialWork' : 'batch'
              }`,
              search: `?year=${data?._id?.year}&month=${
                data?._id?.month
              }${`&organization=${data._id.organization}&socialWork=${data._id.socialWork}`}`,
            }}
          >
            <Button type="link">
              Ver Detalle <InfoCircleOutlined />
            </Button>
          </Link>,
        ]
      : [
          <Link
            to={{
              pathname: `/billing-detail/${data._id}`,
              hash: '#view',
            }}
          >
            <Button type="link">
              Ver lote
              <InfoCircleOutlined />
            </Button>
          </Link>,
        ];

  type !== 'month' &&
    type !== 'socialWork' &&
    data.status !== 'Cobrado' &&
    actions.push(
      <Button type="link" onClick={() => billingChargedModal(data)}>
        Agregar cobro
        <PlusOutlined />
      </Button>
    );

  return (
    <List.Item>
      <Card className="card" actions={actions} hoverable>
        <Card.Meta
          title={
            type === 'month'
              ? `${data?.month}/${data?.year}`
              : type === 'socialWork'
              ? data.socialWork
              : data.practice && (
                  <div>
                    {data?.practice[0]?.name} - {data?.practice[0]?.code}
                  </div>
                )
          }
          description={
            type === 'month'
              ? `Cantidad de financiadores: ${data?.quantity}`
              : type === 'socialWork'
              ? `Cantidad de lotes: ${data?.quantity}`
              : `Fuente de datos: ${data.turnType}`
          }
          className="head-card"
        />
        <div>
          <Descriptions
            column={{ xs: 1, md: 1, lg: 1 }}
            title={
              <Tag color={data.realStatus.color}>{data.realStatus.status} </Tag>
            }
          >
            <Descriptions.Item label="Cantidad de practicas realizadas">
              {data?.quantityPractices?.toLocaleString('es-AR')}
            </Descriptions.Item>
            <Descriptions.Item label="Valor facturado">
              {data?.totalPrice?.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              })}
            </Descriptions.Item>
            <Descriptions.Item label="Cantidad de practicas cobradas">
              {data?.totalQuantityCharged?.toLocaleString('es-AR')}
              <Tag style={{ marginLeft: '5px' }}>
                {(
                  (data?.totalQuantityCharged / data?.quantityPractices) *
                  100
                ).toFixed(2)}
                %
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Valor cobrado">
              {data?.totalValueCharged?.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              })}
            </Descriptions.Item>
            <Descriptions.Item label="Saldo">
              {(data?.totalPrice - data?.totalValueCharged)?.toLocaleString(
                'es-AR',
                {
                  style: 'currency',
                  currency: 'ARS',
                  minimumFractionDigits: 2,
                }
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Prestador">
              <span style={{ textTransform: 'capitalize' }}>
                {data?.organization}
              </span>
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Card>
    </List.Item>
  );
}
