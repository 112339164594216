import React from 'react';
import { Tabs, Row, Col } from 'antd';
import AmpinaLogo from '../../assets/img/a1-8.png';
import RegisterForm from '../../components/RegisterForm';

import '../SignIn/SignIn.scss';
export default function RegisterAmbulatory() {
  const { TabPane } = Tabs;

  return (
    <div className="main-banner">
      <div className="main-banner__dark" />
      <Row>
        <Col md={2} />
        <Col xs={24} md={8}>
          <div className="main-banner__logo">
            <img src={AmpinaLogo} alt="Logo" />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={1} />
        <Col xs={24} md={10}>
          <div className="sign-in-content__tabs">
            <Tabs size="large" type="card">
              <TabPane tab={<span>Regitrarme</span>} key="2">
                <RegisterForm />
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
}
