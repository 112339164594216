import React, { useState, useEffect } from 'react';
import { Table, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getControlApi } from '../../../api/control';
import { getAccessTokenApi } from '../../../api/auth';

export default function TableControls(props) {
  const { id, reload, setReload, scrollY } = props;
  const [controls, setControls] = useState();

  useEffect(() => {
    const getData = async () =>{
       const token = await getAccessTokenApi();
    getControlApi(id, token)
      .then((response) => {
        setControls(response.controls);
      })
      .catch((err) => {});

    setReload(false);
    }
    getData();
   
  }, [id, reload]);

  const columns = [
    {
      title: 'Fecha y Hora',
      dataIndex: 'date',
      width: 70,
      key: 'date',
      render: (date) => <span>{moment(date).format('DD/MM HH:mm')}</span>,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: 'Frecuencia Cardiaca',
      dataIndex: 'heartRate',
      key: 'heartRate',
      width: 70,
      sorter: (a, b) => a.heartRate - b.heartRate,
      render: (heartRate) =>
        (heartRate > 100 && heartRate <= 120) ||
        (heartRate >= 40 && heartRate <= 50) ? (
          <Tag color="warning">{heartRate}</Tag>
        ) : heartRate >= 120 || heartRate <= 40 ? (
          <Tag color="error">{heartRate}</Tag>
        ) : (
          <text>{heartRate}</text>
        ),
    },
    {
      title: 'Tensión Arterial',
      dataIndex: 'bloodPresure',
      key: 'bloodPresure',
      width: 60,
      sorter: (a, b) => a.bloodPresure - b.bloodPresure,
      render: (bloodPresure) =>
        bloodPresure &&
        ((bloodPresure.split('/', 1) > 140 &&
          bloodPresure.split('/', 1) <= 160) ||
        (bloodPresure.split('/', 1) >= 80 &&
          bloodPresure.split('/', 1) <= 90) ? (
          <Tag color="warning">{bloodPresure}</Tag>
        ) : bloodPresure.split('/', 1) > 160 ||
          bloodPresure.split('/', 1) < 80 ? (
          <Tag color="error">{bloodPresure}</Tag>
        ) : (
          <text>{bloodPresure}</text>
        )),
    },
    {
      title: 'Saturación de O2.',
      dataIndex: 'o2Saturation',
      key: 'o2Saturation',
      width: 70,
      sorter: (a, b) => a.o2Saturation - b.o2Saturation,
      render: (o2Saturation) =>
        o2Saturation > 88 && o2Saturation <= 92 ? (
          <Tag color="warning">{o2Saturation} %</Tag>
        ) : o2Saturation <= 88 ? (
          <Tag color="error">{o2Saturation} %</Tag>
        ) : (
          <text>{o2Saturation} %</text>
        ),
    },
    {
      title: 'Temperatura',
      dataIndex: 'temperature',
      key: 'temperature',
      width: 75,
      sorter: (a, b) => a.temperature - b.temperature,
      render: (temperature) =>
        temperature > 37.5 && temperature <= 38 ? (
          <Tag color="warning">{temperature} °C</Tag>
        ) : temperature >= 38 ? (
          <Tag color="error">{temperature} °C</Tag>
        ) : (
          <text>{temperature} °C</text>
        ),
    },
    {
      title: 'Frecuencia Respiratoria',
      dataIndex: 'breathingFrequency',
      key: 'breathingFrequency',
      width: 75,
      sorter: (a, b) => a.breathingFrequency - b.breathingFrequency,
      render: (breathingFrequency) =>
        breathingFrequency > 20 && breathingFrequency <= 30 ? (
          <Tag color="warning">{breathingFrequency}</Tag>
        ) : breathingFrequency >= 30 ? (
          <Tag color="error">{breathingFrequency}</Tag>
        ) : (
          <text>{breathingFrequency}</text>
        ),
    },
    {
      title: 'Observaciones',
      width: 120,
      dataIndex: 'observations',
      key: 'observations',
    },
    {
      title: 'Enfermero/a',
      width: 120,
      dataIndex: 'user',
      key: 'user',
      render: (user) => (
        <span>
          {user?.name} {user?.lastname}
        </span>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={controls}
        scroll={{ x: 660, y: scrollY ? scrollY : '70vh' }}
        pagination={false}
        bordered
        locale={{ emptyText: 'No hay controles' }}
        loading={
          !controls && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
      />
    </div>
  );
}
