import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  PageHeader,
  Radio,
  Card,
  Tooltip,
  notification,
} from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './OperationForm.scss';
import {
  addOperationApi,
  getOneOperationByIdApi,
  updateOperationApi,
} from '../../api/operation';
import { useSelector } from 'react-redux';
import { getAccessTokenApi } from '../../api/auth';
import Spin from '../Spin';

export default function OperationForm(props) {
  const { id, name, bed, type, operationId } = props.match.params;
  const path = props.match.path;

  const [loading, setLoading] = useState(false);
  const [operation, setOperation] = useState(props.location?.operation);
  const [operationForm] = Form.useForm();
  const userId = useSelector((store) => store.userData?.user?._id);

  useEffect(() => {
    const getData = async () => {
      if (
        operation === undefined &&
        path === '/edit-operation/:operationId/:name/:bed/:type'
      ) {
        const token = await getAccessTokenApi();
        getOneOperationByIdApi(operationId, token).then((response) => {
          setOperation(response.operation);
        });
      }
    };
    getData();
  }, []);

  const onFinish = async (values) => {
    const token = await getAccessTokenApi();

    setLoading(true);
    values.user = userId;

    if (path === '/new-operation/:id/:name/:bed/:type') {
      values.income = id;
      await addOperationApi(values, token)
        .then((response) => {
          if (response?.code !== 200) {
            notification['error']({ message: response.message });
            setLoading(false);
          } else {
            notification['success']({ message: response.message });
            setLoading(false);
            window.history.back();
          }
        })
        .catch(() => {
          notification['error']({
            message: 'Error del servidor, intente mas tarde.',
          });
          setLoading(false);
        });
    } else {
      await updateOperationApi(operationId, values, token)
        .then((response) => {
          if (response?.code !== 200) {
            notification['error']({ message: response.message });
            setLoading(false);
          } else {
            notification['success']({ message: response.message });
            setLoading(false);
            window.history.back();
          }
        })
        .catch(() => {
          notification['error']({
            message: 'Error del servidor, intente mas tarde.',
          });
          setLoading(false);
        });
    }
  };

  if (!operation && path === '/edit-operation/:operationId/:name/:bed/:type') {
    return <Spin />;
  }

  return (
    <div className="operation">
      <div className="operation-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={name}
          subTitle={`Procedencia: ${type} - Cama: ${bed}`}
        />
      </div>

      <Form name={operationForm} onFinish={onFinish}>
        <Card>
          <Row gutter={24}>
            <Col sm={12}>
              <Form.Item
                name="date"
                rules={[{ required: true, message: 'Debe cargar una fecha' }]}
                label="Fecha de Cirugia"
                initialValue={operation ? moment(operation?.date) : moment()}
                labelCol={{ span: 24 }}
              >
                <DatePicker
                  placeholder="Seleccione fecha de ingreso (*)"
                  inputReadOnly
                  style={{ width: '100%' }}
                  format="DD-MM-YYYY"
                  locale={locale}
                  showToday={false}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Item
                name="hourStart"
                initialValue={
                  operation?.hourStart && moment(operation.hourStart)
                }
                label="Hora inicio"
                labelCol={{ span: 24 }}
              >
                <DatePicker.TimePicker
                  placeholder="Inicio"
                  inputReadOnly
                  style={{ width: '100%' }}
                  format="HH:mm"
                  showTime={{ format: 'HH:mm' }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Item
                name="hourFinish"
                initialValue={
                  operation?.hourFinish && moment(operation.hourFinish)
                }
                label="Hora fin"
                labelCol={{ span: 24 }}
              >
                <DatePicker.TimePicker
                  placeholder="Fin"
                  inputReadOnly
                  style={{ width: '100%' }}
                  format="HH:mm"
                  showTime={{ format: 'HH:mm' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Row gutter={24}>
          <Col sm={24} md={12}>
            <Card
              title="Personal interviniente"
              headStyle={{
                textAlign: 'center',
                backgroundColor: '#1764ab',
                color: '#fff',
              }}
              style={{ minHeight: '59vh' }}
            >
              <Row gutter={24}>
                <Col sm={16}>
                  <Form.Item
                    name="surgeon"
                    initialValue={operation?.surgeon}
                    rules={[
                      {
                        required: true,
                        message: 'Debe cargar el nombre.',
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label="Cirujano"
                  >
                    <Input placeholder="Nombre (*)" />
                  </Form.Item>
                </Col>
                <Col sm={8}>
                  <Form.Item
                    name="mpSurgeon"
                    initialValue={operation?.mpSurgeon}
                    rules={[
                      {
                        required: true,
                        message: 'Debe cargar el nombre.',
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label="Matricula"
                  >
                    <Input type="number" inputMode="numeric" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.List name="helpers" initialValue={operation?.helpers}>
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Row gutter={24} key={index}>
                          <Col sm={16}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              label="Ayudante"
                              labelCol={{ span: 24 }}
                            >
                              <Input placeholder="Nombre (*)" />
                            </Form.Item>
                          </Col>

                          <Col sm={6}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'mp']}
                              fieldKey={[field.fieldKey, 'mp']}
                              label="Matricula"
                              labelCol={{ span: 24 }}
                            >
                              <Input type="number" inputMode="numeric" />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ paddingLeft: '0' }}>
                            <Tooltip title="Eliminar ayudante">
                              <Button
                                type="link"
                                size="large"
                                onClick={() => {
                                  remove(field.name);
                                }}
                              >
                                <MinusCircleOutlined />
                              </Button>
                            </Tooltip>
                          </Col>
                        </Row>
                      ))}
                      <Form.Item style={{ textAlign: 'right' }}>
                        <Tooltip title="Agregar ayudante">
                          <Button
                            type="default"
                            shape="circle"
                            onClick={() => {
                              add();
                            }}
                            size="large"
                          >
                            <PlusOutlined />
                          </Button>
                        </Tooltip>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
              <Row gutter={24}>
                <Col sm={10}>
                  <Form.Item
                    name="anesthetist"
                    initialValue={operation?.anesthetist}
                    labelCol={{ span: 24 }}
                    label="Anestesista"
                  >
                    <Input placeholder="Nombre (*)" />
                  </Form.Item>
                </Col>
                <Col sm={8}>
                  <Form.Item
                    name="typeAnethetist"
                    initialValue={operation?.typeAnethetist}
                    labelCol={{ span: 24 }}
                    label="Tipo anestesia"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <Form.Item
                    name="mpAnesthetist"
                    initialValue={operation?.mpAnesthetist}
                    labelCol={{ span: 24 }}
                    label="Matricula"
                  >
                    <Input type="number" inputMode="numeric" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col sm={10}>
                  <Form.Item
                    name="instrumenter"
                    initialValue={operation?.instrumenter}
                    labelCol={{ span: 24 }}
                    label="Instrumentador/a"
                  >
                    <Input placeholder="Nombre (*)" />
                  </Form.Item>
                </Col>
                <Col sm={8}>
                  <Form.Item
                    name="transfusion"
                    initialValue={operation?.transfusion}
                    labelCol={{ span: 24 }}
                    label="Transfusion"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <Form.Item
                    name="pathological"
                    initialValue={operation?.pathological}
                    labelCol={{ span: 24 }}
                    label="Anatomia patologica"
                  >
                    <Radio.Group>
                      <Radio value="Si">Si</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              title="Datos medicos"
              headStyle={{
                textAlign: 'center',
                backgroundColor: '#1764ab',
                color: '#fff',
              }}
              style={{ minHeight: '59vh' }}
            >
              <Row gutter={24}>
                <Col sm={9}>
                  <Form.Item
                    name="principalDiagnostic"
                    initialValue={operation?.principalDiagnostic}
                    rules={[
                      {
                        required: true,
                        message: 'Debe cargar el nombre.',
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label="Diagnostico principal"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={9}>
                  <Form.Item
                    name="operationDiagnostic"
                    initialValue={operation?.operationDiagnostic}
                    rules={[
                      {
                        required: true,
                        message: 'Debe cargar el nombre.',
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label="Diagnostico operatorio"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <Form.Item
                    name="arc"
                    initialValue={operation?.arc}
                    rules={[
                      {
                        required: true,
                        message: 'Debe cargar el nombre.',
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label="Uso de arco en C"
                  >
                    <Radio.Group>
                      <Radio value="Si">Si</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col sm={24}>
                  <Form.Item
                    name="description"
                    initialValue={operation?.description}
                    rules={[
                      {
                        required: true,
                        message: 'Debe escribir una descripción.',
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label="Descripción operatoria"
                  >
                    <Input.TextArea rows={8} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%', marginTop: '10px' }}
                disabled={loading}
                loading={loading}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
