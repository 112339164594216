import React, { useState } from 'react';
import IsoTipo from '../../../assets/img/a-iso-blanco-8.png';
import LogoNombre from '../../../assets/img/a-blanco-8.png';
import Enfermeria from '../../../assets/img/enfermeria.png';
import './MenuTopInfarmary.scss';
import { Button, Row, Tooltip, Dropdown, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
  DownOutlined,
  HistoryOutlined,
  MessageOutlined,
  WarningOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { onLogout } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MessageForm from '../../Message/MessageForm';
import Modal from '../../Modal';

export default function MenuTopInfarmary(props) {
  const [modalTitle, setModalTitle] = useState('');
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { menuCollapsed, setMenuCollapsed } = props;

  const { name, lastname } = useSelector((store) => store.userData?.user);

  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(onLogout());
  };

  const addMessage = (report = false) => {
    setIsVisibleModalForm(true);
    setModalTitle(report ? 'Reportar problema' : 'Nuevo mensaje');
    setModalContent(
      <MessageForm
        setIsVisibleModal={setIsVisibleModalForm}
        type="Enfermero"
        report={report}
      />
    );
  };

  return (
    <div className="menu-top-infarmary">
      <div className="menu-top-left">
        {!menuCollapsed ? (
          <img className="menu-top-left__logo" src={LogoNombre} alt="logo" />
        ) : (
          <img className="menu-top-left-mini__logo" src={IsoTipo} alt="logo" />
        )}
        <Button type="link" onClick={() => setMenuCollapsed(!menuCollapsed)}>
          {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <h1
        style={{
          color: '#fff',
          textAlign: 'center',
        }}
      >
        Enfermeria{' '}
        <img
          className="menu-top-left-mini__logo"
          style={{ height: '30px' }}
          src={Enfermeria}
          alt="logo"
        />
      </h1>
      <Row style={{ right: 0, position: 'absolute' }}>
        <div>
          <Dropdown
            style={{ padding: 0 }}
            trigger={['click']}
            overlay={
              <Menu
                theme="dark"
                mode="inline"
                style={{ backgroundColor: '#3e4b5e' }}
              >
                <Menu.Item key="/message">
                  <Button
                    type="link"
                    onClick={() => addMessage()}
                    style={{
                      color: '#fff',
                    }}
                  >
                    <MessageOutlined />
                    <span className="navText">Dejar Mensaje</span>
                  </Button>
                </Menu.Item>
                <Menu.Item key="/report">
                  <Button
                    type="link"
                    onClick={() => addMessage(true)}
                    style={{
                      color: '#fff',
                    }}
                  >
                    <WarningOutlined />
                    <span className="navText">Reportar problema</span>
                  </Button>
                </Menu.Item>
                <Menu.Item key="/configuration">
                  <Link to="/configuration">
                    <Button
                      type="link"
                      style={{
                        color: '#fff',
                      }}
                    >
                      <SettingOutlined />
                      <span className="navText">Configuración</span>
                    </Button>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/message-history">
                  <Link to="/message-history">
                    <Button
                      type="link"
                      style={{
                        color: '#fff',
                      }}
                    >
                      <HistoryOutlined />{' '}
                      <span className="navText">Historial Mensajes</span>
                    </Button>
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <Button type="link">
              {name} {lastname} <DownOutlined />
            </Button>
          </Dropdown>
          <Tooltip title="Cerrar sesión">
            <Button type="link" onClick={logoutUser}>
              <PoweroffOutlined />
            </Button>
          </Tooltip>
        </div>
      </Row>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
