import { basePath, apiVersion } from './config';

export async function getMonthBillings({ token, search }) {
  const url = `${basePath}/${apiVersion}/month-billing/${search && search}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function getBatchPeriodBillings({ token, search }) {
  const url = `${basePath}/${apiVersion}/batch-period-billing${search}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function getBilling({ token, id }) {
  const url = `${basePath}/${apiVersion}/billing/${id}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function getBillings({ token, search }) {
  const url = `${basePath}/${apiVersion}/billings/${search && search}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function getTurnUserBillingDone({
  token,
  turnUser,
  turnType,
  periodStart,
  periodEnd,
}) {
  const url = `${basePath}/${apiVersion}/billings-done?turnUser=${turnUser}&turnType=${turnType}&periodStart=${periodStart}&periodEnd=${periodEnd}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function addBillingApi({ data, token }) {
  const url = `${basePath}/${apiVersion}/billing`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function updateBillingApi({ data, token, id }) {
  const url = `${basePath}/${apiVersion}/billing/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function addChargedBillingApi({ data, token, id }) {
  const url = `${basePath}/${apiVersion}/charged-billing/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function addDetailBillingInTurn({ shiftId, turnId, token, data }) {
  const url = `${basePath}/${apiVersion}/billing-detail-turn/${turnId}&${shiftId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function getNomenclators(token) {
  const url = `${basePath}/${apiVersion}/nomenclators`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function getNomenclatorBySocialWork({ token, nomenclator }) {
  const url = `${basePath}/${apiVersion}/nomenclator/${nomenclator}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function deleteBillingApi({ token, id }) {
  const url = `${basePath}/${apiVersion}/delete-billing/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}
