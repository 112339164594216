import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleBody } from './styles';
import moment from 'moment';

export function PdfOperation({ operation, income, index }) {
  return (
    <View>
      <View style={styleBody.dataEvolution}>
        <Text style={styleBody.subtitle}>Operación {index}</Text>
        <Text style={styleBody.evolutionTitle}>Identificación</Text>
        <Text style={[styleBody.text, { textTransform: 'capitalize' }]}>
          {income.name} - Obra social: {income.socialWork}
        </Text>
        <Text style={styleBody.text}>
          Fecha de intervención: {moment(operation.date).format('DD/MM/YYYY')} -
          Hora inicio: {moment(operation.hourStart).format('HH:mm')} - Hora fin:{' '}
          {moment(operation.hourFinish).format('HH:mm')}
        </Text>
      </View>
      <View style={styleBody.dataEvolution}>
        <Text style={styleBody.evolutionTitle}>Personal interviniente</Text>
        <Text style={styleBody.text}>
          Cirujano: Dr. {operation.surgeon} - Matricula: {operation.mpSurgeon}
        </Text>
        {operation?.helpers?.map((helper, index) => (
          <Text style={styleBody.text}>
            Ayudante {index + 1}: Dr. {helper?.name} - Matricula: {helper?.mp}
          </Text>
        ))}
        <Text style={styleBody.text}>
          Anestesista: Dr. {operation.anesthetist} - Tipo anestesia:{' '}
          {operation.typeAnethetist} - Matricula: {operation.mpAnesthetist}
        </Text>
        <Text style={styleBody.text}>
          Instrumentador/a: {operation.instrumenter} - Transfusión:{' '}
          {operation.transfusion} - Anatomia patologica:{' '}
          {operation.pathological}
        </Text>
      </View>
      <View style={styleBody.dataEvolution}>
        <Text style={styleBody.evolutionTitle}>Datos medicos</Text>
        <Text style={styleBody.text}>
          Diagnostico principal: {operation.principalDiagnostic}
        </Text>
        <Text style={styleBody.text}>
          Diagnostico operatorio: {operation.operationDiagnostic}
        </Text>
        <Text style={styleBody.text}>Uso de arco en C: {operation.arc}</Text>
      </View>
      <View style={styleBody.dataEvolution}>
        <Text style={styleBody.evolutionTitle}>Descripción operatoria</Text>
        <Text style={styleBody.text}>{operation.description}</Text>
      </View>
    </View>
  );
}
