import React, { useState } from 'react';
import { Button, Card, Col, Row, Tooltip, Tag } from 'antd';
import moment from 'moment';
import '../../ListAndDetail/ViewInfo.scss';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { useEffect } from 'react';

export default function DyalisisTableControls(props) {
  const { data, editControl, deleteControl } = props;

  const [changeOrder, setChangeOrder] = useState(false);

  useEffect(() => {
    if (!editControl) {
      data.reverse();
      setChangeOrder(true);
    }
  }, []);

  let childCard = (hd) => (
    <>
      {hd.present &&
        (hd.cantHeparina ? (
          <Tag color="success">
            <CheckOutlined /> Heparina Indicada
          </Tag>
        ) : (
          <Tag color="error">
            <CloseOutlined /> Falta indicar heparina
          </Tag>
        ))}
      {editControl && (
        <p style={{ fontWeight: 'lighter', fontSize: '8pt' }}>
          Ult. Actualización: {hd.user?.name} {hd.user?.lastname}
        </p>
      )}
      <h4>Fecha {moment(hd?.initialParams?.hourStart).format('DD/MM/YY')}</h4>
      <Tag color={hd.present ? 'success' : 'error'}>
        {hd.present ? 'Presente' : 'Ausente'}
      </Tag>
      {hd.present && (
        <ul>
          <li>
            <h4>Parametros Iniciales</h4>
          </li>
          <ul>
            <li>
              Hora de inicio:{' '}
              {moment(hd?.initialParams?.hourStart).format('HH:mm')} hs
            </li>
            <li>Peso HD Anterior: {hd?.initialParams?.weightAnt} Kg</li>
            <li>Peso pre HD: {hd?.initialParams?.weightPre} Kg</li>
            <li>UF Prescripta: {hd?.initialParams?.uf}</li>
            <li>TA Ingreso: {hd?.initialParams?.taIngreso}</li>
            <li>
              Test Desinfeccion: {hd?.initialParams?.testDes ? 'OK' : 'No'}
            </li>
            <li>Filtro: {hd?.initialParams?.filter}</li>
            <li>Temperatura: {hd?.initialParams?.temperature} °C</li>
            <li>Tec Resp: {hd?.initialParams?.tecResp}</li>
          </ul>

          {hd?.params?.map((param, index) => (
            <>
              <li>
                <h4>Parametros {index + 1}.ª hora</h4>
              </li>
              <ul>
                <li>TAS / TAD: {param?.tas_tad}</li>
                <li>UF: {param?.uf}</li>
                <li>Presión Arterial: {param?.pArterial}</li>
                <li>Presión Venosa: {param?.pVenosa}</li>
                <li>Qb efectivo: {param?.qbEfec}</li>
              </ul>
            </>
          ))}

          {hd?.endParams && (
            <>
              <li>
                <h4>Parametros Finales</h4>
              </li>
              <ul>
                <li>
                  Hora fin: {moment(hd?.endParams?.hourEnd).format('HH:mm')} hs
                </li>
                <li>TA Egreso: {hd?.endParams?.taEgreso}</li>
                <li>
                  Peso post:{' '}
                  {hd?.endParams?.weightPost || hd?.initialParams?.weightPost}
                  Kg
                </li>
                <li>Temperatura: {hd?.endParams?.temperature} °C</li>
                <li>UF Final: {hd?.endParams?.uf}</li>
                <li>Ktv: {hd?.endParams?.ktv}</li>
              </ul>
            </>
          )}
        </ul>
      )}
    </>
  );

  return (
    <div>
      {editControl && (
        <h2 style={{ textAlign: 'center' }}>
          Controles dialisis
          <Tooltip title="Invertir Orden">
            <Button
              type="link"
              onClick={() => {
                data.reverse();
                setChangeOrder(!changeOrder);
              }}
            >
              <SwapOutlined />
            </Button>
          </Tooltip>
        </h2>
      )}
      <Row gutter={[24, 24]}>
        {data?.map((hd, index) =>
          editControl ? (
            <Col xs={24} md={12} lg={8}>
              <Card
                title={`Hemodialisis Nº ${
                  changeOrder ? index + 1 : data.length - index
                }`}
                extra={
                  editControl && (
                    <>
                      <Tooltip title="Eliminar control">
                        <Button
                          type="link"
                          onClick={() =>
                            deleteControl(
                              changeOrder ? index + 1 : data.length - index,
                              data[index]
                            )
                          }
                        >
                          <DeleteOutlined style={{ color: '#fff' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Editar control">
                        <Button
                          type="link"
                          style={{ color: '#fff' }}
                          onClick={() =>
                            editControl(
                              changeOrder ? index + 1 : data.length - index,
                              data[index]
                            )
                          }
                        >
                          <EditOutlined />
                        </Button>
                      </Tooltip>
                    </>
                  )
                }
                headStyle={{ backgroundColor: '#002a52', color: '#fff' }}
                bodyStyle={{ height: '40vh', overflow: 'auto' }}
                key={index}
              >
                {childCard(hd)}
              </Card>
            </Col>
          ) : (
            <Col xs={24}>{childCard(hd)}</Col>
          )
        )}
      </Row>
    </div>
  );
}
