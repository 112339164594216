import React, { useEffect, useState } from 'react';
import { getEvolutionForUserApi } from '../../api/evolution';
import { Button, Row, Col, Input, Select, DatePicker, Divider } from 'antd';
import { getAccessTokenApi } from '../../api/auth';
import { getUsersTypeApi } from '../../api/user';
import { ViewInfo } from '../ListAndDetail';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import Spin from '../Spin';

export function EvolutionsForUser() {
  const [users, setUsers] = useState();
  const [search, setSearch] = useState();
  const [selectUser, setSelectUser] = useState();
  const [evolutions, setEvolutions] = useState();
  const [month, setMonth] = useState(moment().toISOString());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getUsersTypeApi(token, true, 'Medico')
        .then((response) => {
          setUsers(response.users);
        })
        .catch(() => {});
    };
    getData();
  }, []);

  const generate = async () => {
    setLoading(true);
    const token = await getAccessTokenApi();
    var evolutions;
    await getEvolutionForUserApi(
      selectUser,
      search === '' ? undefined : search,
      token
    )
      .then((response) => {
        evolutions = response.evolutions;
      })
      .then(() => {
        const data = [];
        evolutions.map((evolution) => {
          if (
            moment(month)
              .startOf('month')
              .isSame(moment(evolution.date).startOf('month'), 'month')
          ) {
            data.push(evolution);
          }
        });

        return data;
      })
      .then((data) => {
        setEvolutions(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Row gutter={24}>
        <Col lg={3} md={8} sm={8} xs={8}>
          <DatePicker
            picker="month"
            locale={locale}
            placeholder="Fecha"
            onChange={(value) => {
              setMonth(moment(value).toISOString());
            }}
            defaultValue={moment()}
          />
        </Col>
        <Col lg={9} md={16} sm={24}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Seleccione un medico.. (*)"
            optionFilterProp="children"
            onChange={(value) => setSelectUser(value)}
          >
            {users?.map((user, index) => (
              <Select.Option key={index} value={user._id}>
                {user.name} {user.lastname}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={9} md={16} sm={24}>
          <Input
            placeholder="Nombre medico (en caso de elegir interconsulta).."
            onChange={(e) => setSearch(e.target.value)}
            allowClear
          />
        </Col>
        <Col lg={3} md={8} sm={24}>
          <Button
            type="default"
            onClick={() => generate()}
            style={{ width: '100%' }}
          >
            Generar
          </Button>
        </Col>
      </Row>
      {loading && <Spin />}
      {evolutions && (
        <div>
          <h1 style={{ textAlign: 'center', padding: 5 }}>
            Cantidad {moment(month).format('MMMM-YYYY')} : {evolutions.length}
          </h1>
          {evolutions?.map((data) => (
            <>
              <p style={{ paddingLeft: 5 }}>
                Paciente: {data?.income.name} - Obra Soical:{' '}
                {data?.income.socialWork} - Lugar: {data?.incomeType}
              </p>
              <ViewInfo data={data} />
              <Divider style={{ backgroundColor: 'black' }} />
            </>
          ))}
        </div>
      )}
    </div>
  );
}
