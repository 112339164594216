import React, { useEffect, useState } from 'react';
import { Form, Button, Input, Checkbox, Row, Col, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { onMessageCreate } from '../../actions';
import PermissionsAsigner from '../PermissionsAsigner';
import { ROLES } from '../../utils/constants';

export default function MessageForm(props) {
  const { setIsVisibleModal, type, report, turn } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [messageForm] = Form.useForm();

  const { name, lastname, email, _id } = useSelector(
    (store) => store.userData.user
  );
  const { organizationType } = useSelector((store) => store.tenantData);

  useEffect(() => {
    messageForm.resetFields();
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    values.author = `${name} ${lastname}`;
    values.userType = type;
    values.type = report
      ? 'Reporte error'
      : values?.important
      ? 'Comunicado Institucional'
      : !values.type
      ? type
      : 'Enfermero';
    values.userType = type;
    values.report = report;
    values.email = email;
    values.turn = turn ? true : false;
    values.userId = turn || _id;
    dispatch(onMessageCreate(values, setIsVisibleModal, type));
    messageForm.resetFields();

    setLoading(false);
  };

  return (
    <Form form={messageForm} onFinish={onFinish}>
      <Form.Item
        name="description"
        rules={[{ required: true, message: 'Debe ingresar un mensaje' }]}
      >
        <Input.TextArea
          placeholder={
            report ? 'Explique el problema o error...' : 'Mensaje...'
          }
          autoSize={{ minRows: 4 }}
        />
      </Form.Item>

      {!report && (
        <Row gutter={24}>
          {!turn && (
            <PermissionsAsigner requiredRole={ROLES.OWNER}>
              <Tooltip title="El mensaje sera enviado a todos los usuarios de la institución">
                <Form.Item
                  name="important"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox
                    onChange={() =>
                      messageForm.setFieldsValue({
                        type: false,
                        turn: false,
                        fixed: true,
                      })
                    }
                  >
                    Comunicado Institucional
                  </Checkbox>
                </Form.Item>
              </Tooltip>
            </PermissionsAsigner>
          )}
          <Col>
            <Tooltip title="Al fijar el mensaje solo lo podra resolver usted">
              <Form.Item name="fixed" valuePropName="checked">
                <Checkbox
                  onChange={() => messageForm.setFieldsValue({ type: false })}
                >
                  Fijar mensaje
                </Checkbox>
              </Form.Item>
            </Tooltip>
          </Col>
          {!turn && organizationType === 'hospitalaria' && (
            <PermissionsAsigner requiredRole={ROLES.SECRETARY}>
              <Tooltip title="El mensaje sera visualizado por los enfermeros">
                <Form.Item name="type" valuePropName="checked">
                  <Checkbox
                    onChange={(e) =>
                      messageForm.setFieldsValue({
                        fixed: false,
                        turn: false,
                        type: e.target.checked ? 'Enfermero' : false,
                        important: false,
                      })
                    }
                  >
                    Mandar a enfermería
                  </Checkbox>
                </Form.Item>
              </Tooltip>
            </PermissionsAsigner>
          )}
        </Row>
      )}

      <Form.Item>
        <Button
          type="default"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={loading}
          disabled={loading}
        >
          Enviar Mensaje
        </Button>
      </Form.Item>
    </Form>
  );
}
