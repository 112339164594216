import {
  ON_INCOME_ACTIVE_READ,
  ON_INCOME_ACTIVE_READING,
  ON_INCOME_HISTORY_READ,
  ON_INCOME_HISTORY_READING,
  ON_INCOME_HISTORY_SEARCH_READING,
  ON_INCOME_HISTORY_SEARCH_READ,
  ON_INCOME_VALUE_CHANGE,
} from '../actions/types';

const INITIAL_STATE = {
  incomesActive: [],
  incomesHistory: [],
  count: 0,
  search: '',
  searchType: '',
  searchSocialWork: undefined,
  searchDateStart: undefined,
  searchDateEnd: undefined,
  page: 1,
  loadingActive: false,
  loadingHistory: false,
  loadingSearch: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_INCOME_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_INCOME_ACTIVE_READING:
      return { ...state, loadingActive: true };
    case ON_INCOME_ACTIVE_READ:
      return { ...state, incomesActive: action.payload, loadingActive: false };
    case ON_INCOME_HISTORY_READING:
      return { ...state, loadingHistory: true };
    case ON_INCOME_HISTORY_READ:
      return {
        ...state,
        incomesHistory: action.payload.incomes,
        count: action.payload.count,
        loadingHistory: false,
      };
    case ON_INCOME_HISTORY_SEARCH_READING:
      return { ...state, loadingSearch: true };
    case ON_INCOME_HISTORY_SEARCH_READ:
      return {
        ...state,
        incomesHistory: action.payload.incomes,
        count: action.payload.count,
        loadingSearch: false,
      };

    default:
      return state;
  }
};
