import { combineReducers, createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { loadState, saveState } from './config';
import UserDataReducer from './UserDataReducer';
import { ON_LOGOUT_FINISHED } from '../actions/types';
import BedDataReducer from './BedDataReducer';
import IncomeDataReducer from './IncomeDataReducer';
import CityDataReducer from './CityDataReducer';
import SocialWorkDataReducer from './SocialWorkDataReducer';
import PhoneDataReducer from './PhoneDataReducer';
import MessageDataReducer from './MessageDataReducer';
import DiagnosticDischargeReducer from './DiagnosticDischargeReducer';
import PatientDataReducer from './PatientDataReducer';
import TenantDataReducer from './TenantDataReducer';
import PharmacyDataReducer from './PharmacyDataReducer';
import SearchData from './SearchData';

const initialData = loadState() || {};

const reducers = combineReducers({
  userData: UserDataReducer,
  bedData: BedDataReducer,
  incomeData: IncomeDataReducer,
  cityData: CityDataReducer,
  socialWorkData: SocialWorkDataReducer,
  phoneData: PhoneDataReducer,
  messageData: MessageDataReducer,
  diagnosticData: DiagnosticDischargeReducer,
  patientData: PatientDataReducer,
  tenantData: TenantDataReducer,
  pharmacyData: PharmacyDataReducer,
  searchData: SearchData,
});

const rootReducer = (state, action) => {
  if (action.type === ON_LOGOUT_FINISHED) {
    state = undefined;
  }

  return reducers(state, action);
};

const store = createStore(
  rootReducer,
  initialData,
  applyMiddleware(ReduxThunk)
);

store.subscribe(function () {
  saveState(store.getState());
});

export default store;
