import React, { useEffect } from 'react';
import { Select, Form, Button, Modal } from 'antd';
import { onBedRead, onIncomeRollback } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';

export default function RollBackIncomeForm(props) {
  const { income, setIsVisibleModal } = props;
  const [rollbackForm] = Form.useForm();

  const dispatch = useDispatch();

  const { beds } = useSelector((store) => store.bedData);
  const { count } = useSelector((store) => store.incomeData);

  useEffect(() => {
    dispatch(onBedRead(income.type));
    rollbackForm.resetFields();
  }, [income]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Volver a internar paciente',
      content: `¿ Estas seguro que quieres volver a internar a ${income.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(
          onIncomeRollback(income._id, values, setIsVisibleModal, count)
        );
      },
    });
  };

  return (
    <Form form={rollbackForm} onFinish={onFinish}>
      <Form.Item
        name="bed"
        rules={[
          {
            required: true,
            message: 'Debe seleccionar una cama',
          },
        ]}
      >
        <Select
          placeholder="Seleccione una cama.. (*)"
          showSearch
          optionFilterProp="children"
          notFoundContent="No hay camas disponibles"
        >
          {beds
            ? beds.map((bed, index) => (
                <Select.Option key={index} value={bed._id}>
                  {bed.name}
                </Select.Option>
              ))
            : null}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="default" htmlType="submit" style={{ width: '100%' }}>
          Confirmar
        </Button>
      </Form.Item>
    </Form>
  );
}
