import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button } from 'antd';
import { onPhoneCreate, onPhoneUpdate } from '../../actions';
import { useDispatch } from 'react-redux';

export default function AddPhone(props) {
  const { setIsVisibleModal, phone } = props;
  const [phoneForm] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    phoneForm.resetFields();
  });

  const onFinish = (values) => {
    if (!phone) {
      dispatch(onPhoneCreate(values, setIsVisibleModal));
    } else {
      dispatch(onPhoneUpdate(phone._id, values, setIsVisibleModal));
    }
  };

  return (
    <div>
      <Form form={phoneForm} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="name"
              initialValue={phone && phone.name}
              rules={[{ required: true, message: 'Debe cargar nombre.' }]}
            >
              <Input placeholder="Nombre (*)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="number"
              initialValue={phone && phone.number}
              rules={[{ required: true, message: 'Debe cargar el número.' }]}
            >
              <Input placeholder="Número (*)" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
