import { basePath, apiVersion } from './config';

export async function getTurnApi(token, user, date) {
  const url = `${basePath}/${apiVersion}/turn/${user}&${date}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function addTurnApi({ token, data }) {
  const url = `${basePath}/${apiVersion}/turn`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function editTurnApi({ shiftId, turnId, token, dataTurn }) {
  const url = `${basePath}/${apiVersion}/edit-turn/${turnId}&${shiftId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(dataTurn),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function updateTurnApi({ token, turnId, dataTurn }) {
  const url = `${basePath}/${apiVersion}/turn/${turnId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(dataTurn),
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function updateStatusTurnApi({
  shiftId,
  turnId,
  status,
  token,
  important,
}) {
  const url = `${basePath}/${apiVersion}/update-turn-status/${turnId}&${shiftId}?status=${status}&important=${important}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function deleteTurnApi({
  shiftId,
  turnId,
  token,
  infoDelete,
  email,
  exclude,
}) {
  const url = `${basePath}/${apiVersion}/delete-turn/${turnId}&${shiftId}?infoDelete=${infoDelete}&email=${email}&exclude=${exclude}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function moveTurnApi({ token, turnId, shiftId, dataTurn }) {
  const url = `${basePath}/${apiVersion}/move-turn/${turnId}&${shiftId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(dataTurn),
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function getFeriadosApi(year) {
  const url = `http://nolaborables.com.ar/api/v2/feriados/${year} `;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getMonthTurnsApi(token, user, date) {
  const url = `${basePath}/${apiVersion}/month-turns/${user}&${date}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function changeScheduleDayApi({ token, turnId, scheduleDay }) {
  const url = `${basePath}/${apiVersion}/change-schedule-day/${turnId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(scheduleDay),
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function getHistoryTurnsPatientApi({ token, patient }) {
  const url = `${basePath}/${apiVersion}/history-turns-patient/${patient}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
