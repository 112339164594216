import React from 'react';

import './IncomeView.scss';

export default function IncomeEpicrisis({ income, signature }) {
  return (
    <div className="income-view">
      <p>
        <text>Epicrisis: </text> {income.epicrisis}
      </p>
      {income.userEpicrisis && (
        <div
          style={{
            textAlign: 'right',
            marginTop: '1%',
            marginRight: '2%',
          }}
        >
          <img style={{ width: '18%', height: '10%' }} src={signature} />
          <h6
            style={{
              textAlign: 'right',
              marginTop: '1%',
              marginRight: '2%',
              textTransform: 'capitalize',
            }}
          >
            {income.userEpicrisis.name} {income.userEpicrisis.lastname}
          </h6>
        </div>
      )}
    </div>
  );
}
