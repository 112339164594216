import React, { useEffect, useState } from 'react';
import { Row, Col, Statistic, Card, notification } from 'antd';
import { getGeneralStatsApi } from '../../api/stats';
import { getAccessTokenApi } from '../../api/auth';
import { LoadingOutlined } from '@ant-design/icons';

export function GeneralStatsIncomes({ start, end, floor, generate }) {
  const [stats, setStats] = useState();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();

      setLoading(true);
      await getGeneralStatsApi({ token, start, end })
        .then((response) => {
          if (response?.code !== 200) {
            notification(['error'])({ message: response.message });
          } else {
            setStats(response.stats);
            response?.stats?.total > 0 && setVisible(true);
            setLoading(false);
          }
        })
        .catch((err) =>
          notification(['error'])({ message: 'Error del servidor' })
        );
    };

    getData();
  }, [generate]);

  return (
    <div>
      {visible && (
        <div style={{ marginTop: '2%' }}>
          {loading && (
            <div style={{ textAlign: 'center' }}>
              <span>Cargando...</span> <LoadingOutlined />
            </div>
          )}
          <Row gutter={24} style={{ marginBottom: '2%' }}>
            <Col md={12} lg={8}>
              <Card style={{ height: '100%' }}>
                <Statistic
                  title="Cantidad de dias en el periodo elegido: "
                  value={stats.daysPeriod}
                  valueStyle={{ color: '#3f8600' }}
                  groupSeparator="."
                />
              </Card>
            </Col>
            <Col md={12} lg={8}>
              <Card style={{ height: '100%' }}>
                <Statistic
                  title={'Total de pacientes en el periodo:'}
                  value={stats.totalIncomes}
                  valueStyle={{ color: '#3f8600' }}
                  groupSeparator="."
                />
              </Card>
            </Col>
            <Col md={12} lg={8}>
              <Card style={{ height: '100%' }}>
                <Statistic
                  title="Promedio de estadia de pacientes: "
                  value={
                    stats.totalIncomes !== 0
                      ? stats.promedioEstadia.toFixed(2)
                      : 0
                  }
                  suffix="dias"
                  valueStyle={{ color: '#3f8600' }}
                  decimalSeparator=","
                />
              </Card>
            </Col>
            <Col md={12} lg={8}>
              <Card style={{ height: '100%' }}>
                <Statistic
                  title={'Promedio diario de pacientes:'}
                  value={stats.censoDiario.toFixed(2)}
                  valueStyle={{ color: '#3f8600' }}
                  decimalSeparator=","
                />
              </Card>
            </Col>
            <Col md={12} lg={8}>
              <Card style={{ height: '100%' }}>
                <Statistic
                  title="Promedio diario de camas disponibles: "
                  value={stats.disponibilidadCamas.toFixed(2)}
                  valueStyle={{ color: '#3f8600' }}
                  decimalSeparator=","
                />
              </Card>
            </Col>
            <Col md={12} lg={8}>
              <Card style={{ height: '100%' }}>
                <Statistic
                  title="Porcentaje de ocupación de camas: "
                  value={stats.porcentajeOcup.toFixed(2)}
                  valueStyle={
                    stats.porcentajeOcup < 85
                      ? { color: '#3f8600' }
                      : { color: '#cf1322' }
                  }
                  suffix={'%'}
                  decimalSeparator=","
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
