import { basePath, apiVersion } from './config';

export async function getControlApi(id, token) {
  const url = `${basePath}/${apiVersion}/control/${id}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function addControlApi(controlData, token) {
  const url = `${basePath}/${apiVersion}/control`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(controlData),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function getDyalisisControlApi(id, token, month) {
  const url = `${basePath}/${apiVersion}/dyalisis-control/${id}?month=${month}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function addDyalisisControlApi(controlData, token) {
  const url = `${basePath}/${apiVersion}/dyalisis-control`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(controlData),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function updateDyalisisControlApi({
  controlData,
  token,
  id,
  view,
  paramId,
}) {
  const url = `${basePath}/${apiVersion}/dyalisis-control/${id}?type=${view}&paramId=${paramId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(controlData),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function deleteDyalisisControlApi({ token, id, user }) {
  const url = `${basePath}/${apiVersion}/delete-dyalisis-control/${id}?user=${user}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}
