import React, { useEffect, useState } from 'react';
import { Input, Button } from 'antd';
import './Phones.scss';
import ListPhones from '../../components/Phones/ListPhones';
import Modal from '../../components/Modal';
import AddPhone from '../../components/Phones/AddPhone';
import { useDispatch } from 'react-redux';
import { onPhoneRead } from '../../actions';

export default function Phones() {
  const [search, setSearch] = useState('');
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onPhoneRead());
  }, []);

  const addPhone = () => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar teléfono`);
    setModalContent(<AddPhone setIsVisibleModal={setIsVisibleModal} />);
  };

  return (
    <div className="phones">
      <div className="phones-header">
        <Input.Search
          placeholder="Buscar teléfono.."
          onSearch={(value) => setSearch(value)}
          allowClear
          enterButton
        />
        <Button type="primary" onClick={addPhone}>
          Nuevo Teléfono
        </Button>
      </div>

      <ListPhones search={search} />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
