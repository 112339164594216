import { Document } from '@react-pdf/renderer';
import React from 'react';
import { PageIncome } from './PageIncome';

export default function DocumentPass({ incomes }) {
  return (
    <Document>
      {incomes.map((income) => (
        <PageIncome income={income} title="Ingreso" news={true} />
      ))}
    </Document>
  );
}
