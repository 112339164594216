import React, { useEffect, useState } from 'react';
import TableControls from '../../components/Infarmary/TableControls';
import {
  BalanceView,
  BalanceCalculate,
} from '../../components/Infarmary/Balances';
import Modal from '../../components/Modal';
import { Button, Row, Col, Card, PageHeader, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './ViewInfarmary.scss';
import { getBalanceApi } from '../../api/balance';
import { getAccessTokenApi } from '../../api/auth';
import { getNoteApi } from '../../api/note';
import { ViewInfo } from '../../components/ListAndDetail';
import { Link } from 'react-router-dom';
import {
  AuditOutlined,
  DiffOutlined,
  MedicineBoxOutlined,
} from '@ant-design/icons';
import Spin from '../../components/Spin';

export default function ViewInfarmary(props) {
  const { id } = props.match.params;
  const [balances, setBalances] = useState();
  const [balanceData, setBalanceData] = useState({
    id: '',
    date: '',
    ingresos: [],
    egresos: [],
  });
  const [dataNotes, setDataNotes] = useState();
  const [order, setOrder] = useState('desc');
  const [reload, setReload] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const { incomesActive } = useSelector((store) => store.incomeData);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getBalanceApi(id, token)
        .then((response) => {
          setBalances(response.balances);
          setBalanceData({
            id: response.balances[0]._id,
            date: response.balances[0].date,
            ingresos: response.balances[0].ingreso,
            egresos: response.balances[0].egreso,
          });
        })
        .catch((err) => {});

      getNoteApi(id, order, token)
        .then((response) => {
          setDataNotes(response.notes);
        })
        .catch((err) => {});
    };
    getData();
  }, [id, reload]);

  const calculateBalance = () => {
    setIsVisibleModal(true);
    setModalTitle(`Calcular balance a ${income.name}`);
    setModalContent(
      <BalanceCalculate
        balances={balances}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        date={income.date}
      />
    );
  };

  var income = {};
  incomesActive.find((i) => {
    if (i._id === id) {
      income = i;
    }
  });

  if (!dataNotes) {
    return <Spin />;
  }

  return (
    <div className="view-infarmary">
      <div className="view-infarmary-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`Cama: ${income.bed.name}`}
          subTitle={
            <Tooltip
              title={`Cama: ${income.bed.name} ${income.name} - ${
                income.age
              } años | Fecha Ingreso: ${moment(income.date).format(
                'DD/MM/YYYY'
              )}`}
            >
              {income.name} - {income.age} años | Fecha Ingreso:{' '}
              {moment(income.date).format('DD/MM/YYYY')}
            </Tooltip>
          }
          extra={[
            <Tooltip title="Indicaciones">
              <Link to={`/medicine/${income._id}`}>
                <Button type="link">
                  <MedicineBoxOutlined />
                </Button>
              </Link>
            </Tooltip>,
            <Tooltip title="Evoluciones">
              <Link to={`/evolution/${income._id}`}>
                <Button type="link">
                  <AuditOutlined />
                </Button>
              </Link>
            </Tooltip>,
            <Tooltip title="Calcular Balance">
              <Button type="link" onClick={() => calculateBalance()}>
                <DiffOutlined />
              </Button>
            </Tooltip>,
          ]}
        />
      </div>
      <TableControls
        id={id}
        reload={reload}
        setReload={setReload}
        scrollY={'40vh'}
      />

      <div className="view-infarmary__footer">
        <Row gutter={24}>
          <Col md={12} sm={24} xs={24}>
            <>
              <BalanceView
                balanceData={balanceData}
                setReload={setReload}
                id={id}
              />
            </>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Card
              title="Notas de enfermería"
              headStyle={{ textAlign: 'center' }}
              style={{
                borderColor: '#002a52',
                overflowY: 'scroll',
                height: '80vh',
              }}
            >
              {dataNotes?.length > 0 ? (
                <div className="view-info">
                  {dataNotes.map((data) => (
                    <ViewInfo data={data} />
                  ))}
                </div>
              ) : (
                <span
                  style={{
                    textAlign: 'center',
                    display: 'inherit',
                    color: 'grey',
                  }}
                >
                  No existen notas
                </span>
              )}
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
