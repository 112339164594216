import { getAccessTokenApi } from '../api/auth';
import { getPatientApi } from '../api/patient';
import {
  ON_PATIENT_VALUE_CHANGE,
  ON_PATIENT_READ,
  ON_PATIENT_READING,
  ON_FAVOURITE_PATIENT_READ,
} from './types';
import { notification } from 'antd';
import { addFavouritePatients, getFavouritePatients } from '../api/user';
import { onUserDataRead } from './UserDataActions';

export const onPatientValueChange = (payload) => {
  return { type: ON_PATIENT_VALUE_CHANGE, payload };
};

export const onPatientFilterRead =
  ({ searchPatient }) =>
  async (dispatch) => {
    dispatch({ type: ON_PATIENT_READING });
    const token = await getAccessTokenApi();

    return await getPatientApi(token, searchPatient)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['warning']({
            message: 'No se han encontrado coincidencias',
            duration: 1,
          });
        } else {
          if (response?.patients?.length === 0) {
            notification['warning']({
              message: 'No se han encontrado coincidencias',
              duration: 1,
            });
          }
        }
        await dispatch({
          type: ON_PATIENT_READ,
          payload: {
            patients: response.patients,
            searchPatient: response.search || '',
          },
        });
      })
      .catch((err) => {
        return err;
      });
  };

export const onPatientFavouriteRead = () => async (dispatch) => {
  dispatch({ type: ON_PATIENT_READING });
  const token = await getAccessTokenApi();

  return await getFavouritePatients(token)
    .then(async (response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        response.favouritePatients.length === 0 &&
          notification['warning']({ message: 'No tiene pacientes favoritos' });
        await dispatch({
          type: ON_FAVOURITE_PATIENT_READ,
          payload: {
            patients: response.favouritePatients,
          },
        });
      }
    })
    .catch((err) => {
      return err;
    });
};

export const onAddPatientFavourite =
  (patientId, type, searchPatient) => async (dispatch) => {
    const token = await getAccessTokenApi();

    return addFavouritePatients(token, patientId, type)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          await dispatch(onUserDataRead());
          !searchPatient && (await dispatch(onPatientFavouriteRead()));
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };
