import { ON_CITY_READ, ON_CITY_READING } from './types';
import { getCitiesApi, addCityApi, deleteCityApi } from '../api/city';
import { notification } from 'antd';
import { getAccessTokenApi } from '../api/auth';

export const onCityRead = (token) => async (dispatch) => {
  let token = await getAccessTokenApi();

  dispatch({ type: ON_CITY_READING });

  return getCitiesApi(token).then(async (response) => {
    await dispatch({ type: ON_CITY_READ, payload: response.cities });
  });
};

export const onCityCreate =
  (cityData, setIsVisibleModal) => async (dispatch) => {
    let token = await getAccessTokenApi();
    return addCityApi(cityData, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          await dispatch(onCityRead());
          setIsVisibleModal(false);
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onCityDelete = (id) => async (dispatch) => {
  let token = await getAccessTokenApi();
  return deleteCityApi(id, token)
    .then(async (response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        await dispatch(onCityRead());
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};
