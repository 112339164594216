import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Select,
  Input,
  Radio,
  Card,
  Tooltip,
  notification,
  Checkbox,
} from 'antd';
import { getAccessTokenApi } from '../../api/auth';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { getMedicineDayApi, suspendActionApi } from '../../api/medicine';

export default function InfarmaryActionsForm(props) {
  const {
    incomeId,
    medicineId,
    path,
    actions,
    change,
    setChange,
    actionForm,
    action,
    setAction,
  } = props;
  const [extra, setExtra] = useState(false);

  useEffect(() => {
    action === undefined &&
      actionForm.setFieldsValue({
        action: [{}],
      });
  }, []);

  const suspendAction = async (id, status) => {
    let token = await getAccessTokenApi();

    await suspendActionApi(medicineId, action[id]._id, status, incomeId, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({
            message: response.message,
          });
        } else {
          notification['success']({
            message: response.message,
          });
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });

    getMedicineDayApi(medicineId, token).then((response) => {
      setAction(response.medicine.action);
      var action = response.medicine.action;

      action &&
        actionForm.setFieldsValue({
          action,
        });
      setAction(action);
    });
  };

  return (
    <div>
      <Form.List name="action">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Card
                  title={`Acción Enfermería ${index + 1}`}
                  headStyle={
                    action && action[field.name]?.suspend
                      ? {
                          textAlign: 'center',
                          backgroundColor: '#6f080e',
                          color: '#fff',
                        }
                      : {
                          textAlign: 'center',
                          backgroundColor: '#1764ab',
                          color: '#fff',
                        }
                  }
                  extra={
                    <>
                      {path ===
                        '/new-medicine/:incomeId/:incomeType?/:medicineId?' ||
                      action.length - 1 < field.name ? (
                        <Tooltip title="Eliminar indicación">
                          <Button
                            type="link"
                            size="large"
                            onClick={() => {
                              remove(field.name);
                            }}
                            style={{ color: '#fff' }}
                          >
                            <CloseOutlined />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Form.Item
                          {...field}
                          name={[field.name, 'suspend']}
                          fieldKey={[field.fieldKey, 'suspend']}
                        >
                          <Tooltip
                            title={
                              change &&
                              'Guarde los cambios realizados para poder suspender'
                            }
                          >
                            <Checkbox
                              onChange={(e) => {
                                suspendAction(field.name, e.target.checked);
                              }}
                              style={{ color: '#fff' }}
                              defaultChecked={
                                action ? action[field.name]?.suspend : false
                              }
                              disabled={change}
                            >
                              Suspender
                            </Checkbox>
                          </Tooltip>
                        </Form.Item>
                      )}
                    </>
                  }
                >
                  <Row gutter={24} key={index}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'action']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir una acción',
                          },
                        ]}
                        label="Seleccione una acción"
                        labelCol={{ span: 24 }}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Seleccione un acción.. (*)"
                          optionFilterProp="children"
                          onChange={() => setChange(true)}
                        >
                          {actions?.map((action, index) => (
                            <Select.Option key={index} value={action._id}>
                              {action.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'extra']}
                        label="Seleccione en caso de ser necesario"
                        initialValue={'Habitual'}
                        labelCol={{ span: 24 }}
                      >
                        <Radio.Group
                          onChange={(e) => {
                            setChange(true);
                            setExtra(
                              e.target.value === 'Unica Dosis' ? true : false
                            );
                          }}
                        >
                          <Radio value="Habitual">Habitual</Radio>
                          <Radio value="Unica Dosis">Unica Dosis</Radio>
                          <Radio value="SOS">SOS</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'time']}
                        label="Cada"
                        labelCol={{ span: 24 }}
                      >
                        <Input
                          disabled={
                            actionForm.getFieldValue().action[field.name] &&
                            actionForm.getFieldValue().action[field.name]
                              .extra === 'Unica Dosis'
                          }
                          type="number"
                          inputMode="numeric"
                          min={0}
                          max={24}
                          suffix="hs"
                          onChange={() => setChange(true)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'hour']}
                        label="Horarios"
                        labelCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Indicar horarios. Ej: 8 - 16 - 24"
                          onChange={() => setChange(true)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'description']}
                        label="Observaciones"
                        labelCol={{ span: 24 }}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 1 }}
                          onChange={() => setChange(true)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              ))}
              <Form.Item style={{ textAlign: 'right', marginTop: '5px' }}>
                <Tooltip title="Agregar indicación">
                  <Button
                    type="default"
                    shape="circle"
                    onClick={() => {
                      add();
                    }}
                    size="large"
                  >
                    <PlusOutlined />
                  </Button>
                </Tooltip>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
}
