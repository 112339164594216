import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button, notification } from 'antd';
import { addDrugApi } from '../../api/drug';
import { addInfusionApi } from '../../api/infusion';
import { addActionInfarmaryApi } from '../../api/actionInfarmary';
import { getAccessTokenApi } from '../../api/auth';

export default function DrugForm(props) {
  const { setIsVisibleModal, getDrug, infusion, action } = props;
  const [drugForm] = Form.useForm();

  useEffect(() => {
    drugForm.resetFields();
  });

  const onFinish = async (values) => {
    let token = await getAccessTokenApi();
    infusion
      ? addInfusionApi(values, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              getDrug();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          })
      : action
      ? addActionInfarmaryApi(values, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              getDrug();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          })
      : addDrugApi(values, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              getDrug();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
  };

  return (
    <div>
      <Form form={drugForm} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              name="name"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: 'Debe cargar el nombre.',
                },
              ]}
            >
              <Input placeholder="Nombre (*)" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
