import React, { useEffect, useState } from 'react';
import BillingList from '../../components/Billing/BillingList';
import { getAccessTokenApi } from '../../api/auth';
import { getBatchPeriodBillings, getMonthBillings } from '../../api/billing';
import { useSelector } from 'react-redux';
import moment from 'moment';

export default function BillingBatchDetails(props) {
  const { type } = props.match.params;

  const [billingData, setBillingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const { search, searchYear } = useSelector((store) => store.searchData);
  const { viewBillingOrganizations } = useSelector(
    (store) => store.userData?.user
  );

  useEffect(() => {
    let getData = async () => {
      setLoading(true);

      let token = await getAccessTokenApi();
      type === 'month' || type === 'socialWork'
        ? await getMonthBillings({
            token,
            search:
              type === 'month'
                ? `?year=${
                    searchYear || moment().format('YYYY')
                  }&organization=${
                    search != '' || search?.length !== 0
                      ? search
                      : viewBillingOrganizations
                  }`
                : props?.location?.search,
          }).then((response) => setBillingData(response?.billingData))
        : await getBatchPeriodBillings({
            token,
            search: props?.location?.search,
          }).then((response) => setBillingData(response?.billingData));

      setLoading(false);
      setReload(false);
    };

    getData();
  }, [type, reload, search, searchYear]);

  return (
    <div>
      <BillingList
        billingData={billingData}
        loading={loading}
        type={type}
        setReload={setReload}
      />
    </div>
  );
}
