import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { styleBody } from './styles';
import Signature1 from '../../assets/img/firma1.png';
import Signature2 from '../../assets/img/firma2.png';

export function PdfFooter({ type }) {
  return (
    <View style={styleBody.pageFooter} fixed>
      <Text
        style={styleBody.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
      {type === 'cardio' && (
        <View
          style={{
            flexDirection: 'row',
            width: '40%',
          }}
        >
          <View
            style={{ flexDirection: 'column', width: '50%', marginBottom: 0 }}
          >
            <Image src={Signature1} style={styleBody.signatureImageFooter} />
          </View>
          <View style={{ flexDirection: 'column', width: '50%' }}>
            <Image src={Signature2} style={styleBody.signatureImageFooter} />
          </View>
        </View>
      )}
    </View>
  );
}
