import React, { useState, useEffect } from 'react';
import { Select, Form, Button, Modal as ModalAntd } from 'antd';
import { onIncomeDischarge, onDiagnosticRead } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  dischargeDyalisisReasons,
  dischargeReasons,
} from '../../utils/constants';
import DiagnosticForm from './DiagnosticForm';
import Modal from '../Modal';

export default function DischargeForm(props) {
  const { income, setIsVisibleModal, incomeType } = props;
  const [modalTitle, setModalTitle] = useState('');
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [dischargeForm] = Form.useForm();

  const { diagnostics } = useSelector((store) => store.diagnosticData);
  const dispatch = useDispatch();

  useEffect(() => {
    dischargeForm.resetFields();
  }, [income]);

  useEffect(() => {
    dispatch(onDiagnosticRead());
  }, []);

  const onFinish = (values) => {
    if (!income.epicrisis) {
      ModalAntd.confirm({
        title: 'Dar alta paciente',
        content: `Para dar de alta a un paciente debe realizar la epicrisis antes ¿ Quiere ir a realizar la epicrisis de ${income.name} ?`,
        okText: 'Hacer epicrisis',
        okType: 'default',
        cancelText: 'Cancelar',
        onOk() {
          window.location.href = `/#add-epicrisis/${income._id}`;
          setIsVisibleModal(false);
        },
      });
    } else {
      dispatch(
        onIncomeDischarge(values, income._id, incomeType, setIsVisibleModal)
      );
    }
  };

  const addDiagnostic = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nuevo diagnostico de alta');
    setModalContent(
      <DiagnosticForm setIsVisibleModal={setIsVisibleModalForm} />
    );
  };

  return (
    <div>
      <Form form={dischargeForm} onFinish={onFinish}>
        <Form.Item
          name="dischargeDiagnostic"
          rules={[
            { required: true, message: 'Debe cargar el diagnostico de alta' },
          ]}
        >
          <Select
            placeholder="Seleccione un diagnostico de alta (*)"
            showSearch
            optionFilterProp="children"
          >
            <Select.Option disabled>
              <Button type="link" onClick={addDiagnostic}>
                + Nuevo Diagnostico
              </Button>
            </Select.Option>
            {diagnostics &&
              diagnostics.map((diagnostic, index) => (
                <Select.Option key={index} value={diagnostic.name}>
                  {diagnostic.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="dischargeReason"
          rules={[{ required: true, message: 'Debe cargar el motivo de alta' }]}
        >
          <Select
            placeholder="Motivo de alta..."
            showSearch
            optionFilterProp="children"
          >
            {income.bed.bedType === 'Dialisis'
              ? dischargeDyalisisReasons.map((reason, index) => (
                  <Select.Option value={reason.name} key={index}>
                    {reason.name}
                  </Select.Option>
                ))
              : dischargeReasons.map((reason, index) => (
                  <Select.Option value={reason.name} key={index}>
                    {reason.name}
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="default" htmlType="submit" style={{ width: '100%' }}>
            Dar Alta
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
