import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleTable } from './styles';

export function PdfTableDyalisisHeader() {
  return (
    <View style={[styleTable.row, styleTable.header]} fixed>
      <Text style={styleTable.column8}>HD Nº</Text>
      <Text style={styleTable.column3}>{`Fecha/Hora\nIncio`}</Text>
      <Text style={styleTable.column7}>{`Peso\nInicial`}</Text>
      <Text style={styleTable.column7}>{`Peso\nFinal`}</Text>
      <Text style={styleTable.column7}>TA Inicial</Text>
      <Text style={styleTable.column7}>TA Final</Text>
      <Text style={styleTable.column3}>{`Acceso\nVascular`}</Text>
      <Text style={styleTable.column7}>{`Heparina\nTotal`}</Text>
      <Text style={styleTable.column7}>{`Reporta\nIncidente\nGrave`}</Text>
      <Text style={styleTable.column7}>{`Control\nBacteorologico`}</Text>
      <Text style={styleTable.column7}>Hora Fin</Text>
      <Text style={styleTable.column3}>{`Firma\nMedico`}</Text>
    </View>
  );
}
