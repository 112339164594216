import React, { useState, useEffect } from 'react';
import { Form, Button, Input, DatePicker, notification } from 'antd';
import { useSelector } from 'react-redux';
import { addNoteApi } from '../../../api/note';
import { getAccessTokenApi } from '../../../api/auth';
import moment from 'moment';

export default function NotesForm(props) {
  const { incomeId, setIsVisibleModal, setReload, discharge } = props;
  const [noteForm] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const id = useSelector((store) => store.userData?.user?._id);

  useEffect(() => {
    noteForm.resetFields();
  }, [incomeId]);

  const onFinish = async (values) => {
    if (moment(values.date).isAfter(discharge)) {
      notification['warning']({
        message: 'La fecha debe coincidir con los dias que estuvo internado.',
      });
      return;
    }
    setLoading(true);
    values.user = id;
    values.income = incomeId;
    let token = await getAccessTokenApi();
    addNoteApi(values, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
          setLoading(false);
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          setReload(true);
          noteForm.resetFields();
          setLoading(false);
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
        setLoading(false);
      });
  };

  return (
    <Form form={noteForm} onFinish={onFinish}>
      {discharge && (
        <Form.Item
          name="date"
          rules={[{ required: true, message: 'Debe cargar una fecha' }]}
          label="Fecha del control"
          labelCol={{ span: 24 }}
          initialValue={moment()}
        >
          <DatePicker
            placeholder="Seleccione fecha de la nota (*)"
            style={{ width: '100%' }}
            format="DD-MM HH:mm"
            showTime={{ format: 'HH:mm' }}
            onChange={() => {
              if (moment().isSameOrBefore(noteForm.getFieldValue('date'))) {
                notification['warning']({
                  message:
                    'No se puede elegir una fecha/hora mayor a la actual.',
                });
                noteForm.setFieldsValue({ date: moment() });
              }
            }}
          />
        </Form.Item>
      )}
      <Form.Item
        name="description"
        rules={[{ required: true, message: 'Debe ingresar la nota' }]}
      >
        <Input.TextArea
          placeholder="Decripción de la nota..."
          autoSize={{ minRows: 18 }}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="default"
          htmlType="submit"
          style={{ width: '100%' }}
          disabled={loading}
          loading={loading}
        >
          Agregar Nota
        </Button>
      </Form.Item>
    </Form>
  );
}
