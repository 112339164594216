import React, { useEffect, useState } from 'react';
import { Row, List, Input, Col, Tag, Tooltip, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../api/auth';
import { getMedicineStatsApi } from '../../api/stats';

export function MedicineForMonth({ start, end, floor, generate }) {
  const [dataChart, setDataChart] = useState();
  const [searchDrugs, setSearchDrugs] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let getData = async () => {
      let token = await getAccessTokenApi();
      setLoading(true);
      await getMedicineStatsApi({ token, floor, start, end })
        .then((response) => {
          if (response?.code !== 200) {
            notification['info']({ message: response.message });
            setLoading(false);
          } else {
            setDataChart(response.dataChart);
            setLoading(false);
          }
        })
        .catch((err) => {
          notification['error']({ message: 'Error del servidor' });
          setLoading(false);
        });
    };

    getData();
  }, [generate]);

  if (searchDrugs) {
    var drugsFilters = dataChart.filter((drug) =>
      drug._id.name
        .toLowerCase()
        .trim()
        .includes(searchDrugs.toLowerCase().trim())
    );
  }

  return (
    <>
      <Row gutter={24}>
        <Col xs={24}>
          <Input.Search
            enterButton
            placeholder="Buscar drogas.."
            onSearch={(value) => setSearchDrugs(value)}
            allowClear
          />
        </Col>
      </Row>
      <List
        className="socialWorks-list"
        itemLayout="horizontal"
        dataSource={searchDrugs ? drugsFilters : dataChart}
        renderItem={(drug) => <Drug drug={drug} />}
        pagination={{ pageSize: 100 }}
        loading={
          loading && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
      />
    </>
  );
}

function Drug(props) {
  const { drug } = props;

  return (
    <List.Item>
      <List.Item.Meta
        title={drug._id.name}
        description={
          <ul>
            <li>
              Se indicaron: {drug.cantTime} {drug._id.unity} - Marcado como
              dado: {drug.cantChecked} {drug._id.unity}
            </li>
            <li>
              Cantidad de veces indicada: {drug.quantity}
              {drug.suspend > 0 &&
                ` | Se suspendio
                ${drug.suspend} 
                ${drug.suspend > 1 ? 'veces' : 'vez'}`}
            </li>
          </ul>
        }
      />
    </List.Item>
  );
}
