import { ON_SOCIAL_WORK_READ, ON_SOCIAL_WORK_READING } from './types';
import {
  getSocialWorksApi,
  addSocialWorkApi,
  deleteSocialWorkApi,
  updateSocialWorkApi,
} from '../api/socialWork';
import { getAccessTokenApi } from '../api/auth';
import { notification } from 'antd';

export const onSocialWorkRead = () => async (dispatch) => {
  dispatch({ type: ON_SOCIAL_WORK_READING });
  const token = await getAccessTokenApi();

  return getSocialWorksApi(token).then((response) => {
    dispatch({ type: ON_SOCIAL_WORK_READ, payload: response.socialWorks });
  });
};

export const onSocialWorkCreate =
  (socialWorkData, setIsVisibleModal) => async (dispatch) => {
    const token = await getAccessTokenApi();

    return addSocialWorkApi(token, socialWorkData)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          await dispatch(onSocialWorkRead());
          setIsVisibleModal(false);
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onSocialWorkAddNomenclator =
  ({ socialWorkData, setIsVisibleModal, id }) =>
  async (dispatch) => {
    const token = await getAccessTokenApi();
    console.log(socialWorkData);

    return updateSocialWorkApi(token, socialWorkData, id)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          await dispatch(onSocialWorkRead());
          setIsVisibleModal(false);
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onSocialWorkDelete = (id) => async (dispatch) => {
  let token = await getAccessTokenApi();
  return deleteSocialWorkApi(id, token)
    .then(async (response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        await dispatch(onSocialWorkRead());
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};
