import React, { useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Select,
  Input,
  Card,
  Tooltip,
  Checkbox,
  notification,
} from 'antd';
import { getAccessTokenApi } from '../../api/auth';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { getMedicineDayApi, suspendInfusionApi } from '../../api/medicine';
import { units } from '../../utils/constants';

export default function InfusionForm(props) {
  const {
    incomeId,
    medicineId,
    path,
    infusiones,
    drugs,
    change,
    setChange,
    infusionForm,
    infusion,
    setInfusion,
  } = props;

  useEffect(() => {
    infusion === undefined &&
      infusionForm.setFieldsValue({
        infusion: [{}],
      });
  }, []);

  const suspendInfusion = async (id, status) => {
    let token = await getAccessTokenApi();

    await suspendInfusionApi(
      medicineId,
      infusion[id]._id,
      status,
      incomeId,
      token
    )
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({
            message: response.message,
          });
        } else {
          notification['success']({
            message: response.message,
          });
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });

    getMedicineDayApi(medicineId, token).then((response) => {
      setInfusion(response.medicine.infusion);
      var infusion = response.medicine.infusion;

      infusion &&
        infusionForm.setFieldsValue({
          infusion,
        });
      setInfusion(infusion);
    });
  };

  return (
    <div>
      <Form.List name="infusion">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Card
                  title={`Infusión ${index + 1}`}
                  headStyle={
                    infusion && infusion[field.name]?.suspend
                      ? {
                          textAlign: 'center',
                          backgroundColor: '#6f080e',
                          color: '#fff',
                        }
                      : {
                          textAlign: 'center',
                          backgroundColor: '#1764ab',
                          color: '#fff',
                        }
                  }
                  extra={
                    <>
                      {path ===
                        '/new-medicine/:incomeId/:incomeType?/:medicineId?' ||
                      infusion.length - 1 < field.name ? (
                        <Tooltip title="Eliminar indicación">
                          <Button
                            type="link"
                            size="large"
                            onClick={() => {
                              remove(field.name);
                            }}
                            style={{ color: '#fff' }}
                          >
                            <CloseOutlined />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Form.Item
                          {...field}
                          name={[field.name, 'suspend']}
                          fieldKey={[field.fieldKey, 'suspend']}
                        >
                          <Tooltip
                            title={
                              change &&
                              'Guarde los cambios realizados para poder suspender'
                            }
                          >
                            <Checkbox
                              onChange={(e) => {
                                suspendInfusion(field.name, e.target.checked);
                              }}
                              style={{ color: '#fff' }}
                              value={infusion && infusion[field.name]?.suspend}
                              defaultChecked={
                                infusion ? infusion[field.name]?.suspend : false
                              }
                              disabled={change}
                            >
                              Suspender
                            </Checkbox>
                          </Tooltip>
                        </Form.Item>
                      )}
                    </>
                  }
                >
                  <Row gutter={24} key={index}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'infusion']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir una infusión',
                          },
                        ]}
                        label="Seleccione una infusión"
                        labelCol={{ span: 24 }}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Seleccione una infusión.. (*)"
                          optionFilterProp="children"
                          onChange={() => setChange(true)}
                        >
                          {infusiones?.map((infusion, index) => (
                            <Select.Option key={index} value={infusion._id}>
                              {infusion.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={8} md={8} lg={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'quantity']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir la dosis.',
                          },
                        ]}
                        label="Cantidad"
                        labelCol={{ span: 24 }}
                        initialValue={500}
                      >
                        <Input
                          type="number"
                          inputMode="numeric"
                          min={0}
                          suffix="ml"
                          onChange={() => setChange(true)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={8} md={8} lg={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'ritmo']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir la dosis.',
                          },
                        ]}
                        label="Ritmo de Infusión"
                        labelCol={{ span: 24 }}
                        initialValue={21}
                      >
                        <Input
                          type="number"
                          inputMode="numeric"
                          min={0}
                          onChange={() => setChange(true)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'unity']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir la unidad.',
                          },
                        ]}
                        label="Unidad de medida"
                        labelCol={{ span: 24 }}
                        initialValue={'ml/h'}
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder="Seleccione.. (*)"
                          optionFilterProp="children"
                          onChange={() => setChange(true)}
                        >
                          <Select.Option value={'ml/h'}>ml/h</Select.Option>
                          <Select.Option value={'Microgota'}>
                            Microgota
                          </Select.Option>
                          <Select.Option value={'Macrogota'}>
                            Macrogota
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'drug']}
                        label="Seleccione droga a diluir"
                        labelCol={{ span: 24 }}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Seleccione un medicamento.. (*)"
                          optionFilterProp="children"
                          onChange={() => setChange(true)}
                        >
                          {drugs?.map((drug, index) => (
                            <Select.Option key={index} value={drug._id}>
                              {drug.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'dosis']}
                        label="Dosis"
                        labelCol={{ span: 24 }}
                      >
                        <Input
                          type="number"
                          min={0}
                          onChange={() => setChange(true)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'unityDrug']}
                        label="Unidad de medida"
                        labelCol={{ span: 24 }}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Seleccione.. (*)"
                          optionFilterProp="children"
                          onChange={() => setChange(true)}
                        >
                          {units.map((unit) => (
                            <Select.Option value={unit.name}>
                              {unit.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'description']}
                        label="Observaciones"
                        labelCol={{ span: 24 }}
                      >
                        <Input.TextArea
                          placeholder="Observaciones sobre la infusión. Ej: otra droga a diluir.."
                          autoSize={{ minRows: 2 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              ))}

              <Form.Item style={{ textAlign: 'right', marginTop: '5px' }}>
                <Tooltip title="Agregar indicación">
                  <Button
                    type="default"
                    shape="circle"
                    onClick={() => {
                      add();
                    }}
                    size="large"
                  >
                    <PlusOutlined />
                  </Button>
                </Tooltip>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
}
