import {
  ON_PHARMACY_READ,
  ON_PHARMACY_READING,
  ON_PHARMACY_VALUE_CHANGE,
  ON_PHARMACY_SEARCH_READ,
  ON_PHARMACY_SEARCH_READING,
} from '../actions/types';

const INITIAL_STATE = {
  movements: [],
  count: 0,
  search: '',
  searchStore: undefined,
  searchProvider: undefined,
  searchDateStart: undefined,
  searchDateEnd: undefined,
  page: 1,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_PHARMACY_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_PHARMACY_READING:
      return { ...state, loading: true };
    case ON_PHARMACY_READ:
      return {
        ...state,
        movements: action.payload.movements,
        count: action.payload.count,
        loading: false,
      };
    case ON_PHARMACY_SEARCH_READING:
      return { ...state, loading: true };
    case ON_PHARMACY_SEARCH_READ:
      return {
        ...state,
        movements: action.payload.movements,
        count: action.payload.count,
        loading: false,
      };
    default:
      return state;
  }
};
