import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { notification } from 'antd';
import Spin from '../Spin';
import { getPieStatsApi, getTurnsStatsApi } from '../../api/stats';
import { getAccessTokenApi } from '../../api/auth';

export function PieChart({
  start,
  end,
  floor,
  type,
  userId,
  generate,
  status,
  chartTitle,
  percent,
  statsType,
  dyalisis,
  studieType,
}) {
  const [dataChart, setDataChart] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      setLoading(true);
      statsType === 'amb'
        ? getTurnsStatsApi({
            token,
            type,
            id: userId,
            status,
            start,
            end,
            studieType,
          }).then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              setDataChart(response.dataChart);
              setLoading(false);
            }
          })
        : getPieStatsApi({ token, type, start, end, floor, dyalisis }).then(
            (response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
              } else {
                setDataChart(response.dataChart);
                setLoading(false);
              }
            }
          );
    };

    getData();
  }, [generate, type]);

  if (loading)
    return (
      <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <span>Cargando...</span>
        <Spin />
      </div>
    );

  return (
    <Chart
      width="auto"
      height="250px"
      chartType="PieChart"
      loader={<Spin />}
      data={dataChart}
      options={{
        title: chartTitle,
        sliceVisibilityThreshold: percent ? percent / 100 : 0.02,
        pieResidueSliceLabel: 'Otros',
      }}
      rootProps={{ 'data-testid': '2' }}
    />
  );
}
