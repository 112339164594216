import React, { useState } from 'react';
import { Form, Col, Row, Button, Card, InputNumber } from 'antd';

export function Imc() {
  const [imc, setImc] = useState();

  const calculateImc = (values) => {
    var imc = (values.weigth / Math.pow(values.heigth, 2)).toFixed(2);
    if (imc <= 18.5) {
      imc += ' - Peso insuficiente';
    } else if (18.5 < imc && imc <= 24.99) {
      imc += ' - Normopeso';
    } else if (25 <= imc && imc <= 26.99) {
      imc += ' - Sobrepeso grado I';
    } else if (27 <= imc && imc <= 29.99) {
      imc += ' - Sobrepeso grado II (preobesidad)';
    } else if (30 <= imc && imc <= 34.99) {
      imc += ' - Obesidad de tipo I';
    } else if (35 <= imc && imc <= 39.99) {
      imc += ' - Obesidad de tipo II';
    } else if (40 <= imc && imc <= 49.99) {
      imc += ' - Obesidad de tipo III (mórbida)';
    } else {
      imc += ' - Obesidad de tipo IV (extrema)';
    }
    setImc(imc);
  };

  return (
    <Row gutter={24}>
      <Col lg={6}></Col>
      <Col lg={12}>
        <Form onFinish={calculateImc}>
          <Card
            title="Ídice de Masa Corporal"
            headStyle={{ textAlign: 'center' }}
          >
            <Col span={24}>
              <Form.Item
                name="weigth"
                label="Peso"
                rules={[
                  {
                    required: true,
                    message: 'Debe cargar el peso.',
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="74"
                  suffix="Kg"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="heigth"
                label="Talla"
                rules={[
                  {
                    required: true,
                    message: 'Debe cargar la talla.',
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="1.72"
                  suffix="m"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="default"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  Calcular
                </Button>
              </Form.Item>
            </Col>
            {imc && <h1>IMC: {imc}</h1>}
          </Card>
        </Form>
      </Col>
      <Col lg={6}></Col>
    </Row>
  );
}
