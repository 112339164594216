import React, { useEffect, useState } from 'react';
import { TableChart } from '../../components/Charts';
import { deleteBillingApi, getBilling } from '../../api/billing';
import { getAccessTokenApi } from '../../api/auth';
import { Row, Tooltip, Button, message, Modal as ModalAntd } from 'antd';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Spin from '../../components/Spin';
import BackButton from '../../components/BackButton';
import PermissionsAsigner from '../../components/PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import BillingDetailNotIn from '../../components/Billing/BillingDetailNotIn';
import Modal from '../../components/Modal';

export default function BillingDetail(props) {
  const { id } = props.match.params;

  const [billingData, setBillingData] = useState();
  const [turnsDetail, setTurnsDetail] = useState();

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      await getBilling({ token, id }).then((response) => {
        setBillingData(response.billingData);
        setTurnsDetail(response.turnsDetail);
      });
    };

    getData();
  }, []);

  if (!billingData || !turnsDetail) return <Spin />;

  const deleteBilling = async () => {
    const token = await getAccessTokenApi();
    ModalAntd.confirm({
      title: 'Eliminar liquidación',
      content: `¿ Estas seguro que quieres eliminar la liquidación ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        deleteBillingApi({ token, id })
          .then((response) => {
            if (response?.code !== 200) {
              message.error(response.message);
            } else {
              message.success(response.message);
              window.history.back();
            }
          })
          .catch(() => {
            message.error('Error del servidor, intente mas tarde.');
          });
      },
    });
  };

  const viewDetail = () => {
    setIsVisibleModal(true);
    setModalTitle('Detalle turnos no liquidados');
    setModalContent(<BillingDetailNotIn billingId={id} />);
  };

  return (
    <div>
      <Row>
        <BackButton />
        <PermissionsAsigner requiredRole={ROLES.OWNER}>
          <Button
            type="link"
            style={{ right: '10px', position: 'absolute', color: 'red' }}
            onClick={() => deleteBilling()}
          >
            Anular Liquidación
          </Button>
        </PermissionsAsigner>
      </Row>
      <TableChart
        viewDetail={viewDetail}
        data={turnsDetail}
        start={billingData.period.start}
        end={billingData.period.end}
        userName={billingData.turnType}
        selectedSocialWorks={billingData.socialWork}
        practice={billingData.practice}
        charged={billingData.billingCharged}
        responsible={billingData.user}
        billingDate={billingData.billingDate}
        sendColumns={[
          {
            title: '#',
            dataIndex: 'rank',
            key: 'rank',
            width: '2%',
            align: 'center',
          },
          {
            title: 'Fecha',
            dataIndex: 'hourStart',
            key: 'hourStart',
            align: 'center',
            render: (hourStart) => (
              <span>{moment(hourStart).format('DD/MM/YY HH:mm')}</span>
            ),
            sorter: (a, b) =>
              moment(a.hourStart).unix() - moment(b.hourStart).unix(),
          },
          {
            title: 'Paciente',
            dataIndex: 'patientName',
            key: 'patientName',
            render: (patientName) => (
              <span style={{ textTransform: 'capitalize' }}>{patientName}</span>
            ),
          },
          {
            title: 'Obra Social',
            dataIndex: 'socialWork',
            key: 'socialWork',
            sorter: (a, b) => a.socialWork?.localeCompare(b.socialWork),
          },
          {
            title: 'Nro de Afiliado',
            dataIndex: 'affiliate',
            key: 'affiliate',
            sorter: (a, b) => a?.affiliate?.localeCompare(b?.affiliate),
          },
          {
            title: 'Estudio',
            dataIndex: 'studies',
            key: 'studies',
            render: (studies) => <span>{studies?.name}</span>,
            sorter: (a, b) => a.studies?.localeCompare(b.studies),
          },
          {
            title: 'Solicitante',
            dataIndex: 'medicoSolicitante',
            key: 'medicoSolicitante',
            sorter: (a, b) =>
              a.medicoSolicitante?.localeCompare(b.medicoSolicitante),
          },
          {
            title: 'Efector',
            dataIndex: 'medicoEfector',
            key: 'medicoEfector',
            render: (text, record, index) =>
              record.medicoEfector
                ? record.medicoEfector
                : record.medicoTurnera,
            sorter: (a, b) => a.medicoEfector?.localeCompare(b.medicoEfector),
          },
          {
            title: 'Orden médica',
            dataIndex: 'medicalOrder',
            key: 'medicalOrder',
            width: '5%',
            align: 'center',
            render: (medicalOrder) =>
              medicalOrder ? (
                <Tooltip title="Presento orden médica">
                  <CheckCircleOutlined style={{ color: 'green' }} />
                </Tooltip>
              ) : (
                <Tooltip title="No presento orden médica">
                  <CloseCircleOutlined style={{ color: 'red' }} />
                </Tooltip>
              ),
          },
          {
            title: 'Destacado',
            dataIndex: 'important',
            key: 'important',
            align: 'center',
            width: '5%',
            render: (important) => (
              <span>{important && <CheckOutlined />}</span>
            ),
          },
        ]}
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'75%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
