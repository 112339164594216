import React, { useState, useEffect } from 'react';
import { Input, Button, List, Switch, Tooltip, Form } from 'antd';
import PatientForm from '../../components/Ambulatory/PatientForm';
import Modal from '../../components/Modal';
import PatientList from '../../components/Ambulatory/PatientList';
import { useDispatch, useSelector } from 'react-redux';
import {
  onPatientFavouriteRead,
  onPatientFilterRead,
  onPatientValueChange,
} from '../../actions';
import '../Ambulatory/Ambulatory.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function Ambulatory() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const { patients, searchPatient, searchFavourite, loading } = useSelector(
    (store) => store.patientData
  );
  const dispatch = useDispatch();
  const [ambulatoryForm] = Form.useForm();

  useEffect(() => {
    !searchFavourite &&
      isVisibleModal === false &&
      dispatch(
        onPatientFilterRead({
          searchPatient,
        })
      );
    ambulatoryForm.setFieldsValue({ search: searchPatient });
  }, [searchPatient, searchFavourite, isVisibleModal]);

  useEffect(() => {
    searchFavourite && dispatch(onPatientFavouriteRead());
  }, [searchFavourite]);

  if (searchFavourite && searchPatient.length) {
    var patientFilters = patients.filter(
      (patient) =>
        patient.name
          .toLowerCase()
          .trim()
          .includes(searchPatient?.toLowerCase().trim()) ||
        patient.dni.toString().includes(searchPatient)
    );
  }

  const addPatient = () => {
    setIsVisibleModal(true);
    setModalTitle('Nuevo Paciente');
    setModalContent(<PatientForm setIsVisibleModal={setIsVisibleModal} />);
  };

  return (
    <div className="ambulatory">
      <div className="ambulatory__header">
        <div className="ambulatory__header-switch">
          <Switch
            checked={searchFavourite}
            onChange={() =>
              dispatch(
                onPatientValueChange({ searchFavourite: !searchFavourite })
              )
            }
          />
          <span>
            {searchFavourite
              ? `Mis Pacientes (${patients?.length || 0})`
              : 'Todos'}
          </span>
        </div>
        <div className="ambulatory__header-search">
          <Form form={ambulatoryForm}>
            <Form.Item name="search" style={{ padding: 0, margin: 0 }}>
              <Input.Search
                enterButton
                placeholder={
                  searchFavourite
                    ? 'Buscar en mis pacientes..'
                    : 'Buscar pacientes... (Presione enter o  click en la lupita para buscar)'
                }
                onSearch={(value) => {
                  !searchFavourite &&
                    dispatch(onPatientValueChange({ searchPatient: value }));
                }}
                onChange={(e) => {
                  (searchFavourite || e.target.value.length === 0) &&
                    dispatch(
                      onPatientValueChange({ searchPatient: e.target.value })
                    );
                }}
                defaultValue={searchPatient}
                allowClear
                loading={loading}
                prefix={
                  <Tooltip
                    title={
                      searchFavourite
                        ? 'Solo esta buscando sobre sus pacientes marcados como favoritos'
                        : 'Esta buscando sobre todos los pacientes'
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Form>
        </div>

        <Button type="primary" onClick={() => addPatient()}>
          Nuevo Paciente
        </Button>
      </div>

      <List
        dataSource={patientFilters ? patientFilters : patients}
        renderItem={(patient) => <PatientList patient={patient} />}
        pagination={{ pageSize: 20, size: 'small' }}
        locale={
          searchPatient
            ? {
                emptyText: 'No se han encontrado coincidencias...',
              }
            : { emptyText: 'Debe buscar un paciente...' }
        }
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'80%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
