import React, { useState, useEffect } from 'react';

const TimeSince = ({ startDate }) => {
  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    // Función para calcular el tiempo transcurrido
    const calculateElapsedTime = () => {
      const now = new Date();
      const start = new Date(startDate);
      const diff = now - start;

      const seconds = Math.floor((diff / 1000) % 60);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const hours = Math.floor(diff / 1000 / 60 / 60);

      hours > 0
        ? setElapsedTime(` ${hours}h ${minutes}m ${seconds}s`)
        : setElapsedTime(` ${minutes}m ${seconds}s`);
    };

    // Ejecutar la función una vez al inicio y luego cada segundo
    calculateElapsedTime();
    const interval = setInterval(calculateElapsedTime, 1000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
  }, [startDate]);

  return <span>{elapsedTime}</span>;
};

export default TimeSince;
