import React, { useState } from 'react';
import { Col, Card, Row, Button, Tooltip, Input } from 'antd';
import {
  CloseOutlined,
  ContainerOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  SwapOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

export function PageListAndView(props) {
  const {
    datesTitle,
    order,
    setOrder,
    viewAll,
    componentDate,
    detailTitle,
    data,
    dataAll,
    componentDetail,
    extra,
    extraDate,
    setSearch,
  } = props;

  const [searchView, setSearchView] = useState();

  return (
    <div>
      <Row gutter={24}>
        <Col md={8} sm={24} xs={24}>
          <Card
            title={datesTitle}
            style={{
              borderColor: '#002a52',
              overflowY: 'scroll',
              height: '75vh',
            }}
            extra={
              order ? (
                searchView ? (
                  <>
                    <Input.Search
                      enterButton
                      placeholder="Buscar.."
                      onSearch={(value) => setSearchView(value)}
                      onChange={(e) =>
                        (e.target.value.length === 0 ||
                          e.target.value.length >= 3) &&
                        setSearch(e.target.value)
                      }
                      allowClear
                      addonAfter={
                        <Tooltip title="Cerrar">
                          <Button
                            type="link"
                            onClick={() => {
                              setSearchView(false);
                              setSearch('');
                            }}
                          >
                            <CloseOutlined />
                          </Button>
                        </Tooltip>
                      }
                    />
                  </>
                ) : (
                  <>
                    {setSearch && (
                      <Tooltip title="Buscar">
                        <Button
                          type="link"
                          size="large"
                          onClick={() => setSearchView(true)}
                        >
                          <SearchOutlined />
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip title="Cambiar orden">
                      <Button
                        type="link"
                        size="large"
                        onClick={() =>
                          order === 'asc' ? setOrder('desc') : setOrder('asc')
                        }
                      >
                        {order === 'asc' ? (
                          <SortAscendingOutlined />
                        ) : (
                          <SortDescendingOutlined />
                        )}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Ver todos">
                      <Button type="link" onClick={() => viewAll()}>
                        <ContainerOutlined />
                      </Button>
                    </Tooltip>
                  </>
                )
              ) : (
                extraDate && <div> {extraDate} </div>
              )
            }
          >
            {componentDate}
          </Card>
        </Col>
        <Col md={16} sm={24} xs={24}>
          <Card
            title={detailTitle}
            headStyle={{ textAlign: 'center' }}
            style={{
              borderColor: '#002a52',
              overflowY: 'scroll',
              height: '75vh',
            }}
            extra={extra}
          >
            {data ? (
              <div className="view-info">{componentDetail}</div>
            ) : dataAll ? (
              dataAll
            ) : (
              <span
                style={{
                  textAlign: 'center',
                  display: 'inherit',
                  color: 'grey',
                }}
              >
                {componentDetail}
                No se han encontrado datos...
              </span>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
