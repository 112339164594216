import React, { useState, useEffect, createContext } from 'react';
import jwtDecode from 'jwt-decode';
import {
  getAccessTokenApi,
  getRefreshTokenApi,
  refreshAccessTokenApi,
  logout,
} from '../api/auth';
import { onFloorsRead, onPrimaryDataRead } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
export const AuthContext = createContext();

export default function AuthProvider(props) {
  const { children } = props;
  const [user, setUser] = useState({
    user: null,
    isLoading: true,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    checkUserLogin(setUser, user, dispatch);
  }, []);

  user?.user !== null &&
    setInterval(function () {
      const refreshToken = getRefreshTokenApi();
      if (!refreshToken) {
        logout();
        setUser({
          user: null,
          isLoading: false,
        });
      }
    }, 60000);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

async function checkUserLogin(setUser, user, dispatch) {
  const accessToken = await getAccessTokenApi();
  if (!accessToken) {
    const refreshToken = getRefreshTokenApi();
    if (!refreshToken) {
      user?.user && logout();
      setUser({
        user: null,
        isLoading: false,
      });
      return dispatch(onPrimaryDataRead());
    }
    return dispatch(onFloorsRead());
  } else {
    setUser({
      isLoading: false,
      user: jwtDecode(accessToken),
    });
    dispatch(onFloorsRead());
  }
}
