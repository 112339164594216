import React from 'react';
import { Card, Statistic, Progress, Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import './CardOcupation.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function CardOcupation(props) {
  const { title, data } = props;

  return (
    <div className="ocupations">
      <Card
        title={title}
        extra={
          <Link to={`/income/${data?._id}`}>
            <Tooltip title="Ver Piso">
              <Button
                type="link"
                style={{ color: '#fff', margin: 0, padding: 0 }}
              >
                <InfoCircleOutlined />
              </Button>
            </Tooltip>
          </Link>
        }
      >
        <Statistic
          value={data?.ocupate}
          valueStyle={
            ((data?.ocupate * 100) / data?.quantity).toFixed(0) < 75
              ? { color: '#3f8600' }
              : ((data?.ocupate * 100) / data?.quantity).toFixed(0) < 85
              ? { color: '#d6d100' }
              : { color: '#cf1322' }
          }
          prefix={
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.45)',
                fontSize: '0.8em',
                padding: 5,
              }}
            >
              Ocupación
            </span>
          }
          suffix={`/ ${data?.quantity}`}
        />
        <Progress
          percent={((data?.ocupate * 100) / data?.quantity).toFixed(0)}
          strokeColor={
            ((data?.ocupate * 100) / data?.quantity).toFixed(0) < 75
              ? '#3f8600'
              : ((data?.ocupate * 100) / data?.quantity).toFixed(0) < 85
              ? '#d6d100'
              : '#cf1322'
          }
          status="normal"
        />
      </Card>
    </div>
  );
}
