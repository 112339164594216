import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Col, Row, message } from 'antd';
import { addProviderApi, editProviderApi } from '../../api/provider';
import { getAccessTokenApi } from '../../api/auth';

export default function ProviderForm(props) {
  const { setIsVisibleModal, getProvider, data } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();

    form.setFieldsValue(data);
  }, [data]);

  const onFinish = async (values) => {
    let token = await getAccessTokenApi();
    data
      ? editProviderApi({ providerData: values, token, id: data._id })
          .then(async (response) => {
            if (response?.code !== 200) {
              message.error(response.message);
            } else {
              message.success(response.message);
              setIsVisibleModal(false);
              getProvider && getProvider();
              form.resetFields();
            }
          })
          .catch(() => {
            message.error('Error del servidor, intente mas tarde.');
          })
      : addProviderApi({ providerData: values, token })
          .then(async (response) => {
            if (response?.code !== 200) {
              message.error(response.message);
            } else {
              message.success(response.message);
              setIsVisibleModal(false);
              getProvider && getProvider();
              form.resetFields();
            }
          })
          .catch(() => {
            message.error('Error del servidor, intente mas tarde.');
          });
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Debe cargar un nombre' }]}
              labelCol={{ span: 24 }}
              label="Nombre fantasia"
            >
              <Input placeholder="Drogueria del valle" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="businessName"
              labelCol={{ span: 24 }}
              label="Razon social"
            >
              <Input placeholder="Valle srl" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="cuit" labelCol={{ span: 24 }} label="CUIT">
              <Input placeholder="20248129312" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="direction"
              labelCol={{ span: 24 }}
              label="Dirección"
            >
              <Input placeholder="Juan B JUSTO 520 - Cordoba" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="phone"
              labelCol={{ span: 24 }}
              label="Tel. de Contacto"
            >
              <Input placeholder="351 4889217" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="contact"
              labelCol={{ span: 24 }}
              label="Persona de Contacto"
            >
              <Input placeholder="Maria Perez (Administrativa)" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Button
                style={{ marginTop: 0, width: '100%' }}
                type="primary"
                htmlType="submit"
                className="button-submit"
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
