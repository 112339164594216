import React from 'react';
import { Card, List, Button, Tooltip } from 'antd';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

export function ListDateBalances(props) {
  const { balances, viewBalance } = props;

  return (
    <div>
      <Card
        title="Balances Hídricos"
        headStyle={{ textAlign: 'center' }}
        style={{ borderColor: '#002a52', overflowY: 'scroll', height: '80vh' }}
      >
        <List
          itemLayout="horizontal"
          dataSource={balances}
          renderItem={(balance) => (
            <Ingresos balance={balance} viewBalance={viewBalance} />
          )}
          locale={{ emptyText: 'No hay balances' }}
          loading={
            !balances && {
              style: { width: '100%', padding: '200px 0' },
              size: 'large',
              indicator: <LoadingOutlined />,
            }
          }
        />
      </Card>
    </div>
  );
}

function Ingresos(props) {
  const { balance, viewBalance } = props;
  return (
    <List.Item
      actions={[
        <Tooltip title="Ver Detalle">
          <Button
            type="default"
            onClick={() => {
              viewBalance(balance);
            }}
          >
            <EyeOutlined />
          </Button>
        </Tooltip>,
      ]}
    >
      <List.Item.Meta title={moment(balance.date).format('DD/MM')} />
    </List.Item>
  );
}
