import { ON_SOCIAL_WORK_READING, ON_SOCIAL_WORK_READ } from '../actions/types';

const INITIAL_STATE = { socialWorks: [], loading: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_SOCIAL_WORK_READING:
      return { ...state, loading: true };
    case ON_SOCIAL_WORK_READ:
      return { ...state, socialWorks: action.payload, loading: false };
    default:
      return state;
  }
};
