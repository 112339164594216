import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { notification } from 'antd';
import { getAccessTokenApi } from '../../api/auth';
import { getBubbleStatsApi } from '../../api/stats';
import Spin from '../Spin';

export function BubbleChart({ height, diagnostic }) {
  const [dataChart, setDataChart] = useState();
  const [total, setTotal] = useState();

  useEffect(() => {
    if (diagnostic) {
      const getData = async () => {
        let token = await getAccessTokenApi();
        getBubbleStatsApi({ token, diagnostic }).then((response) => {
          if (response?.code !== 200) {
            notification['warning']({ message: response.message });
          } else {
            setDataChart(response.dataChart);
            setTotal(response.total);
          }
        });
      };
      getData();
    }
  }, [diagnostic]);

  if (!dataChart)
    return diagnostic
      ? `No se han encontrado obitos con ${diagnostic}`
      : 'Debe seleccionar un diagnostico';

  return (
    <div>
      <Chart
        width="auto"
        height={height || '250px'}
        chartType="BubbleChart"
        loader={<Spin />}
        data={dataChart}
        rootProps={{ 'data-testid': '2' }}
        options={{
          title: `Correlación entre edad, dias internado y obitos por ${diagnostic}. Cantidad obitos: ${total}`,
          hAxis: { title: 'Edad' },
          vAxis: { title: 'Dias Internado' },
        }}
      />
    </div>
  );
}
