import { ON_DIAGNOSTIC_READ, ON_DIAGNOSTIC_READING } from './types';
import { notification } from 'antd';
import {
  addDiagnosticApi,
  getDiagnosticApi,
  deleteDiagnosticApi,
} from '../api/dischargeDiagnostic';
import { getAccessTokenApi } from '../api/auth';

export const onDiagnosticRead = () => async (dispatch) => {
  dispatch({ type: ON_DIAGNOSTIC_READING });
  let token = await getAccessTokenApi();

  return getDiagnosticApi(token).then(async (response) => {
    await dispatch({ type: ON_DIAGNOSTIC_READ, payload: response.diagnostics });
  });
};

export const onDiagnosticCreate =
  (diagnosticData, setIsVisibleModal) => async (dispatch) => {
    let token = await getAccessTokenApi();
    return addDiagnosticApi(diagnosticData, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          await dispatch(onDiagnosticRead());
          setIsVisibleModal(false);
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onDiagnosticDelete = (id) => async (dispatch) => {
  let token = await getAccessTokenApi();

  return deleteDiagnosticApi(id, token)
    .then(async (response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        await dispatch(onDiagnosticRead());
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};
