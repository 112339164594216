import React, { useEffect, useState } from 'react';
import { Select, Form, Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { onBedRead, onIncomeChangeFloor } from '../../actions';

export default function ChangeFloorForm(props) {
  const { income, setIsVisibleModal, incomeType } = props;
  const [disable, setDisable] = useState(true);
  const [changeFloorForm] = Form.useForm();
  const { beds } = useSelector((store) => store.bedData);
  const { floors } = useSelector((store) => store.tenantData);

  const dispatch = useDispatch();

  useEffect(() => {
    changeFloorForm.resetFields();
    setDisable(true);
  }, [income]);

  const changeFloor = async (value) => {
    await dispatch(onBedRead(value));
    setDisable(false);
  };

  const onFinish = (values) => {
    dispatch(
      onIncomeChangeFloor(income._id, values, incomeType, setIsVisibleModal)
    );
  };

  return (
    <div>
      <Form form={changeFloorForm} onFinish={onFinish}>
        <Form.Item
          name="type"
          rules={[{ required: true, message: 'Debe elegir el piso' }]}
        >
          <Select
            placeholder="Seleccione un piso (*)"
            showSearch
            optionFilterProp="children"
            onChange={(value) => {
              changeFloorForm.setFieldsValue({ bed: '' });
              setDisable(true);
              changeFloor(value);
            }}
          >
            {floors?.map((floor, index) => (
              <Select.Option key={index} value={floor.id}>
                {floor.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Tooltip title={disable && 'Primero debe elegir el piso'}>
          <Form.Item
            name="bed"
            rules={[{ required: true, message: 'Debe elegir una cama' }]}
          >
            <Select
              placeholder="Seleccione una cama (*)"
              showSearch
              optionFilterProp="children"
              disabled={disable}
              notFoundContent="No hay camas disponibles"
            >
              {beds?.map((bed, index) => (
                <Select.Option value={bed._id} key={index}>
                  {bed.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Tooltip>
        <Form.Item>
          <Button type="default" htmlType="submit" style={{ width: '100%' }}>
            Cambiar Piso
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
