import React from 'react';
import { Table } from 'antd';

export function DosisFarmacos() {
  const columns = [
    {
      title: 'Droga',
      dataIndex: 'droga',
      key: 'droga',
      sorter: (a, b) => a.droga.localeCompare(b.droga),
    },
    {
      title: 'Dosis',
      dataIndex: 'dosis',
      key: 'dosis',
      align: 'center',
    },
    {
      title: 'Dilución',
      dataIndex: 'dilucion',
      key: 'dilucion',
      align: 'center',
    },

    {
      title: 'Gamas',
      dataIndex: 'gamas',
      key: 'gamas',
      align: 'center',
    },
  ];

  const data = [
    {
      droga: 'Nitroglicerina',
      dosis: '25 mg',
      dilucion: '100 ml',
      gamas: 'Según TA (5-40) µgr/min',
    },
    {
      droga: 'Adrenalina',
      dosis: '1 mg',
      dilucion: '100 ml',
      gamas: '0.01 - 0.03 gammas',
    },
    {
      droga: 'Noradrenalina',
      dosis: '8 mg',
      dilucion: '100 ml',
      gamas: '0.01 - 0.4 gammas',
    },
    {
      droga: 'Dobutamina',
      dosis: '250 mg',
      dilucion: '100 ml',
      gamas: '2 - 20 gammas',
    },
    {
      droga: 'Dopamina',
      dosis: '200 mg',
      dilucion: '100 ml',
      gamas: '2 - 20 gammas',
    },
    {
      droga: 'Efedrina',
      dosis: '50 mg',
      dilucion: '100 ml',
      gamas: '--',
    },
    {
      droga: 'Furosemida',
      dosis: '100 mg',
      dilucion: '100 ml',
      gamas: 'Según ind',
    },
    {
      droga: 'Isoprotenerol',
      dosis: '2 mg',
      dilucion: '100 ml',
      gamas: '0.02 -0.2 gammas',
    },
    {
      droga: 'Labetatol',
      dosis: '100 mg',
      dilucion: '100 ml',
      gamas: '1 - 3 gammas',
    },
    {
      droga: 'Lidocaina',
      dosis: '400 mg',
      dilucion: '100 ml',
      gamas: '20 - 50',
    },
    {
      droga: 'Milrinona',
      dosis: '10 mg',
      dilucion: '100 ml',
      gamas: '0.375 -0.750',
    },
    {
      droga: 'Nitroprusatio',
      dosis: '50 mg',
      dilucion: '100 ml',
      gamas: '0.1 - 10 gammas',
    },
    {
      droga: 'Remifentanilo',
      dosis: '5 mg',
      dilucion: '250 ml',
      gamas: '0.1 -0.2 gammas',
    },
    {
      droga: 'Midazolam',
      dosis: '50 - 80  mg',
      dilucion: '100 ml',
      gamas: '0.1 -0.4 gammas',
    },
    {
      droga: 'Amiodarona',
      dosis: 'S/indic',
    },
    {
      droga: 'Fentanilo',
      dosis: '1250 ugr',
      dilucion: '100 ml',
      gamas: '1 - 2 ugr/Kg/h',
    },
  ];

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>
        Drogas administradas en bomba de infusión
      </h1>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        className="table"
        pagination={{ pageSize: 6 }}
      />
    </div>
  );
}
