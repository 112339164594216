import {
  ON_BED_READ,
  ON_BED_READING,
  ON_BED_STATS_READ,
  ON_BED_STATS_READING,
} from '../actions/types';

const INITIAL_STATE = { beds: [], bedsStats: [], loading: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_BED_READING:
      return { ...state, loading: true };
    case ON_BED_READ:
      return { ...state, beds: action.payload, loading: false };
    case ON_BED_STATS_READING:
      return { ...state, loading: true };
    case ON_BED_STATS_READ:
      return { ...state, bedsStats: action.payload, loading: false };
    default:
      return state;
  }
};
