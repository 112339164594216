import React from 'react';
import { Table, Button, Tooltip, Row, Alert, Col } from 'antd';
import {
  EditOutlined,
  FormOutlined,
  ControlOutlined,
  DiffOutlined,
  EyeOutlined,
  LoadingOutlined,
  MedicineBoxOutlined,
  PullRequestOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './IncomeTableInfarmary.scss';
import { onIncomeValueChange } from '../../../actions';

export default function IncomeTableInfarmary(props) {
  const { search, incomesActive, incomeType } = props;
  const { loadingActive, page } = useSelector((store) => store.incomeData);

  const dispatch = useDispatch();

  const { floors } = useSelector((store) => store.tenantData);

  let floorType = floors?.find(
    (floor) => floor.id === incomeType && floor?.type
  );

  const data = [];
  if (incomesActive) {
    if (search) {
      const incomesFilters = incomesActive.filter(
        (income) =>
          income.name
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase().trim()) ||
          income.bed.name
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase().trim()) ||
          income.socialWork
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase().trim())
      );
      incomesFilters.map((income, index) => {
        data.push({
          key: index,
          _id: income._id,
          date: income.date,
          bed: income.bed,
          name: income.name,
          dni: income.dni,
          age: income.age,
          weigth: income.weigth,
          heigth: income.heigth,
          imc: income.imc,
          sex: income.sex,
          socialWork: income.socialWork,
          affiliate: income.affiliate,
          city: income.city,
          doctor: income.doctor,
          origin: income.origin,
          queryReason: income.queryReason,
          diagnostic: income.diagnostic,
          news: income.news,
          epicrisis: income.epicrisis,
          lastMedicineHour: income.lastMedicineHour,
        });
      });
    }
  }

  const columns = [
    {
      title: 'Cama',
      width: 50,
      dataIndex: 'bed',
      key: 'bed',
      render: (bed) => <span>{bed.name}</span>,
    },
    {
      title: 'Paciente',
      className: 'text-capitalize',
      width: 180,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: (incomeType === 'all' || floorType) && 'ascend',
    },
    {
      title: 'Fecha de ingreso',
      width: 50,
      dataIndex: 'date',
      key: 'date',
      responsive: ['md'],
      render: (record) => <span>{moment(record).format('DD/MM/YY')}</span>,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: 'Diagnostico presuntivo',
      width: 150,
      dataIndex: 'diagnostic',
      responsive: ['lg'],
      key: 'diagnostic',
    },
    {
      title: 'Obra social',
      className: 'text-uppercase',
      width: 100,
      dataIndex: 'socialWork',
      key: 'socialWork',
      sorter: (a, b) => a.socialWork.localeCompare(b.socialWork),
    },
    {
      title: 'HOY',
      width: 5,
      responsive: ['lg'],
      render: (income) => (
        <Row gutter={24}>
          <Col span={6}>
            <Tooltip
              title={
                income.lastMedicineHour ? (
                  <span>
                    Ult. Indicación: {moment(income.lastMedicineHour).fromNow()}
                  </span>
                ) : (
                  <span>Sin Medicaciones</span>
                )
              }
            >
              <Alert
                type={
                  moment().isSame(income.lastMedicineHour, 'day') &&
                  income?.lastMedicineHour
                    ? 'success'
                    : 'error'
                }
                showIcon
                banner
                style={{ padding: 0, paddingTop: 5 }}
              />
            </Tooltip>
          </Col>
          {income.bed?.bedType !== 'Dialisis' ? (
            <Col span={6}>
              <Tooltip
                title={
                  income?.pdfReports.some(
                    (pdf) =>
                      pdf.type === 'Laboratorio' &&
                      moment().isSame(pdf.date, 'day')
                  )
                    ? `Se cargo un laboratorio hoy`
                    : 'No tiene laboratorios hoy'
                }
              >
                <Alert
                  type={
                    income?.pdfReports.some(
                      (pdf) =>
                        pdf.type === 'Laboratorio' &&
                        moment().isSame(pdf.date, 'day')
                    )
                      ? 'success'
                      : 'error'
                  }
                  showIcon
                  banner
                  style={{ padding: 0, paddingTop: 5 }}
                />
              </Tooltip>
            </Col>
          ) : (
            <Col span={6}>
              <Tooltip
                title={
                  income?.lastDyalisisControlHour ? (
                    <span>
                      Ult. Control:{' '}
                      {moment(income?.lastDyalisisControlHour).fromNow()}
                    </span>
                  ) : (
                    <span>Sin Controles</span>
                  )
                }
              >
                <Alert
                  type={
                    moment().isSame(income?.lastDyalisisControlHour, 'day') &&
                    income?.lastDyalisisControlHour
                      ? 'success'
                      : 'error'
                  }
                  showIcon
                  banner
                  style={{ padding: 0, paddingTop: 5 }}
                />
              </Tooltip>
            </Col>
          )}
        </Row>
      ),
    },
    {
      title: 'Acciones',
      key: 'action',
      width: 150,
      align: 'center',
      render: (income) => (
        <>
          <Row gutter={24}>
            <Tooltip title="Editar Ingreso">
              <Link to={`/edit-income/${income._id}/${incomeType}`}>
                <Button type="link">
                  <FormOutlined />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip title="Informe internación">
              <Link
                to={{
                  pathname: `/report/${income?._id}`,
                  search:
                    income.bed?.bedType === 'Dialisis' &&
                    `?${income.bed?.bedType}`,
                }}
              >
                <Button type="link">
                  <EyeOutlined />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip title="Notas">
              <Link to={`/notes/${income._id}`}>
                <Button type="link">
                  <EditOutlined />
                </Button>
              </Link>
            </Tooltip>
          </Row>
          <Row gutter={24}>
            <Tooltip title="Controles">
              <Link
                to={
                  income.bed?.bedType !== 'Dialisis'
                    ? `/controls/${income._id}`
                    : `/dialisis-controls/${income._id}`
                }
              >
                <Button type="link">
                  <ControlOutlined />
                </Button>
              </Link>
            </Tooltip>
            {income.bed?.bedType === 'Dialisis' ? (
              <Tooltip title="Insumos">
                <Link to={`/insumos/${income._id}`}>
                  <Button type="link">
                    <PullRequestOutlined />
                  </Button>
                </Link>
              </Tooltip>
            ) : (
              <Tooltip title="Balances">
                <Link to={`/balances/${income._id}`}>
                  <Button type="link">
                    <DiffOutlined />
                  </Button>
                </Link>
              </Tooltip>
            )}
            <Tooltip title="Indicaciones">
              <Link
                to={{
                  pathname: `/medicine/${income?._id}`,
                  hash: income.bed?.bedType,
                }}
              >
                <Button type="link">
                  <MedicineBoxOutlined />
                </Button>
              </Link>
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];

  return (
    <div>
      <Table
        className="table"
        columns={columns}
        dataSource={search ? data : incomesActive}
        scroll={{ x: 500 }}
        pagination={{
          pageSize: 20,
          showSizeChanger: false,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} pacientes`,
          current: page,
          onChange: (page) => {
            dispatch(onIncomeValueChange({ page }));
          },
        }}
        loading={
          loadingActive && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
        locale={{ emptyText: 'No hay pacientes internados' }}
      />
    </div>
  );
}
