import React from 'react';
import moment from 'moment';
import { Text, View } from '@react-pdf/renderer';
import { styleBody } from './styles';

export function PdfEvolution({ data }) {
  return (
    <View style={styleBody.dataEvolution}>
      <Text style={styleBody.evolutionTitle}>
        {moment(data.date).format('DD/MM/YYYY HH:mm')}
      </Text>
      <Text style={styleBody.text}>{data.description}</Text>
    </View>
  );
}
