import React, { useState, useEffect } from 'react';
import { getControlApi } from '../../../api/control';
import { getAccessTokenApi } from '../../../api/auth';
import moment from 'moment';
import Spin from '../../Spin';
import './ControlPdf.scss';

export default function ControlPdf(props) {
  const { id, name } = props;
  const [controls, setControls] = useState();

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getControlApi(id, token)
        .then((response) => {
          setControls(response.controls);
        })
        .catch((err) => {});
    };
    getData();
  }, [id]);

  if (!controls) {
    return <Spin />;
  }

  return controls.length > 0 ? (
    <>
      <h4 className="title">Controles Frecuentes {name && `- ${name}`}</h4>
      <table
        cellSpacing="1"
        cellPadding="3"
        bgcolor="#fff"
        className="control-table"
      >
        <thead>
          <tr>
            <th>Fecha y Hora</th>
            <th>Frecuencia Cardiaca</th>
            <th>Tensión Arterial</th>
            <th>Saturación de O2.</th>
            <th>Temperatura</th>
            <th>Frecuencia Respiratoria</th>
            <th style={{ width: '25%' }}>Observaciones</th>
          </tr>
        </thead>
        {controls.map((control, index) => (
          <tbody key={index}>
            <tr>
              <td>{moment(control.date).format('DD/MM HH:mm')}</td>
              <td>{control.heartRate}</td>
              <td>{control.bloodPresure}</td>
              <td>{control.o2Saturation} %</td>
              <td>{control.temperature} °C</td>
              <td>{control.breathingFrequency}</td>
              <td>{control.observations}</td>
            </tr>
          </tbody>
        ))}
      </table>
    </>
  ) : null;
}
