import React, { useState } from 'react';
import Modal from '../Modal';
import { List, Button, Modal as ModalAntd, Dropdown, Menu } from 'antd';
import {
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { onPhoneDelete } from '../../actions';
import AddPhone from './AddPhone';

export default function ListPhones(props) {
  const { search } = props;
  const { phones, loading } = useSelector((store) => store.phoneData);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const dispatch = useDispatch();

  const deletePhone = (phone) => {
    ModalAntd.confirm({
      title: 'Eliminar teléfono',
      content: `¿ Estas seguro que quieres eliminar el teléfono de ${phone.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onPhoneDelete(phone._id));
      },
    });
  };

  const editPhone = (phone) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar teléfono`);
    setModalContent(
      <AddPhone setIsVisibleModal={setIsVisibleModal} phone={phone} />
    );
  };

  if (search) {
    var phoneFilters = phones.filter((phone) =>
      phone.name.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  }
  return (
    <div>
      <List
        className="incomes-list"
        itemLayout="horizontal"
        dataSource={search ? phoneFilters : phones}
        renderItem={(phone) => (
          <Phone
            phone={phone}
            deletePhone={deletePhone}
            editPhone={editPhone}
          />
        )}
        locale={{ emptyText: 'No hay teléfonos' }}
        loading={
          phones &&
          phones.length === 0 &&
          loading && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function Phone(props) {
  const { phone, deletePhone, editPhone } = props;
  return (
    <List.Item
      actions={[
        <Dropdown
          trigger="click"
          overlay={
            <Menu>
              <Menu.Item>
                <Button
                  type="link"
                  style={{
                    color: '#1764ab',
                  }}
                  onClick={() => editPhone(phone)}
                >
                  <span>Editar</span> <EditOutlined />
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" onClick={() => deletePhone(phone)}>
                  <span style={{ color: 'red' }}>Eliminar</span>
                  <DeleteOutlined style={{ color: 'red' }} />
                </Button>
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link">
            <MoreOutlined style={{ color: '#1764ab' }} />
          </Button>
        </Dropdown>,
      ]}
    >
      <List.Item.Meta title={phone.name} description={phone.number} />
    </List.Item>
  );
}
