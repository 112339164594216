import { basePath, apiVersion } from './config';

export async function getInsumoApi(token) {
  const url = `${basePath}/${apiVersion}/insumo`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function addInsumoApi(insumonData, token) {
  const url = `${basePath}/${apiVersion}/insumo`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(insumonData),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function updateStockInsumoApi(id, stockData, token) {
  const url = `${basePath}/${apiVersion}/insumo-stock/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(stockData),
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function deleteInsumoApi(id, token) {
  const url = `${basePath}/${apiVersion}/insumo/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function getIdicationInsumoApi(id, token, month) {
  const url = `${basePath}/${apiVersion}/indication-insumos/${id}?month=${month}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function getIdicationInsumoDetailApi({
  id,
  token,
  type,
  month = undefined,
  start,
  end,
}) {
  const url = `${basePath}/${apiVersion}/indication-insumos-detail/${id}?type=${type}&month=${month}&start=${start}&end=${end}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function getIdicationFilterApi(id, token, month) {
  const url = `${basePath}/${apiVersion}/indication-filter/${id}?month=${month}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function addIdicationInsumoApi({ indicationData, token }) {
  const url = `${basePath}/${apiVersion}/indication-insumo`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(indicationData),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function updateIdicationInsumoApi({ indicationData, token, id }) {
  const url = `${basePath}/${apiVersion}/indication-insumo/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(indicationData),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function deleteIndicationInsumoApi(id, token) {
  const url = `${basePath}/${apiVersion}/indication-insumo/${id}`;

  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}
