import React, { useEffect, useState } from 'react';
import {
  Button,
  Upload,
  message,
  List,
  Tooltip,
  notification,
  Modal as ModalAntd,
  PageHeader,
  Radio,
  Dropdown,
  Menu,
  Space,
  Input,
  Row,
  Col,
} from 'antd';
import '../Evolution/Evolution.scss';
import {
  UploadOutlined,
  EyeOutlined,
  LoadingOutlined,
  DeleteOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { getPdfByIncome, changeTypePdf } from '../../api/income';
import { getAccessTokenApi } from '../../api/auth';
import { deletePdfApi, getPdfApi, uploadPdfApi } from '../../api/cloudStorage';
import moment from 'moment';
import { useSelector } from 'react-redux';
import PermissionsAsigner from '../../components/PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import PdfViewer from '../../components/PdfViewer';
import Spin from '../../components/Spin';
import { PageListAndView } from '../../components/ListAndDetail';
import { getPatientStudiesApi } from '../../api/patient';
import './PdfReports.scss';
import Modal from '../../components/Modal';

export default function PdfReports(props) {
  const { id, name } = props.match.params;
  const { path } = props.match;
  const [pdfReports, setPdfReports] = useState();
  const [pdfReportFilter, setPdfReportFilter] = useState('Todos');
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [pdf, setPdf] = useState();
  const [pdfName, setPdfName] = useState();
  const [extension, setExtension] = useState();

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const { incomesActive } = useSelector((store) => store.incomeData);
  const { reportTypes } = useSelector((store) => store.tenantData);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      path === '/pdf-report/:id/:name?'
        ? getPdfByIncome(id, token)
            .then((response) =>
              setPdfReports(
                response.pdfReports.sort(
                  (a, b) => moment(b.date).unix() - moment(a.date).unix()
                )
              )
            )
            .catch((err) => console.log(err))
        : getPatientStudiesApi(id, token)
            .then((response) => {
              return setPdfReports(
                response.studies.sort(
                  (a, b) => moment(b.date).unix() - moment(a.date).unix()
                )
              );
            })
            .catch((err) => console.log(err));
      setLoadingDownload(false);
    };
    getData();
  }, [id, loadingDownload]);

  const propsUpload = {
    name: 'file',
    accept: 'image/*,.pdf, video/*',
    multiple: true,
    showUploadList: false,
    async beforeUpload(info) {
      const token = await getAccessTokenApi();
      if (
        !pdfReports.some((pdf) => info.name === pdf.title) ||
        info.name === 'image.jpg'
      ) {
        message.loading(`Subiendo ${info.name}`, 0);
        await uploadPdfApi(
          token,
          info,
          id,
          path === '/patient-studies/:id/:name?' && 'Ambulatory'
        )
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message }).then(() =>
                message.destroy()
              );
              setLoadingUpload(false);
            } else {
              message
                .success(`${info.name} se ha subido con exito.`, 1)
                .then(() => message.destroy());
              setPdfName();
              setPdf();
              setLoadingUpload(false);
              setLoadingDownload(true);
            }
          })
          .catch((err) => {
            console.log(err);
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            }).then(() => message.destroy());
          });
      } else {
        setLoadingUpload(false);
        notification['warning']({
          message: 'Ya existe un archivo con ese nombre.',
        });
        return;
      }
    },
  };

  let income;
  incomesActive?.find((i) => {
    if (i._id === id) {
      income = i;
    }
  });

  const deletePdf = () => {
    let deletePdf = pdfReports.find((pdf) => pdfName === pdf.title && pdf.id);

    ModalAntd.confirm({
      title: 'Eliminar informe',
      content: `¿ Estas seguro que quieres eliminar este archivo ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        setLoadingUpload(true);
        const token = await getAccessTokenApi();
        deletePdfApi(
          token,
          pdfName,
          deletePdf?.id || id,
          path === '/patient-studies/:id/:name?' && !deletePdf && 'Ambulatory'
        )
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
              setLoadingUpload(false);
            } else {
              notification['success']({ message: response.message });
              setPdfName();
              setPdf();
              setLoadingDownload(true);
              setLoadingUpload(false);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoadingUpload(false);
          });
      },
    });
  };

  const viewPdf = async (pdfReport) => {
    const token = await getAccessTokenApi();
    setLoadingUpload(true);
    setPdfName(pdfReport.title);
    getPdfApi(
      token,
      pdfReport?.id ? pdfReport.id : id,
      pdfReport.title || pdfReport
    ).then((response) => {
      setPdf(response.url);
      setExtension(response.url.split('.').pop());
      setLoadingUpload(false);
    });
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  const filterPdfType = async (value) => {
    value === 'Todos'
      ? setPdfReportFilter(value)
      : setPdfReportFilter(pdfReports?.filter((pdf) => pdf?.type === value));
  };

  const changeName = async (pdfReport) => {
    var newName = pdfReport?.viewTitle || pdfReport?.title;

    setIsVisibleModal(true);
    setModalTitle('Cambiar nombre archivo');
    setModalContent(
      <>
        <Row gutter={24}>
          <Col xs={12} md={12} lg={12}>
            <Input
              key={pdfReport.title}
              defaultValue={newName}
              placeholder="Electro"
              onChange={(e) => {
                newName = e.target.value;
              }}
              allowClear
            />
          </Col>
          <Col xs={12} md={12} lg={12}>
            <Button
              type="default"
              style={{ width: '100%' }}
              onClick={async () => {
                const token = await getAccessTokenApi();
                changeTypePdf({
                  token,
                  id: pdfReport?.id ? pdfReport.id : id,
                  title: pdfReport.title,
                  type: pdfReport.type,
                  name: newName,
                })
                  .then((response) => {
                    if (response?.code !== 200) {
                      notification['error']({ message: response.message });
                      setIsVisibleModal(false);
                    } else {
                      notification['success']({
                        message: response.message,
                      });
                      setIsVisibleModal(false);
                      setLoadingDownload(true);
                    }
                  })
                  .catch(() => {
                    notification['error']({
                      message: 'Error del servidor, intente mas tarde.',
                    });
                  });
              }}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className="evolution">
      {income ? (
        <div className="evolution-header">
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={`${income.bed.name}`}
            subTitle={
              <Tooltip
                title={`Cama: ${income.bed.name} ${income.name} - ${
                  income.age
                } años | Fecha Ingreso: ${moment(income.date).format(
                  'DD/MM/YYYY'
                )}`}
              >
                {income.name} - {income.age} años | Fecha Ingreso:{' '}
                {moment(income.date).format('DD/MM/YYYY')}
              </Tooltip>
            }
            extra={[
              <PermissionsAsigner requiredRole={ROLES.INFARMARY}>
                <Upload {...propsUpload}>
                  <Button type="primary">
                    <UploadOutlined /> Subir PDF
                  </Button>
                </Upload>
              </PermissionsAsigner>,
            ]}
          />
        </div>
      ) : (
        <div className="evolution-header">
          <PageHeader
            ghost={false}
            title={name}
            style={{ textTransform: 'capitalize' }}
            onBack={() => window.history.back()}
            extra={
              path === '/patient-studies/:id/:name?' && [
                <PermissionsAsigner requiredRole={ROLES.VIEW}>
                  <Upload {...propsUpload}>
                    <Button type="primary">
                      <UploadOutlined /> Subir Archivo
                    </Button>
                  </Upload>
                </PermissionsAsigner>,
              ]
            }
          />
        </div>
      )}
      <PageListAndView
        datesTitle="Informes complementarios"
        componentDate={
          <List
            itemLayout="horizontal"
            dataSource={
              pdfReportFilter === 'Todos' ? pdfReports : pdfReportFilter
            }
            renderItem={(pdfReport) => (
              <Item
                pdfReport={pdfReport}
                viewPdf={viewPdf}
                loadingUpload={loadingUpload}
                id={id}
                setLoadingDownload={setLoadingDownload}
                changeName={changeName}
                reportTypes={reportTypes}
              />
            )}
            locale={{ emptyText: 'No se han encontrado datos ' }}
            loading={
              !PdfReports && {
                style: { width: '100%', padding: '200px 0' },
                size: 'large',
                indicator: <LoadingOutlined />,
              }
            }
          />
        }
        detailTitle="Detalle informe"
        data={pdf}
        componentDetail={
          loadingUpload ? (
            <Spin />
          ) : pdf && extension === 'pdf' ? (
            <PdfViewer pdf={pdf} />
          ) : pdf && (extension === 'mp4' || extension === 'MOV') ? (
            <video height="100%" width="100%" controls>
              <source src={pdf} type="video/mp4" />
              <source src={pdf} type="video/ogg" />
              <source src={pdf} type="video/mov" />
              No se puede visualizar el video
            </video>
          ) : (
            <embed src={pdf} style={{ width: '100%', height: '100%' }} />
          )
        }
        extra={
          <div>
            <Tooltip title="Ver en pantalla completa">
              <a href={pdf} target="_blank" referrerPolicy="origin">
                <Button type="link" size="large" disabled={!pdf && true}>
                  <EyeOutlined />
                </Button>
              </a>
            </Tooltip>
            <PermissionsAsigner requiredRole={ROLES.SECRETARY}>
              <Tooltip title="Eliminar Informe">
                <Button
                  type="link"
                  size="large"
                  onClick={() => deletePdf()}
                  disabled={!pdf && true}
                >
                  <DeleteOutlined style={{ color: pdf ? 'red' : '' }} />
                </Button>
              </Tooltip>
            </PermissionsAsigner>
          </div>
        }
        extraDate={
          <Tooltip title="Filtrar por">
            <Dropdown
              trigger="click"
              overlay={
                <Menu>
                  <Menu.Item>
                    <Radio.Group
                      onChange={(e) => {
                        filterPdfType(e.target.value);
                      }}
                    >
                      <Space direction="vertical">
                        <Radio value={'Todos'}>Todos</Radio>
                        {reportTypes?.map((r) => (
                          <Radio value={r.name}>{r.name}</Radio>
                        ))}
                        <Radio value={false}>Otros</Radio>
                      </Space>
                    </Radio.Group>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="link">
                <MoreOutlined style={{ color: '#1764ab' }} />
              </Button>
            </Dropdown>
          </Tooltip>
        }
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={modalTitle === 'Cancelar turno' ? '50%' : '80%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function Item(props) {
  const {
    pdfReport,
    viewPdf,
    loadingUpload,
    id,
    setLoadingDownload,
    changeName,
    reportTypes,
  } = props;

  const changeType = async (type) => {
    const token = await getAccessTokenApi();
    changeTypePdf({
      token,
      id: pdfReport?.id ? pdfReport.id : id,
      title: pdfReport.title,
      type,
      name: pdfReport.viewTitle || pdfReport.title || pdfReport,
    })
      .then((response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({
            message: response.message,
          });
          setLoadingDownload(true);
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

  let type = reportTypes?.find((r) => pdfReport?.type === r.name);

  return (
    <List.Item
      style={{ backgroundColor: type?.color, padding: 5 }}
      actions={[
        type?.image && (
          <img
            src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/logos/${type?.image}`}
            style={{ height: '30px' }}
          />
        ),
        <Tooltip title="Ver Detalle">
          <Button
            type="default"
            size="small"
            onClick={() => {
              viewPdf(pdfReport);
            }}
            disabled={loadingUpload}
          >
            <EyeOutlined />
          </Button>
        </Tooltip>,
        <Dropdown
          trigger="click"
          overlay={
            <Menu>
              <Menu.Item>
                <Button type="link" onClick={() => changeName(pdfReport)}>
                  Cambiar Nombre
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Radio.Group
                  defaultValue={pdfReport?.type}
                  disabled={!pdfReport?.date}
                  onChange={(e) => changeType(e.target.value)}
                >
                  <Space direction="vertical">
                    {reportTypes.map((r) => (
                      <Radio value={r.name}>{r.name}</Radio>
                    ))}
                    <Radio value={false}>Otros</Radio>
                  </Space>
                </Radio.Group>
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link">
            <MoreOutlined style={{ color: '#1764ab' }} />
          </Button>
        </Dropdown>,
      ]}
    >
      <List.Item.Meta
        title={pdfReport.viewTitle || pdfReport.title || pdfReport}
        description={
          <>
            <span>
              {pdfReport?.date && moment(pdfReport.date).format('DD-MM-YY')}
            </span>
            <br />
            {pdfReport.type !== 'false' && <span>{pdfReport?.type}</span>}
          </>
        }
      />
    </List.Item>
  );
}
