import React from 'react';
import { List, Card } from 'antd';
import './Pharmacy.scss';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import Stock from '../../assets/img/stock.jpeg';
import Send from '../../assets/img/send.jpg';
import Buy from '../../assets/img/buy.jpg';

export default function Pharmacy() {
  const data = [
    {
      title: 'Stock de Farmacia',
      actions: [
        <Link to={`/pharmacy/stock`}>
          <span>
            <EyeOutlined /> Ver Stock
          </span>
        </Link>,
      ],
      description: 'Podes ver el stock actual que tenes en tu centro.',
      image: Stock,
    },
    {
      title: 'Nueva Compra',
      description:
        'Podes ver el el listado y detalle de las compras y realizar nuevas.',
      actions: [
        <Link to={`/pharmacy/buy`}>
          <PlusOutlined /> Nueva Compra
        </Link>,
        <Link to={`/view-pharmacy/buy`}>
          <EyeOutlined /> Ver Compras
        </Link>,
      ],
      image: Buy,
    },
    {
      title: 'Envios de Farmacia',
      description:
        'Podes ver el el listado y detalle de los dispendios de medicamentos y/o insumos y realizar nuevos.',
      actions: [
        <Link to={`/pharmacy/send`}>
          <PlusOutlined /> Nuevo Envio
        </Link>,
        <Link to={`/view-pharmacy/send`}>
          <EyeOutlined /> Ver Envios
        </Link>,
      ],
      image: Send,
    },
  ];
  return (
    <div>
      <List
        className="cards"
        grid={{ gutter: 16, column: 3, xs: 1, sm: 1, md: 2 }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Card
              cover={<img alt="example" src={item.image} />}
              actions={item.actions}
              hoverable
            >
              <Card.Meta title={item.title} description={item.description} />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
}
