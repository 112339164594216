import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Input,
  notification,
  Row,
  Col,
  DatePicker,
  Select,
  Tooltip,
} from 'antd';
import { useSelector } from 'react-redux';
import { addEvolutionApi } from '../../api/evolution';
import { ROLES } from '../../utils/constants';
import moment from 'moment';
import { getUsersTypeApi } from '../../api/user';
import { getAccessTokenApi } from '../../api/auth';
import { getTemplatesApi } from '../../api/template';
import locale from 'antd/es/date-picker/locale/es_ES';

export default function EvolutionForm(props) {
  const {
    incomeId,
    setIsVisibleModal,
    setReload,
    dischargeDate,
    date,
    incomeType,
    bedType,
  } = props;

  const [evolutionForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const userRole = useSelector((store) => store.userData?.user?.role);

  const id = useSelector((store) => store.userData?.user?._id);

  useEffect(() => {
    const getData = async () => {
      evolutionForm.resetFields();
      let token = await getAccessTokenApi();

      getTemplatesApi({ token, type: 'Evolucion' })
        .then((response) => {
          setTemplates(response.templates);
        })
        .catch((err) => {});
      if (dischargeDate) {
        getUsersTypeApi(token, true, 'Medico').then((response) => {
          setUsers(response.users);
        });
      }
    };
    getData();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    values.user = dischargeDate ? values.user : id;
    values.income = incomeId;
    values.signature &&
      (values.description = `${values.description}\n\n${values.signature.name} M.P. ${values.signature.mp}\n${values.signature.esp}`);
    values.incomeType = incomeType ? incomeType : 'Alta';
    let token = await getAccessTokenApi();

    addEvolutionApi(values, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
          setLoading(false);
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          setReload(true);
          evolutionForm.resetFields();
          setLoading(false);
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
        setLoading(false);
      });
  };

  return (
    <Form form={evolutionForm} onFinish={onFinish}>
      {templates?.length > 0 && (
        <Form.Item name="template">
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Templates.. (Opcional)"
            onChange={(id) => {
              let { description } =
                templates.find((template) => template._id === id) || '';
              evolutionForm.setFieldsValue({
                description,
              });
            }}
          >
            {templates?.map((template, index) => (
              <Select.Option
                key={index}
                value={template._id}
                style={{ textTransform: 'capitalize' }}
              >
                {template.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {(dischargeDate || bedType === '#Dialisis') && (
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              name="date"
              rules={[{ required: true, message: 'Debe cargar una fecha' }]}
              labelCol={{ span: 24 }}
              initialValue={!dischargeDate && moment()}
            >
              <DatePicker
                placeholder="Seleccione fecha de la evolución (*)"
                style={{ width: '100%' }}
                format="DD-MM-YYYY HH:mm"
                showToday={false}
                showTime
                locale={locale}
                disabledDate={(current) =>
                  current && current > moment().endOf('day')
                }
                onChange={() => {
                  if (
                    dischargeDate &&
                    !(
                      moment(dischargeDate).isSameOrAfter(
                        evolutionForm.getFieldValue('date')
                      ) &&
                      moment(date).isSameOrBefore(
                        evolutionForm.getFieldValue('date')
                      )
                    )
                  ) {
                    notification['warning']({
                      message:
                        'La fecha debe coincidir con los dias que estuvo internado.',
                    });
                    evolutionForm.setFieldsValue({ date: '' });
                  }
                }}
              />
            </Form.Item>
          </Col>
          {dischargeDate && (
            <Col xs={24} md={12}>
              <Form.Item
                name="user"
                rules={[
                  {
                    required: true,
                    message: 'Debe elegir un medico',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Seleccione un medico.. (*)"
                  optionFilterProp="children"
                >
                  {users?.map((user, index) => (
                    <Select.Option key={index} value={user._id}>
                      {user.name} {user.lastname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
      <Form.Item
        name="description"
        rules={[{ required: true, message: 'Debe ingresar la evolución' }]}
      >
        <Input.TextArea
          placeholder="Decripción de evolución..."
          autoSize={{ minRows: 16 }}
        />
      </Form.Item>
      {userRole === ROLES.EMPLOYEE.value && (
        <Form.Item name="signature">
          <Input.Group>
            <Row>
              <Col span={12}>
                <Form.Item
                  name={['signature', 'name']}
                  rules={[{ required: true, message: 'Debe ingresar nombre' }]}
                >
                  <Input placeholder="Apellido y Nombre" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['signature', 'esp']}
                  rules={[
                    { required: true, message: 'Debe ingresar especialidad' },
                  ]}
                >
                  <Input placeholder="Especialidad" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name={['signature', 'mp']}
                  rules={[
                    { required: true, message: 'Debe ingresar la matricula' },
                  ]}
                >
                  <Input placeholder="MP" />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </Form.Item>
      )}
      <Form.Item>
        <Button
          type="default"
          htmlType="submit"
          style={{ width: '100%' }}
          disabled={loading}
          loading={loading}
        >
          Agregar Evolución
        </Button>
      </Form.Item>
    </Form>
  );
}
