import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleTable } from './styles';

export function PdfTableHeader() {
  return (
    <View style={[styleTable.row, styleTable.header]} fixed>
      <Text style={styleTable.column1}>Fecha y hora</Text>
      <Text style={styleTable.column1}>Frecuencia Cardiaca</Text>
      <Text style={styleTable.column1}>Tensión Arterial</Text>
      <Text style={styleTable.column1}>Saturación de O2.</Text>
      <Text style={styleTable.column1}>Temperatura</Text>
      <Text style={styleTable.column1}>Frecuencia Respiratoria</Text>
      <Text style={styleTable.column2}>Observaciones</Text>
    </View>
  );
}
