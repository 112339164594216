import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  Button,
  Tooltip,
  message,
  DatePicker,
  Radio,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { pdf, Document } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { PdfHighMedical } from '../PdfReports/PdfHighMedical';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import { useSelector } from 'react-redux';

export default function HighMedicalForm({ income }) {
  const [highForm] = Form.useForm();
  const {
    organization,
    tenant,
    logos,
    tenantId,
    tenantLogo,
    organizationPhone,
  } = useSelector((store) => store.tenantData);

  if (!income) {
    highForm.resetFields();
    highForm.setFieldsValue({
      medicines: [{}],
    });
  }

  useEffect(() => {
    highForm.resetFields();
    highForm.setFieldsValue({
      medicines: [{}],
    });
  }, [income]);

  const options = ['08', '10', '12', '14', '16', '18', '20', '22'];

  const onFinish = async (values) => {
    message.loading('Generando documento. Por favor espere...', 0);
    const doc = (
      <Document>
        <PdfHighMedical
          income={income}
          data={values}
          tenant={tenant}
          organization={organization}
          tenantId={tenantId}
          tenantLogo={tenantLogo}
          organizationPhone={organizationPhone}
        />
      </Document>
    );

    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, 'Formulario alta');
    message
      .success('El documento se ha generado con exito.', 1)
      .then(() => message.destroy());
  };

  return (
    <div>
      <Form form={highForm} onFinish={onFinish}>
        {!income && (
          <Row gutter={24}>
            <Col xs={24} sm={8}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Debe cargar el nombre.',
                  },
                ]}
                label="Paciente"
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Apellido y nombre" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8}>
              <Form.Item name="age" label="Edad" labelCol={{ span: 24 }}>
                <Input placeholder="Edad" type="number" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8}>
              <Form.Item
                name="date"
                rules={[{ required: true, message: 'Debe cargar una fecha' }]}
                label="Fecha"
                initialValue={moment()}
                labelCol={{ span: 24 }}
              >
                <DatePicker
                  placeholder="Seleccione fecha (*)"
                  style={{ width: '100%' }}
                  format="DD-MM-YYYY"
                  locale={locale}
                  showToday={false}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.List name="medicines">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Row gutter={24} key={index}>
                    <Col span={15}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'drug']}
                        fieldKey={[field.fieldKey, 'drug']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe escribir una indicación',
                          },
                        ]}
                        label="Indicación"
                        labelCol={{ span: 24 }}
                      >
                        <Input placeholder="Indicación o medicamento" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'hours']}
                        fieldKey={[field.fieldKey, 'hours']}
                        label="Horarios"
                        labelCol={{ span: 24 }}
                      >
                        <Checkbox.Group options={options}></Checkbox.Group>
                      </Form.Item>
                    </Col>
                    <Col span={1} style={{ paddingLeft: '0' }}>
                      <Tooltip title="Eliminar indicación">
                        <Button
                          type="link"
                          onClick={() => {
                            remove(field.name);
                          }}
                        >
                          <MinusCircleOutlined />
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                ))}
                <Form.Item style={{ textAlign: 'right', marginTop: '5px' }}>
                  <Tooltip title="Agregar indicación">
                    <Button
                      type="default"
                      shape="circle"
                      onClick={() => {
                        add();
                      }}
                      size="large"
                    >
                      <PlusOutlined />
                    </Button>
                  </Tooltip>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Form.Item name="others" label="Otros" labelCol={{ span: 24 }}>
          <Input.TextArea autoSize={{ minRows: 2 }} placeholder="Otros.." />
        </Form.Item>
        {!income && logos === 2 && (
          <Form.Item
            name="logo"
            label="Seleccione logo"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: 'Debe elegir un logo.',
              },
            ]}
          >
            <Radio.Group>
              <Radio value="cardio">
                Logo{' '}
                <img
                  src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo1.png`}
                  style={{ objectFit: 'contain', height: '30px' }}
                />
              </Radio>
              <Radio value="clinica">
                Logo{' '}
                <img
                  style={{ objectFit: 'contain', height: '30px' }}
                  src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo.png`}
                />
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item>
          {income ? (
            <Button type="default" htmlType="submit" style={{ width: '100%' }}>
              Generar PDF
            </Button>
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <Button
                  type="default"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  Generar PDF
                </Button>
              </Col>
              <Col span={12}>
                <a
                  href={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/formulario_alta.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="default" style={{ width: '100%' }}>
                    Imprimir formulario en blanco
                  </Button>
                </a>
              </Col>
            </Row>
          )}
        </Form.Item>
      </Form>
    </div>
  );
}
