import React, { useState } from 'react';
import { Form, Col, Row, Input, Button, DatePicker, notification } from 'antd';
import moment from 'moment';
import { addControlApi } from '../../../api/control';
import { getAccessTokenApi } from '../../../api/auth';

import { useSelector } from 'react-redux';

export default function ControlForm(props) {
  const { incomeId, discharge, setIsVisibleModal, setReload } = props;

  const [loading, setLoading] = useState(false);

  const [controlForm] = Form.useForm();

  const id = useSelector((store) => store.userData?.user?._id);

  const onFinish = async (values) => {
    if (moment(values.date).isAfter(discharge)) {
      notification['warning']({
        message: 'La fecha debe coincidir con los dias que estuvo internado.',
      });
      return;
    }
    setLoading(true);
    values.user = id;
    values.income = incomeId;
    let token = await getAccessTokenApi();
    addControlApi(values, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
          setLoading(false);
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          setReload(true);
          controlForm.resetFields();
          setLoading(false);
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
        setLoading(false);
      });
  };
  return (
    <div>
      <Form form={controlForm} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Form.Item
              name="date"
              rules={[{ required: true, message: 'Debe cargar una fecha' }]}
              label="Fecha del control"
              labelCol={{ span: 24 }}
              initialValue={moment()}
            >
              <DatePicker
                placeholder="Seleccione fecha de control (*)"
                style={{ width: '100%' }}
                inputReadOnly
                format="DD-MM HH:mm"
                showTime={{ format: 'HH:mm' }}
                onChange={() => {
                  if (
                    moment().isSameOrBefore(controlForm.getFieldValue('date'))
                  ) {
                    notification['warning']({
                      message:
                        'No se puede elegir una fecha/hora mayor a la actual.',
                    });
                    controlForm.setFieldsValue({ date: moment() });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Form.Item
              name="heartRate"
              label="Frecuencia cardiaca"
              labelCol={{ span: 24 }}
            >
              <Input type="number" inputMode="numeric" max={300} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Form.Item
              name="bloodPresure"
              label="Tensión arterial"
              labelCol={{ span: 24 }}
            >
              <Input maxLength={7} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Form.Item
              name="o2Saturation"
              label="Saturación de O2"
              labelCol={{ span: 24 }}
            >
              <Input type="number" suffix="%" max={100} inputMode="numeric" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Form.Item
              name="temperature"
              label="Temperatura"
              labelCol={{ span: 24 }}
            >
              <Input type="number" suffix="°C" step={0.1} min={32} max={45} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Form.Item
              name="breathingFrequency"
              label="Frecuencia respiratoria"
              labelCol={{ span: 24 }}
            >
              <Input type="number" max={99} inputMode="numeric" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="observations"
              label="Observaciones"
              labelCol={{ span: 24 }}
            >
              <Input.TextArea maxLength={120} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
                loading={loading}
                disabled={loading}
              >
                Agregar Control
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
