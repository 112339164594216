import { basePath, apiVersion } from './config';

export async function getBedsApi(type, token) {
  const url = `${basePath}/${apiVersion}/beds-free/${type}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function getStatsBedsApi(token) {
  const url = `${basePath}/${apiVersion}/all-beds`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function getAllBedsApi(token, type) {
  const url = `${basePath}/${apiVersion}/beds/${type}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function updateBedStatus(token, bedId, status, disable) {
  const url = disable
    ? `${basePath}/${apiVersion}/disable-bed/${bedId}/${status}`
    : `${basePath}/${apiVersion}/update-bed/${bedId}/${status}`;
  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}
