import React, { useState, useEffect } from 'react';
import { Button, Table, Tag, Tooltip, Form, Input, message } from 'antd';
import { getAccessTokenApi } from '../../api/auth';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  EditOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Modal from '../Modal';
import { addDetailBillingInTurn, getBilling } from '../../api/billing';

export default function BillingDetailNotIn({ billingId }) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [turnsDetailNotIn, setTurnsDetailNotIn] = useState([]);

  const [descriptionForm] = Form.useForm();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let token = await getAccessTokenApi();
      await getBilling({ token, id: billingId }).then((response) => {
        console.log('🚀 ~ awaitgetBilling ~ response:', response);
        setTurnsDetailNotIn(response?.turnsDetail?.turnsDetailNotIn);
      });
      setLoading(false);
    };

    getData();
  }, [isVisibleModal]);

  const addDescription = (turn) => {
    const onFinish = async (values) => {
      setLoading(true);
      let token = getAccessTokenApi();

      await addDetailBillingInTurn({
        token,
        turnId: turn._id,
        shiftId: turn.shiftId,
        data: values,
      })
        .then((response) => {
          if (response?.code !== 200) {
            message.error(response.message);
          } else {
            message.success(response.message);

            descriptionForm.resetFields();
          }
        })
        .then(() => {
          setIsVisibleModal(false);
          setLoading(false);
        })
        .catch((err) => message.error('Error del servidor'));
    };

    setIsVisibleModal(true);
    setModalTitle('Agregar descripción');
    setModalContent(
      <Form form={descriptionForm} onFinish={onFinish}>
        <Form.Item
          name="billingDescription"
          initialValue={turn?.billingDescription}
          rules={[{ required: true, message: 'Debe escribir una descripción' }]}
        >
          <Input.TextArea
            placeholder="Agregar descripción de porque no se incluyo el turno el la facturación"
            maxLength={80}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="default" style={{ width: '100%' }}>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div>
      <Table
        columns={[
          {
            title: 'Fecha',
            dataIndex: 'hourStart',
            key: 'hourStart',
            align: 'center',
            width: '5%',
            render: (hourStart) => (
              <span>{moment(hourStart).format('DD/MM/YY HH:mm')}</span>
            ),
            sorter: (a, b) =>
              moment(a.hourStart).unix() - moment(b.hourStart).unix(),
          },
          {
            title: 'Paciente',
            dataIndex: 'patientName',
            key: 'patientName',
            width: '20%',
            render: (patientName) => (
              <span style={{ textTransform: 'capitalize' }}>{patientName}</span>
            ),
          },
          {
            title: 'Nro de Afiliado',
            dataIndex: 'affiliate',
            key: 'affiliate',
            width: '10%',
            sorter: (a, b) => a?.affiliate?.localeCompare(b?.affiliate),
          },
          {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '5%',
            render: (status) => (
              <Tag color={status !== 'Atendido' ? 'error' : 'success'}>
                {status || 'Sin estado'}
              </Tag>
            ),
            sorter: (a, b) => a?.status?.localeCompare(b?.status),
          },
          {
            title: 'Estudio',
            dataIndex: 'studies',
            key: 'studies',
            render: (studies) => <span>{studies?.name}</span>,
            sorter: (a, b) => a.studies?.localeCompare(b.studies),
          },
          {
            title: 'Orden médica',
            dataIndex: 'medicalOrder',
            key: 'medicalOrder',
            width: '5%',
            align: 'center',
            render: (medicalOrder) =>
              medicalOrder ? (
                <Tooltip title="Presento orden médica">
                  <CheckCircleOutlined style={{ color: 'green' }} />
                </Tooltip>
              ) : (
                <Tooltip title="No presento orden médica">
                  <CloseCircleOutlined style={{ color: 'red' }} />
                </Tooltip>
              ),
          },
          {
            title: 'Destacado',
            dataIndex: 'important',
            key: 'important',
            align: 'center',
            width: '5%',
            render: (important) => (
              <span>{important && <CheckOutlined />}</span>
            ),
          },
          {
            title: 'Detalle',
            dataIndex: 'billingDescription',
            key: 'billingDescription',
            align: 'center',
            width: '15%',
          },
          {
            title: 'Acciones',
            dataIndex: 'shiftId',
            key: 'shiftId',
            align: 'center',
            width: '5%',
            render: (text, record, index) => (
              <Button
                type="link"
                onClick={() => {
                  addDescription(record);
                }}
              >
                <EditOutlined />
              </Button>
            ),
          },
        ]}
        dataSource={turnsDetailNotIn}
        bordered
        scrollY={2}
        locale={{ emptyText: 'No hay datos' }}
        className="table"
        pagination={{ pageSize: 20, responsive: true, size: 'small' }}
        loading={
          loading && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
