import React, { useState } from 'react';
import { Row, Col, Form, Input, Card, Button } from 'antd';

export function Heparina() {
  const [kpptObjetivo, setKpptObjetivo] = useState();
  const [mantenimiento, setMantenimiento] = useState();
  const [ajuste, setAjuste] = useState();

  const [dosisForm] = Form.useForm();
  const [ajusteForm] = Form.useForm();

  const calculateDosis = (values) => {
    const inf = values.kpptBasal * 1.5;
    const sup = values.kpptBasal * 2.5;
    setKpptObjetivo({ inf, sup });
    setMantenimiento(
      ((values.dosisDeseada * values.peso) / values.unidadesDiluidas) *
        values.cantidadSolucion
    );
  };

  const calculateAjuste = (values) => {
    if (!dosisForm.getFieldValue('kpptBasal')) {
      setAjuste('Primero debe calcular dosis de inicio');
      return;
    }

    if (
      values.kpptControl >= kpptObjetivo.inf &&
      values.kpptControl <= kpptObjetivo.sup
    ) {
      setAjuste('Sin cambios');
    } else if (
      values.kpptControl >= dosisForm.getFieldValue('kpptBasal') * 1.2 &&
      values.kpptControl <= dosisForm.getFieldValue('kpptBasal') * 1.5
    ) {
      const value =
        ((dosisForm.getFieldValue('dosisDeseada') + 2) * values.mantenimiento) /
        dosisForm.getFieldValue('dosisDeseada');
      setAjuste(`Aumentar infusión a ${value.toFixed(2)} ml/hs`);
    } else if (
      values.kpptControl <
      dosisForm.getFieldValue('kpptBasal') * 1.2
    ) {
      const value =
        ((dosisForm.getFieldValue('dosisDeseada') + 4) * values.mantenimiento) /
        dosisForm.getFieldValue('dosisDeseada');
      setAjuste(`Aumentar infusión a ${value.toFixed(2)} ml/hs`);
    } else if (
      values.kpptControl >= dosisForm.getFieldValue('kpptBasal') * 2.5 &&
      values.kpptControl <= dosisForm.getFieldValue('kpptBasal') * 3
    ) {
      const value =
        ((dosisForm.getFieldValue('dosisDeseada') - 2) * values.mantenimiento) /
        dosisForm.getFieldValue('dosisDeseada');
      setAjuste(`Disminuir infusión a ${value.toFixed(2)} ml/hs`);
    } else if (values.kpptControl > dosisForm.getFieldValue('kpptBasal') * 3) {
      const value =
        ((dosisForm.getFieldValue('dosisDeseada') - 3) * values.mantenimiento) /
        dosisForm.getFieldValue('dosisDeseada');
      setAjuste(
        `Suspender la infusión durante 1 h y después reducirla a ${value.toFixed(
          2
        )} ml/hs`
      );
    }
  };
  return (
    <div>
      <h2>Pasos: </h2>
      <h1>
        1 - Administrar bolo de 80 U/kg, generalizar en 5000 Unidades (1ml)
      </h1>
      <h1>
        2 - Comenzar con infusión de 12- 18 U/Kg/hs (según patología e
        indicación medica)
      </h1>
      <h1>
        3 – Control de KPTT a las 6 hs y luego cada 6 hs hasta estabilizarlo
        (control cada 24 hs)
      </h1>
      <h1>
        Se brindan las siguientes herramientas para facilitar el calculo:{' '}
      </h1>
      <Row gutter={24} style={{ marginTop: '2%' }}>
        <Col lg={12}>
          <Form form={dosisForm} onFinish={calculateDosis}>
            <Card title="Dosis de inicio" headStyle={{ textAlign: 'center' }}>
              <Col span={24}>
                <Form.Item
                  name="peso"
                  label="Peso del paciente"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar el peso.',
                    },
                  ]}
                >
                  <Input type="number" suffix="kg" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="kpptBasal"
                  label="KPPT basal"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar el kppt basal.',
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="unidadesDiluidas"
                  label="Unidades diluidas"
                  labelCol={{ span: 24 }}
                  initialValue={20000}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar las unidades diluidas.',
                    },
                  ]}
                >
                  <Input type="number" suffix="unidades" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="cantidadSolucion"
                  label="Cantidad de solución"
                  labelCol={{ span: 24 }}
                  initialValue={250}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar cantidad de solución.',
                    },
                  ]}
                >
                  <Input type="number" suffix="ml" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="dosisDeseada"
                  label="Dosis deseada"
                  labelCol={{ span: 24 }}
                  initialValue={18}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar dosis deseada.',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    max={18}
                    min={12}
                    suffix="unidades/kg/hs"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="default"
                    htmlType="submit"
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    Calcular
                  </Button>
                </Form.Item>
              </Col>
              {mantenimiento && (
                <>
                  <h1>Mantenimiento: {mantenimiento} ml/hs</h1>
                  <h1>
                    KPPT Objetivo entre: {kpptObjetivo.inf} y {kpptObjetivo.sup}
                  </h1>
                </>
              )}
            </Card>
          </Form>
        </Col>
        <Col lg={12}>
          <Form form={ajusteForm} onFinish={calculateAjuste}>
            <Card title="Ajuste" headStyle={{ textAlign: 'center' }}>
              <Col span={24}>
                <Form.Item
                  name="kpptControl"
                  label="KPPT control"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar kppt control.',
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="mantenimiento"
                  label="Mantenimiento actual"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar el mantenimiento actual.',
                    },
                  ]}
                >
                  <Input type="number" suffix="ml/hs" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="default"
                    htmlType="submit"
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    Calcular
                  </Button>
                </Form.Item>
              </Col>
              {ajuste && <h1>{ajuste}</h1>}
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
