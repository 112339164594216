import {
  Form,
  InputNumber,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
  Divider,
  Button,
  Card,
  message,
  Tooltip,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getAccessTokenApi } from '../../api/auth';
import { addPharmacyApi, getPharmacyStockApi } from '../../api/pharmacy';
import BackButton from '../../components/BackButton';
import locale from 'antd/es/date-picker/locale/es_ES';
import { useSelector } from 'react-redux';
import Spin from '../../components/Spin';

export default function NewSendPharmacy() {
  const [stock, setStock] = useState([]);
  const [selectProvider, setSelectProvider] = useState();
  const [quantityMax, setQuantityMax] = useState([]);
  const [loading, setLoading] = useState(false);

  const [sendForm] = Form.useForm();

  const { name, lastname } = useSelector((store) => store.userData?.user);
  const { drugStores } = useSelector((store) => store.tenantData);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let token = await getAccessTokenApi();
      await getPharmacyStockApi({
        token,
        store: selectProvider,
        expire: 'true',
        extra: 'name',
      }).then((response) => {
        if (response.code === 200) {
          setStock(response.dataStock);
          sendForm.setFieldsValue({ products: [{}] });
        } else {
          message.error('Error al consultar stock');
        }
        setLoading(false);
      });
    };

    selectProvider && getData();
  }, [selectProvider]);

  const onFinish = async (values) => {
    values.type = 'send';
    values.user = `${name} ${lastname}`;

    let token = await getAccessTokenApi();

    addPharmacyApi({ token, pharmacyData: values })
      .then(async (response) => {
        if (response?.code !== 200) {
          message.error(response.message);
        } else {
          message.success(response.message);
          window.history.back();
        }
      })
      .catch(() => {
        message.error('Error del servidor, intente mas tarde.');
      });
  };

  if (loading) return <Spin />;

  return (
    <div>
      <Card
        title={
          <>
            <BackButton /> <span>Nuevo Envio</span>
          </>
        }
      >
        <Form form={sendForm} onFinish={onFinish}>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={6}>
              <Form.Item
                name="date"
                initialValue={moment()}
                rules={[{ required: true, message: 'Debe cargar la fecha' }]}
                labelCol={{ span: 24 }}
                label="Fecha de Envio"
              >
                <DatePicker
                  locale={locale}
                  style={{ width: '100%' }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="store"
                rules={[
                  { required: true, message: 'Debe elegir un proveedor' },
                ]}
                labelCol={{ span: 24 }}
                label="Origen"
              >
                <Select
                  placeholder="Seleccione el origen del envio.. (*)"
                  showSearch
                  optionFilterProp="children"
                  onChange={(value) => setSelectProvider(value)}
                >
                  {drugStores.map((store) => (
                    <Select.Option value={store}>{store}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={10}>
              <Form.Item
                name="name"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: 'Debe indicar un titulo a la compra',
                  },
                ]}
                labelCol={{ span: 24 }}
                label="Concepto"
              >
                <Input placeholder="Envio mensual diciembre 2023" />
              </Form.Item>
            </Col>
          </Row>

          <Divider>Datos Productos</Divider>
          <Form.List name="products">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Row gutter={24} key={index}>
                      <Col xs={23} md={18}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'prodExpire']}
                          rules={[
                            {
                              required: true,
                              message: 'Debe elegir el producto',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Seleccione un producto.. (*)"
                            optionFilterProp="children"
                            onChange={(e, o) => {
                              setQuantityMax([...quantityMax, o.children[2]]);
                              let data =
                                sendForm.getFieldValue().products[field.name];
                              Object.assign(data, {
                                product: e.split('|')[0],
                                name: o.key.split('|')[0],
                                dateExpire: o.key.split('|')[1],
                              });
                            }}
                          >
                            {stock.map((product) => (
                              <Select.Option
                                key={`${product._id.name}|${product._id.dateExpire}`}
                                value={`${product._id?.productId}|${product._id.dateExpire}`}
                                disabled={sendForm
                                  .getFieldsValue()
                                  .products?.find(
                                    (i) =>
                                      i?.product === product._id?.productId &&
                                      i?.dateExpire === product._id?.dateExpire
                                  )}
                              >
                                {product._id.name} (Stock: {product.quantity}){' '}
                                {product._id.dateExpire &&
                                  `- ${product._id.dateExpire}`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={23} md={5}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'quantityIndicate']}
                          rules={[
                            {
                              required: true,
                              message: 'Debe cargar la cantidad',
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            placeholder="Cantidad (*)"
                            type="number"
                            inputMode="numeric"
                            min={1}
                            max={quantityMax[field.name]}
                            onChange={(e) => {
                              let data =
                                sendForm.getFieldValue().products[field.name];
                              Object.assign(data, { quantity: e * -1 });
                            }}
                          />
                        </Form.Item>
                      </Col>

                      {fields.length > 1 ? (
                        <Col span={1} style={{ paddingLeft: '0' }}>
                          <Tooltip title="Eliminar item">
                            <Button
                              type="link"
                              size="large"
                              onClick={() => {
                                setQuantityMax([
                                  ...quantityMax.slice(0, field.name),
                                  ...quantityMax.slice(field.name + 1),
                                ]);

                                remove(field.name);
                              }}
                            >
                              <MinusCircleOutlined />
                            </Button>
                          </Tooltip>
                        </Col>
                      ) : null}
                    </Row>
                  ))}
                  <Form.Item style={{ textAlign: 'right' }}>
                    <Tooltip
                      title={
                        selectProvider
                          ? 'Agregar nuevo item'
                          : 'Debe elegir el origen'
                      }
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        onClick={() => {
                          add();
                        }}
                        size="large"
                        disabled={!selectProvider}
                      >
                        <PlusOutlined />
                      </Button>
                    </Tooltip>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>

          <Form.Item>
            <Button className="button-submit" type="primary" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
