import React, { useState, useEffect } from 'react';
import { onUserDataRead, onMessageRead, onStatsBedsRead } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import './Home.scss';
import {
  Statistic,
  Col,
  Row,
  Badge,
  Button,
  message,
  Divider,
  notification,
  Tabs,
} from 'antd';
import '../../scss/primaryButton.scss';
import '../../scss/secondButton.scss';
import '../../scss/linkButton.scss';
import MessageList from '../../components/Message/MessageList';
import CardOcupation from '../../components/CardOcupation';
import Spin from '../../components/Spin';
import { MessageOutlined } from '@ant-design/icons';
import Modal from '../../components/Modal';
import { KpiChart } from '../../components/Charts';
import moment from 'moment';
import { getTurnsStatsApi } from '../../api/stats';
import { getAccessTokenApi } from '../../api/auth';

export default function Home() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [ambulatoryStats, setAmbulatoryStats] = useState(null);
  const [ambulatoryOrganizaitonStats, setAmbulatoryOrganizationStats] =
    useState(null);
  const [readAmbulatory, setReadAmbulatory] = useState(false);

  const dispatch = useDispatch();
  const { email, name, lastname, type, _id, role } = useSelector(
    (store) => store.userData?.user
  );
  const { loading, incomes } = useSelector((store) => store.userData);
  const { messages } = useSelector((store) => store.messageData);
  const allBeds = useSelector((store) => store.bedData?.bedsStats);
  const loadingStats = useSelector(
    (store) => store.bedData?.bedsStats?.loading
  );

  const {
    floors,
    tenant,
    logos,
    organizationType,
    tenantType,
    quantitySchedules,
    studies,
    tenantLogo,
  } = useSelector((store) => store.tenantData);

  const loadingFloors = useSelector((store) => store.tenantData?.loading);

  useEffect(() => {
    if (!email) {
      dispatch(onUserDataRead());
    }
    organizationType === 'hospitalaria' && dispatch(onStatsBedsRead());

    type && dispatch(onMessageRead(type));
  }, [email]);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();

      type !== 'Administrativo' &&
        getTurnsStatsApi({
          token,
          type: 'ambulatoryHome',
          year: moment().format('YYYY'),
          month: moment().format('MM'),
          id: _id,
          day: moment().format('D'),
        }).then((response) => {
          if (response?.code !== 200) {
            notification['error']({ message: response.message });
          } else {
            setAmbulatoryStats(response.data);
          }
        });

      ((role === 2 &&
        (tenantType !== 'personal' ||
          quantitySchedules > 1 ||
          studies?.length > 0)) ||
        role === 0) &&
        getTurnsStatsApi({
          token,
          type: 'ambulatoryHome',
          year: moment().format('YYYY'),
          month: moment().format('MM'),
          day: moment().format('D'),
        }).then((response) => {
          if (response?.code !== 200) {
            notification['error']({ message: response.message });
          } else {
            setAmbulatoryOrganizationStats(response.data);
          }
        });
    };

    type !== 'Enfermero' &&
      email &&
      (organizationType === 'ambulatoria' || readAmbulatory) &&
      getData();
  }, [readAmbulatory, email]);

  if (loading || loadingStats || loadingFloors) {
    return <Spin />;
  }

  const showMessages = () => {
    setIsVisibleModal(true);
    setModalTitle('Mensajes');
    setModalContent(
      <MessageList messages={messages} setIsVisibleModal={setIsVisibleModal} />
    );
  };

  return (
    <div className="home">
      <div className="sm-responsive">
        <h6>{`¡ Bienvenido ${name} ${lastname} !`}</h6>
        <div className="messages">
          <Badge count={messages?.length}>
            <Button
              type="link"
              onClick={() => {
                messages?.length > 0
                  ? showMessages()
                  : message.info('No hay mensajes');
              }}
            >
              <MessageOutlined />
            </Button>
          </Badge>
        </div>
      </div>
      <div>
        <Row>
          <Col xs={24} md={12}>
            <Tabs
              type="line"
              centered
              onTabClick={(e) => e == 2 && setReadAmbulatory(true)}
            >
              {organizationType === 'hospitalaria' && (
                <Tabs.TabPane tab="Internados" key={1}>
                  <Col span={24}>
                    <Row>
                      <Col xs={12}>
                        <CardOcupation
                          title="General"
                          data={{
                            _id: 'all',
                            ocupate: allBeds?.reduce(
                              (p, a) => p + a.ocupate,
                              0
                            ),
                            free: allBeds?.reduce((p, a) => p + a.free, 0),
                            quantity: allBeds?.reduce(
                              (p, a) => p + a.quantity,
                              0
                            ),
                          }}
                        />
                      </Col>
                      {floors?.map((floor, index) => (
                        <Col xs={12}>
                          <CardOcupation
                            title={floor.name}
                            data={allBeds?.find((b) => b._id === floor.id)}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Tabs.TabPane>
              )}
              {type !== 'Enfermero' && (
                <Tabs.TabPane tab="Ambulatorio" key={2}>
                  {
                    <>
                      {type !== 'Administrativo' && (
                        <>
                          <Divider>Mis Turnos</Divider>
                          <Row>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Visitas hoy ${moment().format(
                                  'DD'
                                )}/${moment().format('MM')}`}
                                value={ambulatoryStats?.data?.cantidad}
                                info="Cantidad de turnos asignados al dia de hoy"
                              />
                            </Col>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Atendidos hoy ${moment().format(
                                  'DD'
                                )}/${moment().format('MM')}`}
                                value={ambulatoryStats?.data?.atendido}
                                percent={ambulatoryStats?.data?.cantidad}
                                info="Turnos marcados como atendidos hoy"
                              />
                            </Col>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Pendientes hoy ${moment().format(
                                  'DD'
                                )}/${moment().format('MM')}`}
                                value={ambulatoryStats?.data?.pendiente}
                                percent={ambulatoryStats?.data?.cantidad}
                                info="Turnos sin estado hoy, es decir estan pendientes"
                              />
                            </Col>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Mañana ${moment()
                                  .add(1, 'd')
                                  .format('DD')}/${moment()
                                  .add(1, 'd')
                                  .format('MM')}`}
                                value={ambulatoryStats?.dataNextDay?.cantidad}
                                info="Turnos agendados para mañana"
                              />
                            </Col>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Visitas en ${moment().format('MMMM')}`}
                                value={ambulatoryStats?.dataMonth?.cantidad}
                                info="Turnos agendados en el mes"
                              />
                            </Col>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Pendientes en ${moment().format(
                                  'MMMM'
                                )}`}
                                value={ambulatoryStats?.dataMonth?.pendiente}
                                percent={ambulatoryStats?.dataMonth?.cantidad}
                                info="Turnos pendientes en el mes, que aun no se atendieron"
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      {((role === 2 &&
                        (tenantType !== 'personal' ||
                          quantitySchedules > 1 ||
                          studies?.length > 0)) ||
                        type === 'Administrativo') && (
                        <>
                          <Divider>Todo el Centro</Divider>
                          <Row>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Visitas hoy ${moment().format(
                                  'DD'
                                )}/${moment().format('MM')}`}
                                value={
                                  ambulatoryOrganizaitonStats?.data?.cantidad
                                }
                                info="Cantidad de turnos asignados al dia de hoy en todo el centro"
                              />
                            </Col>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Atendidos hoy ${moment().format(
                                  'DD'
                                )}/${moment().format('MM')}`}
                                value={
                                  ambulatoryOrganizaitonStats?.data?.atendido
                                }
                                percent={
                                  ambulatoryOrganizaitonStats?.data?.cantidad
                                }
                                info="Turnos marcados como atendidos hoy en todo el centro"
                              />
                            </Col>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Pendientes hoy ${moment().format(
                                  'DD'
                                )}/${moment().format('MM')}`}
                                value={
                                  ambulatoryOrganizaitonStats?.data?.pendiente
                                }
                                percent={
                                  ambulatoryOrganizaitonStats?.data?.cantidad
                                }
                                info="Turnos sin estado hoy, es decir estan pendientes, en todo el centro"
                              />
                            </Col>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Mañana ${moment()
                                  .add(1, 'd')
                                  .format('DD')}/${moment()
                                  .add(1, 'd')
                                  .format('MM')}`}
                                value={
                                  ambulatoryOrganizaitonStats?.dataNextDay
                                    ?.cantidad
                                }
                                info="Turnos agendados para mañana en todo el centro"
                              />
                            </Col>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Visitas en ${moment().format('MMMM')}`}
                                value={
                                  ambulatoryOrganizaitonStats?.dataMonth
                                    ?.cantidad
                                }
                                info="Turnos agendados en el mes en todo el centro"
                              />
                            </Col>
                            <Col xs={12} xl={8}>
                              <KpiChart
                                title={`Pendientes en ${moment().format(
                                  'MMMM'
                                )}`}
                                value={
                                  ambulatoryOrganizaitonStats?.dataMonth
                                    ?.pendiente
                                }
                                percent={
                                  ambulatoryOrganizaitonStats?.dataMonth
                                    ?.cantidad
                                }
                                info="Turnos pendientes en el mes, que aun no se atendieron en el centro"
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  }
                </Tabs.TabPane>
              )}
            </Tabs>
          </Col>
          <Col sm={12} className="md-responsive">
            <div>
              {messages && messages.length > 0 && (
                <Col span={24}>
                  <MessageList messages={messages} />
                </Col>
              )}
              <h3>{`¡ Bienvenido ${name} ${lastname} !`}</h3>
              {organizationType === 'hospitalaria' && (
                <Statistic
                  className="home-statistic"
                  value={incomes}
                  valueStyle={{
                    color: 'rgba(0, 0, 0, 0.45)',
                    fontSize: '1em',
                  }}
                  prefix={
                    <span
                      style={{
                        color: 'rgba(0, 0, 0, 0.45)',
                        fontSize: '1em',
                        padding: 5,
                      }}
                    >
                      Ingresos Realizados:
                    </span>
                  }
                />
              )}
              {logos === 2 ? (
                <div className="home-logo">
                  <div className="home-logo-left">
                    <img
                      src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo.png`}
                      alt="Logo"
                    />
                  </div>
                  <div className="home-logo-rigth">
                    <img
                      src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo1.png`}
                      alt="Logo"
                    />
                  </div>
                </div>
              ) : (
                <div className="home-logo">
                  <img
                    src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/${tenantLogo}.png`}
                    alt="Logo"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'90%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
