import { basePath, apiVersion } from './config';

export async function getServicesApi(token) {
  const url = `${basePath}/${apiVersion}/services`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function addServiceApi(token, serviceData) {
  const url = `${basePath}/${apiVersion}/service`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(serviceData),
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function updateServiceApi(id, serviceData, token) {
  const url = `${basePath}/${apiVersion}/update-service/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(serviceData),
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function deleteServiceApi(id, token) {
  const url = `${basePath}/${apiVersion}/service/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}
