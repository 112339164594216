import React, { useEffect, useState } from 'react';
import { Calendar, Radio } from 'antd';
import Modal from '../../components/Modal';
import moment from 'moment';
import './Calendar.scss';
import CalendarForm from '../../components/CalendarForm';
import locale from 'antd/es/date-picker/locale/es_ES';
import { getCalendarApi } from '../../api/calendar';
import PermissionsAsigner from '../../components/PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import Spin from '../../components/Spin';
import { getAccessTokenApi } from '../../api/auth';

export default function Calendar1() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [editable, setEditable] = useState(false);
  const [dates, setDates] = useState();
  const [month, setMonth] = useState(moment().format('MMMM'));
  const [year, setYear] = useState(moment().format('YYYY'));

  const [reload, setReload] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      getCalendarApi(token, year).then((response) => {
        setDates(response.data);
      });
      setReload(false);
    };
    getData();
  }, [reload, month]);

  const addData = (value) => {
    let data = dates.find((date) =>
      moment(value._d)
        .startOf('day')
        .isSame(moment(date.date).startOf('day'), 'day')
    );
    // Arreglo por inconsistencia en tipos de datos de string a objeto
    if (typeof data?.users[0] === 'string') {
      let a = [];
      data.users.map((d) => {
        a.push({ name: d });
      });
      data.users = a;
    }

    setIsVisibleModal(true);
    setModalTitle(`${moment(value._d).format('DD-MM-YYYY')}`);
    setModalContent(
      <CalendarForm
        date={value}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        data={data}
      />
    );
  };

  if (!dates) {
    return <Spin />;
  }

  const viewDate = (value) => {
    const day = dates.find((date) =>
      moment(value._d)
        .startOf('day')
        .isSame(moment(date.date).startOf('day'), 'day')
    );
    setIsVisibleModal(true);
    setModalTitle(`${moment(value._d).format('DD-MM-YYYY')}`);
    setModalContent(
      day?.users || day?.description ? (
        <>
          <h3>{day?.description}</h3>
          <ul className="events">
            {day?.users?.map((item) => (
              <li key={item} style={{ marginBottom: '1%' }}>
                <span
                  style={{
                    backgroundColor:
                      item?.type === 'Activa'
                        ? '#FF9AA2'
                        : item?.type === 'Pasiva'
                        ? '#FFDAC1'
                        : item?.type === 'Residente' && '#E2F0CB',
                    padding: 5,
                    borderRadius: 4,
                  }}
                >
                  {typeof item === 'string'
                    ? item
                    : `${item?.name} ${item?.type ? ` - ${item?.type}` : ''}`}
                </span>
              </li>
            ))}
          </ul>
        </>
      ) : (
        'No hay datos cargados'
      )
    );
  };

  function getListData(value) {
    let listData;

    const day = dates.find((date) =>
      moment(value._d)
        .startOf('day')
        .isSame(moment(date.date).startOf('day'), 'day')
    );
    if (day) {
      listData = { users: day.users, description: day.description };
    }

    return listData || [];
  }

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <>
        <p>{listData?.users?.length}</p>
        <h3>{listData?.description}</h3>
        <ul className="events">
          {listData?.users?.map((item) => (
            <>
              <li key={item} style={{ marginBottom: '4%' }}>
                <span
                  style={{
                    backgroundColor:
                      item?.type === 'Activa'
                        ? '#FF9AA2'
                        : item?.type === 'Pasiva'
                        ? '#FFDAC1'
                        : item?.type === 'Residente' && '#E2F0CB',
                    padding: 5,
                    borderRadius: 4,
                  }}
                >
                  {typeof item === 'string'
                    ? item
                    : `${item?.name} ${item?.type ? ` - ${item?.type}` : ''}`}
                </span>
              </li>
            </>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div className="calendar">
      <div className="calendar-header">
        <h1 style={{ textTransform: 'capitalize' }}>
          Calendario Guardias - {month} / {year}
        </h1>
        <PermissionsAsigner requiredRole={ROLES.SECRETARY}>
          <Radio.Group defaultValue={editable} buttonStyle="solid">
            <Radio.Button value={false} onClick={() => setEditable(false)}>
              Modo Ver
            </Radio.Button>
            <Radio.Button value={true} onClick={() => setEditable(true)}>
              Modo Edición
            </Radio.Button>
          </Radio.Group>
        </PermissionsAsigner>
      </div>

      <div className="site-calendar-demo-card">
        <Calendar
          onSelect={(date) => (editable ? addData(date) : viewDate(date))}
          dateCellRender={dateCellRender}
          locale={locale}
          onChange={(date) => {
            setMonth(moment(date).format('MMMM'));
            setYear(moment(date).format('YYYY'));
          }}
        />
      </div>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
