import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  HistoryOutlined,
  LineChartOutlined,
  CalculatorOutlined,
  PhoneOutlined,
  WarningOutlined,
  CalendarOutlined,
  UnorderedListOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  ShopOutlined,
  DollarOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import './MenuSider.scss';
import PermissionsAsigner from '../PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import { useSelector } from 'react-redux';
import Spin from '../Spin';

function MenuSider(props) {
  const { menuCollapsed, setMenuCollapsed, location, typeMenu } = props;

  const { Sider } = Layout;
  const { SubMenu } = Menu;

  const { floors, loadingFloors, drugStores, billingOrganizations } =
    useSelector((store) => store.tenantData);

  if (loadingFloors) {
    return <Spin />;
  }

  let menuHosp = (
    <Menu
      theme="dark"
      mode="vertical"
      defaultSelectedKeys={[location.pathname]}
      selectedKeys={[location.pathname]}
      style={{ zIndex: 1 }}
    >
      <Menu.Item key="/">
        <Link to={'/'}>
          <HomeOutlined /> <span className="navText">Home</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/ambulatory">
        <Link to={'/ambulatory'}>
          <ReconciliationOutlined /> <span className="navText">Pacientes</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/turns">
        <PermissionsAsigner requiredRole={ROLES.VIEW}>
          <Link to={'/turns'}>
            <ScheduleOutlined /> <span className="navText">Turnos</span>
          </Link>
        </PermissionsAsigner>
      </Menu.Item>
      <SubMenu
        key="/income/:incomeType"
        icon={<UnorderedListOutlined />}
        title="Internados"
      >
        {floors?.map((floor) => (
          <Menu.Item key={`/income/${floor.id}`}>
            <Link to={`/income/${floor.id}`}>
              <span className="navText">{floor.name}</span>
            </Link>
          </Menu.Item>
        ))}
      </SubMenu>
      <Menu.Item key="/incomes-history">
        <PermissionsAsigner requiredRole={ROLES.VIEW}>
          <Link to={'/incomes-history'}>
            <HistoryOutlined /> <span className="navText">Historial</span>
          </Link>
        </PermissionsAsigner>
      </Menu.Item>

      <Menu.Item key="/calendar">
        <PermissionsAsigner requiredRole={ROLES.VIEW}>
          <Link to={'/calendar'}>
            <CalendarOutlined />{' '}
            <span className="navText">Calendario Guardias</span>
          </Link>
        </PermissionsAsigner>
      </Menu.Item>
      {billingOrganizations && (
        <Menu.Item key="/billing">
          <Link to={'/billing'}>
            <DollarOutlined /> <span className="navText">Facturación</span>
          </Link>
        </Menu.Item>
      )}
      {drugStores && (
        <Menu.Item key="/pharmacy">
          <Link to={'/pharmacy'}>
            <ShopOutlined /> <span className="navText">Farmacia</span>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="/charts">
        <Link to={'/charts'}>
          <LineChartOutlined /> <span className="navText">Estadisticas</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/users">
        <Link to={'/users'}>
          <UserOutlined /> <span className="navText">Usuarios</span>
        </Link>
      </Menu.Item>
      <SubMenu icon={<MoreOutlined />} title="Otros">
        <Menu.Item key="/calculator">
          <PermissionsAsigner requiredRole={ROLES.VIEW}>
            <Link to={'/calculator'}>
              <CalculatorOutlined />{' '}
              <span className="navText">Calculadora</span>
            </Link>
          </PermissionsAsigner>
        </Menu.Item>
        <Menu.Item key="/protocolo">
          <PermissionsAsigner requiredRole={ROLES.VIEW}>
            <Link to={'/protocolo'}>
              <WarningOutlined /> <span className="navText">Protocolos</span>
            </Link>
          </PermissionsAsigner>
        </Menu.Item>
        <Menu.Item key="/phones">
          <PermissionsAsigner requiredRole={ROLES.VIEW}>
            <Link to={'/phones'}>
              <PhoneOutlined />{' '}
              <span className="navText">Telefonos Utiles</span>
            </Link>
          </PermissionsAsigner>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
  let menuAmb = (
    <Menu
      theme="dark"
      mode="vertical"
      defaultSelectedKeys={[location.pathname]}
      selectedKeys={[location.pathname]}
      style={{ zIndex: 1 }}
    >
      <Menu.Item key="/">
        <Link to={'/'}>
          <HomeOutlined /> <span className="navText">Home</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/ambulatory">
        <Link to={'/ambulatory'}>
          <ReconciliationOutlined /> <span className="navText">Pacientes</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/turns">
        <Link to={'/turns'}>
          <ScheduleOutlined /> <span className="navText">Turnos</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/calendar">
        <Link to={'/calendar'}>
          <CalendarOutlined /> <span className="navText">Calendario</span>
        </Link>
      </Menu.Item>
      {billingOrganizations && (
        <Menu.Item key="/billing">
          <Link to={'/billing'}>
            <DollarOutlined /> <span className="navText">Facturación</span>
          </Link>
        </Menu.Item>
      )}
      {drugStores && (
        <Menu.Item key="/pharmacy">
          <Link to={'/pharmacy'}>
            <ShopOutlined /> <span className="navText">Farmacia</span>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="/charts">
        <Link to={'/charts'}>
          <LineChartOutlined />
          <span className="navText">Estadisticas</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/users">
        <Link to={'/users'}>
          <UserOutlined /> <span className="navText">Usuarios</span>
        </Link>
      </Menu.Item>
      <SubMenu icon={<MoreOutlined />} title="Otros">
        <Menu.Item key="/protocolo">
          <Link to={'/protocolo'}>
            <WarningOutlined /> <span className="navText">Protocolos</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/phones">
          <Link to={'/phones'}>
            <PhoneOutlined /> <span className="navText">Telefonos Utiles</span>
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );

  return (
    <Sider
      className="admin-sider"
      collapsed={menuCollapsed}
      breakpoint="lg"
      onBreakpoint={(broken) => {
        setMenuCollapsed(broken);
      }}
    >
      {typeMenu ? menuAmb : menuHosp}
    </Sider>
  );
}

export default withRouter(MenuSider);
