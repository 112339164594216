import React from 'react';
import { Text } from '@react-pdf/renderer';
import { styleBody } from './styles';

export function PdfTitle({ title }) {
  return (
    <Text style={styleBody.title} fixed>
      {title}
    </Text>
  );
}
