import { saveAs } from 'file-saver';
import { write, utils } from 'xlsx';

export function exportToExcel(data, fileName) {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Convert workbook to binary array
  const excelBuffer = write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });

  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
}
