import React from 'react';
import { Collapse } from 'antd';
import {
  RespiratorConfig,
  DosisFarmacos,
  ToolsFarmacos,
  Heparina,
  Imc,
  Chads2,
} from '../../components/Calculator';

export default function Calculator() {
  return (
    <div>
      <Collapse>
        <Collapse.Panel header="Configuración inicial del respirador">
          <RespiratorConfig />
        </Collapse.Panel>
        <Collapse.Panel header="Dosis de fármacos comunes">
          <DosisFarmacos />
          <ToolsFarmacos />
        </Collapse.Panel>
        <Collapse.Panel header="Infusión de heparina">
          <Heparina />
        </Collapse.Panel>
        <Collapse.Panel header="IMC">
          <Imc />
        </Collapse.Panel>
        <Collapse.Panel header="CHADS2-VASc Score">
          <Chads2 />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
