import React, { useEffect } from 'react';
import { Form, Button, Input } from 'antd';
import { onIncomeAddNews } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';

export default function NewsForm(props) {
  const { income, setIsVisibleModal, incomeType } = props;
  console.log('🚀 ~ NewsForm ~ incomeType:', incomeType);
  const dispatch = useDispatch();
  const [newsForm] = Form.useForm();

  const { name, lastname } = useSelector((store) => store.userData?.user);

  useEffect(() => {
    newsForm.resetFields();
  }, [income]);

  const onFinish = (values) => {
    values.userNews = `${name} ${lastname}`;

    dispatch(
      onIncomeAddNews(values, income._id, incomeType, setIsVisibleModal)
    );
  };

  return (
    <Form form={newsForm} onFinish={onFinish}>
      <Form.Item
        name="news"
        initialValue={income.news && income.news}
        rules={[{ required: true, message: 'Debe ingresar una novedad' }]}
      >
        <Input.TextArea
          placeholder="Decripción de novedades..."
          autoSize={{ minRows: 4 }}
        />
      </Form.Item>
      <Form.Item>
        <Button type="default" htmlType="submit" style={{ width: '100%' }}>
          Agregar Novedad
        </Button>
      </Form.Item>
    </Form>
  );
}
