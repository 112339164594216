import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal as ModalAntd,
  Tabs,
  Form,
  Affix,
  notification,
  DatePicker,
  Row,
  Col,
  Select,
} from 'antd';
import Spin from '../Spin';
import MedicineForm from '../MedicineForm';
import InfusionForm from '../InfusionForm/InfusionForm';
import { getDrugApi } from '../../api/drug';
import { getInfusionApi } from '../../api/infusion';
import { getActionInfarmaryApi } from '../../api/actionInfarmary';
import { ArrowLeftOutlined } from '@ant-design/icons';
import InfarmaryActionsForm from '../InfarmaryActionsForm';
import moment from 'moment';
import {
  addMedicineApi,
  checkDateMedicineApi,
  getMedicineDayApi,
  updateMedicineApi,
} from '../../api/medicine';
import { getUsersTypeApi } from '../../api/user';
import { useSelector } from 'react-redux';
import { getAccessTokenApi } from '../../api/auth';
import Modal from '../Modal';
import locale from 'antd/es/date-picker/locale/es_ES';

export default function NewDosis(props) {
  const { incomeId, medicineId, incomeType } = props.match.params;
  const { income, search, hash, name } = props.location;

  const { path } = props.match;

  const [medicine, setMedicine] = useState();
  const [medicineInfusion, setMedicineInfusion] = useState();
  const [medicineAction, setMedicineAction] = useState();
  const [change, setChange] = useState();
  const [drugs, setDrugs] = useState();
  const [infusiones, setInfusiones] = useState();
  const [actions, setActions] = useState();
  const [loading, setLoading] = useState();
  const [isVisibleModal, setIsVisibleModal] = useState(
    hash === '#alta' || (search === '?Dialisis' && hash !== '#edit')
      ? true
      : false
  );
  const [datePicker, setDatePicker] = useState(!income && moment());
  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const { TabPane } = Tabs;
  const [medicineForm] = Form.useForm();
  const userId = useSelector((store) => store.userData.user?._id);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();

      getDrugApi(token)
        .then((response) => {
          setDrugs(response.drugs);
        })
        .catch(() => {});
      getInfusionApi(token)
        .then((response) => {
          setInfusiones(response.infusiones);
        })
        .catch(() => {});
      getActionInfarmaryApi(token)
        .then((response) => {
          setActions(response.actions);
        })
        .catch(() => {});

      if (income) {
        getUsersTypeApi(token, true, 'Medico').then((response) => {
          setUsers(response.users);
        });
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (medicineId) {
        const token = await getAccessTokenApi();
        getMedicineDayApi(medicineId, token).then(async (response) => {
          let indication = response.medicine.indication;
          let infusion = response.medicine.infusion;
          let action = response.medicine.action;
          hash === '#edit' &&
            moment().isAfter(response?.medicine?.date, 'day') &&
            setDatePicker(response?.medicine?.date);

          if (path === '/new-medicine/:incomeId/:incomeType?/:medicineId?') {
            indication = await response.medicine.indication.filter(
              (indication) => {
                indication.status = ['0', '1', '2', '3'];
                return (
                  indication.extra !== 'Unica Dosis' && !indication.suspend
                );
              }
            );
            infusion = await response.medicine.infusion.filter((infusion) => {
              infusion.status = [];
              return !infusion.suspend;
            });
            action = await response.medicine.action.filter((action) => {
              action.status = ['0', '1', '2', '3'];
              return action.extra !== 'Unica Dosis' && !action.suspend;
            });
            (indication || infusion || action) && setChange(true);
          }

          medicineForm.setFieldsValue({
            indication,
            infusion,
            action,
          });

          setMedicine(indication);
          setMedicineInfusion(infusion);
          setMedicineAction(action);

          if (
            path === '/update-medicine/:incomeId/:medicineId?' &&
            search !== '?Dialisis' &&
            moment().isAfter(response?.medicine?.date, 'day')
          ) {
            notification.warning({
              message: 'Cambio de dia. Debe indicar una nueva dosis',
            });
            window.history.back();
          }
        });
      }
    };
    getData();
  }, [medicineId]);

  const callbackControl = () => {
    if (change) {
      ModalAntd.confirm({
        title: 'No guardo los cambios',
        content: `Si presiona aceptar se perderan todos los cambios realizados ¿ Estas seguro que quieres volver atras sin guardar los cambios ?`,
        okText: 'Aceptar',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk() {
          window.history.back();
        },
      });
    } else {
      window.history.back();
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    let token = await getAccessTokenApi();
    values = await medicineForm.getFieldsValue(true);
    values.user = user ? user : userId;
    values.income = incomeId;
    values.date = datePicker ? datePicker : moment();
    values.incomeType = incomeType && incomeType;

    path === '/new-medicine/:incomeId/:incomeType?/:medicineId?'
      ? addMedicineApi(values, token)
          .then(async (response) => {
            if (response?.code !== 200) {
              if (response?.code === 404) {
                notification['warning']({ message: response.message });
                setChange(false);
                setLoading(false);

                window.history.back();
              } else {
                notification['error']({ message: response.message });
              }
            } else {
              notification['success']({
                message: 'Las indicaciones se guardaron con exito',
              });
              setChange(false);
              setLoading(false);

              window.history.back();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoading(false);
          })
      : updateMedicineApi(medicineId, values, token)
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({
                message: 'Las indicaciones se actualizaron con exito',
              });
              setChange(false);
              setLoading(false);
              window.history.back();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoading(false);
          });
  };

  if (!medicine && medicineId) {
    return <Spin />;
  }

  const onFinishFailed = () => {
    if (medicineForm.getFieldsError()) {
      notification['warning']({
        message:
          'Debe completar todos los campos obligatorios (*). Revise las tres solapas.',
      });
    }
  };

  return (
    <div>
      <span style={{ textTransform: 'capitalize' }}>{name}</span>
      <Form
        form={medicineForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={{
            left: (
              <Button type="text" onClick={() => callbackControl()}>
                <ArrowLeftOutlined /> Atras
              </Button>
            ),
          }}
        >
          <TabPane tab="Medicación" key="1">
            <MedicineForm
              incomeId={incomeId}
              medicineId={medicineId}
              path={path}
              drugs={drugs}
              incomeType={incomeType}
              medicineForm={medicineForm}
              indication={medicine}
              setIndication={setMedicine}
              change={change}
              setChange={setChange}
              bedType={search}
            />
          </TabPane>
          {search !== '?Dialisis' && (
            <>
              <TabPane tab="Infusión" key="2">
                <InfusionForm
                  incomeId={incomeId}
                  medicineId={medicineId}
                  path={path}
                  infusiones={infusiones}
                  drugs={drugs}
                  infusionForm={medicineForm}
                  infusion={medicineInfusion}
                  setInfusion={setMedicineInfusion}
                  change={change}
                  setChange={setChange}
                />
              </TabPane>
              <TabPane tab="Acciones Enfermería" key="3">
                <InfarmaryActionsForm
                  incomeId={incomeId}
                  medicineId={medicineId}
                  path={path}
                  actions={actions}
                  actionForm={medicineForm}
                  action={medicineAction}
                  setAction={setMedicineAction}
                  change={change}
                  setChange={setChange}
                />
              </TabPane>
            </>
          )}
        </Tabs>
        <Form.Item>
          <Affix offsetBottom={10}>
            <Button
              type="default"
              htmlType="submit"
              style={{ width: '100%' }}
              disabled={loading}
              loading={loading}
            >
              Guardar
            </Button>
          </Affix>
        </Form.Item>
      </Form>

      <Modal
        title="Fecha de indicación"
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        maskClosable={false}
        onCancel={() => window.history.back()}
        width="40%"
        afterClose={() => {
          if (hash ? !datePicker || !user : !datePicker) {
            window.history.back();
            notification['warning']({
              message: hash
                ? 'Debe elegir fecha y medico'
                : 'Debe elegir fecha',
            });
          }
        }}
      >
        <Row gutter={24} style={{ marginBottom: 5 }}>
          <Col xs={24} md={24} lg={12}>
            <DatePicker
              placeholder="Seleccione fecha de la indicación (*)"
              style={{ width: '100%' }}
              format="DD-MM-YYYY HH:mm"
              locale={locale}
              showToday={false}
              showTime
              defaultValue={datePicker}
              disabledDate={(current) =>
                current && current > moment().endOf('day')
              }
              onChange={(value) => {
                if (
                  hash &&
                  !(
                    moment(income?.dischargeDate).isAfter(value) &&
                    moment(income?.date).isBefore(value)
                  )
                ) {
                  notification['warning']({
                    message:
                      'La fecha debe coincidir con los dias que estuvo internado.',
                  });
                } else {
                  setDatePicker(value);
                }
              }}
            />
          </Col>
          {hash && (
            <Col xs={24} md={24} lg={12}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Seleccione un medico.. (*)"
                optionFilterProp="children"
                onChange={(value) => setUser(value)}
              >
                {users?.map((user, index) => (
                  <Select.Option key={index} value={user._id}>
                    {user.name} {user.lastname}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Button
              type="default"
              disabled={hash ? !datePicker || !user : !datePicker}
              style={{ width: '100%' }}
              onClick={async () => {
                const token = await getAccessTokenApi();
                const response = await checkDateMedicineApi(
                  incomeId,
                  datePicker,
                  token
                );
                if (response?.code !== 200) {
                  notification['warning']({ message: response.message });
                } else {
                  setIsVisibleModal(false);
                }
              }}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
