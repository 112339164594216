import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Select,
  notification,
  Input,
  Divider,
} from 'antd';
import { getUsersTypeApi } from '../../api/user';
import { getAccessTokenApi } from '../../api/auth';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { addCalendarApi, updateCalendarApi } from '../../api/calendar';

export default function CalendarForm(props) {
  const { date, setIsVisibleModal, setReload, data } = props;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [calendarForm] = Form.useForm();

  useEffect(() => {
    calendarForm.resetFields();
    calendarForm.setFieldsValue(data);
  }, [date]);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getUsersTypeApi(token, true, 'Medico').then((response) => {
        setUsers(response.users);
      });
    };

    getData();
  }, []);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let token = await getAccessTokenApi();
      if (data) {
        await updateCalendarApi(data._id, values, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              setReload(true);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      } else {
        values.date = date;
        await addCalendarApi(values, token)
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              setReload(true);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      }
      setLoading(false);
    } catch {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    }
  };

  return (
    <div>
      <Form form={calendarForm} onFinish={onFinish}>
        <Row>
          <Col xs={24}>
            <Form.Item name="description">
              <Input.TextArea
                maxLength={30}
                placeholder="Agendar algun evento"
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Agregar Medicos</Divider>
        <Form.List name="users">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Row gutter={24} key={index}>
                    <Col xs={22} lg={11}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'name']}
                        fieldKey={[field.fieldKey, 'name']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir un medico',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Seleccione un medico.. (*)"
                          optionFilterProp="children"
                        >
                          {users.map((user, index) => (
                            <Select.Option
                              key={index}
                              value={`${user.name} ${user.lastname}`}
                            >
                              {user.name} {user.lastname}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={22} lg={11}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'type']}
                        fieldKey={[field.fieldKey, 'type']}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Seleccione tipo de guardia.."
                          optionFilterProp="children"
                          allowClear
                        >
                          <Select.Option value="Activa">Activa</Select.Option>
                          <Select.Option value="Pasiva">Pasiva</Select.Option>
                          <Select.Option value="Residente">
                            Residente
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={1} style={{ paddingLeft: '0' }}>
                      <Button
                        type="link"
                        size="large"
                        onClick={() => {
                          remove(field.name);
                        }}
                      >
                        <MinusCircleOutlined />
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button
                    type="default"
                    shape="circle"
                    onClick={() => {
                      add();
                    }}
                    size="large"
                  >
                    <PlusOutlined />
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Form.Item>
          <Button
            type="default"
            htmlType="submit"
            style={{ width: '100%' }}
            loading={loading}
          >
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
