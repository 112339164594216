import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import React from 'react';
import { DocumentHistory } from './DocumentHistory';
import { getIncomeApi } from '../../api/income';
import { getAccessTokenApi } from '../../api/auth';
import { getNoteApi } from '../../api/note';
import { getEvolutionApi } from '../../api/evolution';
import { getMedicineApi } from '../../api/medicine';
import { getSignatureApi } from '../../api/user';
import { getControlApi, getDyalisisControlApi } from '../../api/control';
import { getOperationApi } from '../../api/operation';
import { Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

export default function PdfHistoryDownload(props) {
  const { id, type } = props;
  const { organization, tenant } = useSelector((store) => store.tenantData);

  const getProps = async () => {
    try {
      const token = await getAccessTokenApi();

      var incomeData = await getIncomeApi(id, token)
        .then(async (response) => {
          if (response.income.dischargeDate) {
            var epicrisisSignature;
            response.income.userEpicrisis.signature &&
              (await getSignatureApi(
                response.income.userEpicrisis?.signature,
                token
              )
                .then((response) => {
                  if (response?.status !== 200) {
                    epicrisisSignature = null;
                  } else {
                    epicrisisSignature = response.url;
                  }
                })
                .catch((err) => console.log(err)));
          }
          return { income: response.income, epicrisisSignature };
        })
        .catch((err) => {});

      var dataEvolutions = await getEvolutionApi(id, 'asc', token)
        .then((response) => {
          var signatures = Array(response?.evolutions?.length);
          Promise.all(
            response.evolutions?.map(async (data, index) => {
              data.user?.signature &&
                (await getSignatureApi(data.user.signature, token)
                  .then((response) => {
                    if (response?.status !== 200) {
                      signatures[index] = null;
                    } else {
                      signatures[index] = response.url;
                    }
                  })
                  .catch((err) => console.log(err)));
            })
          );
          return {
            evolutions: response.evolutions,
            signatures: signatures,
          };
        })
        .catch((err) => {});
      var medicines = await getMedicineApi({ id, order: 'asc', token })
        .then((response) => {
          const signatures = Array(response?.medicines?.length);
          Promise.all(
            response.medicines?.map(async (data, index) => {
              data.user?.signature &&
                (await getSignatureApi(data.user.signature, token)
                  .then((response) => {
                    if (response?.status !== 200) {
                      signatures[index] = null;
                    } else {
                      signatures[index] = response.url;
                    }
                  })
                  .catch((err) => console.log(err)));
            })
          );
          return {
            medicines: response.medicines,
            signatures: signatures,
          };
        })
        .catch((err) => {});

      var dataOperations = await getOperationApi(id, 'asc', token)
        .then((response) => {
          return response.operations;
        })
        .catch((err) => {});

      var dataNotes = await getNoteApi(id, 'asc', token)
        .then((response) => {
          return response.notes;
        })
        .catch((err) => {});

      var controls =
        type === '?Dialisis'
          ? await getDyalisisControlApi(id, token)
              .then((response) => {
                return response.controls.reverse();
              })
              .catch((err) => {})
          : await getControlApi(id, token)
              .then((response) => {
                return response.controls;
              })
              .catch((err) => {});

      return {
        incomeData,
        dataEvolutions,
        medicines,
        dataOperations,
        dataNotes,
        controls,
        organization,
        tenant,
        type,
      };
    } catch {
      message.error('Error al generar documento. Intente nuevamente');
    }
  };

  return (
    <Button
      type="link"
      style={{
        paddingLeft: 0,
      }}
      size="middle"
      onClick={async () => {
        try {
          message.loading(
            'Generando documento. Por favor espere... Esta acción puede tardar...',
            0
          );
          const props = await getProps();

          const doc = <DocumentHistory {...props} />;
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          saveAs(blob, `${props.incomeData?.income.name}`);
          message
            .success('El documento se ha generado con exito.', 1)
            .then(() => message.destroy());
        } catch (err) {
          message
            .info(`${err}. En caso de aparecer este error, informar!`, 1)
            .then(() => message.destroy());
        }
      }}
    >
      Descargar PDF <DownloadOutlined />
    </Button>
  );
}
