import React, { useState } from 'react';
import moment from 'moment';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Comment, Tooltip, Card, List, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { onMessageResolve } from '../../actions';

export default function MessageList(props) {
  const { messagesHistory, history, setIsVisibleModal, turn } = props;
  const { name, lastname, type } = useSelector((store) => store.userData.user);
  const { messages } = useSelector((store) => store.messageData);

  const dispatch = useDispatch();

  const resolveMessage = (message) => {
    dispatch(onMessageResolve(message._id, type, turn));
    setIsVisibleModal && setIsVisibleModal(false);
  };

  return (
    <Card
      title={
        history
          ? `Historial de Mesajes (${messagesHistory?.length})`
          : `Mensajes (${messages?.length})`
      }
      className="message-color"
      style={
        history
          ? {
              overflowY: 'scroll',
              height: '80vh',
            }
          : {
              overflowY: 'scroll',
              height: '40vh',
            }
      }
    >
      <List
        grid={
          history
            ? {
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 2,
                xxl: 3,
              }
            : {
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1,
              }
        }
        dataSource={messagesHistory ? messagesHistory : messages}
        renderItem={(message) => (
          <Message
            message={message}
            resolveMessage={resolveMessage}
            name={name}
            lastname={lastname}
            history={history}
            type={type}
          />
        )}
        pagination={history && { pageSize: 30 }}
      />
    </Card>
  );
}

function Message(props) {
  const { message, resolveMessage, name, lastname, history } = props;

  return (
    <List.Item>
      <Comment
        actions={
          (!message.fixed ||
            (message.fixed && message.author === `${name} ${lastname}`)) &&
          !history && [
            <Button
              type="text"
              style={{ color: '#fff' }}
              onClick={() => resolveMessage(message)}
            >
              Resolver <CheckOutlined />
            </Button>,
          ]
        }
        author={
          <span
            style={
              message.type === 'Comunicado Institucional'
                ? { color: '#cf1322' }
                : { color: '#fff' }
            }
          >
            {message.author}{' '}
            {message.userType !== message.type && message.type === 'Enfermero'
              ? `- A Enfermeria`
              : message.type === 'Comunicado Institucional' &&
                '- Comunicado Institucional'}
          </span>
        }
        content={<p style={{ color: '#fff' }}>{message.description}</p>}
        datetime={
          <Tooltip title={moment(message.date).format('YYYY-MM-DD HH:mm:ss')}>
            <span>{moment(message.date).fromNow()}</span>
          </Tooltip>
        }
      />
      <Divider style={{ backgroundColor: '#fff' }} />
    </List.Item>
  );
}
