import React from 'react';
import { Tooltip, Card, Progress, Statistic } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

export function KpiChart({ title, value, percent, info }) {
  return (
    <div className="ocupations">
      <Card
        title={title}
        style={{ height: 130 }}
        extra={
          <div className="tooltip-visible">
            <Tooltip title={info}>
              <InfoCircleOutlined style={{ color: '#fff' }} size="small" />
            </Tooltip>
          </div>
        }
      >
        <Statistic value={value} />
        {percent > 0 && (
          <Progress
            percent={((value * 100) / percent).toFixed(0)}
            strokeColor={
              title.includes('Atendidos')
                ? ((value * 100) / percent).toFixed(0) > 60
                  ? '#3f8600'
                  : ((value * 100) / percent).toFixed(0) > 85
                  ? '#d6d100'
                  : '#cf1322'
                : ((value * 100) / percent).toFixed(0) < 20
                ? '#3f8600'
                : ((value * 100) / percent).toFixed(0) < 40
                ? '#d6d100'
                : '#cf1322'
            }
            status="normal"
            style={{ padding: 5 }}
          />
        )}
      </Card>
    </div>
  );
}
