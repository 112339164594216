import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleBody } from './styles';

export function PdfInfusion({ infusion, index }) {
  return (
    <View style={styleBody.dataEvolution}>
      <Text style={infusion.suspend && styleBody.textSuspend}>
        <Text style={styleBody.text}>{index}. </Text>
        <Text
          style={styleBody.text}
          render={() =>
            infusion.suspend
              ? `SUSPENDIDA - ${infusion.infusion.name} - ${infusion.quantity} ml - ${infusion.ritmo} ${infusion.unity}`
              : `${infusion.infusion.name} - ${infusion.quantity} ml - ${infusion.ritmo} ${infusion.unity}`
          }
        />
        <Text
          style={styleBody.text}
          render={() =>
            infusion.drug &&
            ` (${infusion.drug.name} ${infusion.dosis ? infusion.dosis : ''} ${
              infusion.unityDrug ? infusion.unityDrug : ''
            })`
          }
        />
        <Text
          style={styleBody.text}
          render={() => infusion.description && `. ${infusion.description}`}
        />
      </Text>
    </View>
  );
}
