import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleBody } from './styles';
import moment from 'moment';

export function PdfDyalisisControl({ control, index }) {
  return (
    <View>
      <View style={styleBody.dataEvolution}>
        <Text style={styleBody.subtitle}>Hemodialisis Nº {index}</Text>
        <View style={styleBody.data}>
          <Text style={styleBody.text}>
            Fecha: {moment(control.date).format('DD/MM/YYYY')} - Hora inicio:{' '}
            {moment(control.initialParams.hourStart).format('HH:mm')} hs - Hora
            fin: {moment(control?.endParams?.hourEnd).format('HH:mm')} hs
          </Text>
        </View>

        <Text style={styleBody.evolutionTitle}>Parametros Inciales</Text>
        <View style={styleBody.data}>
          <Text style={styleBody.text}>Peso HD Anterior: </Text>
          <Text style={styleBody.text}>
            {control?.initialParams?.weightAnt} Kg
          </Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.text}>Peso pre HD :</Text>
          <Text style={styleBody.text}>
            {control?.initialParams?.weightPre} Kg
          </Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.text}>Peso post: </Text>
          <Text style={styleBody.text}>
            {control?.initialParams?.weightPost} Kg
          </Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.text}>UF Prescripta: </Text>
          <Text style={styleBody.text}>{control?.initialParams?.uf}</Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.text}>TA Ingreso: </Text>
          <Text style={styleBody.text}>
            {control?.initialParams?.taIngreso}
          </Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.text}>Test Desinfeccion: </Text>
          <Text style={styleBody.text}>
            {control?.initialParams?.testDes ? 'OK' : 'No'}
          </Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.text}>Filtro: </Text>
          <Text style={styleBody.text}>{control?.initialParams?.filter}</Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.text}>Temperatura: </Text>
          <Text style={styleBody.text}>
            {control?.initialParams?.temperature} °C
          </Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.text}>Tec Resp: </Text>
          <Text style={styleBody.text}>{control?.initialParams?.tecResp}</Text>
        </View>

        {control?.params?.map((param, index) => (
          <View>
            <Text style={styleBody.evolutionTitle}>
              Parametros {index + 1}.ª hora
            </Text>
            <View style={styleBody.data}>
              <Text style={styleBody.text}>TAS / TAD: </Text>
              <Text style={styleBody.text}>{param?.tas_tad}</Text>
            </View>
            <View style={styleBody.data}>
              <Text style={styleBody.text}>UF: </Text>
              <Text style={styleBody.text}>{param?.uf}</Text>
            </View>
            <View style={styleBody.data}>
              <Text style={styleBody.text}>Presión Arterial: </Text>
              <Text style={styleBody.text}>{param?.pArterial}</Text>
            </View>
            <View style={styleBody.data}>
              <Text style={styleBody.text}>Qb efectivo: </Text>
              <Text style={styleBody.text}>{param?.qbEfec}</Text>
            </View>
          </View>
        ))}

        <Text style={styleBody.evolutionTitle}>Parametros Finales</Text>

        <View style={styleBody.data}>
          <Text style={styleBody.text}>TA Egreso: </Text>
          <Text style={styleBody.text}>{control?.endParams?.taEgreso}</Text>
        </View>

        <View style={styleBody.data}>
          <Text style={styleBody.text}>Temperatura:</Text>
          <Text style={styleBody.text}>
            {control?.endParams?.temperature} °C
          </Text>
        </View>

        <View style={styleBody.data}>
          <Text style={styleBody.text}>UF Final: </Text>
          <Text style={styleBody.text}>{control?.endParams?.uf}</Text>
        </View>

        <View style={styleBody.data}>
          <Text style={styleBody.text}>UF Final: </Text>
          <Text style={styleBody.text}>Ktv: {control?.endParams?.ktv}</Text>
        </View>
      </View>
    </View>
  );
}
