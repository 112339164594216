import React from 'react';
import { List, Row } from 'antd';
import PassView from './PassView';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './PassView.scss';
import PdfDownload from '../PdfReports/PdfDownload';
import BackButton from '../BackButton';

export default function IncomePass(props) {
  const { type } = props.match.params;
  const { incomesActive } = useSelector((store) => store.incomeData);
  const date = moment().format('DD/MM/YYYY');

  if (type) {
    var incomesFilter = incomesActive.filter(
      (income) => income.bed.type === type
    );
  }
  return (
    <div>
      {type !== 'all' && (
        <h1 style={{ textAlign: 'center' }}>
          Pase <span style={{ textTransform: 'uppercase' }}>{type}</span>
        </h1>
      )}
      <Row>
        <BackButton />
        <PdfDownload incomes={type === 'all' ? incomesActive : incomesFilter} />

        <table
          cellspacing="1"
          cellpadding="3"
          border="1px"
          bgcolor="#fff"
          className="income-pass-view-table"
        >
          <div className="income-view-pass-date">
            <h4>{date}</h4>
          </div>
          <List
            itemLayout="horizontal"
            dataSource={type === 'all' ? incomesActive : incomesFilter}
            renderItem={(income) => <PassView income={income} />}
          />
        </table>
      </Row>
    </div>
  );
}
