import React, { useState, useEffect } from 'react';
import { getAccessTokenApi } from '../../api/auth';
import { getUsersActiveApi, getUsersTypeApi } from '../../api/user';
import ListUsers from '../../components/Users/ListUsers';
import Spin from '../../components/Spin';
import { useSelector } from 'react-redux';
import { ROLES } from '../../utils/constants';

export default function Users(props) {
  const [usersActive, setUsersActive] = useState([]);
  const [usersInactive, setUsersInactive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [loading, setLoading] = useState(false);

  const { role, type } = useSelector((store) => store.userData?.user);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();

      setLoading(true);

      getUsersActiveApi(token, true).then((response) => {
        setUsersActive(response.users);
        setLoading(false);
      });
      getUsersActiveApi(token, false).then((response) => {
        setUsersInactive(response.users);
      });

      setReloadUsers(false);
    };
    getData();
  }, [reloadUsers]);

  return (
    <div className="users">
      {loading ? (
        <Spin />
      ) : (
        <ListUsers
          usersActive={usersActive}
          usersInactive={usersInactive}
          setReloadUsers={setReloadUsers}
        />
      )}
    </div>
  );
}
