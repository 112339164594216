import React, { useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  notification,
  InputNumber,
  Checkbox,
} from 'antd';
import { getAccessTokenApi } from '../../api/auth';
import { addInsumoApi, updateStockInsumoApi } from '../../api/insumo';

export default function StockForm(props) {
  const { setIsVisibleModal, getInsumo, data } = props;
  const [stockForm] = Form.useForm();

  useEffect(() => {
    stockForm.resetFields();
    stockForm.setFieldsValue({ name: data?.name, filter: data?.filter });
  });

  const onFinish = async (values) => {
    let token = await getAccessTokenApi();

    data
      ? updateStockInsumoApi(data._id, values, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              getInsumo();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          })
      : addInsumoApi(values, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              getInsumo();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
  };

  return (
    <div>
      <Form form={stockForm} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} sm={16}>
            <Form.Item name="filter" initialValue={data ? data.filter : false}>
              <Checkbox
                defaultChecked={data ? data.filter : false}
                onChange={(e) =>
                  stockForm.setFieldsValue({ filter: e.target.checked })
                }
              >
                Filtro Dialisis
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} sm={16}>
            <Form.Item
              name="name"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: 'Debe cargar el nombre.',
                },
              ]}
            >
              <Input placeholder="Nombre (*)" disabled={data} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
