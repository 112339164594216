import { basePath, apiVersion } from './config';

export async function getDiagnosticApi(token) {
  const url = `${basePath}/${apiVersion}/diagnostic`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function addDiagnosticApi(diagnosticData, token) {
  const url = `${basePath}/${apiVersion}/diagnostic`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(diagnosticData),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function deleteDiagnosticApi(id, token) {
  const url = `${basePath}/${apiVersion}/diagnostic/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}
