import React, { useEffect, useState } from 'react';
import {
  Carousel,
  Row,
  Col,
  Card,
  InputNumber,
  Select,
  Button,
  Tooltip,
  Tabs,
  DatePicker,
  TreeSelect,
} from 'antd';
import {
  GeneralStatsIncomes,
  MedicineForMonth,
  EvolutionsForUser,
  BubbleChart,
  BarChart,
  TableChart,
  LineChart,
  PieChart,
  KpiChart,
} from '../../components/Charts';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../components/Modal';
import moment from 'moment';
import {
  CheckOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { onDiagnosticRead } from '../../actions';
import './DashBoard.scss';
import { getUsersTypeApi } from '../../api/user';
import { getAccessTokenApi } from '../../api/auth';
import {
  getDyalisisPresentsStatsApi,
  getFiltersUseStatsApi,
} from '../../api/stats';
import { getIdicationInsumoDetailApi } from '../../api/insumo';
import locale from 'antd/es/date-picker/locale/es_ES';
import CardOcupation from '../../components/CardOcupation';
import { getServicesApi } from '../../api/service';
import { exportToExcel } from '../../components/ExcelExport';

export default function DashBoard(props) {
  const { type, name, lastname, viewStudies, role, modulesAccess } =
    useSelector((store) => store.userData?.user);
  const userId = useSelector((store) => store.userData?.user?._id);

  const [start, setStart] = useState(
    moment()
      .utcOffset(-180)
      .subtract(1, 'month')
      .startOf('month', 'day')
      .toISOString()
  );
  const [end, setEnd] = useState(
    moment()
      .utcOffset(-180)
      .subtract(1, 'month')
      .endOf('month')
      .startOf('day')
      .toISOString()
  );

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [floor, setFloor] = useState('General');
  const [percent, setPercent] = useState(1);
  const [diagnostic, setDiagnostic] = useState('');
  const [turnUser, setTurnUser] = useState(
    type === 'Medico' ? userId : undefined
  );
  const [users, setUsers] = useState([]);
  const [generate, setGenerate] = useState(false);
  const [generateInt, setGenerateInt] = useState(false);

  const [turnType, setTurnType] = useState();
  const [status, setStatus] = useState('');
  const [presents, setPresents] = useState({ data: [], total: 0 });
  const [insumos, setInsumos] = useState();
  const [filtersData, setFiltersData] = useState();
  const [tab, setTab] = useState(1);
  const [selectedStudieType, setSelectedStudieType] = useState();
  const [servicesData, setServicesData] = useState([]);
  const { diagnostics } = useSelector((store) => store.diagnosticData);
  const dispatch = useDispatch();
  const { floors, studies, organizationType } = useSelector(
    (store) => store.tenantData
  );

  const allBeds = useSelector((store) => store.bedData?.bedsStats);

  const view = (component) => {
    setIsVisibleModal(true);
    setModalTitle('Grafico');
    setModalContent(component);
  };

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getUsersTypeApi(token, true, 'Medico').then((response) => {
        setUsers(response.users);
      });

      getServicesApi(token).then((response) => {
        setServicesData(response?.services);
      });
    };
    getData();
  }, [turnUser]);

  useEffect(() => {
    if (type === 'Enferemero') props.history.push('/');
    if (diagnostics?.length === 0) {
      dispatch(onDiagnosticRead());
    }

    tab == 3 && generateDialisis();
  }, [tab]);

  const generateDialisis = () => {
    const getData = async () => {
      let token = await getAccessTokenApi();

      await getDyalisisPresentsStatsApi(token, start, end).then(
        async (response) => {
          setPresents({ data: response.data, total: response.total });
        }
      );

      await getIdicationInsumoDetailApi({
        id: undefined,
        token,
        type: 'total',
        start,
        end,
      }).then((result) => {
        setInsumos(result);
      });

      await getFiltersUseStatsApi({
        token,
        start,
        end,
      }).then((result) => {
        setFiltersData(result);
      });
    };

    getData();
  };

  const treeData = [
    {
      value: 'med',
      title: (
        <Tooltip title="Presionar sobre la flecha">Staff Profesional</Tooltip>
      ),
      selectable: false,
      children:
        role === 2 || modulesAccess?.includes('Estadisticas')
          ? users?.map((user, index) => {
              return {
                value: user._id,
                title: `${user.name} ${user.lastname}`,
              };
            })
          : [
              {
                value: userId,
                title: `${name} ${lastname}`,
              },
            ],
    },
  ];

  studies?.length > 0 &&
    treeData.push({
      value: 'est',
      title: <Tooltip title="Presionar sobre la flecha">Estudios</Tooltip>,
      selectable: false,
      children: studies?.map((studie, index) => {
        let disabled = viewStudies?.find((e) => e === studie.id) || role === 2;
        let services = servicesData?.filter((service) => {
          if (studie.scheduleConfig.service?.find((e) => e === service._id)) {
            return service;
          }
        });
        return {
          value: `${studie.id}/`,
          title: (
            <Tooltip title="Click aqui para seleccionar toda la turnera, sin especificar ningun tipo de estudio">
              {studie.name}
            </Tooltip>
          ),
          disabled: !disabled,
          children:
            disabled &&
            services?.map((service, i) => {
              return {
                value: `${studie.id}-${i}`,
                title: service?.name,
              };
            }),
        };
      }),
    });

  (role === 2 || modulesAccess?.includes('Estadisticas')) &&
    treeData.push({
      value: '',
      title: 'Todo el centro',
    });

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    fade: true,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let filters = (
    <Row gutter={[24, 24]}>
      <Col xs={24} sm={12} md={12} xl={7}>
        <span>Fecha Inicio / Fecha Fin: </span>
        <DatePicker.RangePicker
          placeholder={['Fecha inicio ', 'Fecha fin ']}
          style={{ width: '100%' }}
          format="DD-MM-YYYY"
          value={[start && moment(start), end && moment(end)]}
          onChange={(value) => [
            setStart(
              moment(value[0]).utcOffset(-180).startOf('day').toISOString()
            ),
            setEnd(
              moment(value[1]).utcOffset(-180).startOf('day').toISOString()
            ),
          ]}
          locale={locale}
          showToday={false}
          allowClear={false}
        />
      </Col>
      {tab == 1 ? (
        <>
          <Col xs={24} sm={12} md={12} xl={7}>
            <span>
              Medico / Estudios:{' '}
              <Tooltip title="Si no selecciona un medico, las estadisticas se generan para todo el centro">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
            <TreeSelect
              style={{ width: '100%' }}
              placeholder="Busque por medico o especialidad"
              onChange={(value, options) => {
                setTurnType(
                  value?.includes('-') || value?.includes('/')
                    ? 'studie'
                    : options[0]
                );
                setTurnUser(
                  value?.includes('-')
                    ? value.split('-')[0]
                    : value?.includes('/')
                    ? value?.split('/')[0]
                    : value
                );
                value.includes('-')
                  ? setSelectedStudieType(options[0])
                  : setSelectedStudieType('');
              }}
              treeExpandAction="click"
              defaultValue={turnUser}
              treeData={treeData}
            />
          </Col>
          <Col xs={24} sm={12} md={12} xl={7}>
            <span>Estado: </span>

            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccione un estado.. (*)"
              optionFilterProp="children"
              onChange={(value, options) => {
                setStatus(value);
              }}
              allowClear
            >
              <Select.Option key="1" value="Atendido">
                Atendido
              </Select.Option>
              <Select.Option key="2" value="Ausente">
                Ausente
              </Select.Option>
            </Select>
          </Col>
        </>
      ) : (
        <Col xs={24} sm={12} md={12} xl={14}>
          <span> {tab == 2 ? 'Piso:' : 'Turno:'}</span>

          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Seleccione un turno.. (*)"
            optionFilterProp="children"
            onChange={(value, options) => {
              setFloor(value);
            }}
            defaultValue={floor}
          >
            <Select.Option value={'General'}>General</Select.Option>
            {floors?.map((floor, index) =>
              tab == 3 && floor?.type === 'Dialisis' ? (
                <Select.Option key={index} value={floor.id}>
                  {floor.name}
                </Select.Option>
              ) : (
                tab == 2 &&
                floor?.type !== 'Dialisis' && (
                  <Select.Option key={index} value={floor.id}>
                    {floor.name}
                  </Select.Option>
                )
              )
            )}
          </Select>
        </Col>
      )}
      <Col xs={24} sm={12} md={12} xl={3}>
        <Button
          type="default"
          style={{ width: '100%', height: '100%' }}
          onClick={() => {
            tab == 1
              ? setGenerate(!generate)
              : tab == 2
              ? setGenerateInt(!generateInt)
              : generateDialisis();
          }}
        >
          Generar
        </Button>
      </Col>
    </Row>
  );

  const exportData = async (data) => {
    const dataExport = data[0].primary
      ? data
      : await data.map((data) => {
          return {
            fecha: moment(data?.hourStart).format('DD/MM/YY HH:mm'),
            paciente: data?.patientName,
            'obra social': data?.socialWork,
            estudio: data?.studies?.name,
            solicitante: data?.medicoSolicitante,
            efector: data.medicoEfector
              ? data.medicoEfector
              : data.medicoTurnera,
            valor:
              role === 2 || modulesAccess?.includes('Estadisticas')
                ? data.price
                : userId === data.medicoEfectorId && data?.pricePercent,
            destacado: data.important ? 'Si' : 'No',
          };
        });
    exportToExcel(
      dataExport,
      `exportacion ${moment().format('DD/MM/YY HH:mm')}`
    );
  };

  return (
    <div>
      <Tabs
        type="line"
        centered
        onChange={(e, s) => {
          setTab(e);
          setFloor('General');
        }}
      >
        <Tabs.TabPane tab="Ambulatorio" key={1}>
          {filters}
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Card
                title="Evolucion Mensual de Turnos"
                headStyle={{
                  backgroundColor: '#002a52',
                  color: '#fff',
                  height: '65px',
                }}
                style={{ borderColor: '#002a52', minHeight: '400px' }}
                extra={
                  <Row>
                    <Tooltip title="No impactan filtro de fechas, muestra todo el historico">
                      <Button type="link">
                        <InfoCircleOutlined style={{ color: '#fff' }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Ver grafico">
                      <Button
                        onClick={() =>
                          view(
                            <LineChart
                              type="month"
                              userId={turnUser}
                              generate={generate}
                              status={status}
                              chartTitle="Total Historico de Turnos"
                              hAxisTitle="Meses"
                              statsType="amb"
                              height="600px"
                              studieType={selectedStudieType}
                            />
                          )
                        }
                        type="link"
                      >
                        <EyeOutlined style={{ color: '#fff' }} />
                      </Button>
                    </Tooltip>
                  </Row>
                }
              >
                <LineChart
                  type={'month'}
                  userId={turnUser}
                  generate={generate}
                  status={status}
                  chartTitle="Total Historico de Turnos"
                  hAxisTitle="Meses"
                  statsType="amb"
                  height="350px"
                  studieType={selectedStudieType}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Card
                title="Turnos por estado"
                headStyle={{
                  backgroundColor: '#002a52',
                  color: '#fff',
                  height: '65px',
                }}
                style={{ borderColor: '#002a52', height: '400px' }}
                extra={
                  <Row>
                    <Tooltip title="Ver grafico">
                      <Button
                        onClick={() =>
                          view(
                            <BarChart
                              start={start}
                              end={end}
                              type="status"
                              userId={turnUser}
                              generate={generate}
                              height="70vh"
                              statsType="amb"
                              studieType={selectedStudieType}
                            />
                          )
                        }
                        type="link"
                      >
                        <EyeOutlined style={{ color: '#fff' }} />
                      </Button>
                    </Tooltip>
                  </Row>
                }
              >
                <BarChart
                  start={start}
                  end={end}
                  type="status"
                  userId={turnUser}
                  generate={generate}
                  statsType="amb"
                  studieType={selectedStudieType}
                />
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card
                title="Turnos por Obra Social"
                headStyle={{
                  backgroundColor: '#002a52',
                  color: '#fff',
                  height: '65px',
                }}
                style={{
                  borderColor: '#002a52',
                  height: '400px',
                  overflowY: 'scroll',
                }}
                extra={
                  <Row>
                    <Tooltip title="Ver grafico">
                      <Button
                        onClick={() =>
                          view(
                            <TableChart
                              start={start}
                              end={end}
                              type="socialWork"
                              userId={turnUser}
                              generate={generate}
                              primaryTitle="Obra social"
                              status={status}
                              studieType={selectedStudieType}
                            />
                          )
                        }
                        type="link"
                      >
                        <EyeOutlined style={{ color: '#fff' }} />
                      </Button>
                    </Tooltip>
                  </Row>
                }
              >
                <TableChart
                  start={start}
                  end={end}
                  type="socialWork"
                  userId={turnUser}
                  generate={generate}
                  primaryTitle="Obra social"
                  status={status}
                  studieType={selectedStudieType}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Card
                title="Turnos Destacados"
                headStyle={{
                  backgroundColor: '#002a52',
                  color: '#fff',
                  height: '65px',
                }}
                style={{
                  borderColor: '#002a52',
                  maxHeight: '400px',
                }}
                extra={
                  <Row>
                    <Tooltip title="Ver grafico">
                      <Button
                        onClick={() =>
                          view(
                            <BarChart
                              start={start}
                              end={end}
                              type="important"
                              userId={turnUser}
                              generate={generate}
                              height="70vh"
                              status={status}
                              statsType="amb"
                              studieType={selectedStudieType}
                            />
                          )
                        }
                        type="link"
                      >
                        <EyeOutlined style={{ color: '#fff' }} />
                      </Button>
                    </Tooltip>
                  </Row>
                }
              >
                <BarChart
                  start={start}
                  end={end}
                  type="important"
                  userId={turnUser}
                  generate={generate}
                  status={status}
                  statsType="amb"
                  studieType={selectedStudieType}
                />
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card
                title="Turnos por Sexo Paciente"
                headStyle={{
                  backgroundColor: '#002a52',
                  color: '#fff',
                  height: '65px',
                }}
                style={{
                  borderColor: '#002a52',
                  maxHeight: '400px',
                }}
                extra={
                  <Row>
                    <Tooltip title="Ver grafico">
                      <Button
                        onClick={() =>
                          view(
                            <PieChart
                              start={start}
                              end={end}
                              type="sex"
                              userId={turnUser}
                              generate={generate}
                              status={status}
                              statsType="amb"
                              studieType={selectedStudieType}
                            />
                          )
                        }
                        type="link"
                      >
                        <EyeOutlined style={{ color: '#fff' }} />
                      </Button>
                    </Tooltip>
                  </Row>
                }
              >
                <PieChart
                  start={start}
                  end={end}
                  type="sex"
                  userId={turnUser}
                  generate={generate}
                  status={status}
                  statsType="amb"
                  studieType={selectedStudieType}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Card
                title="Evolucion Diaria de Turnos en el Mes"
                headStyle={{
                  backgroundColor: '#002a52',
                  color: '#fff',
                  height: '65px',
                }}
                style={{ borderColor: '#002a52', maxHeight: '400px' }}
                extra={
                  <Row>
                    <Tooltip title="Ver grafico">
                      <Button
                        onClick={() =>
                          view(
                            <LineChart
                              type="day"
                              userId={turnUser}
                              generate={generate}
                              status={status}
                              chartTitle="Total de Turnos en el periodo"
                              hAxisTitle="Meses"
                              statsType="amb"
                              start={start}
                              end={end}
                              height={'600px'}
                              studieType={selectedStudieType}
                            />
                          )
                        }
                        type="link"
                      >
                        <EyeOutlined style={{ color: '#fff' }} />
                      </Button>
                    </Tooltip>
                  </Row>
                }
              >
                <LineChart
                  type="day"
                  userId={turnUser}
                  generate={generate}
                  status={status}
                  chartTitle="Total de Turnos en el periodo"
                  hAxisTitle="Dias"
                  start={start}
                  end={end}
                  statsType="amb"
                  studieType={selectedStudieType}
                />
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card
                title="Turnos por Dia de la Semana"
                headStyle={{
                  backgroundColor: '#002a52',
                  color: '#fff',
                  height: '65px',
                }}
                style={{ borderColor: '#002a52', height: '400px' }}
                extra={
                  <Row>
                    <Tooltip title="Ver grafico">
                      <Button
                        onClick={() =>
                          view(
                            <BarChart
                              type="weekDay"
                              userId={turnUser}
                              generate={generate}
                              status={status}
                              chartTitle="Total de Turnos en el mes"
                              hAxisTitle="Dias"
                              start={start}
                              end={end}
                              statsType="amb"
                              height="70vh"
                              studieType={selectedStudieType}
                            />
                          )
                        }
                        type="link"
                      >
                        <EyeOutlined style={{ color: '#fff' }} />
                      </Button>
                    </Tooltip>
                  </Row>
                }
              >
                <BarChart
                  type="weekDay"
                  userId={turnUser}
                  generate={generate}
                  status={status}
                  chartTitle="Total de Turnos en el mes"
                  hAxisTitle="Dias"
                  start={start}
                  end={end}
                  statsType="amb"
                  studieType={selectedStudieType}
                />
              </Card>
            </Col>
          </Row>

          {((turnType === 'studie' &&
            (role === 2 || modulesAccess?.includes('Estadisticas'))) ||
            turnType !== 'studie') && (
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Card
                  title={
                    turnType === 'studie'
                      ? 'Cantidad liquidada por estudios'
                      : 'Cantidad Liquidada por Medico'
                  }
                  headStyle={{
                    backgroundColor: '#002a52',
                    color: '#fff',
                    height: '65px',
                  }}
                  style={{
                    borderColor: '#002a52',
                    height: '400px',
                    overflowY: 'scroll',
                  }}
                  extra={
                    <Row>
                      <Tooltip title="Ver grafico">
                        <Button
                          onClick={() =>
                            view(
                              <TableChart
                                start={start}
                                end={end}
                                type="userTurns"
                                generate={generate}
                                primaryTitle="Usuario"
                                status={status}
                                format="table"
                                secondTitle="Liquidacion"
                                userId={turnUser}
                                studieType={selectedStudieType}
                                sendColumns={
                                  role === 2 ||
                                  modulesAccess?.includes('Estadisticas')
                                    ? [
                                        {
                                          title: 'Usuario',
                                          dataIndex: 'primary',
                                          key: 'primary',
                                          sorter: (a, b) =>
                                            a.primary.localeCompare(b.primary),
                                        },
                                        {
                                          title: 'Cantidad Turnos',
                                          dataIndex: 'quantity',
                                          key: 'quantity',
                                          sorter: (a, b) =>
                                            a.primary.localeCompare(b.primary),
                                        },
                                        {
                                          title: 'Liquidación Total',
                                          dataIndex: 'price',
                                          key: 'price',
                                          sorter: (a, b) =>
                                            a.primary.localeCompare(b.primary),
                                          render: (price) =>
                                            price?.toLocaleString('es-AR', {
                                              style: 'currency',
                                              currency: 'ARS',
                                              minimumFractionDigits: 2,
                                            }),
                                        },
                                        {
                                          title: 'Liquidación Medico',
                                          key: 'pricePercent',
                                          sorter: (a, b) =>
                                            a.primary.localeCompare(b.primary),
                                          render: (dataChart) => (
                                            <Tooltip
                                              title={`Promedio de porcentaje periodo: ${
                                                dataChart?.percent?.toFixed(
                                                  2
                                                ) || 0
                                              }%`}
                                            >
                                              {dataChart.pricePercent?.toLocaleString(
                                                'es-AR',
                                                {
                                                  style: 'currency',
                                                  currency: 'ARS',
                                                  minimumFractionDigits: 2,
                                                }
                                              )}
                                            </Tooltip>
                                          ),
                                        },
                                        {
                                          title: 'Liquidación Centro',
                                          key: 'primary',
                                          render: (dataChart) =>
                                            (
                                              dataChart.price -
                                              dataChart.pricePercent
                                            )?.toLocaleString('es-AR', {
                                              style: 'currency',
                                              currency: 'ARS',
                                              minimumFractionDigits: 2,
                                            }),
                                          sorter: (a, b) =>
                                            a.primary.localeCompare(b.primary),
                                        },
                                      ]
                                    : [
                                        {
                                          title: 'Usuario',
                                          dataIndex: 'primary',
                                          key: 'primary',
                                          sorter: (a, b) =>
                                            a.primary.localeCompare(b.primary),
                                        },
                                        {
                                          title: 'Cantidad Turnos',
                                          dataIndex: 'quantity',
                                          key: 'quantity',
                                          sorter: (a, b) =>
                                            a.primary.localeCompare(b.primary),
                                        },
                                        {
                                          title: 'Liquidación Medico',
                                          key: 'pricePercent',
                                          sorter: (a, b) =>
                                            a.primary.localeCompare(b.primary),
                                          render: (dataChart) => (
                                            <Tooltip
                                              title={`Promedio de porcentaje periodo: ${
                                                dataChart?.percent?.toFixed(
                                                  2
                                                ) || 0
                                              }%`}
                                            >
                                              {dataChart.pricePercent?.toLocaleString(
                                                'es-AR',
                                                {
                                                  style: 'currency',
                                                  currency: 'ARS',
                                                  minimumFractionDigits: 2,
                                                }
                                              )}
                                            </Tooltip>
                                          ),
                                        },
                                      ]
                                }
                              />
                            )
                          }
                          type="link"
                        >
                          <EyeOutlined style={{ color: '#fff' }} />
                        </Button>
                      </Tooltip>
                    </Row>
                  }
                >
                  <TableChart
                    start={start}
                    end={end}
                    type="userTurns"
                    generate={generate}
                    primaryTitle="Usuario"
                    status={status}
                    format="table"
                    secondTitle="Liquidacion"
                    userId={turnUser}
                    studieType={selectedStudieType}
                    exportData={exportData}
                    sendColumns={
                      role === 2 || modulesAccess?.includes('Estadisticas')
                        ? [
                            {
                              title: 'Usuario',
                              dataIndex: 'primary',
                              key: 'primary',
                              sorter: (a, b) =>
                                a.primary.localeCompare(b.primary),
                            },
                            {
                              title: 'Cantidad Turnos',
                              dataIndex: 'quantity',
                              key: 'quantity',
                              sorter: (a, b) =>
                                a.primary.localeCompare(b.primary),
                            },
                            {
                              title: 'Liquidación Total',
                              dataIndex: 'price',
                              key: 'price',
                              sorter: (a, b) =>
                                a.primary.localeCompare(b.primary),
                              render: (price) =>
                                price?.toLocaleString('es-AR', {
                                  style: 'currency',
                                  currency: 'ARS',
                                  minimumFractionDigits: 2,
                                }),
                            },
                            {
                              title: 'Liquidación Medico',
                              key: 'pricePercent',
                              sorter: (a, b) =>
                                a.primary.localeCompare(b.primary),
                              render: (dataChart) => (
                                <Tooltip
                                  title={`Promedio de porcentaje periodo: ${
                                    dataChart?.percent?.toFixed(2) || 0
                                  }%`}
                                >
                                  {dataChart.pricePercent?.toLocaleString(
                                    'es-AR',
                                    {
                                      style: 'currency',
                                      currency: 'ARS',
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </Tooltip>
                              ),
                            },
                            {
                              title: 'Liquidación Centro',
                              key: 'primary',
                              render: (dataChart) =>
                                (
                                  dataChart.price - dataChart.pricePercent
                                )?.toLocaleString('es-AR', {
                                  style: 'currency',
                                  currency: 'ARS',
                                  minimumFractionDigits: 2,
                                }),
                              sorter: (a, b) =>
                                a.primary.localeCompare(b.primary),
                            },
                          ]
                        : [
                            {
                              title: 'Usuario',
                              dataIndex: 'primary',
                              key: 'primary',
                              sorter: (a, b) =>
                                a.primary.localeCompare(b.primary),
                            },
                            {
                              title: 'Cantidad Turnos',
                              dataIndex: 'quantity',
                              key: 'quantity',
                              sorter: (a, b) =>
                                a.primary.localeCompare(b.primary),
                            },
                            {
                              title: 'Liquidación Medico',
                              key: 'pricePercent',
                              sorter: (a, b) =>
                                a.primary.localeCompare(b.primary),
                              render: (dataChart) => (
                                <Tooltip
                                  title={`Promedio de porcentaje periodo: ${
                                    dataChart?.percent?.toFixed(2) || 0
                                  }%`}
                                >
                                  {dataChart.pricePercent?.toLocaleString(
                                    'es-AR',
                                    {
                                      style: 'currency',
                                      currency: 'ARS',
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </Tooltip>
                              ),
                            },
                          ]
                    }
                  />
                </Card>
              </Col>
            </Row>
          )}

          {turnType === 'studie' &&
            (role === 2 || modulesAccess?.includes('Estadisticas')) && (
              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <Card
                    title="Turnos por Medico efector y tipo de estudio"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                      height: '65px',
                    }}
                    style={{
                      borderColor: '#002a52',
                      maxHeight: '400px',
                      overflowY: 'scroll',
                    }}
                    extra={
                      <Row>
                        <Tooltip title="Solo lo ven administrativos y acceso completo">
                          <Button type="link">
                            <InfoCircleOutlined style={{ color: '#fff' }} />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Ver grafico">
                          <Button
                            onClick={() =>
                              view(
                                <TableChart
                                  start={start}
                                  end={end}
                                  type="studieDoctorDerivate"
                                  userId={turnUser}
                                  generate={generate}
                                  primaryTitle="Medico - Tipo estudio"
                                  status={status}
                                  studieType={selectedStudieType}
                                  sendColumns={[
                                    {
                                      title: 'Usuario',
                                      dataIndex: 'primary',
                                      key: 'primary',
                                      sorter: (a, b) =>
                                        a.primary.localeCompare(b.primary),
                                    },
                                    {
                                      title: 'Cantidad Turnos',
                                      dataIndex: 'quantity',
                                      key: 'quantity',
                                      sorter: (a, b) =>
                                        a.primary.localeCompare(b.primary),
                                    },
                                    {
                                      title: 'Liquidación Total',
                                      dataIndex: 'price',
                                      key: 'price',
                                      sorter: (a, b) =>
                                        a.primary.localeCompare(b.primary),
                                      render: (price) =>
                                        price?.toLocaleString('es-AR', {
                                          style: 'currency',
                                          currency: 'ARS',
                                          minimumFractionDigits: 2,
                                        }),
                                    },
                                    {
                                      title: 'Liquidación Medico',
                                      key: 'pricePercent',
                                      sorter: (a, b) =>
                                        a.primary.localeCompare(b.primary),
                                      render: (dataChart) => (
                                        <Tooltip
                                          title={`Promedio de porcentaje periodo: ${
                                            dataChart?.percent?.toFixed(2) || 0
                                          }%`}
                                        >
                                          {dataChart.pricePercent?.toLocaleString(
                                            'es-AR',
                                            {
                                              style: 'currency',
                                              currency: 'ARS',
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </Tooltip>
                                      ),
                                    },
                                    {
                                      title: 'Liquidación Centro',
                                      key: 'primary',
                                      render: (dataChart) =>
                                        (
                                          dataChart.price -
                                          dataChart.pricePercent
                                        )?.toLocaleString('es-AR', {
                                          style: 'currency',
                                          currency: 'ARS',
                                          minimumFractionDigits: 2,
                                        }),
                                      sorter: (a, b) =>
                                        a.primary.localeCompare(b.primary),
                                    },
                                  ]}
                                />
                              )
                            }
                            type="link"
                          >
                            <EyeOutlined style={{ color: '#fff' }} />
                          </Button>
                        </Tooltip>
                      </Row>
                    }
                  >
                    <TableChart
                      start={start}
                      end={end}
                      type="studieDoctorDerivate"
                      userId={turnUser}
                      generate={generate}
                      primaryTitle="Medico - Tipo estudio"
                      status={status}
                      studieType={selectedStudieType}
                      sendColumns={[
                        {
                          title: 'Usuario',
                          dataIndex: 'primary',
                          key: 'primary',
                          sorter: (a, b) => a.primary.localeCompare(b.primary),
                        },
                        {
                          title: 'Cantidad Turnos',
                          dataIndex: 'quantity',
                          key: 'quantity',
                          sorter: (a, b) => a.primary.localeCompare(b.primary),
                        },
                        {
                          title: 'Liquidación Total',
                          dataIndex: 'price',
                          key: 'price',
                          sorter: (a, b) => a.primary.localeCompare(b.primary),
                          render: (price) =>
                            price?.toLocaleString('es-AR', {
                              style: 'currency',
                              currency: 'ARS',
                              minimumFractionDigits: 2,
                            }),
                        },
                        {
                          title: 'Liquidación Medico',
                          key: 'pricePercent',
                          sorter: (a, b) => a.primary.localeCompare(b.primary),
                          render: (dataChart) => (
                            <Tooltip
                              title={`Promedio de porcentaje periodo: ${
                                dataChart?.percent?.toFixed(2) || 0
                              }%`}
                            >
                              {dataChart.pricePercent?.toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2,
                              })}
                            </Tooltip>
                          ),
                        },
                        {
                          title: 'Liquidación Centro',
                          key: 'primary',
                          render: (dataChart) =>
                            (
                              dataChart.price - dataChart.pricePercent
                            )?.toLocaleString('es-AR', {
                              style: 'currency',
                              currency: 'ARS',
                              minimumFractionDigits: 2,
                            }),
                          sorter: (a, b) => a.primary.localeCompare(b.primary),
                        },
                      ]}
                    />
                  </Card>
                </Col>
                <Col xs={24}>
                  <Card
                    title="Turnos por Medico solicitante y tipo de estudio"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                      height: '65px',
                    }}
                    style={{
                      borderColor: '#002a52',
                      maxHeight: '400px',
                      overflowY: 'scroll',
                    }}
                    extra={
                      <Row>
                        <Tooltip title="Solo lo ven administrativos y acceso completo">
                          <Button type="link">
                            <InfoCircleOutlined style={{ color: '#fff' }} />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Ver grafico">
                          <Button
                            onClick={() =>
                              view(
                                <TableChart
                                  start={start}
                                  end={end}
                                  type="studieDoctorApplicant"
                                  userId={turnUser}
                                  generate={generate}
                                  primaryTitle="Medico - Tipo estudio"
                                  status={status}
                                  studieType={selectedStudieType}
                                />
                              )
                            }
                            type="link"
                          >
                            <EyeOutlined style={{ color: '#fff' }} />
                          </Button>
                        </Tooltip>
                      </Row>
                    }
                  >
                    <TableChart
                      start={start}
                      end={end}
                      type="studieDoctorApplicant"
                      userId={turnUser}
                      generate={generate}
                      primaryTitle="Medico - Tipo estudio"
                      status={status}
                      studieType={selectedStudieType}
                    />
                  </Card>
                </Col>
              </Row>
            )}
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Card
                title="Informe de Turnos"
                headStyle={{
                  backgroundColor: '#002a52',
                  color: '#fff',
                  height: '65px',
                }}
                style={{
                  borderColor: '#002a52',
                  height: '500px',
                  overflowY: 'scroll',
                }}
              >
                <TableChart
                  start={start}
                  end={end}
                  type="reportTurns"
                  userId={turnUser}
                  generate={generate}
                  status={status}
                  studieType={selectedStudieType}
                  exportData={exportData}
                  sendColumns={[
                    {
                      title: '#',
                      dataIndex: 'rank',
                      key: 'index',
                    },
                    {
                      title: 'Fecha',
                      dataIndex: 'hourStart',
                      key: 'hourStart',
                      render: (hourStart) => (
                        <span>
                          {moment(hourStart).format('DD/MM/YY HH:mm')}
                        </span>
                      ),
                      sorter: (a, b) =>
                        moment(a.hourStart).unix() - moment(b.hourStart).unix(),
                    },
                    {
                      title: 'Paciente',
                      dataIndex: 'patientName',
                      key: 'patientName',
                      render: (patientName) => (
                        <span style={{ textTransform: 'capitalize' }}>
                          {patientName}
                        </span>
                      ),
                    },
                    {
                      title: 'Obra Social',
                      dataIndex: 'socialWork',
                      key: 'socialWork',
                      sorter: (a, b) =>
                        a.socialWork?.localeCompare(b.socialWork),
                    },
                    {
                      title: 'Estudio',
                      dataIndex: 'studies',
                      key: 'studies',
                      render: (studies) => <span>{studies?.name}</span>,
                      sorter: (a, b) =>
                        a.studies?.name?.localeCompare(b.studies?.name),
                    },
                    {
                      title: 'Solicitante',
                      dataIndex: 'medicoSolicitante',
                      key: 'medicoSolicitante',
                      sorter: (a, b) =>
                        a.medicoSolicitante?.localeCompare(b.medicoSolicitante),
                    },
                    {
                      title: 'Efector',
                      dataIndex: 'medicoEfector',
                      key: 'medicoEfector',
                      render: (text, record, index) =>
                        record.medicoEfector
                          ? record.medicoEfector
                          : record.medicoTurnera,
                      sorter: (a, b) =>
                        a.medicoEfector?.localeCompare(b.medicoEfector),
                    },
                    {
                      title: 'Destacado',
                      dataIndex: 'important',
                      key: 'important',
                      render: (important) => (
                        <span>{important && <CheckOutlined />}</span>
                      ),
                    },
                    {
                      title: 'Valor',
                      dataIndex: 'dataChart',
                      key: 'price',
                      render: (text, record) =>
                        role === 2 ||
                        modulesAccess?.includes('Estadisticas') ? (
                          <span>
                            {record.price?.toLocaleString('es-AR', {
                              style: 'currency',
                              currency: 'ARS',
                              minimumFractionDigits: 2,
                            })}
                          </span>
                        ) : (
                          record.medicoEfectorId === userId && (
                            <span>
                              {record.pricePercent?.toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2,
                              })}
                            </span>
                          )
                        ),
                    },
                  ]}
                />
              </Card>
            </Col>
          </Row>
          {(role === 2 || modulesAccess?.includes('Estadisticas')) && (
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Card
                  title={
                    turnType === 'studie'
                      ? 'Turnos por Tipo de Estudio'
                      : 'Turnos por Usuario'
                  }
                  headStyle={{
                    backgroundColor: '#002a52',
                    color: '#fff',
                    height: '65px',
                  }}
                  style={{ borderColor: '#002a52', maxHeight: '400px' }}
                  extra={
                    <Row>
                      <Tooltip
                        title={
                          turnType === 'studie'
                            ? 'Solo lo ven administrativos y acceso completo'
                            : 'A este grafico no le impactan el filtro de usuario. Solo lo ven administrativos y acceso completo'
                        }
                      >
                        <Button type="link">
                          <InfoCircleOutlined style={{ color: '#fff' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Ver grafico">
                        <Button
                          onClick={() =>
                            view(
                              <BarChart
                                start={start}
                                end={end}
                                type={
                                  turnType === 'studie'
                                    ? 'studieType'
                                    : 'userTurns'
                                }
                                userId={turnType === 'studie' ? turnUser : ''}
                                generate={generate}
                                status={status}
                                statsType="amb"
                                studieType={selectedStudieType}
                              />
                            )
                          }
                          type="link"
                        >
                          <EyeOutlined style={{ color: '#fff' }} />
                        </Button>
                      </Tooltip>
                    </Row>
                  }
                >
                  <BarChart
                    start={start}
                    end={end}
                    type={turnType === 'studie' ? 'studieType' : 'userTurns'}
                    userId={turnType === 'studie' ? turnUser : ''}
                    generate={generate}
                    status={status}
                    statsType="amb"
                    studieType={selectedStudieType}
                  />
                </Card>
              </Col>
            </Row>
          )}
        </Tabs.TabPane>

        {(role === 2 || modulesAccess?.includes('Estadisticas')) &&
          organizationType === 'hospitalaria' &&
          floors?.find((floor) => floor.type !== 'Dialisis') && (
            <Tabs.TabPane tab="Internados" key={2}>
              {filters}
              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <Card
                    title="Cantidad de altas por mes"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                    }}
                    style={{ borderColor: '#002a52' }}
                    extra={
                      <Tooltip title="No impactan filtro de fechas, muestra todo el historico">
                        <InfoCircleOutlined style={{ color: '#fff' }} />
                      </Tooltip>
                    }
                  >
                    <LineChart
                      type={'months'}
                      chartTitle="Total de Altas: "
                      hAxisTitle="Meses"
                      floor={floor}
                      generate={generateInt}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col md={12}>
                  <Card
                    title="Graficos de torta"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                    }}
                    style={{
                      borderColor: '#002a52',
                      height: '400px',
                    }}
                    extra={
                      <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <InputNumber
                          min={0}
                          value={percent}
                          step={0.1}
                          formatter={(value) => `${value}%`}
                          parser={(value) => value.replace('%', '')}
                          onChange={(value) => {
                            setPercent(value);
                          }}
                        />
                      </div>
                    }
                  >
                    <Carousel
                      style={{ maxHeight: '400px' }}
                      {...settings}
                      className="carousel"
                    >
                      <PieChart
                        chartTitle="Obras Sociales"
                        type="socialWork"
                        percent={percent}
                        setPercent={setPercent}
                        start={start}
                        end={end}
                        floor={floor}
                        generate={generateInt}
                      />
                      <PieChart
                        chartTitle="Origen Interno"
                        type="origin"
                        start={start}
                        end={end}
                        floor={floor}
                        generate={generateInt}
                      />
                      <PieChart
                        chartTitle="Motivos de Alta"
                        type="dischargeReason"
                        start={start}
                        end={end}
                        floor={floor}
                        generate={generateInt}
                      />
                      <PieChart
                        chartTitle="Diagnosticos de Alta"
                        type="dischargeDiagnostic"
                        percent={percent}
                        setPercent={setPercent}
                        start={start}
                        end={end}
                        floor={floor}
                        generate={generateInt}
                      />
                      <PieChart
                        chartTitle="Ingresos por Usuario"
                        type="user"
                        percent={percent}
                        setPercent={setPercent}
                        start={start}
                        end={end}
                        floor={floor}
                        generate={generateInt}
                      />
                      <PieChart
                        chartTitle="Internaciones por Sexo"
                        type="sex"
                        start={start}
                        end={end}
                        floor={floor}
                        generate={generateInt}
                      />
                      <PieChart
                        chartTitle="Internaciones por Ciudad"
                        type="city"
                        start={start}
                        end={end}
                        floor={floor}
                        generate={generateInt}
                      />
                    </Carousel>
                  </Card>
                </Col>
                <Col xs={24} md={12}>
                  <Card
                    title="Egresos por edad"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                      height: '65px',
                    }}
                    style={{ borderColor: '#002a52', maxHeight: '400px' }}
                    extra={
                      <Tooltip title="Ver grafico">
                        <Button
                          onClick={() =>
                            view(
                              <LineChart
                                type="age"
                                chartTitle="Total de Egresos: "
                                hAxisTitle="Meses"
                                height="70vh"
                                floor={floor}
                                start={start}
                                end={end}
                              />
                            )
                          }
                          type="link"
                        >
                          <EyeOutlined style={{ color: '#fff' }} />
                        </Button>
                      </Tooltip>
                    }
                  >
                    <LineChart
                      type="age"
                      chartTitle="Total de Egresos: "
                      hAxisTitle="Meses"
                      start={start}
                      end={end}
                      floor={floor}
                      generate={generateInt}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                  <Card
                    title="Ingreso / Egresos por dia"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                      height: '65px',
                    }}
                    style={{ borderColor: '#002a52', height: '400px' }}
                    extra={
                      <Row>
                        <Tooltip title="Ver grafico">
                          <Button
                            onClick={() =>
                              view(
                                <BarChart
                                  start={start}
                                  end={end}
                                  floor={floor}
                                  type="incomeDay"
                                  generate={generateInt}
                                  height="70vh"
                                />
                              )
                            }
                            type="link"
                          >
                            <EyeOutlined style={{ color: '#fff' }} />
                          </Button>
                        </Tooltip>
                      </Row>
                    }
                  >
                    <BarChart
                      start={start}
                      end={end}
                      floor={floor}
                      type="incomeDay"
                      generate={generateInt}
                    />
                  </Card>
                </Col>
                <Col xs={24} md={12}>
                  <Card
                    title="Obitos según diagnostico"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                      height: '65px',
                    }}
                    style={{ borderColor: '#002a52', height: '400px' }}
                    extra={
                      <Row>
                        <Select
                          placeholder="Seleccione un diagnostico de alta (*)"
                          showSearch
                          optionFilterProp="children"
                          defaultValue={diagnostic}
                          onChange={(value) => {
                            setDiagnostic(value);
                          }}
                          style={{ width: '150px' }}
                        >
                          {diagnostics &&
                            diagnostics.map((diagnostic, index) => (
                              <Select.Option
                                key={index}
                                value={diagnostic.name}
                              >
                                {diagnostic.name}
                              </Select.Option>
                            ))}
                        </Select>
                        <Tooltip title="Ver grafico">
                          <Button
                            onClick={() =>
                              view(
                                <BubbleChart
                                  diagnostic={diagnostic}
                                  height="70vh"
                                />
                              )
                            }
                            type="link"
                          >
                            <EyeOutlined style={{ color: '#fff' }} />
                          </Button>
                        </Tooltip>
                      </Row>
                    }
                  >
                    <BubbleChart diagnostic={diagnostic} />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                  <Card
                    title="Estadisticas generales de ocupación"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                    }}
                    style={{
                      borderColor: '#002a52',
                      height: '500px',
                      overflowY: 'scroll',
                    }}
                    extra={
                      <Tooltip title="No impacta el filtro de piso, estadisticas generales de ocupación en todo el centro">
                        <InfoCircleOutlined style={{ color: '#fff' }} />
                      </Tooltip>
                    }
                  >
                    <GeneralStatsIncomes
                      start={start}
                      end={end}
                      floor={floor}
                      generate={generateInt}
                    />
                  </Card>
                </Col>
                <Col xs={24} md={12}>
                  <Card
                    title="Indicaciones de Medicamentos"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                    }}
                    style={{
                      borderColor: '#002a52',
                      height: '500px',
                      overflowY: 'scroll',
                    }}
                  >
                    <MedicineForMonth
                      start={start}
                      end={end}
                      floor={floor}
                      generate={generateInt}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <Card
                    title="Evoluciones por usuario"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                      height: '65px',
                    }}
                    style={{
                      borderColor: '#002a52',
                      height: '600px',
                      overflowY: 'scroll',
                    }}
                  >
                    <EvolutionsForUser />
                  </Card>
                </Col>
              </Row>
            </Tabs.TabPane>
          )}
        {(role === 2 || modulesAccess?.includes('Estadisticas')) &&
          floors?.find((floor) => floor.type === 'Dialisis') && (
            <Tabs.TabPane tab="Diálisis" key={3}>
              {filters}
              <Row gutter={[24, 24]}>
                <Col xs={24} md={8}>
                  <CardOcupation
                    title="Pacientes Activos"
                    data={
                      floor === 'General'
                        ? {
                            _id: 'all',
                            ocupate: allBeds?.reduce(
                              (p, a) => p + a.ocupate,
                              0
                            ),
                            free: allBeds?.reduce((p, a) => p + a.free, 0),
                            quantity: allBeds?.reduce(
                              (p, a) => p + a.quantity,
                              0
                            ),
                          }
                        : allBeds?.find((b) => b._id === floor)
                    }
                    info="No impacta filtro tiempo"
                  />
                </Col>
                {presents.data?.map((p) => (
                  <Col xs={24} md={8}>
                    <KpiChart
                      title={p._id}
                      value={p.quantity}
                      percent={presents.total}
                      info={`Cantidad de dialisis ${p._id} en el periodo seleccionado`}
                    />
                  </Col>
                ))}
              </Row>
              <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                  <Card
                    title="Insumos Utilizados"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                      height: '65px',
                    }}
                    style={{
                      borderColor: '#002a52',
                      height: '500px',
                      overflowY: 'scroll',
                    }}
                    extra={
                      <Row>
                        <Tooltip title="Ver grafico">
                          <Button
                            onClick={() =>
                              view(
                                <TableChart
                                  generate={insumos}
                                  primaryTitle="Insumos"
                                  data={insumos}
                                />
                              )
                            }
                            type="link"
                          >
                            <EyeOutlined style={{ color: '#fff' }} />
                          </Button>
                        </Tooltip>
                      </Row>
                    }
                  >
                    <TableChart
                      generate={insumos}
                      primaryTitle="Insumos"
                      data={insumos}
                    />
                  </Card>
                </Col>
                <Col xs={24} md={12}>
                  <Card
                    title="Filtros Utilizados"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                      height: '65px',
                    }}
                    style={{
                      borderColor: '#002a52',
                      height: '500px',
                      overflowY: 'scroll',
                    }}
                    extra={
                      <Row>
                        <Tooltip title="Ver grafico">
                          <Button
                            onClick={() =>
                              view(
                                <TableChart
                                  primaryTitle="Filtros"
                                  data={filtersData}
                                />
                              )
                            }
                            type="link"
                          >
                            <EyeOutlined style={{ color: '#fff' }} />
                          </Button>
                        </Tooltip>
                      </Row>
                    }
                  >
                    <TableChart
                      generate={filtersData}
                      primaryTitle="Filtros"
                      data={filtersData}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <Card
                    title="Indicaciones de Medicamentos"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                    }}
                    style={{
                      borderColor: '#002a52',
                      height: '500px',
                      overflowY: 'scroll',
                    }}
                  >
                    <MedicineForMonth
                      start={start}
                      end={end}
                      floor={floor}
                      generate={presents}
                    />
                  </Card>
                </Col>
              </Row>

              <h1>
                Estaditicas de pacientes activos, no impacta el filtro del
                tiempo
              </h1>
              <Row gutter={[24, 24]}>
                <Col xs={24} md={8}>
                  <Card
                    title="Pacientes Activos por Obra Social"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                    }}
                    style={{
                      borderColor: '#002a52',
                      height: '400px',
                    }}
                    extra={
                      <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <InputNumber
                          min={0}
                          value={percent}
                          step={0.1}
                          formatter={(value) => `${value}%`}
                          parser={(value) => value.replace('%', '')}
                          onChange={(value) => {
                            setPercent(value);
                          }}
                        />
                      </div>
                    }
                  >
                    <PieChart
                      type="socialWork"
                      percent={percent}
                      setPercent={setPercent}
                      start={start}
                      end={end}
                      floor={floor}
                      generate={presents}
                      dyalisis={true}
                    />
                  </Card>
                </Col>
                <Col xs={24} md={8}>
                  <Card
                    title="Pacientes Activos por Sexo"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                    }}
                    style={{
                      borderColor: '#002a52',
                      height: '400px',
                    }}
                    extra={
                      <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <InputNumber
                          min={0}
                          value={percent}
                          step={0.1}
                          formatter={(value) => `${value}%`}
                          parser={(value) => value.replace('%', '')}
                          onChange={(value) => {
                            setPercent(value);
                          }}
                        />
                      </div>
                    }
                  >
                    <PieChart
                      type="sex"
                      percent={percent}
                      setPercent={setPercent}
                      start={start}
                      end={end}
                      floor={floor}
                      generate={presents}
                      dyalisis={true}
                    />
                  </Card>
                </Col>
                <Col xs={24} md={8}>
                  <Card
                    title="Pacientes Activos por Ciudad"
                    headStyle={{
                      backgroundColor: '#002a52',
                      color: '#fff',
                    }}
                    style={{
                      borderColor: '#002a52',
                      height: '400px',
                    }}
                    extra={
                      <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <InputNumber
                          min={0}
                          value={percent}
                          step={0.1}
                          formatter={(value) => `${value}%`}
                          parser={(value) => value.replace('%', '')}
                          onChange={(value) => {
                            setPercent(value);
                          }}
                        />
                      </div>
                    }
                  >
                    <PieChart
                      type="city"
                      percent={percent}
                      setPercent={setPercent}
                      start={start}
                      end={end}
                      floor={floor}
                      generate={presents}
                      dyalisis={true}
                    />
                  </Card>
                </Col>
              </Row>
            </Tabs.TabPane>
          )}
      </Tabs>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'75%'}
        destroyOnClose={true}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
