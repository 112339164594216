import {
  ON_PHARMACY_VALUE_CHANGE,
  ON_PHARMACY_READING,
  ON_PHARMACY_READ,
  ON_PHARMACY_SEARCH_READING,
} from './types';
import { getAccessTokenApi } from '../api/auth';
import { message } from 'antd';
import { getPharmacyFilterApi, getPharmacyMovementsApi } from '../api/pharmacy';

export const onPharmacyValueChange = (payload) => {
  return { type: ON_PHARMACY_VALUE_CHANGE, payload };
};

export const onPharmacyRead =
  ({ limit, type }) =>
  async (dispatch) => {
    dispatch({ type: ON_PHARMACY_READING });
    const token = await getAccessTokenApi();

    getPharmacyMovementsApi({ token, limit, type })
      .then((response) => {
        dispatch({
          type: ON_PHARMACY_READ,
          payload: { movements: response.data, count: response.count },
        });
        if (response?.code !== 200) {
          message.error(response?.message);
        }
      })
      .catch(() => {
        return message.error('Error del servidor, intente nuevamente.');
      });
  };

export const onPharmacyLoadMore =
  ({ movements, limit, count, type }) =>
  async (dispatch) => {
    dispatch({ type: ON_PHARMACY_READING });
    const token = await getAccessTokenApi();

    return getPharmacyMovementsApi({ token, limit, type, skip: count })
      .then(async (response) => {
        if (response?.code !== 200) {
          message['error']({ message: response.message });
        } else {
          await dispatch({
            type: ON_PHARMACY_READ,
            payload: {
              movements: movements.concat(response.data),
              count: count + response.count,
            },
          });
          response.count === 0
            ? message.warning('No hay mas items.')
            : message.success(`Se cargaron 10 mas`);
        }
      })
      .catch(() => {
        return message.error('Error del servidor, intente nuevamente.');
      });
  };

export const onPharmacyFilterRead =
  ({ search, type, provider, store, dateStart, dateEnd }) =>
  async (dispatch) => {
    dispatch({ type: ON_PHARMACY_SEARCH_READING });
    const token = await getAccessTokenApi();

    return await getPharmacyFilterApi({
      token,
      search,
      type,
      store,
      provider,
      dateStart,
      dateEnd,
    })
      .then(async (response) => {
        if (response?.code !== 200) {
          message.error(response.message);
        } else {
          await dispatch({
            type: ON_PHARMACY_READ,
            payload: {
              movements: response.data,
            },
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };
