import {
  ON_BED_READ,
  ON_BED_READING,
  ON_BED_STATS_READ,
  ON_BED_STATS_READING,
} from './types';
import { getBedsApi, getStatsBedsApi } from '../api/bed';
import { getAccessTokenApi } from '../api/auth';

export const onBedRead = (type) => async (dispatch) => {
  dispatch({ type: ON_BED_READING });

  let token = await getAccessTokenApi();

  const response = await getBedsApi(type, token);
  dispatch({ type: ON_BED_READ, payload: response.beds });
};

export const onStatsBedsRead = () => async (dispatch) => {
  dispatch({ type: ON_BED_STATS_READING });

  let token = await getAccessTokenApi();

  const response = await getStatsBedsApi(token);
  dispatch({ type: ON_BED_STATS_READ, payload: response.bedsTypes });
};
