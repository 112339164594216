import { basePath, apiVersion } from './config';

export async function getProtocoloApi(token) {
  const url = `${basePath}/${apiVersion}/protocolo`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
