import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button, DatePicker, message } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { addChargedBillingApi } from '../../api/billing';
import { getAccessTokenApi } from '../../api/auth';
export default function BillingChargedForm({
  setIsVisibleModal,
  totalPrice,
  id,
  quantityPractices,
  setReload,
}) {
  const [chargedForm] = Form.useForm();

  useEffect(() => {
    chargedForm.resetFields();
  }, [id]);

  const onFinish = async (values) => {
    let token = await getAccessTokenApi();

    values.status = totalPrice > values.value ? 'Cobrado parcial' : 'Cobrado';

    await addChargedBillingApi({
      token,
      id,
      data: values,
    })
      .then(async (response) => {
        if (response?.code !== 200) {
          message.error(response.message);
        } else {
          message.success(response.message);
          chargedForm.resetFields();
          setIsVisibleModal(false);
          setReload(true);
        }
      })
      .catch(() => {
        message.error('Error del servidor, intente mas tarde.');
      });
  };

  return (
    <div>
      <Form form={chargedForm} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              name="date"
              rules={[{ required: true, message: 'Debe cargar una fecha' }]}
              label="Fecha de Cobro"
              initialValue={moment()}
              labelCol={{ span: 24 }}
            >
              <DatePicker
                placeholder="Seleccione fecha de cobro (*)"
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
                locale={locale}
                showToday
                inputReadOnly
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="quantity"
              label="Cantidad de practicas cobradas"
              labelCol={{ span: 24 }}
              help={`Practicas: ${quantityPractices.toLocaleString('es-AR')}`}
              rules={[{ required: true, message: 'Debe indicar cantidad' }]}
            >
              <Input
                allowClear
                type="number"
                inputMode="numeric"
                max={quantityPractices}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="value"
              label="Valor"
              labelCol={{ span: 24 }}
              help={`Saldo: ${totalPrice.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              })}`}
              rules={[{ required: true, message: 'Debe cargar el monto' }]}
            >
              <Input
                placeholder="Usar punto como separador de decimales"
                allowClear
                type="number"
                inputMode="numeric"
                prefix="$"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="description"
              label="Descripción"
              labelCol={{ span: 24 }}
            >
              <Input.TextArea maxLength={5000} placeholder="Descripcion..." />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
