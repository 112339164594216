import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  notification,
  Row,
  Col,
  Radio,
  Select,
  Tooltip,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import './RegisterForm.scss';
import { signUpApi } from '../../api/user';
import Spin from '../../components/Spin';

export default function RegisterForm({
  setIsVisibleModal,
  setReloadUsers,
  schedulesUsers,
}) {
  const [loading, setLoading] = useState(false);
  const [registerForm] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const { user } = useSelector((store) => store.userData);

  const {
    organizationType,
    medicalSpecialities,
    tenantType,
    tenantId,
    quantitySchedules,
  } = useSelector((store) => store.tenantData);

  const register = async (values) => {
    setLoading(true);

    if (values.password !== values.repeatPassword) {
      notification['error']({
        message: 'Las contraseñas tienen que ser iguales',
      });
    } else {
      if (tenantType === 'personal') {
        values.tenantId = tenantId;
      }
      const result = await signUpApi(values);
      if (!result.ok) {
        notification['error']({
          message: result.message,
        });
      } else {
        notification['success']({
          message: result.message,
        });
        registerForm.resetFields();
      }
    }
    setLoading(false);
    if (user.tenantId) {
      setReloadUsers(true);
      setIsVisibleModal(false);
    }
  };

  if (loading) return <Spin />;
  return (
    <Form className="register-form" form={registerForm} onFinish={register}>
      <Row gutter={24} style={{ marginBottom: '3%' }}>
        <Col span={12}>
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Debe cargar un nombre' }]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
              placeholder="Nombre"
              className="register-form__input"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastname"
            rules={[{ required: true, message: 'Debe cargar un apellido' }]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
              placeholder="Apellido"
              className="register-form__input"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="email"
        initialValue=""
        rules={[
          {
            required: true,
            message: 'Debe cargar un email valido',
            type: 'email',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
          placeholder="Correo electronico..."
          className="register-form__input"
          inputMode="email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        initialValue=""
        help="Debe tener 6 caracteres o mas"
        rules={[{ required: true, message: 'Debe ingresar una contraseña' }]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
          placeholder="Contraseña"
          className="register-form__input"
          minLength={6}
        />
      </Form.Item>
      <Form.Item
        name="repeatPassword"
        initialValue=""
        rules={[
          {
            required: true,
            message: 'Debe ingresar una contraseña',
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
          placeholder="Repetir contraseña"
          className="register-form__input"
          minLength={6}
        />
      </Form.Item>
      <Form.Item
        name="type"
        rules={[
          {
            required: true,
            message: 'Debe seleccionar tipo de usuario.',
          },
        ]}
      >
        <Radio.Group
          onChange={(e) =>
            setVisible(e.target.value === 'Medico' ? true : false)
          }
        >
          <Tooltip
            title={
              tenantType === 'personal' &&
              schedulesUsers >= quantitySchedules &&
              'Debido a su plan contratado, no puede registrar mas medicos. Consulte con soporte'
            }
          >
            <Radio
              value={'Medico'}
              disabled={
                tenantType === 'personal' && schedulesUsers >= quantitySchedules
              }
            >
              Personal Medico
            </Radio>
          </Tooltip>

          <Radio
            value={'Enfermero'}
            disabled={organizationType === 'ambulatoria'}
          >
            Personal Enfermeria
          </Radio>
          <Radio value={'Administrativo'}>Personal Administrativo</Radio>
        </Radio.Group>
      </Form.Item>
      {visible && (
        <Form.Item name="medicalSpeciality">
          <Select
            placeholder="Selecciona una especialidad"
            allowClear
            inputMode="none"
          >
            {medicalSpecialities?.map((speciality) => (
              <Select.Option value={speciality}>{speciality}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          style={{ width: '100%' }}
          className="register-form__button"
          disabled={loading}
          loading={loading}
        >
          Crear cuenta
        </Button>
      </Form.Item>
    </Form>
  );
}
