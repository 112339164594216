import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleTable } from './styles';
import moment from 'moment';

export function PdfDyalisisDeliver({ control, index }) {
  function tranformText(text) {
    const url = text?.replace(/[. ,]/g, '/');
    return url;
  }

  return (
    <View wrap={false}>
      {control?.present ? (
        <View style={styleTable.rowDyalisisData}>
          <Text style={styleTable.column8}>{index}</Text>
          <Text style={styleTable.column3}>
            {moment(control?.initialParams?.hourStart).format(
              'DD/MM/YYYY HH:mm'
            )}{' '}
            hs
          </Text>
          <Text style={styleTable.column7}>
            {control?.initialParams?.weightPre} Kg
          </Text>
          <Text style={styleTable.column7}>
            {control?.endParams?.weightPost ||
              control?.initialParams?.weightPost}{' '}
            Kg
          </Text>
          <Text style={styleTable.column7}>
            {tranformText(control?.initialParams?.taIngreso)}
          </Text>
          <Text style={styleTable.column7}>
            {tranformText(control?.endParams?.taEgreso)}
          </Text>
          <Text style={styleTable.column3}>{control?.vascularAccess}</Text>
          <Text style={styleTable.column7}>{control?.cantHeparina}</Text>
          <Text style={styleTable.column7}>
            {control?.initialParams?.incident ? 'Si' : 'No'}
          </Text>
          <Text style={styleTable.column7}>
            {control?.initialParams?.controlBac ? 'Si' : 'No'}
          </Text>
          <Text style={styleTable.column7}>
            {control?.endParams?.hourEnd &&
              `${moment(control?.endParams?.hourEnd).format('HH:mm')} hs`}
          </Text>
        </View>
      ) : (
        <View style={styleTable.rowDyalisisData}>
          <Text style={styleTable.column8}>{index}</Text>
          <Text style={styleTable.column3}>
            {moment(control?.initialParams?.hourStart).format(
              'DD/MM/YYYY HH:mm'
            )}{' '}
            hs
          </Text>
          <Text style={styleTable.column3}>Ausente</Text>
        </View>
      )}
    </View>
  );
}
