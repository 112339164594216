import React from 'react';
import { Page } from '@react-pdf/renderer';
import {
  PdfHeader,
  PdfIncome,
  PdfTitle,
  PdfFooter,
  PdfSignature,
} from './index';
import { styleBody } from './styles';

export function PageIncome({
  income,
  title,
  signature,
  news,
  organization,
  tenant,
}) {
  return (
    <Page style={styleBody.body}>
      <PdfHeader
        name={income.name}
        socialWork={income.socialWork}
        type={income.type}
        organization={organization}
        tenant={tenant}
      />
      <PdfTitle
        title={title == 'Ingreso' ? 'Anamnesis y examen físico' : title}
      />
      <PdfIncome income={income} type={title} news={news} />
      <PdfSignature
        signature={signature}
        name={title === 'Ingreso' ? income.user : income?.userEpicrisis?.name}
        lastname={title === 'Ingreso' ? '' : income.userEpicrisis?.lastname}
      />
      <PdfFooter type={news || income.type} />
    </Page>
  );
}
