import { ON_PHONE_READ, ON_PHONE_READING } from './types';
import { notification } from 'antd';
import {
  getPhoneApi,
  addPhoneApi,
  deletePhoneApi,
  updatePhoneApi,
} from '../api/phone';
import { getAccessTokenApi } from '../api/auth';

export const onPhoneRead = () => async (dispatch) => {
  dispatch({ type: ON_PHONE_READING });
  let token = await getAccessTokenApi();

  return getPhoneApi(token).then((response) => {
    dispatch({ type: ON_PHONE_READ, payload: response.phones });
  });
};

export const onPhoneCreate =
  (phoneData, setIsVisibleModal) => async (dispatch) => {
    let token = await getAccessTokenApi();

    return addPhoneApi(phoneData, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          await dispatch(onPhoneRead());
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onPhoneUpdate =
  (id, phoneData, setIsVisibleModal) => async (dispatch) => {
    let token = await getAccessTokenApi();

    return updatePhoneApi(id, phoneData, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          await dispatch(onPhoneRead());
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onPhoneDelete = (id) => async (dispatch) => {
  let token = await getAccessTokenApi();

  return deletePhoneApi(id, token)
    .then(async (response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        await dispatch(onPhoneRead());
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};
