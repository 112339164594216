import React from 'react';
import { Page, View, Text, Image } from '@react-pdf/renderer';
import { styleBody, styleTable } from './styles';
import moment from 'moment';

export function PdfHighMedical({
  income,
  data,
  tenant,
  organization,
  organizationPhone,
  tenantLogo,
}) {
  const image =
    income?.type === 'cardio' || data?.logo === 'cardio'
      ? `https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo1.png`
      : ` https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/${
          tenantLogo || 'logo'
        }.png`;
  return (
    <Page style={styleBody.bodyWithoutHeader}>
      <View style={{ paddingBottom: 10, alignItems: 'center' }}>
        <Image style={{ width: '60%', objectFit: 'contain' }} src={image} />
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Paciente:</Text>
        <Text style={styleBody.text}>{income ? income.name : data.name}</Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>
          {income ? 'Fecha de alta:' : 'Fecha'}
        </Text>
        <Text style={styleBody.text}>
          {income
            ? moment(income.dischargeDate).format('DD/MM/YYYY')
            : moment(data.date).format('DD/MM/YYYY')}
        </Text>
        <Text style={[styleBody.textTitle, { paddingLeft: 5 }]}>Edad:</Text>
        <Text style={styleBody.text}>
          {income ? income.age : data.age} años
        </Text>
      </View>
      <View style={[styleTable.row, styleTable.header]} fixed>
        <Text style={styleTable.column4}>Indicación</Text>
        <Text style={styleTable.column5}>Horario</Text>
      </View>
      {data?.medicines?.map((data) => (
        <View wrap={false}>
          <View style={styleTable.row}>
            <Text style={styleTable.column4}>{data.drug}</Text>
            <Text style={styleTable.column5}>
              {data.hours?.map((hour) => `${hour} hs `)}
            </Text>
          </View>
        </View>
      ))}
      <View
        render={() =>
          data.others && (
            <View style={[styleBody.data, { paddingTop: 10 }]}>
              <Text style={styleBody.textTitle}>Otros:</Text>
              <Text style={styleBody.text}>{data.others}</Text>
            </View>
          )
        }
      />
      <Text fixed style={styleBody.pageNumber}>
        ¡ No olvides llevarme en tu proxima consulta ! {'\n'} {'\n'}
        {organization} - {organizationPhone}
      </Text>
    </Page>
  );
}
