import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import useAuth from '../hooks/useAuth';
import MenuTop from '../components/MenuTop';
import MenuSider from '../components/MenuSider';
import AdminSignIn from '../pages/SignIn';
import MenuSiderInfarmary from '../components/Infarmary/MenuSiderInfarmary';
import MenuTopInfarmary from '../components/Infarmary/MenuTopInfarmary';
import { useSelector } from 'react-redux';
import './LayoutAdmin.scss';
import Maintenance from '../pages/Maintenance';
import RegisterAmbulatory from '../pages/RegisterAmbulatory/RegisterAmbulatory';

export default function LayoutAdmin(props) {
  const { routes } = props;
  const [menuCollapsed, setMenuCollapsed] = useState();
  const { Header, Content, Footer } = Layout;
  const { user, isLoading } = useAuth();
  const { organizationType, organization, down } = useSelector(
    (store) => store.tenantData
  );

  window.document.title = `AmpinaSoft | ${organization}`;

  if (down) return <Maintenance />;

  if (!user && !isLoading) {
    if (
      window.location.hash === '#/register' &&
      window.location.hostname.includes('ambulatorio')
    ) {
      return (
        <>
          <Route path="/register" component={RegisterAmbulatory} />
          <Redirect to="/register" />
        </>
      );
    }
    return (
      <>
        <Route path={'/login'} component={AdminSignIn} />
        <Redirect to="/login" />
      </>
    );
  }

  if (organizationType === 'ambulatoria' && !isLoading) {
    return (
      <Layout>
        <MenuSider
          menuCollapsed={menuCollapsed}
          setMenuCollapsed={setMenuCollapsed}
          typeMenu="amb"
        />
        <Layout
          className="layout-admin"
          style={{ marginLeft: menuCollapsed ? '80px' : '200px' }}
        >
          <Header className="layout-admin__header" style={{ zIndex: 1 }}>
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>
          <Content className="layout-admin__content">
            <LoadRoutes routes={routes} />
          </Content>
          <Footer className="layout-admin__footer">
            <h5>AmpinaSoft - Contacto: soporte@ampinasoft.com - CopyRight ©</h5>
          </Footer>
        </Layout>
      </Layout>
    );
  }
  if (
    (user?.type === 'Medico' || user?.type === 'Administrativo') &&
    !isLoading
  ) {
    return (
      <Layout>
        <MenuSider
          menuCollapsed={menuCollapsed}
          setMenuCollapsed={setMenuCollapsed}
        />
        <Layout
          className="layout-admin"
          style={{ marginLeft: menuCollapsed ? '80px' : '200px' }}
        >
          <Header className="layout-admin__header" style={{ zIndex: 1 }}>
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>
          <Content className="layout-admin__content">
            <LoadRoutes routes={routes} />
          </Content>
          <Footer className="layout-admin__footer">
            <h5>AmpinaSoft - Contacto: soporte@ampinasoft.com - CopyRight ©</h5>
          </Footer>
        </Layout>
      </Layout>
    );
  } else if (user?.type === 'Enfermero' && !isLoading) {
    return (
      <Layout>
        <MenuSiderInfarmary
          menuCollapsed={menuCollapsed}
          setMenuCollapsed={setMenuCollapsed}
        />
        <Layout
          className="layout-admin"
          style={{ marginLeft: menuCollapsed ? '80px' : '200px' }}
        >
          <Header className="layout-admin__header" style={{ zIndex: 1 }}>
            <MenuTopInfarmary
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>
          <Content className="layout-admin__content">
            <LoadRoutes routes={routes} />
          </Content>
          <Footer className="layout-admin__footer">
            <h5>AmpinaSoft - Contacto: soporte@ampinasoft.com - CopyRight ©</h5>
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
}

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}
