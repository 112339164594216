import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { notification } from 'antd';
import Spin from '../Spin';
import { getLineStatsApi, getTurnsStatsApi } from '../../api/stats';
import { getAccessTokenApi } from '../../api/auth';

export function LineChart({
  type,
  userId,
  generate,
  status,
  chartTitle,
  hAxisTitle,
  floor,
  statsType,
  height,
  start,
  end,
  studieType,
}) {
  const [dataChart, setDataChart] = useState();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      setLoading(true);
      statsType === 'amb'
        ? getTurnsStatsApi({
            token,
            type,
            id: userId,
            status,
            start,
            end,
            studieType,
          }).then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              setDataChart(response.dataChart);
              setTotal(response.total);
              setLoading(false);
            }
          })
        : getLineStatsApi({ token, floor, type, start, end })
            .then((response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
              } else {
                setDataChart(response.dataChart);
                setTotal(response.total);
                setLoading(false);
              }
            })
            .catch((err) =>
              notification['error']({ message: 'Error del servidor' })
            );
    };

    getData();
  }, [generate, type]);

  if (loading)
    return (
      <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <span>Cargando...</span>
        <Spin />
      </div>
    );

  if (total === 0) return null;

  return (
    <Chart
      width="100%"
      height={height || '300px'}
      chartType="AreaChart"
      data={dataChart}
      options={{
        title: `${chartTitle}: ${total.toLocaleString('es-AR')}`,
        hAxis: {
          textStyle: {
            fontSize: '8pt',
          },
          title: hAxisTitle,
          titleTextStyle: { color: '#333' },
          slantedText: true,
          slantedTextAngle: 20,
        },
        vAxis: { minValue: 0 },
      }}
    />
  );
}
