import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleBody } from './styles';
import moment from 'moment';

export function PdfDyalisisEvolution({ income, evolution }) {
  return (
    <View>
      <Text style={styleBody.subtitle}>
        {income.name} - {income.age} años
      </Text>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>DNI:</Text>
        <Text style={styleBody.text}>{income.dni}</Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Fecha de nacimiento:</Text>
        <Text style={styleBody.text}>
          {moment(income.birthDate).format('DD/MM/YYYY')}
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Fecha de ingreso:</Text>
        <Text style={styleBody.text}>
          {moment(income.date).format('DD/MM/YYYY')}
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Sexo: </Text>
        <Text style={styleBody.text}>{income.sex} </Text>
        <Text style={styleBody.textTitle}> - Telefono de contacto: </Text>
        <Text style={styleBody.text}>{income?.contactPhone}</Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Ciudad de origen: </Text>
        <Text style={styleBody.text}>{income?.city} </Text>
        <Text style={styleBody.textTitle}> - Dirección: </Text>
        <Text style={styleBody.text}>{income?.direction}</Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Obra social: </Text>
        <Text style={styleBody.textUppercase}>{income.socialWork} </Text>
        <Text style={styleBody.textTitle}> - Nro de afiliado:</Text>
        <Text style={styleBody.text}>{income?.affiliate}</Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Tipo de paciente: </Text>
        <Text style={styleBody.textUppercase}>
          {income?.typePatientDyalisis}{' '}
        </Text>
        <Text style={styleBody.textTitle}> - Tipo de diálisis: </Text>
        <Text style={styleBody.text}>{income?.typeDyalisis}</Text>
      </View>
      <View style={styleBody.data}></View>
    </View>
  );
}
