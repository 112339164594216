import React, { useEffect, useState } from 'react';
import TableControls from '../../../components/Infarmary/TableControls';
import { Button, PageHeader, Tooltip } from 'antd';
import Modal from '../../../components/Modal';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ControlForm from '../../../components/Infarmary/ControlForm';
import { DiffOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export default function Controls(props) {
  const { id, history } = props.match.params;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [reload, setReload] = useState(false);

  const { incomesActive, incomesHistory } = useSelector(
    (store) => store.incomeData
  );
  const { type } = useSelector((store) => store.userData?.user);

  useEffect(() => {
    if (type === 'Medico' && !history) props.history.push('/');
  }, [id]);

  var income = {};
  history
    ? incomesHistory.find((i) => {
        if (i._id === id) {
          income = i;
        }
      })
    : incomesActive.find((i) => {
        if (i._id === id) {
          income = i;
        }
      });

  const addControl = (income) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar control a ${income.name}`);
    setModalContent(
      <ControlForm
        incomeId={id}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        discharge={income.dischargeDate}
      />
    );
  };

  return (
    <div className="evolution">
      <div className="evolution-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`Cama: ${income?.bed?.name}`}
          subTitle={
            <Tooltip
              title={`Cama: ${income?.bed?.name} ${income?.name} - ${
                income.age
              } años | Fecha Ingreso: ${moment(income?.date).format(
                'DD/MM/YYYY'
              )}`}
            >
              {income?.name} - {income?.age} años | Fecha Ingreso:{' '}
              {moment(income?.date).format('DD/MM/YYYY')}
            </Tooltip>
          }
          extra={
            <>
              <Tooltip title="Notas">
                <Link to={`/notes/${income._id}`}>
                  <Button type="link">
                    <EditOutlined />
                  </Button>
                </Link>
              </Tooltip>
              <Tooltip title="Balances">
                <Link to={`/balances/${income._id}`}>
                  <Button type="link">
                    <DiffOutlined />
                  </Button>
                </Link>
              </Tooltip>
              <Button type="primary" onClick={() => addControl(income)}>
                Agregar Control
              </Button>
            </>
          }
        />
      </div>
      <div className="pdf">
        <TableControls id={id} reload={reload} setReload={setReload} />
      </div>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={window.screen.width < 500 ? '80%' : '50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
