import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  DatePicker,
  Input,
  List,
  Popover,
  Row,
  Select,
  Tag,
  Tooltip,
} from 'antd';
import {
  CheckCircleOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import BackButton from '../../components/BackButton';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  onPharmacyFilterRead,
  onPharmacyLoadMore,
  onPharmacyRead,
  onPharmacyValueChange,
} from '../../actions';
import { getProvidersApi } from '../../api/provider';
import { getAccessTokenApi } from '../../api/auth';

export default function PharmacyList(props) {
  const { type } = props.match.params;
  const [providers, setProviders] = useState([]);

  const {
    movements,
    count,
    loading,
    page,
    search,
    searchStore,
    searchProvider,
    searchDateStart,
    searchDateEnd,
  } = useSelector((store) => store.pharmacyData);

  const { drugStores } = useSelector((store) => store.tenantData);

  const dispatch = useDispatch();

  useEffect(() => {
    search || searchStore || searchProvider || searchDateStart || searchDateEnd
      ? dispatch(
          onPharmacyFilterRead({
            search,
            type,
            provider: searchProvider,
            store: searchStore,
            dateStart: searchDateStart,
            dateStart: searchDateStart,
            dateEnd: searchDateEnd,
          })
        )
      : dispatch(onPharmacyRead({ limit: count || 10, type }));

    type === 'send' &&
      dispatch(onPharmacyValueChange({ searchProvider: undefined }));
  }, [search, searchProvider, searchStore, searchDateStart, searchDateEnd]);

  return (
    <div>
      <List
        header={
          <div>
            <BackButton />{' '}
            <span style={{ fontWeight: 600, fontSize: '12pt' }}>
              {type === 'buy' ? 'Compras' : 'Envios'}
            </span>
            <Input.Search
              placeholder="Buscar por concepto..."
              onSearch={(value) =>
                dispatch(onPharmacyValueChange({ search: value }))
              }
              defaultValue={search}
              enterButton
              allowClear
              loading={loading}
              suffix={
                <Popover
                  content={
                    <div className="income-history-header_filter">
                      <Row gutter={24}>
                        <Select
                          placeholder="Seleccione un deposito..."
                          showSearch
                          allowClear
                          size="small"
                          optionFilterProp="children"
                          value={searchStore}
                          onChange={(value) => {
                            dispatch(
                              onPharmacyValueChange({ searchStore: value })
                            );
                          }}
                          style={{ width: '100%', marginLeft: '1%' }}
                        >
                          {drugStores?.map((store, index) => (
                            <Select.Option key={index} value={store}>
                              {store}
                            </Select.Option>
                          ))}
                        </Select>
                      </Row>
                      <Row gutter={24}>
                        <Select
                          placeholder="Proveedor"
                          showSearch
                          allowClear
                          disabled={type === 'send'}
                          size="small"
                          optionFilterProp="children"
                          value={searchProvider}
                          onChange={(value) => {
                            dispatch(
                              onPharmacyValueChange({
                                searchProvider:
                                  value === '' ? undefined : value,
                              })
                            );
                          }}
                          style={{ width: '100%', marginLeft: '1%' }}
                        >
                          {providers?.map((provider, index) => (
                            <Select.Option key={index} value={provider.name}>
                              {provider.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Row>
                      <Row gutter={24}>
                        <DatePicker.RangePicker
                          placeholder={['Fecha inicio ', 'Fecha fin ']}
                          style={{ width: '100%' }}
                          size="small"
                          format="DD-MM-YYYY"
                          value={[
                            searchDateStart && moment(searchDateStart),
                            searchDateEnd && moment(searchDateEnd),
                          ]}
                          onChange={(value) => [
                            value !== null
                              ? dispatch(
                                  onPharmacyValueChange({
                                    searchDateStart: moment(value[0])
                                      .startOf('day')
                                      .toISOString(),
                                    searchDateEnd: moment(value[1])
                                      .startOf('day')
                                      .toISOString(),
                                  })
                                )
                              : dispatch(
                                  onPharmacyValueChange({
                                    searchDateStart: undefined,
                                    searchDateEnd: undefined,
                                  })
                                ),
                          ]}
                          locale={locale}
                          showToday={false}
                        />
                      </Row>
                      <Row>
                        <Button
                          type="link"
                          size="small"
                          style={{ textAlign: 'center' }}
                          onClick={() => {
                            dispatch(
                              onPharmacyValueChange({
                                searchStore: undefined,
                                searchProvider: undefined,
                                searchDateStart: undefined,
                                searchDateEnd: undefined,
                              })
                            );
                          }}
                        >
                          Borrar Filtros
                        </Button>
                      </Row>
                    </div>
                  }
                  title="Filtros"
                  trigger="click"
                  placement="bottom"
                >
                  <Badge
                    count={
                      (searchStore != undefined && 1) +
                      (searchProvider != undefined && 1) +
                      (searchDateStart != undefined && 1)
                    }
                    size="small"
                    title="Cantidad de Filtros Aplicados"
                  >
                    <Button
                      type="link"
                      size="small"
                      onClick={async () => {
                        if (providers?.length === 0) {
                          let token = await getAccessTokenApi();
                          try {
                            getProvidersApi(token).then((response) => {
                              setProviders(response.providers);
                            });
                          } catch {}
                        }
                      }}
                    >
                      Filtros <FilterOutlined />
                    </Button>
                  </Badge>
                </Popover>
              }
            />
          </div>
        }
        className="incomes-list"
        itemLayout="horizontal"
        dataSource={movements}
        renderItem={(data) => <Item data={data} type={type} />}
        locale={{
          emptyText: type === 'buy' ? 'No hay compras' : 'No hay envios',
        }}
        loading={
          loading && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
        pagination={
          movements?.length > 50 && {
            pageSize: 50,
            showSizeChanger: false,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            current: page,
            onChange: (page) => {
              dispatch(onPharmacyValueChange({ page }));
            },
            position: 'top',
          }
        }
      />
      <div style={{ textAlign: 'center', margin: 5 }}>
        <Button
          type="link"
          onClick={() => {
            dispatch(onPharmacyLoadMore({ movements, limit: 10, count, type }));
            dispatch(
              onPharmacyValueChange({ page: Math.ceil((count + 10) / 50) })
            );
          }}
          loading={loading}
          disabled={loading || !count}
        >
          Cargar Mas...
        </Button>
      </div>
    </div>
  );
}

function Item({ data, type }) {
  const day = moment(data?.invoice?.date).format('DD');
  const month = moment(data?.invoice?.date).format('MMMM');
  const year = moment(data?.invoice?.date).format('YYYY');
  return (
    <>
      <Tooltip title="Fecha de factura" className="incomes-list__date-cel">
        <div>
          <span style={{ marginRight: 2 }}>{day}</span>
          <span style={{ marginRight: 2 }}>{month}</span>
          <span>{year}</span>
        </div>
      </Tooltip>
      <List.Item
        actions={[
          type === 'buy' && (
            <Tag
              color={data?.paid ? 'success' : 'default'}
              icon={data?.paid ? <CheckCircleOutlined /> : <SyncOutlined />}
            >
              {data?.paid ? 'Pagado' : 'Pendiente'}
            </Tag>
          ),
          <Tooltip title="Ver detalle">
            <Link
              to={{
                pathname: `/pharmacy/stock/${data._id}`,
                hash: type,
              }}
            >
              <Button type="default">
                <InfoCircleOutlined />
              </Button>
            </Link>
          </Tooltip>,
        ]}
      >
        <List.Item.Meta
          avatar={
            <Tooltip title="Fecha de factura">
              <div className="incomes-list__date">
                <span style={{ marginRight: 2 }}>{day}</span>
                <span style={{ marginRight: 2 }}>{month}</span>
                <span>{year}</span>
              </div>
            </Tooltip>
          }
          title={
            type === 'buy' ? `${data?.name} - ${data?.provider}` : data?.name
          }
          description={
            <span>
              {type === 'buy' ? 'Destino:' : 'Origen:'} {data.store}
              {type === 'buy' && ` - Nro de Factura: ${data?.invoice?.number}`}
            </span>
          }
        />
      </List.Item>
    </>
  );
}
