import React from 'react';
import moment from 'moment';
import './PassView.scss';
import { Divider } from 'antd';

export default function PassView(props) {
  const { income } = props;

  return (
    <div className="income-pass-view">
      <div className="income-pass-view-header-pass">
        <div className="income-pass-view-header-pass__title">
          <text className="income-pass-view-header-pass__title-pass">
            Cama: {income.bed.name} |{' '}
            <text style={{ textTransform: 'capitalize' }}>{income.name}</text> -{' '}
            {income.age} años
          </text>
          <h6>
            Ingreso realizado por: <span>{income.user}</span>
          </h6>
          <h6>
            Ultima novedad por: <span>{income.userNews}</span>
          </h6>
          <h6>
            Fecha de ingreso:{' '}
            <span>{moment(income.date).format('DD/MM/YYYY')}</span>
          </h6>
          <h6>
            {income.bed?.bedType === 'Dialisis'
              ? 'Etiologia de la IRC:'
              : 'Motivo de consulta:'}{' '}
            <span>{income.queryReason}</span>{' '}
          </h6>
        </div>
      </div>
      <div className="income-pass-view-personal">
        <h6>
          Sexo: <span>{income.sex}</span>{' '}
        </h6>
        <h6>
          DNI: <span>{income.dni}</span>{' '}
        </h6>
        <h6>
          Talla: <span>{income.heigth} m</span>{' '}
        </h6>
        <h6>
          Peso:<span>{income.weigth} kg</span>{' '}
        </h6>
        <h6>
          IMC: <span>{income.imc}</span>{' '}
        </h6>
      </div>
      <div>
        <h6>
          Ciudad de origen: <span>{income.city} </span> - Origen interno:{' '}
          <span>{income.origin}</span>
        </h6>
        <h6>
          Medico de cabecera: <span>{income.doctor}</span>{' '}
        </h6>
        <h6>
          Obra social: <span>{income.socialWork} </span> - Nro de afiliado:{' '}
          <span>{income.affiliate}</span>
        </h6>
        <p>
          <text>Alergias:</text> {income.allergy}
        </p>
        <p>
          <text>Antecedentes: </text>
          {income.personalRecord}
        </p>
        <p>
          <text>Antecedentes quirurgicos: </text> {income.surgicalRecord}
        </p>
        <p>
          <text>Antecedentes toxicos: </text> {income.toxicHabits}
        </p>
        <p>
          <text>Antecedentes heredofamiliares: </text> {income.familyRecord}
        </p>
        <p>
          <text>Estudios previos: </text>
          {income.complementaryStudies}
        </p>
        <p>
          <text>Medicacion habitual: </text>
          {income.regularMediation}
        </p>
        <p>
          <text>Antecedentes de la enfermedad actual: </text>
          {income.historyCurrentIllnes}
        </p>
        <p>
          <text>Examen fisico: </text>
          {income.physicalExam}
        </p>
        <p>
          <text>Laboratorio: </text>
          {income.laboratory}
        </p>
        <p>
          <text>ECG: </text>
          {income.ecg}
        </p>
        <p>
          {income.bed?.bedType === 'Dialisis' ? (
            <text>Ingreso a Diálisis: </text>
          ) : (
            <text>Radiografia de torax: </text>
          )}
          {income.rxChest}
        </p>
        <p>
          <text>Otros estudios: </text> {income.otherStudies}
        </p>
        <p>
          <text>Conducta: </text>
          {income.conduct}
        </p>
        <p>
          <text>Diagnostico presuntivo: </text> {income.diagnostic}
        </p>
        <p>
          <text>Novedades: </text> {income.news}
        </p>
      </div>
      <Divider></Divider>
    </div>
  );
}
