import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Select,
  InputNumber,
  Input,
  Radio,
  Card,
  Tooltip,
  notification,
  Checkbox,
} from 'antd';
import { getAccessTokenApi } from '../../api/auth';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { getMedicineDayApi, suspendIndicationApi } from '../../api/medicine';
import { units } from '../../utils/constants';

export default function MedicineForm(props) {
  const {
    incomeId,
    medicineId,
    path,
    drugs,
    change,
    setChange,
    medicineForm,
    indication,
    setIndication,
    bedType,
  } = props;

  const [extra, setExtra] = useState(false);

  useEffect(() => {
    indication === undefined &&
      medicineForm.setFieldsValue({
        indication: [{}],
      });
  }, []);

  const suspendIndication = async (id, status) => {
    let token = await getAccessTokenApi();

    await suspendIndicationApi(
      medicineId,
      indication[id]._id,
      status,
      incomeId,
      token
    )
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({
            message: response.message,
          });
        } else {
          notification['success']({
            message: response.message,
          });
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });

    getMedicineDayApi(medicineId, token).then((response) => {
      setIndication(response.medicine.indication);
      var indication = response.medicine.indication;
      indication &&
        medicineForm.setFieldsValue({
          indication,
        });
      setIndication(indication);
    });
  };

  return (
    <div>
      <Form.List name="indication">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Card
                  title={`Medicamento ${index + 1}`}
                  headStyle={
                    indication && indication[field.name]?.suspend
                      ? {
                          textAlign: 'center',
                          backgroundColor: '#6f080e',
                          color: '#fff',
                        }
                      : {
                          textAlign: 'center',
                          backgroundColor: '#1764ab',
                          color: '#fff',
                        }
                  }
                  extra={
                    <>
                      {path ===
                        '/new-medicine/:incomeId/:incomeType?/:medicineId?' ||
                      indication.length - 1 < field.name ? (
                        <Tooltip title="Eliminar indicación">
                          <Button
                            type="link"
                            size="large"
                            onClick={() => {
                              remove(field.name);
                            }}
                            style={{ color: '#fff' }}
                          >
                            <CloseOutlined />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Form.Item
                          {...field}
                          name={[field.name, 'suspend']}
                          fieldKey={[field.fieldKey, 'suspend']}
                        >
                          <Tooltip
                            title={
                              change &&
                              'Guarde los cambios realizados para poder suspender'
                            }
                          >
                            <Checkbox
                              onChange={(e) => {
                                suspendIndication(field.name, e.target.checked);
                              }}
                              style={{ color: '#fff' }}
                              defaultChecked={
                                indication
                                  ? indication[field.name]?.suspend
                                  : false
                              }
                              disabled={change}
                            >
                              Suspender
                            </Checkbox>
                          </Tooltip>
                        </Form.Item>
                      )}
                    </>
                  }
                >
                  <Row gutter={24} key={index}>
                    <Col xs={24} sm={16} md={12}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'drug']}
                        fieldKey={[field.fieldKey, 'drug']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir un medicamento',
                          },
                        ]}
                        label="Seleccione un medicamento"
                        labelCol={{ span: 24 }}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Seleccione un medicamento.. (*)"
                          optionFilterProp="children"
                          onChange={() => {
                            setChange(true);
                            if (bedType === '?Dialisis') {
                              let data =
                                medicineForm.getFieldValue().indication[
                                  field.name
                                ];
                              Object.assign(data, { time: 24 });
                            }
                          }}
                          disabled={
                            path ===
                              '/new-medicine/:incomeId/:incomeType?/:medicineId?' ||
                            indication.length - 1 < field.name
                              ? false
                              : true
                          }
                        >
                          {drugs?.map((drug, index) => (
                            <Select.Option key={index} value={drug._id}>
                              {drug.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={8} sm={8} md={2}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'dosis']}
                        fieldKey={[field.fieldKey, 'dosis']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir la dosis.',
                          },
                        ]}
                        label="Dosis"
                        labelCol={{ span: 24 }}
                      >
                        <InputNumber
                          min={0}
                          inputMode="numeric"
                          onChange={() => setChange(true)}
                          disabled={
                            path ===
                              '/new-medicine/:incomeId/:incomeType?/:medicineId?' ||
                            indication.length - 1 < field.name
                              ? false
                              : true
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={16} sm={12} md={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'unity']}
                        fieldKey={[field.fieldKey, 'unity']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir la unidad.',
                          },
                        ]}
                        label="Unidad de medida"
                        labelCol={{ span: 24 }}
                      >
                        <Select
                          showSearch={window.screen.width > 500}
                          style={{ width: '100%' }}
                          placeholder="Seleccione.. (*)"
                          optionFilterProp="children"
                          onChange={() => setChange(true)}
                          disabled={
                            path ===
                              '/new-medicine/:incomeId/:incomeType?/:medicineId?' ||
                            indication.length - 1 < field.name
                              ? false
                              : true
                          }
                        >
                          {units.map((unit) => (
                            <Select.Option value={unit.name}>
                              {unit.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'extra']}
                        fieldKey={[field.fieldKey, 'extra']}
                        label="Seleccione en caso de ser necesario"
                        initialValue={'Habitual'}
                        labelCol={{ span: 24 }}
                      >
                        <Radio.Group
                          onChange={(e) => {
                            setChange(true);
                            setExtra(
                              e.target.value === 'Unica Dosis' ? true : false
                            );
                          }}
                          disabled={
                            path ===
                              '/new-medicine/:incomeId/:incomeType?/:medicineId?' ||
                            indication.length - 1 < field.name
                              ? false
                              : true
                          }
                        >
                          <Radio value="Habitual">Habitual</Radio>
                          <Radio value="Unica Dosis">Unica Dosis</Radio>
                          <Radio value="SOS">SOS</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    {bedType !== '?Dialisis' && (
                      <>
                        <Col xs={24} sm={12} md={6}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'time']}
                            fieldKey={[field.fieldKey, 'time']}
                            rules={[
                              {
                                required:
                                  medicineForm.getFieldValue().indication[
                                    field.name
                                  ] &&
                                  medicineForm.getFieldValue().indication[
                                    field.name
                                  ].extra === 'Unica Dosis'
                                    ? false
                                    : true,
                                message: 'Debe elegir cada cuanto tiempo',
                              },
                            ]}
                            label="Cada (hs)"
                            labelCol={{ span: 24 }}
                          >
                            <Radio.Group
                              onChange={(e) => {
                                setChange(true);
                              }}
                              disabled={
                                medicineForm.getFieldValue().indication[
                                  field.name
                                ] &&
                                (medicineForm.getFieldValue().indication[
                                  field.name
                                ].extra === 'Unica Dosis' ||
                                  (path !==
                                    '/new-medicine/:incomeId/:incomeType?/:medicineId?' &&
                                    indication?.length - 1 >= field.name))
                              }
                            >
                              <Radio value={4}>4</Radio>
                              <Radio value={6}>6</Radio>
                              <Radio value={8}>8</Radio>
                              <Radio value={12}>12</Radio>
                              <Radio value={24}>24</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'hour']}
                            fieldKey={[field.fieldKey, 'hour']}
                            label="Horarios"
                            labelCol={{ span: 24 }}
                          >
                            <Input
                              placeholder="Indicar horarios. Ej: 8 - 16 - 24"
                              onChange={() => setChange(true)}
                              disabled={
                                path ===
                                  '/new-medicine/:incomeId/:incomeType?/:medicineId?' ||
                                indication.length - 1 < field.name
                                  ? false
                                  : true
                              }
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'description']}
                        fieldKey={[field.fieldKey, 'description']}
                        label="Observaciones"
                        labelCol={{ span: 24 }}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 1 }}
                          onChange={() => setChange(true)}
                          disabled={
                            path ===
                              '/new-medicine/:incomeId/:incomeType?/:medicineId?' ||
                            indication.length - 1 < field.name
                              ? false
                              : true
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              ))}
              <Form.Item style={{ textAlign: 'right', marginTop: '5px' }}>
                <Tooltip title="Agregar indicación">
                  <Button
                    type="default"
                    shape="circle"
                    onClick={() => {
                      add();
                    }}
                    size="large"
                  >
                    <PlusOutlined />
                  </Button>
                </Tooltip>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
}
