import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Input, Statistic, Tooltip, message } from 'antd';
import './Income.scss';
import { Link } from 'react-router-dom';
import IncomeTable from '../../components/IncomeTable';
import { useDispatch, useSelector } from 'react-redux';
import { onIncomeActiveRead, onStatsBedsRead } from '../../actions';
import PermissionsAsigner from '../../components/PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import { PrinterOutlined } from '@ant-design/icons';
import { pdf, Document, Page } from '@react-pdf/renderer';
import {
  PdfReportIncome,
  PdfReportIncomeTableHeader,
  PdfFooter,
} from '../../components/PdfReports';
import { styleBody } from '../../components/PdfReports/styles';
import { saveAs } from 'file-saver';

export default function Income(props) {
  const action = props.history.action;
  const { incomeType } = props.match.params;
  const [search, setSearch] = useState('');
  const [today, setToday] = useState(false);
  const dispatch = useDispatch();
  const { incomesActive } = useSelector((store) => store.incomeData);
  const { type } = useSelector((store) => store.userData.user);

  let allBeds = useSelector((store) => store.bedData.bedsStats);
  if (incomeType === 'all') {
    allBeds = {
      _id: 'all',
      ocupate: allBeds?.reduce((p, a) => p + a.ocupate, 0),
      free: allBeds?.reduce((p, a) => p + a.free, 0),
      quantity: allBeds?.reduce((p, a) => p + a.quantity, 0),
    };
  } else {
    allBeds = allBeds?.find((b) => b._id === incomeType);
  }

  useEffect(() => {
    type === 'Enfermero' &&
      props.history.push(`/infarmary-income/${incomeType}`);
    dispatch(onIncomeActiveRead(incomeType));
    dispatch(onStatsBedsRead());
  }, [incomeType]);

  const printIncomeReport = async () => {
    try {
      message.loading('Generando informe. Por favor espere...', 0);
      const doc = (
        <Document>
          <Page style={styleBody.body}>
            <PdfReportIncomeTableHeader />
            {incomesActive?.map((income, index) => (
              <>
                <PdfReportIncome index={index + 1} income={income} />
              </>
            ))}
            <PdfFooter />
          </Page>
        </Document>
      );

      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, 'Reporte');
      message
        .success('El documento se ha generado con exito.', 1)
        .then(() => message.destroy());
    } catch {
      message
        .error('Error, intente nuevamente', 1)
        .then(() => message.destroy());
    }
  };

  return (
    <div className="income">
      <div className="income-header">
        <div className="income-header_statistic">
          <Statistic
            value={allBeds?.ocupate}
            valueStyle={
              incomesActive &&
              (0.75 * allBeds?.quantity > allBeds?.ocupate
                ? { color: '#3f8600' }
                : 0.85 * allBeds?.quantity > allBeds?.ocupate
                ? { color: '#d6d100' }
                : { color: '#cf1322' })
            }
            prefix={
              <span
                style={{
                  color: 'rgba(0, 0, 0, 0.45)',
                  fontSize: '0.8em',
                  padding: 5,
                }}
              >
                Ocupación
              </span>
            }
            suffix={`/ ${allBeds?.quantity}`}
          />
        </div>
        <div className="income-header_search">
          <Input.Search
            enterButton
            placeholder="Buscar pacientes activos.."
            onSearch={(value) => setSearch(value)}
            onChange={(e) =>
              (e.target.value.length === 0 || e.target.value.length >= 3) &&
              setSearch(e.target.value)
            }
            allowClear
            suffix={
              <Checkbox
                checked={today}
                onChange={(e) => setToday(e.target.checked)}
              >
                Hoy
              </Checkbox>
            }
          />
        </div>
        <div>
          <Tooltip
            title={`Imprimier grilla ${incomeType === 'all' ? '' : incomeType}`}
          >
            <Button
              type="link"
              onClick={() => {
                printIncomeReport();
              }}
            >
              <PrinterOutlined />
            </Button>
          </Tooltip>
        </div>
        {incomeType !== 'all' && (
          <>
            <Link to={`/view-pass/all`}>
              <Button type="primary">Ver Todos</Button>
            </Link>
            <PermissionsAsigner requiredRole={ROLES.SECRETARY}>
              <Link to={`/new-income/${incomeType}`}>
                <Button type="primary">Nuevo Ingreso</Button>
              </Link>
            </PermissionsAsigner>
          </>
        )}
      </div>

      <IncomeTable
        search={search}
        today={today}
        incomesActive={incomesActive}
        incomeType={incomeType}
        action={action}
      />
    </div>
  );
}
