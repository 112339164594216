import { ON_MESSAGE_READING, ON_MESSAGE_READ } from './types';
import { notification } from 'antd';
import {
  addMessageApi,
  resolveMessageApi,
  getMessagesApi,
} from '../api/message';
import { getAccessTokenApi } from '../api/auth';

export const onMessageRead = (type, turn) => async (dispatch) => {
  dispatch({ type: ON_MESSAGE_READING });
  let token = await getAccessTokenApi();
  return getMessagesApi(type, token, turn).then(async (response) => {
    await dispatch({ type: ON_MESSAGE_READ, payload: response.messages });
  });
};

export const onMessageCreate =
  (messageData, setIsVisibleModal, type) => async (dispatch) => {
    let token = await getAccessTokenApi();

    return addMessageApi(messageData, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          window.location.hash !== '#/turns' &&
            (await dispatch(onMessageRead(type)));
          setIsVisibleModal(false);
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onMessageResolve = (id, type, turn) => async (dispatch) => {
  let token = await getAccessTokenApi();

  return resolveMessageApi(id, token)
    .then(async (response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        await dispatch(onMessageRead(type, turn));
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};
