import React, { useState, useEffect } from 'react';
import { Button, Descriptions, Select, Table, Tag, Tooltip } from 'antd';
import { notification } from 'antd';
import { getTurnsStatsApi } from '../../api/stats';
import { getAccessTokenApi } from '../../api/auth';
import {
  CheckCircleOutlined,
  FileExcelOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Spin from '../Spin';

export function TableChart(props) {
  const {
    primaryTitle,
    start,
    end,
    type,
    userId,
    generate,
    status,
    format,
    secondTitle,
    data,
    sendColumns,
    studieType,
    selection,
    userName,
    selectedSocialWorks,
    setSelectedSocialWorks,
    selectedRowKeys,
    setSelectedRowKeys,
    practice,
    charged,
    responsible,
    billingDate,
    billingDone,
    organization,
    pageSize,
    viewDetail,
    exportData,
  } = props;
  const [dataChart, setDataChart] = useState();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [quantityDone, setQuantityDone] = useState(0);
  const [socialWorks, setSocialWorks] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      setLoading(true);
      await getTurnsStatsApi({
        token,
        type,
        id: userId,
        status,
        format,
        start,
        end,
        socialWorks: selectedSocialWorks,
        studieType,
      }).then((response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          setDataChart(response.dataChart);
          setTotal(response.total);
          selection && setQuantityDone(response.quantityDone);

          if (selection && !selectedSocialWorks) {
            setSocialWorks([
              ...new Set(response.dataChart.map((item) => item?.socialWork)),
            ]);
          }
          setLoading(false);
        }
      });
    };

    if (data) {
      setDataChart(data.dataChart);
      setTotal(data?.total);
    } else getData();
  }, [generate, refresh, selectedSocialWorks]);

  const columns = sendColumns
    ? sendColumns
    : [
        {
          title: primaryTitle,
          dataIndex: 'primary',
          key: 'primary',
          sorter: (a, b) => a.primary.localeCompare(b.primary),
        },
        {
          title: secondTitle || 'Cantidad',
          dataIndex: 'quantity',
          key: 'quantity',
          align: 'center',
          sorter: (a, b) => a.quantity - b.quantity,
          render: (quantity) => (
            <span>
              {secondTitle === 'Liquidacion'
                ? quantity?.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                  })
                : quantity}
            </span>
          ),
        },
        {
          title: 'Porcentaje',
          dataIndex: 'quantity',
          render: (quantity) => (
            <span>{((quantity / total) * 100).toFixed(2)} %</span>
          ),
          key: 'percentage',
          align: 'center',
          sorter: (a, b) => a.percentage - b.percentage,
        },
      ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL],
  };

  if (loading) return <Spin />;

  return (
    <div>
      {secondTitle === 'Liquidacion' ? (
        <h1>
          Liquidacion del periodo:{' '}
          {total.toLocaleString('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 2,
          })}
        </h1>
      ) : (
        start && (
          <Descriptions
            title={`Periodo: ${moment(start).utcOffset(0).format('DD/MM/YY')} - 
        ${moment(end).utcOffset(0).format('DD/MM/YY')}`}
            column={{ xs: 1, sm: 1, md: 2 }}
            style={{
              backgroundColor: '#fff',
              padding: '10px',
              borderRadius: '10px',
              marginBottom: '10px',
              marginTop: '10px',
            }}
            extra={
              data?.totalNotIn > 0 && (
                <div style={{ padding: '5px' }}>
                  <span>
                    {data?.totalNotIn?.toLocaleString('es-AR')} practicas no
                    incluidas
                  </span>
                  <Tooltip title="Ver detalle">
                    <Button
                      type="link"
                      onClick={() => viewDetail()}
                      style={{ paddingLeft: '5px' }}
                    >
                      <InfoCircleOutlined />
                    </Button>
                  </Tooltip>
                </div>
              )
            }
          >
            {userName && (
              <Descriptions.Item
                label={
                  dataChart?.length > 0 && dataChart[0].medicoTurnera
                    ? 'Medico'
                    : 'Estudio'
                }
              >
                {dataChart?.length > 0 && dataChart[0].medicoTurnera
                  ? `${userName} - ${dataChart[0].medicoTurnera}`
                  : userName}
              </Descriptions.Item>
            )}
            {selectedSocialWorks && !setSelectedSocialWorks ? (
              <Descriptions.Item label="Financiador">
                {selectedSocialWorks}
              </Descriptions.Item>
            ) : (
              setSelectedSocialWorks && (
                <Descriptions.Item label="Financiador">
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      width: '100%',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <Select
                      placeholder="Seleccione financiador"
                      showSearch
                      optionFilterProp="children"
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        setSelectedSocialWorks(value);
                        setSelectedRowKeys([]);
                      }}
                      onClear={() => setRefresh(!refresh)}
                      onDeselect={() => setRefresh(!refresh)}
                      allowClear
                      bordered={selectedSocialWorks && false}
                      value={selectedSocialWorks}
                    >
                      {socialWorks?.map((socialWork, index) => {
                        let done = billingDone.some(
                          (b) =>
                            b._id.socialWork === socialWork &&
                            organization === b._id.organization
                        );

                        return (
                          socialWork && (
                            <Select.Option
                              key={index}
                              value={socialWork}
                              style={{ textTransform: 'uppercase' }}
                            >
                              <Tooltip title={done && `Ya fue facturado`}>
                                {socialWork}{' '}
                                {done && (
                                  <CheckCircleOutlined
                                    style={{ color: 'green' }}
                                  />
                                )}
                              </Tooltip>
                            </Select.Option>
                          )
                        );
                      })}
                    </Select>
                  </div>
                </Descriptions.Item>
              )
            )}
            {selection && (
              <Descriptions.Item label="Items seleccionados">
                {selectedRowKeys.length.toLocaleString('es-AR')}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Cantidad de practicas">
              {total?.toLocaleString('es-AR')}
            </Descriptions.Item>
            {practice && (
              <>
                <Descriptions.Item label="Practica">
                  {practice?.map((p) => `${p.code} - ${p.name}`)}
                </Descriptions.Item>
                <Descriptions.Item label="Precio Practica">
                  {practice?.map((p) =>
                    p.price.toLocaleString('es-AR', {
                      style: 'currency',
                      currency: 'ARS',
                      minimumFractionDigits: 2,
                    })
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Precio total lote">
                  {practice?.map((p) =>
                    (p.price * total).toLocaleString('es-AR', {
                      style: 'currency',
                      currency: 'ARS',
                      minimumFractionDigits: 2,
                    })
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Fecha de generación">
                  {moment(billingDate).format('DD/MM/YYYY')}
                </Descriptions.Item>
                <Descriptions.Item label="Responsable">
                  {responsible}
                </Descriptions.Item>
              </>
            )}
            {charged && (
              <>
                {charged?.map((c, index) => (
                  <Descriptions.Item label={`Pago N.º ${index + 1}`}>
                    <ul>
                      <li>Fecha: {moment(c?.date).format('DD/MM/YY')}</li>
                      <li>Practicas: {c?.quantity?.toLocaleString('es-AR')}</li>
                      <li>
                        Valor:{' '}
                        {c?.value?.toLocaleString('es-AR', {
                          style: 'currency',
                          currency: 'ARS',
                          minimumFractionDigits: 2,
                        })}
                      </li>
                      <li>{c?.description}</li>
                      <br></br>
                    </ul>
                  </Descriptions.Item>
                ))}
              </>
            )}
          </Descriptions>
        )
      )}
      {selectedSocialWorks && setSelectedSocialWorks && quantityDone > 0 && (
        <div style={{ textAlign: 'center' }}>
          <h4>
            Se facturaron {quantityDone?.toLocaleString('es-AR')} practicas de{' '}
            {selectedSocialWorks} en este periodo. Quedan{' '}
            {dataChart.length?.toLocaleString('es-AR')} practicas pendientes.
          </h4>
        </div>
      )}

      {exportData && (
        <Button
          type="link"
          onClick={() => exportData(dataChart)}
          disabled={dataChart?.length === 0}
        >
          <FileExcelOutlined /> Exportar
        </Button>
      )}

      <Table
        columns={columns}
        dataSource={dataChart}
        bordered
        scrollY={2}
        scroll={{ x: 150 }}
        locale={{ emptyText: 'No hay datos' }}
        loading={
          loading && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
        className="table"
        pagination={{
          pageSize: pageSize || 20,
          responsive: true,
          size: 'small',
        }}
        rowSelection={selection && rowSelection}
      />
    </div>
  );
}
