import React from 'react';
import { pdf, Document } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import DocumentPass from './DocumentPass';
import { PageIncome } from './PageIncome';
import { useSelector } from 'react-redux';

export default function PdfDownload(props) {
  const { income, title, signature, incomes } = props;
  const { organization, tenant, tenantId, tenantType } = useSelector(
    (store) => store.tenantData
  );

  return (
    <Button
      type="link"
      size="middle"
      onClick={async () => {
        message.loading('Generando documento. Por favor espere...', 0);
        const doc = income ? (
          <Document>
            <PageIncome
              income={income}
              title={title}
              signature={signature && signature}
              organization={organization}
              tenant={tenantType === 'personal' ? tenant : tenantId}
            />
          </Document>
        ) : (
          <DocumentPass incomes={incomes} />
        );
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, income ? `${title} ${income.name}` : 'Pase');
        message
          .success('El documento se ha generado con exito.', 1)
          .then(() => message.destroy());
      }}
    >
      Descargar PDF <DownloadOutlined />
    </Button>
  );
}
