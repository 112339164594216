import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleTable } from './styles';
import moment from 'moment';

export function PdfReportIncome({ income, index }) {
  return (
    <View wrap={false}>
      <View style={styleTable.row}>
        <Text style={styleTable.column8}>{index}</Text>
        <Text
          style={styleTable.column6}
          render={() =>
            income?.dischargeReason !== null
              ? `${moment(income.date).format('DD/MM HH:mm')} ${moment(
                  income.dischargeDate
                ).format('DD/MM HH:mm')}`
              : moment(income.date).format('DD/MM HH:mm')
          }
        />
        <Text style={styleTable.column6}>{income.bed?.name}</Text>
        <Text style={[styleTable.column5, { textTransform: 'capitalize' }]}>
          {income.patient?.name || income?.name}
        </Text>
        <Text style={styleTable.column6}>{income.socialWork}</Text>
        <Text
          style={styleTable.column6}
          render={() => income?.historyTypes?.map((i) => ` ${i}`)}
        />
      </View>
    </View>
  );
}
