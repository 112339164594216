import React, { useState, useEffect } from 'react';
import { Input, Statistic, Button } from 'antd';
import './Income.scss';
import IncomeTableInfarmary from '../../../components/Infarmary/IncomeTableInfarmary';
import { useDispatch, useSelector } from 'react-redux';
import { onIncomeActiveRead, onStatsBedsRead } from '../../../actions';
import { Link } from 'react-router-dom';

export default function IncomeInfarmary(props) {
  const { incomeType } = props.match.params;
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const { incomesActive } = useSelector((store) => store.incomeData);
  const { type } = useSelector((store) => store.userData?.user);
  let allBeds = useSelector((store) => store.bedData?.bedsStats);

  useEffect(() => {
    if (type === 'Medico') props.history.push(`/income/${incomeType}`);
    dispatch(onIncomeActiveRead(incomeType));
    dispatch(onStatsBedsRead());
  }, [incomeType]);

  if (incomeType === 'all') {
    allBeds = {
      _id: 'all',
      ocupate: allBeds?.reduce((p, a) => p + a.ocupate, 0),
      free: allBeds?.reduce((p, a) => p + a.free, 0),
      quantity: allBeds?.reduce((p, a) => p + a.quantity, 0),
    };
  } else {
    allBeds = allBeds?.find((b) => b._id === incomeType);
  }

  return (
    <div className="income-infarmary">
      <div className="income-infarmary-header">
        <Statistic
          value={allBeds?.ocupate}
          valueStyle={
            incomesActive &&
            (0.75 * allBeds?.quantity > allBeds?.ocupate
              ? { color: '#3f8600' }
              : 0.85 * allBeds?.quantity > allBeds?.ocupate
              ? { color: '#d6d100' }
              : { color: '#cf1322' })
          }
          prefix={
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.45)',
                fontSize: '0.8em',
                padding: 5,
              }}
            >
              Ocupación
            </span>
          }
          suffix={`/ ${allBeds?.quantity}`}
        />
        <Input.Search
          placeholder="Buscar pacientes internados.."
          onSearch={(value) => setSearch(value)}
          allowClear
          enterButton
        />
        {incomeType !== 'all' && (
          <Link to={`/new-income/${incomeType}`}>
            <Button type="primary">Nuevo Ingreso</Button>
          </Link>
        )}
      </div>
      <IncomeTableInfarmary
        search={search}
        incomesActive={incomesActive}
        incomeType={incomeType}
      />
    </div>
  );
}
