import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Select,
  Steps,
  Tooltip,
  TreeSelect,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { TableChart } from '../../components/Charts';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessTokenApi } from '../../api/auth';
import { getUsersTypeApi } from '../../api/user';
import { onSocialWorkRead } from '../../actions';
import {
  addBillingApi,
  getBilling,
  getNomenclatorBySocialWork,
  getTurnUserBillingDone,
  updateBillingApi,
} from '../../api/billing';
import BackButton from '../../components/BackButton';
import { getServicesApi } from '../../api/service';
import Spin from '../../components/Spin';

export default function Billing(props) {
  const { search } = props.location;
  const { Step } = Steps;
  const [billingForm] = Form.useForm();

  const [generate, setGenerate] = useState(false);
  const [turnUser, setTurnUser] = useState();
  const [turnType, setTurnType] = useState();

  const [users, setUsers] = useState([]);
  const [selectedSocialWorks, setSelectedSocialWorks] = useState();
  const [selectedStudieType, setSelectedStudieType] = useState();
  const [view, setView] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [practice, setPractice] = useState({ code: '', name: '', price: 0 });
  const [nomenclators, setNomenclators] = useState([]);
  const [billingDone, setBillingsDone] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { viewBillingOrganizations } = useSelector(
    (store) => store.userData?.user
  );

  const { viewStudies, role } = useSelector((store) => store.userData?.user);

  const { studies } = useSelector((store) => store.tenantData);

  const { socialWorks } = useSelector((store) => store.socialWorkData);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getUsersTypeApi(token, true, 'Medico').then((response) => {
        setUsers(response.users);
      });

      dispatch(onSocialWorkRead());

      getServicesApi(token).then((response) => {
        setServicesData(response?.services);
      });
    };
    getData();
  }, [turnUser]);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      let { nomenclator } = await socialWorks.find(
        (item) => item?.name === selectedSocialWorks
      );
      getNomenclatorBySocialWork({ token, nomenclator })
        .then((response) => {
          setNomenclators(response.nomenclators);
        })
        .catch(() => {});
    };
    selectedSocialWorks && getData();
  }, [selectedSocialWorks]);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();

      getTurnUserBillingDone({
        token,
        turnUser,
        turnType,
        organization: billingForm?.getFieldValue('organization'),
        periodStart: moment(billingForm?.getFieldValue('period')[0]).startOf(
          'day'
        ),
        periodEnd: billingForm?.getFieldValue('period')[1],
      })
        .then((response) => {
          setBillingsDone(response.billingDone);
        })
        .catch(() => {});
    };
    view == 1 && getData();
  }, [view]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let token = await getAccessTokenApi();
      await getBilling({ token, id: search.split('?')[1] }).then((response) => {
        setTurnUser(response.billingData.turnUser);
        setSelectedSocialWorks(response.billingData.socialWork);
        setSelectedStudieType(response.billingData.turnType);
        setTurnType(response.billingData.turnType);
        setSelectedRowKeys(response.billingData?.turns);
        billingForm?.setFieldsValue({
          period: [
            moment(response.billingData.period.start),
            moment(response.billingData.period.end),
          ],
          organization: response.billingData.organization,
          turn: response.billingData.turnUser,
        });
      });
      setView(1);
      setLoading(false);
    };

    search && getData();
  }, [search]);

  const onFinish = async (values) => {
    setLoading(true);
    billingForm.setFieldsValue({
      turns: selectedRowKeys,
      socialWork: selectedSocialWorks,
      turnUser: turnUser,
      turnType: turnType,
      useServices: selectedStudieType ? true : false,
      status: values?.status ? 'Pre - Liquidación' : 'Facturado',
      practice: practice,
      totalPrice: practice?.price * selectedRowKeys.length,
      period: [
        moment(billingForm?.getFieldValue('period')[0])
          .utcOffset(-180)
          .startOf('day'),
        moment(billingForm?.getFieldValue('period')[1])
          .utcOffset(-180)
          .startOf('day'),
      ],
    });

    console.log(billingForm.getFieldValue());
    let token = getAccessTokenApi();
    search !== ''
      ? await updateBillingApi({
          id: search.split('?')[1],
          token,
          data: billingForm.getFieldValue(),
        })
          .then(async (response) => {
            if (response?.code !== 200) {
              message.error(response.message);
            } else {
              message.success(response.message);
              billingForm.resetFields();
              window.history.back();
            }
          })
          .catch(() => {
            message.error('Error del servidor, intente mas tarde.');
          })
      : await addBillingApi({ token, data: billingForm.getFieldValue() })
          .then(async (response) => {
            if (response?.code !== 200) {
              message.error(response.message);
            } else {
              message.success(response.message);
              if (values.status) {
                billingForm.resetFields();
                window.history.back();
              } else {
                Modal.confirm({
                  title: `Periodo: ${moment(
                    billingForm?.getFieldValue('period')[0]
                  ).format('DD/MM/YY')}-${moment(
                    billingForm?.getFieldValue('period')[1]
                  ).format('DD/MM/YY')}`,
                  content: `¿ Desea continuar con la liquidación de la turnera ${turnType} y el prestador  ${billingForm?.getFieldValue(
                    'organization'
                  )} ?`,
                  okText: 'Continuar',
                  okType: 'primary',
                  cancelText: 'Finalizar',
                  async onOk() {
                    setSelectedSocialWorks();
                    setSelectedRowKeys([]);
                    setPractice({ code: '', name: '', price: 0 });
                    billingForm.setFieldsValue({
                      socialWork: '',
                      turnUser: turnUser,
                      turnType: turnType,
                      code: '',
                      totalPrice: 0,
                    });
                    setView(1);
                  },
                  async onCancel() {
                    billingForm.resetFields();
                    window.history.back();
                  },
                });
              }
            }
          })
          .catch(() => {
            message.error('Error del servidor, intente mas tarde.');
          });
    setLoading(false);
  };

  const treeData = [
    {
      value: 'med',
      title: 'Staff Profesional',
      selectable: false,
      children: users?.map((user, index) => {
        let disabled = user?.viewBillingOrganizations?.some(
          (bo) => bo === billingForm?.getFieldValue('organization')
        );
        let services = servicesData?.filter((service) => {
          if (user.scheduleConfig.service?.find((e) => e === service._id)) {
            return service;
          }
        });
        services?.length > 0 && services.push({ name: 'Sin servicio' });
        {
          return {
            value: user._id,
            title: `${user.name} ${user.lastname}`,
            disabled: !disabled,
            selectable: services.length > 0 ? false : true,
            children:
              disabled &&
              services?.map((service, i) => {
                return {
                  value: `${user._id}-${i}`,
                  title: service?.name,
                };
              }),
          };
        }
      }),
    },
  ];

  studies?.length > 0 &&
    treeData.push({
      value: 'est',
      title: 'Estudios',
      selectable: false,
      children: studies?.map((studie, index) => {
        let disabled =
          viewStudies?.find((e) => e === studie.id) ||
          role === 2 ||
          studie?.view;
        let services = servicesData?.filter((service) => {
          if (studie.scheduleConfig.service?.find((e) => e === service._id)) {
            return service;
          }
        });
        return {
          value: studie.id,
          title: studie.name,
          disabled: !disabled,
          selectable: services.length > 0 ? false : true,
          children:
            disabled &&
            services?.map((service, i) => {
              return {
                value: `${studie.id}-${i}`,
                title: service?.name,
              };
            }),
        };
      }),
    });

  let filters = (
    <div>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} md={8}>
          <Form.Item
            label="Periodo:"
            labelCol={{ span: 24 }}
            name="period"
            rules={[{ required: true, message: 'Debe elegir un periodo' }]}
          >
            <DatePicker.RangePicker
              placeholder={['Fecha inicio ', 'Fecha fin ']}
              style={{ width: '100%' }}
              format="DD-MM-YYYY"
              onChange={(value) => {
                if (!moment(value[0]).isSame(value[1], 'M')) {
                  message.error(
                    'El rango de fechas debe estar en el mismo mes'
                  );
                  billingForm.setFieldsValue({ period: [moment(value[0])] });
                }

                setView(-1);
              }}
              disabledDate={(current) =>
                current && current > moment().endOf('day')
              }
              locale={locale}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Form.Item
            label="Prestador:"
            labelCol={{ span: 24 }}
            name="organization"
            rules={[{ required: true, message: 'Debe elegir un prestador' }]}
          >
            <Select
              placeholder="Seleccione un prestador"
              showSearch
              optionFilterProp="children"
              style={{ width: '100%' }}
              onChange={() => setView(-2)}
              notFoundContent="No tiene prestadores cargados"
            >
              {viewBillingOrganizations?.map((org, index) => (
                <Select.Option key={index} value={org}>
                  {org}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            label="Medico / Estudio:"
            labelCol={{ span: 24 }}
            name="turn"
            rules={[{ required: true, message: 'Debe ingresar la consulta' }]}
          >
            <TreeSelect
              style={{ width: '100%' }}
              placeholder="Busque por medico o especialidad"
              onChange={(value, options) => {
                setTurnType(options[0]);
                setTurnUser(value.includes('-') ? value.split('-')[0] : value);
                value.includes('-')
                  ? setSelectedStudieType(options[0])
                  : setSelectedStudieType();
              }}
              disabled={!billingForm?.getFieldValue('organization')}
              treeData={treeData}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item>
            <Button
              type="default"
              style={{ width: '100%' }}
              onClick={() => {
                setGenerate(!generate);
                setView(1);
              }}
              disabled={!billingForm.getFieldValue('period') || !turnUser}
            >
              Generar Informe
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );

  let billingData = (
    <Row gutter={[24, 24]}>
      <Col xs={24}>
        <Form.Item
          label="Codigo practica:"
          labelCol={{ span: 24 }}
          name="code"
          rules={[
            {
              required: true,
              message: 'Debe elegir un codigo segun nomenclador',
            },
          ]}
        >
          <Select
            placeholder="Seleccione un codigo"
            showSearch
            optionFilterProp="children"
            style={{ width: '100%' }}
            onChange={(value, options) => {
              setPractice({
                code: value,
                name: options?.children[2],
                price: parseFloat(options.key.split('-')[1]),
              });
            }}
            notFoundContent="No tiene ningun nomenclador asociado"
          >
            {nomenclators?.map((item, index) => (
              <Select.Option key={`${index}-${item.price}`} value={item.code}>
                {item.code} - {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={8}>
        <Form.Item label="Cantidad de practicas" name="quantity">
          <div style={{ fontWeight: 'bold' }}>
            {selectedRowKeys?.length.toLocaleString('es-AR')}
          </div>
        </Form.Item>
      </Col>
      <Col xs={8}>
        <Form.Item label="Precio practica:" name="price">
          <div style={{ fontWeight: 'bold' }}>
            {practice.price.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 2,
            })}
          </div>
        </Form.Item>
      </Col>
      <Col xs={8}>
        <Form.Item label="Precio total:" name="totalPrice">
          <div style={{ fontWeight: 'bold' }}>
            {(practice.price * selectedRowKeys.length).toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 2,
            })}
          </div>
        </Form.Item>
      </Col>

      <Col xs={24}>
        <Form.Item>
          <Button
            type="default"
            htmlType="submit"
            style={{ width: '100%' }}
            onClick={() => {
              setView(2);
            }}
          >
            Guardar
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );

  let reportData = (
    <Row gutter={[24, 24]}>
      <Col xs={24}>
        <TableChart
          start={
            billingForm?.getFieldValue('period') &&
            billingForm?.getFieldValue('period')[0]
          }
          end={
            billingForm?.getFieldValue('period') &&
            billingForm?.getFieldValue('period')[1]
          }
          organization={billingForm?.getFieldValue('organization')}
          type="reportTurns"
          userId={turnUser}
          generate={generate}
          status="Atendido"
          socialWorks={selectedSocialWorks}
          studieType={selectedStudieType}
          selection={true}
          userName={turnType}
          setView={setView}
          format={search ? true : false}
          sendColumns={[
            {
              title: 'Fecha',
              dataIndex: 'hourStart',
              key: 'hourStart',
              align: 'center',
              render: (hourStart) => (
                <span>{moment(hourStart).format('DD/MM/YY HH:mm')}</span>
              ),
              sorter: (a, b) =>
                moment(a.hourStart).unix() - moment(b.hourStart).unix(),
            },
            {
              title: 'Paciente',
              dataIndex: 'patientName',
              key: 'patientName',
              render: (patientName) => (
                <span style={{ textTransform: 'capitalize' }}>
                  {patientName}
                </span>
              ),
            },
            {
              title: 'Obra Social',
              dataIndex: 'socialWork',
              key: 'socialWork',
              sorter: (a, b) => a.socialWork?.localeCompare(b.socialWork),
            },
            {
              title: 'Nro de Afiliado',
              dataIndex: 'affiliate',
              key: 'affiliate',
              sorter: (a, b) => a?.affiliate?.localeCompare(b?.affiliate),
            },
            {
              title: 'Estudio',
              dataIndex: 'studies',
              key: 'studies',
              render: (studies) => <span>{studies?.name}</span>,
              sorter: (a, b) => a.studies?.name.localeCompare(b.studies?.name),
            },
            {
              title: 'Solicitante',
              dataIndex: 'medicoSolicitante',
              key: 'medicoSolicitante',
              sorter: (a, b) =>
                a.medicoSolicitante?.localeCompare(b.medicoSolicitante),
            },
            {
              title: 'Efector',
              dataIndex: 'medicoEfector',
              key: 'medicoEfector',
              render: (text, record, index) =>
                record.medicoEfector
                  ? record.medicoEfector
                  : record.medicoTurnera,
              sorter: (a, b) => a.medicoEfector?.localeCompare(b.medicoEfector),
            },
            {
              title: 'Orden médica',
              dataIndex: 'medicalOrder',
              key: 'medicalOrder',
              width: '5%',
              align: 'center',
              render: (medicalOrder) =>
                medicalOrder ? (
                  <Tooltip title="Presento orden médica">
                    <CheckCircleOutlined style={{ color: 'green' }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="No presento orden médica">
                    <CloseCircleOutlined style={{ color: 'red' }} />
                  </Tooltip>
                ),
            },
            {
              title: 'Destacado',
              dataIndex: 'important',
              key: 'important',
              align: 'center',
              width: '5%',
              render: (important) => (
                <span>{important && <CheckOutlined />}</span>
              ),
            },
          ]}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedSocialWorks={selectedSocialWorks}
          setSelectedSocialWorks={!search && setSelectedSocialWorks}
          billingDone={billingDone}
        />
      </Col>
      <Col xs={12}>
        <Form.Item name="status">
          <Button
            type="default"
            htmlType="submit"
            style={{ width: '100%' }}
            onClick={() => {
              billingForm.setFieldsValue({ status: true });
            }}
            disabled={selectedRowKeys?.length === 0 || !selectedSocialWorks}
          >
            Guardar Pre - Liquidación
          </Button>
        </Form.Item>
      </Col>
      <Col xs={12}>
        <Form.Item>
          <Button
            type="default"
            style={{ width: '100%' }}
            onClick={() => {
              setView(2);
            }}
            disabled={selectedRowKeys?.length === 0 || !selectedSocialWorks}
          >
            Siguiente
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );

  if (loading) return <Spin />;

  return (
    <div>
      <Row>
        <BackButton /> <h1>Nuevo lote de liquidación</h1>
      </Row>
      <Form form={billingForm} onFinish={onFinish}>
        <Steps
          type="navigation"
          current={view}
          size="small"
          onChange={(step) => setView(step)}
          style={{ marginBottom: '5px' }}
        >
          <Step
            title="Generar reporte"
            disabled={search}
            status={view > 0 ? 'finish' : view <= 0 ? 'process' : 'wait'}
          />
          <Step
            title="Elegir"
            status={view > 1 ? 'finish' : view == 1 ? 'process' : 'wait'}
            disabled={view <= 0}
          />
          <Step
            title="Facturación"
            status={view > 2 ? 'finish' : view == 2 ? 'process' : 'wait'}
            disabled={view <= 1}
          />
        </Steps>

        {view <= 0 && filters}

        {view == 1 && reportData}

        {view == 2 && billingData}
      </Form>
    </div>
  );
}
