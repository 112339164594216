import { basePath, apiVersion } from './config';

export async function getPharmacyStockApi({ token, store, id, expire, extra }) {
  const url = `${basePath}/${apiVersion}/pharmacy-stock?store=${store}&id=${id}&expire=${expire}&extra=${extra}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getPharmacyMovementsApi({
  token,
  store,
  skip,
  limit,
  type,
}) {
  const url = `${basePath}/${apiVersion}/pharmacy-movements?store=${store}&skip=${skip}&limit=${limit}&type=${type}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function addPharmacyApi({ pharmacyData, token }) {
  const url = `${basePath}/${apiVersion}/pharmacy`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(pharmacyData),
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function addPaidPharmacyApi({ data, token, id }) {
  const url = `${basePath}/${apiVersion}/add-paid-pharmacy/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function getPharmacyFilterApi({
  token,
  search,
  type,
  provider,
  store,
  dateStart,
  dateEnd,
}) {
  const url = `${basePath}/${apiVersion}/pharmacy-filter-movements?type=${type}&search=${search}&provider=${provider}&store=${store}&dateStart=${dateStart}&dateEnd=${dateEnd}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function deletePharmacySendApi({ id, token }) {
  const url = `${basePath}/${apiVersion}/pharmacy-send/${id}`;

  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}
