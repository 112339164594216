import {
  ON_PATIENT_READ,
  ON_PATIENT_READING,
  ON_PATIENT_VALUE_CHANGE,
  ON_FAVOURITE_PATIENT_READ,
} from '../actions/types';

const INITIAL_STATE = {
  patients: [],
  searchPatient: '',
  searchFavourite: false,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_PATIENT_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_PATIENT_READING:
      return { ...state, loading: true };
    case ON_PATIENT_READ:
      return {
        ...state,
        patients: action.payload.patients,
        searchPatient: action.payload.searchPatient,
        loading: false,
      };
    case ON_FAVOURITE_PATIENT_READ:
      return {
        ...state,
        patients: action.payload.patients,
        loading: false,
      };
    default:
      return state;
  }
};
