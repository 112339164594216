import React, { useState, useEffect } from 'react';
import {
  Button,
  Tooltip,
  PageHeader,
  notification,
  Modal as ModalAntd,
  Row,
  DatePicker,
} from 'antd';
import { Link } from 'react-router-dom';
import PermissionsAsigner from '../../components/PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import {
  PlusSquareOutlined,
  AuditOutlined,
  DeleteOutlined,
  EditOutlined,
  RetweetOutlined,
  ControlOutlined,
  PullRequestOutlined,
  DiffOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getAccessTokenApi } from '../../api/auth';
import { deleteMedicinenApi, getMedicineApi } from '../../api/medicine';
import {
  ListDates,
  ViewMedicine,
  PageListAndView,
} from '../../components/ListAndDetail';
import '../Evolution/Evolution.scss';
import locale from 'antd/es/date-picker/locale/es_ES';

export default function Medicine(props) {
  const { id } = props.match.params;
  const { path } = props.match;
  const [data, setData] = useState(null);
  const [dataAll, setDataAll] = useState(null);
  const [medicines, setMedicines] = useState();
  const [reload, setReload] = useState(false);
  const [order, setOrder] = useState('desc');
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(moment().utcOffset(-180).startOf('day'));

  const { incomesActive, incomesHistory } = useSelector(
    (store) => store.incomeData
  );
  const { type } = useSelector((store) => store.userData?.user);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const token = await getAccessTokenApi();
      await getMedicineApi({
        id,
        order,
        token,
        month: income.bed?.bedType === 'Dialisis' ? month : undefined,
      })
        .then((response) => {
          setMedicines(response.medicines);
          response?.medicines?.length > 0
            ? setData({
                medicineId: response.medicines[0]._id,
                indication: response.medicines[0].indication,
                infusion: response.medicines[0].infusion,
                action: response.medicines[0].action,
                user: response.medicines[0].user,
                date: response.medicines[0].date,
              })
            : setData();
          if (income.bed?.bedType !== 'Dialisis') {
            order === 'desc'
              ? setDisable(
                  moment()
                    .startOf('day')
                    .isSame(
                      moment(response.medicines[0].date).startOf('day'),
                      'day'
                    )
                )
              : setDisable(
                  moment()
                    .startOf('day')
                    .isSame(
                      moment(
                        response.medicines[response.medicines.length - 1].date
                      ).startOf('day'),
                      'day'
                    )
                );
          }
        })
        .catch((err) => {});
      setLoading(false);
    };
    getData();

    setReload(false);
  }, [reload, order, month]);

  const viewMedicine = (medicine) => {
    setData({
      medicineId: medicine._id,
      indication: medicine.indication,
      infusion: medicine.infusion,
      action: medicine.action,
      user: medicine.user,
      date: medicine.date,
    });
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  var income = {};
  if (path === '/medicine/:id') {
    incomesActive.find((i) => {
      if (i._id === id) {
        income = i;
      }
    });
  } else {
    incomesHistory.find((i) => {
      if (i._id === id) {
        income = i;
      }
    });
  }

  const viewAll = () => {
    const data = [];

    medicines &&
      medicines.map((medicine) =>
        data.push({
          indication: medicine.indication,
          infusion: medicine.infusion,
          action: medicine.action,
          user: medicine.user,
          date: medicine.date,
        })
      );
    setData(null);
    setDataAll(data);
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  return (
    <div className="evolution">
      <div className="evolution-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`${income?.bed?.name}`}
          subTitle={
            <Tooltip
              title={`Cama: ${income?.bed?.name} ${income?.name} - ${
                income.age
              } años | Fecha Ingreso: ${moment(income?.date).format(
                'DD/MM/YYYY'
              )}`}
            >
              {income?.name} - {income?.age} años | Fecha Ingreso:{' '}
              {moment(income.date).format('DD/MM/YYYY')}
            </Tooltip>
          }
          extra={
            path === '/medicine/:id'
              ? [
                  income.bed?.bedType === 'Dialisis' && (
                    <DatePicker
                      picker="month"
                      locale={locale}
                      placeholder="Fecha"
                      allowClear={false}
                      onChange={(value) => {
                        setMonth(moment(value));
                      }}
                      disabledDate={(current) =>
                        current && current > moment().endOf('day')
                      }
                      value={month}
                    />
                  ),
                  type === 'Enfermero' ? (
                    <>
                      <Tooltip title="Controles">
                        <Link
                          to={
                            income.bed?.bedType !== 'Dialisis'
                              ? `/controls/${income._id}`
                              : `/dialisis-controls/${income._id}`
                          }
                        >
                          <Button type="link">
                            <ControlOutlined />
                          </Button>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Notas">
                        <Link to={`/notes/${income._id}`}>
                          <Button type="link">
                            <EditOutlined />
                          </Button>
                        </Link>
                      </Tooltip>
                      {income.bed?.bedType === 'Dialisis' ? (
                        <Tooltip title="Insumos">
                          <Link to={`/insumos/${income._id}`}>
                            <Button type="link">
                              <PullRequestOutlined />
                            </Button>
                          </Link>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Balances">
                          <Link to={`/balances/${income._id}`}>
                            <Button type="link">
                              <DiffOutlined />
                            </Button>
                          </Link>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <Tooltip title="Enfermeria">
                      <Link
                        to={
                          props.location.hash === '#Dialisis'
                            ? `/dialisis-controls/${income._id}/view`
                            : `/view-infarmary/${income?._id}`
                        }
                      >
                        <Button
                          type="link"
                          style={{
                            color: '#8e0d1a',
                          }}
                        >
                          <PlusSquareOutlined />
                        </Button>
                      </Link>
                    </Tooltip>
                  ),
                  type !== 'Enfermero' && (
                    <Tooltip title="Evoluciones">
                      <Link
                        to={{
                          pathname: `/evolution/${income?._id}`,
                          hash: props.location.hash,
                        }}
                      >
                        <Button type="link">
                          <AuditOutlined />
                        </Button>
                      </Link>
                    </Tooltip>
                  ),
                  <PermissionsAsigner
                    requiredRole={ROLES.ADMIN}
                    visible={ROLES.EMPLOYEE}
                    type={income.bed?.bedType === 'Dialisis' && 'Enfermero'}
                  >
                    <Link
                      to={{
                        pathname: `/new-medicine/${income?._id}/${income?.type}`,
                        search: `${income.bed?.bedType}`,
                        name: `${income.name}`,
                      }}
                    >
                      <Button disabled={disable || loading} type="primary">
                        Nueva Dosis
                      </Button>
                    </Link>
                  </PermissionsAsigner>,
                ]
              : [
                  <PermissionsAsigner requiredRole={ROLES.SECRETARY}>
                    <Link
                      to={{
                        pathname: `/new-medicine/${income?._id}/${income?.type}`,
                        income: income,
                        search: `${income.bed?.bedType}`,
                        hash: 'alta',
                      }}
                    >
                      <Button type="primary">Nueva Dosis</Button>
                    </Link>
                  </PermissionsAsigner>,
                ]
          }
        />
      </div>

      <PageListAndView
        datesTitle="Medicaciones"
        order={order}
        setOrder={setOrder}
        viewAll={viewAll}
        componentDate={<ListDates data={medicines} viewData={viewMedicine} />}
        detailTitle="Detalle medicación"
        data={data}
        dataAll={
          dataAll && (
            <div className="view-info">
              <>
                <div className="view-info">
                  <h3 style={{ textAlign: 'center' }}>Indicaciones Medicas</h3>
                  {dataAll.map((data) => (
                    <ViewMedicine data={data} />
                  ))}
                </div>
              </>
            </div>
          )
        }
        componentDetail={
          <div className="view-info">
            <h3>Indicaciones Medicas</h3>
            <ViewMedicine data={data} setReload={setReload} />
          </div>
        }
        extra={
          <Row>
            <PermissionsAsigner
              requiredRole={ROLES.OWNER}
              visible={path === '/medicine-history/:id' && ROLES.SECRETARY}
            >
              <DeleteMedicine data={data} setReload={setReload} />
            </PermissionsAsigner>
            <PermissionsAsigner
              requiredRole={
                path === '/medicine/:id' ? ROLES.ADMIN : ROLES.SECRETARY
              }
              visible={ROLES.EMPLOYEE}
              type={income.bed?.bedType === 'Dialisis' && 'Enfermero'}
            >
              <>
                {(moment()
                  .startOf('day')
                  .isSame(moment(data && data.date).startOf('day'), 'day') ||
                  income?.bed?.bedType === 'Dialisis') && (
                  <Link
                    to={
                      data && {
                        pathname: `/update-medicine/${id}/${data.medicineId}`,
                        search: `${income.bed?.bedType}`,
                        hash: 'edit',
                      }
                    }
                  >
                    <Tooltip title="Editar">
                      <Button type="link">
                        <EditOutlined />
                      </Button>
                    </Tooltip>
                  </Link>
                )}
                {!disable && (
                  <Link
                    to={
                      data && income.dischargeDate
                        ? {
                            pathname: `/new-medicine/${id}/${income.type}/${data.medicineId}`,
                            income: income,
                            search: `${income.bed?.bedType}`,
                            hash: 'alta',
                          }
                        : data && {
                            pathname: `/new-medicine/${id}/${income.type}/${data.medicineId}`,
                            search: `${income.bed?.bedType}`,
                            name: `${income.name}`,
                          }
                    }
                  >
                    <Tooltip title="Repetir Indicacion">
                      <Button type="link">
                        <RetweetOutlined />
                      </Button>
                    </Tooltip>
                  </Link>
                )}
              </>
            </PermissionsAsigner>
          </Row>
        }
      />
    </div>
  );
}

function DeleteMedicine(props) {
  const { data, setReload } = props;

  const deleteMedicine = () => {
    ModalAntd.confirm({
      title: 'Eliminar evolución',
      content: `¿ Estas seguro que quieres eliminar la evolución del ${moment(
        data.date
      ).format('DD-MM HH:mm')}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        const token = await getAccessTokenApi();
        deleteMedicinenApi(data.medicineId, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setReload(true);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  return (
    <Tooltip title="Eliminar">
      <Button type="link" onClick={() => deleteMedicine()} disabled={!data}>
        <DeleteOutlined style={data && { color: 'red' }} />
      </Button>
    </Tooltip>
  );
}
