import React, { useState, useEffect } from 'react';
import {
  Switch,
  List,
  Avatar,
  Button,
  Input,
  notification,
  Modal as ModalAntd,
  Tooltip,
} from 'antd';
import NoAvatar from '../../../assets/img/no-avatar.png';
import Modal from '../../Modal';
import EditUserForm from '../EditUserForm';
import {
  getSignatureApi,
  activateUserApi,
  deleteUserApi,
} from '../../../api/user';
import { getAccessTokenApi } from '../../../api/auth';
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import './ListUsers.scss';
import { ROLES } from '../../../utils/constants';
import RegisterForm from '../../RegisterForm/RegisterForm';

const { confirm } = ModalAntd;

export default function ListUsers(props) {
  const { usersActive, usersInactive, setReloadUsers } = props;
  const [viewUsersActive, setViewUsersActive] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [search, setSearch] = useState('');
  const { type, modulesAccess, role } = useSelector(
    (store) => store.userData?.user
  );

  if (search) {
    var userFilters = usersActive.filter(
      (user) =>
        user.name.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
        user.lastname
          .toLowerCase()
          .trim()
          .includes(search.toLowerCase().trim()) ||
        user.type.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  }

  const createNewUser = () => {
    setIsVisibleModal(true);
    setModalTitle('Nuevo Usuario');
    setModalContent(
      <RegisterForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
        schedulesUsers={
          (usersActive?.filter((user) => user.type === 'Medico')?.length || 0) +
          (usersInactive?.filter((user) => user.type === 'Medico')?.length || 0)
        }
      />
    );
  };

  return (
    <div className="list-users">
      <div className="list-users__header">
        <div className="list-users__header-switch">
          <Switch
            defaultChecked
            onChange={() => setViewUsersActive(!viewUsersActive)}
          />
          <span>
            {viewUsersActive
              ? `Usuarios Activos (${usersActive && usersActive.length})`
              : 'Usuarios Inactivos'}
          </span>
        </div>
        {viewUsersActive && (
          <>
            <div className="list-users__header-search">
              <Input.Search
                placeholder="Buscar usurarios activos.."
                enterButton
                onSearch={(value) => setSearch(value)}
                onChange={(e) =>
                  (e.target.value.length === 0 || e.target.value.length >= 3) &&
                  setSearch(e.target.value)
                }
                allowClear
              />
            </div>
            <Button
              type="default"
              onClick={() => createNewUser()}
              disabled={!modulesAccess?.includes('Usuarios') && role !== 2}
            >
              Nuevo usuario
            </Button>
          </>
        )}
      </div>

      {viewUsersActive ? (
        <UsersActive
          usersActive={search ? userFilters : usersActive}
          setIsVisibleModal={setIsVisibleModal}
          setModalTitle={setModalTitle}
          setModalContent={setModalContent}
          setReloadUsers={setReloadUsers}
          type={type}
        />
      ) : (
        <UsersInactive
          usersInactive={usersInactive}
          setReloadUsers={setReloadUsers}
        />
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'75%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function UsersActive(props) {
  const {
    usersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadUsers,
    type,
  } = props;

  const editUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${user.name} ${user.lastname}`);

    setModalContent(
      <EditUserForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
  };

  return (
    <List
      className="users"
      itemLayout="horizontal"
      dataSource={usersActive}
      renderItem={(user) => (
        <UserActive
          user={user}
          editUser={editUser}
          setReloadUsers={setReloadUsers}
          type={type}
        />
      )}
    />
  );
}

function UserActive(props) {
  const { user, editUser, setReloadUsers, type } = props;
  const [signature, setSignature] = useState(null);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      if (user.signature) {
        getSignatureApi(user.signature, token).then((response) => {
          setSignature(response.url);
        });
      } else {
        setSignature(null);
      }
    };
    getData();
  }, [user]);

  const desactivateUser = async () => {
    const token = await getAccessTokenApi();

    activateUserApi(token, user._id, false)
      .then((result) => {
        if (result?.code === 200) {
          notification['success']({ message: result.message });
          setReloadUsers(true);
        } else {
          notification['error']({ message: result.message });
        }
      })
      .catch((err) => {
        notification['error']({ message: err });
      });
  };

  let rolName = '';
  Object.values(ROLES).find((rol) => {
    if (rol.value === user.role) {
      rolName = rol.name;
    }
  });

  return (
    <List.Item
      actions={[
        <Tooltip title="Editar">
          <Button
            type="link"
            onClick={() => editUser(user)}
            disabled={user.role === 2 && type === 'Enfermero'}
          >
            <EditOutlined />
          </Button>
        </Tooltip>,
        <Tooltip title="Desactivar">
          <Button
            type="link"
            onClick={desactivateUser}
            disabled={user.role === 2 && type === 'Enfermero'}
          >
            <StopOutlined style={{ color: 'red' }} />
          </Button>
        </Tooltip>,
        <Tooltip title="Eliminar">
          <Button
            type="link"
            onClick={() => showDeleteConfirm(user, setReloadUsers)}
            disabled={user.role === 2 && type === 'Enfermero'}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Button>
        </Tooltip>,
      ]}
    >
      <List.Item.Meta
        avatar={
          <Avatar src={signature ? signature : NoAvatar} shape="square" />
        }
        title={`${user.name} 
            ${user.lastname}
           `}
        description={
          <div>
            <p>
              {user.email} | Permisos: {rolName}
              {user.infarmaryType ? `en ${user.infarmaryType}` : ''}
            </p>
            <p>
              {user.type}
              {user?.medicalSpeciality &&
                ` - Especialidad: ${user?.medicalSpeciality}`}
            </p>
          </div>
        }
      />
    </List.Item>
  );
}

function UsersInactive(props) {
  const { usersInactive, setReloadUsers } = props;
  return (
    <List
      className="users"
      itemLayout="horizontal"
      dataSource={usersInactive}
      locale={{ emptyText: 'No hay usuarios inactivos' }}
      renderItem={(user) => (
        <UserInactive user={user} setReloadUsers={setReloadUsers} />
      )}
    />
  );
}

function UserInactive(props) {
  const { user, setReloadUsers } = props;

  const activateUser = async () => {
    const token = await getAccessTokenApi();

    activateUserApi(token, user._id, true)
      .then((result) => {
        if (result?.code === 200) {
          notification['success']({ message: result.message });
          setReloadUsers(true);
        } else {
          notification['error']({ message: result.message });
        }
      })
      .catch((err) => {
        notification['error']({ message: err });
      });
  };

  let rolName = '';
  Object.values(ROLES).find((rol) => {
    if (rol.value === user.role && rol.roleId === user.type) {
      rolName = rol.name;
    }
  });

  return (
    <List.Item
      actions={[
        <Button type="link" onClick={activateUser}>
          <CheckOutlined style={{ color: '#5cb85c' }} />
        </Button>,
        <Button
          type="link"
          onClick={() => showDeleteConfirm(user, setReloadUsers)}
        >
          <DeleteOutlined style={{ color: 'red' }} />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={`${user.name && user.name} 
            ${user.lastname && user.lastname} | ${user.type}
            `}
        description={`${user.email} | Permisos: ${rolName}`}
      />
    </List.Item>
  );
}

async function showDeleteConfirm(user, setReloadUsers) {
  const token = await getAccessTokenApi();

  confirm({
    title: 'Eliminando usuario',
    content: `Estas seguro que quieres eliminar a ${user.email}?`,
    okText: 'Eliminar',
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk() {
      deleteUserApi(token, user._id)
        .then((result) => {
          if (result?.code === 200) {
            notification['success']({ message: result.message });
            setReloadUsers(true);
          } else {
            notification['error']({ message: result.message });
          }
        })
        .catch((err) => {
          notification['error']({ message: err });
        });
    },
  });
}
