import React, { useEffect, useState } from 'react';
import { getIncomeApi } from '../../api/income';
import { notification, Row, Anchor, Divider } from 'antd';
import Spin from '../Spin';
import { getAccessTokenApi } from '../../api/auth';
import { getNoteApi } from '../../api/note';
import { getEvolutionApi } from '../../api/evolution';
import { getMedicineApi } from '../../api/medicine';
import ControlPdf from '../Infarmary/ControlPdf';
import IncomeData from '../IncomeView/IncomeData';
import IncomeEpicrisis from '../IncomeView/IncomeEpicrisis';
import { HashLink as Link } from 'react-router-hash-link';
import './IncomeReport.scss';
import { ViewInfo, ViewMedicine, ViewOperation } from '../ListAndDetail';
import PdfHistoryDownload from '../PdfReports/PdfHistoryDownload';
import BackButton from '../BackButton';
import { getOperationApi } from '../../api/operation';
import DyalisisTableControls from '../Infarmary/DyalisisTableControls';
import { getDyalisisControlApi } from '../../api/control';

export default function IncomeView(props) {
  const { id } = props.match.params;
  const [income, setIncome] = useState();
  const [dataEvolutions, setDataEvolutions] = useState();
  const [dataNotes, setDataNotes] = useState();
  const [medicines, setMedicines] = useState();
  const [operations, setOperations] = useState();
  const [dyalisisControls, setDyalisisControls] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const token = await getAccessTokenApi();
      await getIncomeApi(id, token)
        .then((response) => {
          setIncome(response.income);
        })
        .catch((err) => {
          notification['warning']({
            message: 'Error del servidor, vuelva al historial.',
          });
        });

      await getEvolutionApi(id, 'asc', token)
        .then((response) => {
          setDataEvolutions(response.evolutions);
        })
        .catch((err) => {});

      await getMedicineApi({ id, order: 'asc', token })
        .then((response) => {
          setMedicines(response.medicines);
        })
        .catch((err) => {});

      await getOperationApi(id, 'asc', token)
        .then((response) => {
          setOperations(response.operations);
        })
        .catch((err) => {});

      await getNoteApi(id, 'asc', token)
        .then((response) => {
          setDataNotes(response.notes);
        })
        .catch((err) => {});

      props.location.search === '?Dialisis' &&
        (await getDyalisisControlApi(id, token)
          .then((response) => {
            setDyalisisControls(response?.controls);
          })
          .catch((err) => {}));
      setLoading(false);
    };

    getData();
  }, [id]);

  if (loading) {
    return <Spin />;
  }

  return (
    <Row gutter={24} className="scroll">
      <div className="header">
        <BackButton back={income.dischargeDate ? true : income.type} />
        <PdfHistoryDownload id={id} type={props.location.search} />
        <Anchor
          offsetTop={80}
          affix={window.screen.width < 1135 ? false : true}
          className="anchor"
        >
          <Link
            to={{
              pathname: `/report/${id}`,
              hash: 'ingreso',
              search: `${props.location?.search}`,
            }}
          >
            <Anchor.Link href="#ingreso" title="Ingreso" />
          </Link>
          <Link
            to={{
              pathname: `/report/${id}`,
              hash: 'evoluciones',
              search: `${props.location?.search}`,
            }}
          >
            <Anchor.Link href="#evoluciones" title={'Evoluciones'} />
          </Link>
          <Link
            to={{
              pathname: `/report/${id}`,
              hash: 'indicaciones',
              search: `${props.location?.search}`,
            }}
          >
            <Anchor.Link href="#indicaciones" title={'Indicaciones'} />
          </Link>
          <Link
            to={{
              pathname: `/report/${id}`,
              hash: 'notas',
              search: `${props.location?.search}`,
            }}
          >
            <Anchor.Link href={`#notas`} title="Notas" />
          </Link>
          <Link
            to={{
              pathname: `/report/${id}`,
              hash: 'controles',
              search: `${props.location?.search}`,
            }}
          >
            <Anchor.Link href="#controles" title="Controles" />
          </Link>
          <Link
            to={{
              pathname: `/report/${id}`,
              hash: 'operaciones',
              search: `${props.location?.search}`,
            }}
          >
            <Anchor.Link href="#operaciones" title="Operaciones" />
          </Link>
          <Link
            to={{
              pathname: `/report/${id}`,
              hash: 'epicrisis',
              search: `${props.location?.search}`,
            }}
          >
            <Anchor.Link href="#epicrisis" title="Epicrisis" />
          </Link>
          {props.location.search !== '?Dialisis' && (
            <Link to={`/balances/${id}/${income.name}`}>
              <Anchor.Link title="Balances" />
            </Link>
          )}
          <Link to={`/pdf-report/${id}/${income.name}`}>
            <Anchor.Link title="Informes" />
          </Link>
        </Anchor>
        {!income.dischargeDate && (
          <h6 style={{ fontSize: '0.8em', paddingTop: 10 }}>
            Cama: {income?.bed?.name}
          </h6>
        )}
        <h6 style={{ fontSize: '0.8em', paddingTop: 5 }}>
          Teléfono de contacto: {income?.contactPhone}
        </h6>
      </div>

      <table border="1px" bgcolor="#fff" className="income-view-table">
        <div className="income-view">
          <div id="ingreso" />
          <IncomeData income={income} path={'report'} />
          <Divider className="divider" />
          {dataEvolutions?.length !== 0 && (
            <>
              <h4
                style={{ textAlign: 'center', fontWeight: 'bold' }}
                id="evoluciones"
              >
                Evoluciones
              </h4>
              <div className="view-info">
                {dataEvolutions?.map((data, index) => (
                  <ViewInfo data={data} income={income} key={index} />
                ))}
              </div>
              <Divider className="divider" />
            </>
          )}
          {medicines?.length !== 0 && (
            <>
              <h4
                style={{ textAlign: 'center', fontWeight: 'bold' }}
                id="indicaciones"
              >
                Indicaciones Medicas
              </h4>
              <div className="view-info">
                {medicines?.map((data, index) => (
                  <ViewMedicine data={data} report={true} key={index} />
                ))}
              </div>
              <Divider className="divider" />
            </>
          )}
          {dataNotes?.length !== 0 && (
            <>
              <h4
                style={{ textAlign: 'center', fontWeight: 'bold' }}
                id="notas"
              >
                Notas de Enfermería
              </h4>
              <div className="view-info">
                {dataNotes?.map((data, index) => (
                  <ViewInfo key={index} data={data} />
                ))}
              </div>
              <Divider className="divider" />
            </>
          )}
          <div id="controles" />
          {props.location.search !== '?Dialisis' ? (
            <ControlPdf id={id} />
          ) : (
            <div>
              <h4
                style={{ textAlign: 'center', fontWeight: 'bold' }}
                id="indicaciones"
              >
                Controles Diálisis
              </h4>
              <div className="view-info">
                <DyalisisTableControls data={dyalisisControls} />
              </div>
            </div>
          )}
          <Divider className="divider" />
          {operations?.length !== 0 && (
            <>
              <h4
                style={{ textAlign: 'center', fontWeight: 'bold' }}
                id="operaciones"
              >
                Parte de Quirofano
              </h4>

              {operations?.map((data, index) => (
                <>
                  <ViewOperation data={data} income={income} />
                  <Divider className="divider" />
                </>
              ))}
            </>
          )}
          {income.epicrisis && (
            <>
              <div id="epicrisis" />
              <IncomeData income={income} path={'epicrisis'} />
              <IncomeEpicrisis income={income} />
              <Divider className="divider" />
            </>
          )}
        </div>
      </table>
    </Row>
  );
}
