import React, { useState } from 'react';
import { Row, DatePicker, Col, Button, Statistic, notification } from 'antd';
import moment from 'moment';

export function BalanceCalculate(props) {
  const { balances, date } = props;
  const [start, setStart] = useState(
    moment().subtract(1, 'days').hour(7).minute(0).second(0)
  );
  const [end, setEnd] = useState(moment().hour(7).minute(0).second(0));
  const [result, setResult] = useState();
  const [resultAcumulate, setResultAcumulate] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleAcumulate, setVisibleAcumulate] = useState(false);

  const calculate = () => {
    if (!start || !end) {
      notification['warning']({ message: 'Debe elegir un periodo' });
      return;
    }

    var totalIngresos = 0;
    var totalEgresos = 0;
    balances.map((balance) => {
      if (
        moment(start)
          .startOf('day')
          .isSameOrBefore(moment(balance.date).startOf('day'), 'day') &&
        moment(end)
          .startOf('day')
          .isSameOrAfter(moment(balance.date).startOf('day'), 'day')
      ) {
        balance.ingreso.map((ingreso) => {
          if (
            moment(start).isSameOrBefore(moment(ingreso.hour)) &&
            moment(end).isSameOrAfter(moment(ingreso.hour))
          ) {
            totalIngresos += ingreso.quantity;
          }
        });

        balance.egreso.map((egreso) => {
          if (
            moment(start).isSameOrBefore(moment(egreso.hour)) &&
            moment(end).isSameOrAfter(moment(egreso.hour))
          ) {
            totalEgresos += egreso.quantity;
          }
        });
      }
    });
    const total = totalIngresos - totalEgresos;
    setResult({ totalIngresos, totalEgresos, total });
    setVisible(true);
  };

  const calculateAcumulate = () => {
    var totalIngresos = 0;
    var totalEgresos = 0;
    balances.map((balance) => {
      balance.ingreso.map((ingreso) => (totalIngresos += ingreso.quantity));
      balance.egreso.map((egreso) => (totalEgresos += egreso.quantity));
    });
    const total = totalIngresos - totalEgresos;
    setResultAcumulate({ totalIngresos, totalEgresos, total });
    setVisibleAcumulate(true);
  };

  return (
    <div>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={14}>
          <DatePicker.RangePicker
            placeholder={['Fecha inicio ', 'Fecha fin ']}
            style={{ width: '100%', marginTop: 5 }}
            format="DD-MM-YYYY HH:mm"
            value={[start && moment(start), end && moment(end)]}
            onChange={(value) => [
              setStart(moment(value[0]).toISOString()),
              setEnd(moment(value[1]).toISOString()),
            ]}
            showTime
            allowClear={false}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={5}>
          <Button
            type="default"
            style={{ width: '100%', marginTop: 5 }}
            onClick={() => calculate()}
          >
            Generar
          </Button>
        </Col>
        <Col xs={24} sm={12} md={12} lg={5}>
          <Button
            type="default"
            style={{ width: '100%', marginTop: 5 }}
            onClick={() => calculateAcumulate()}
          >
            Acumulado
          </Button>
        </Col>
      </Row>
      {visible && (
        <div style={{ marginTop: '2%', textAlign: 'center' }}>
          <h4>
            Balance calculado desde {moment(start).format('DD/MM HH:mm')} hasta{' '}
            {moment(end).format('DD/MM HH:mm')}
          </h4>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8}>
              <Statistic
                title="Total Ingresos"
                value={result.totalIngresos}
                valueStyle={{ color: '#1890ff' }}
                prefix="+"
                decimalSeparator=","
                groupSeparator="."
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Statistic
                title="Total Egresos"
                value={result.totalEgresos}
                valueStyle={{ color: '#faad14' }}
                prefix="-"
                decimalSeparator=","
                groupSeparator="."
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Statistic
                title="Balance Total"
                value={result.total}
                valueStyle={
                  result.total > 0 ? { color: '#1890ff' } : { color: '#faad14' }
                }
                prefix={result.total > 0 && '+'}
                decimalSeparator=","
                groupSeparator="."
              />
            </Col>
          </Row>
        </div>
      )}
      {visibleAcumulate && (
        <div style={{ marginTop: '2%', textAlign: 'center' }}>
          <h4>Balance Acumulado desde {moment(date).format('DD/MM/YY')}</h4>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8}>
              <Statistic
                title="Total Ingresos Acumulado"
                value={resultAcumulate.totalIngresos}
                valueStyle={{ color: '#1890ff' }}
                prefix="+"
                decimalSeparator=","
                groupSeparator="."
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Statistic
                title="Total Egresos Acumulado"
                value={resultAcumulate.totalEgresos}
                valueStyle={{ color: '#faad14' }}
                prefix="-"
                decimalSeparator=","
                groupSeparator="."
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Statistic
                title="Balance Total Acumlado"
                value={resultAcumulate.total}
                valueStyle={
                  resultAcumulate.total > 0
                    ? { color: '#1890ff' }
                    : { color: '#faad14' }
                }
                prefix={resultAcumulate.total > 0 && '+'}
                decimalSeparator=","
                groupSeparator="."
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
