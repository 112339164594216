import React, { useState } from 'react';
import { Form, Radio, Card, Button, Col, Row } from 'antd';
export function Chads2() {
  const [point, setPoint] = useState();
  const calculate = (values) => {
    const point =
      values.age +
      values.sex +
      values.a +
      values.b +
      values.c +
      values.d +
      values.e;

    point === 0
      ? setPoint(
          point +
            ' puntos - Recomendaciones: Riesgo bajo. Puede no requerir anticoagulación.'
        )
      : point === 1
      ? setPoint(
          point +
            ' puntos - Recomendaciones: Riesgo bajo-moderado- Considerar anticoagulación (individualizar conducta).'
        )
      : setPoint(
          point +
            ' puntos - Recomendaciones: Riesgo moderado o alto. Indicación de anticoagulación'
        );
  };

  return (
    <div>
      <Col lg={24}>
        <Form onFinish={calculate}>
          <Card
            title="CHADS2-VASc Score (Riesgo de ACV en los próximos 12 meses)"
            headStyle={{ textAlign: 'center' }}
          >
            <Row lg={24}>
              <Col lg={12}>
                <Form.Item
                  name="age"
                  rules={[
                    {
                      required: true,
                      message: 'Debe elegir la edad.',
                    },
                  ]}
                  label="Edad"
                  labelCol={{ span: 24 }}
                >
                  <Radio.Group>
                    <Radio value={0}>Menor de 60 años</Radio>
                    <Radio value={1}>Entre 60 y 74 años</Radio>
                    <Radio value={2}>75 años o mayor</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name="sex"
                  rules={[
                    {
                      required: true,
                      message: 'Debe elegir el sexo.',
                    },
                  ]}
                  label="Sexo"
                  labelCol={{ span: 24 }}
                >
                  <Radio.Group>
                    <Radio value={0}>Masculino</Radio>
                    <Radio value={1}>Femenino</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row lg={24}>
              <Col lg={12}>
                <Form.Item
                  name="a"
                  label="Historia de Insuf. Cardiaca Congestiva"
                  labelCol={{ span: 24 }}
                  initialValue={0}
                >
                  <Radio.Group defaultValue={0}>
                    <Radio value={0}>No</Radio>
                    <Radio value={1}>Si</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name="b"
                  label="Hipertensión actual (>140/90 mm Hg), o en tratamiento:"
                  labelCol={{ span: 24 }}
                  initialValue={0}
                >
                  <Radio.Group defaultValue={0}>
                    <Radio value={0}>No</Radio>
                    <Radio value={1}>Si</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row lg={24}>
              <Col lg={12}>
                <Form.Item
                  name="c"
                  label="ACV, AIT, o TEP previos"
                  labelCol={{ span: 24 }}
                  initialValue={0}
                >
                  <Radio.Group defaultValue={0}>
                    <Radio value={0}>No</Radio>
                    <Radio value={2}>Si</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name="d"
                  label="Historia de Enfermedad Vascular"
                  labelCol={{ span: 24 }}
                  initialValue={0}
                >
                  <Radio.Group defaultValue={0}>
                    <Radio value={0}>No</Radio>
                    <Radio value={1}>Si</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Col span={24}>
              <Form.Item
                name="e"
                label="Diabetes:"
                labelCol={{ span: 24 }}
                initialValue={0}
              >
                <Radio.Group defaultValue={0}>
                  <Radio value={0}>No</Radio>
                  <Radio value={1}>Si</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="default"
                  htmlType="submit"
                  style={{ width: '100%', marginTop: '10px' }}
                >
                  Calcular
                </Button>
              </Form.Item>
            </Col>
            {point && <h1>{point}</h1>}
          </Card>
        </Form>
      </Col>
    </div>
  );
}
