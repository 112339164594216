import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  CalculatorOutlined,
  PhoneOutlined,
  WarningOutlined,
  UnorderedListOutlined,
  HistoryOutlined,
  ShopOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import './MenuSiderInfarmary.scss';
import PermissionsAsigner from '../../PermissionsAsigner';
import { ROLES } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import Spin from '../../Spin';

function MenuSiderInfarmary(props) {
  const { menuCollapsed, setMenuCollapsed, location } = props;
  const { Sider } = Layout;
  const { SubMenu } = Menu;

  const { role } = useSelector((store) => store.userData?.user);
  const { floors, loadingFloors, drugStores } = useSelector(
    (store) => store.tenantData
  );

  if (loadingFloors) {
    return <Spin />;
  }

  return (
    <Sider
      className="admin-sider-infarmary"
      collapsed={menuCollapsed}
      breakpoint="lg"
      onBreakpoint={(broken) => {
        setMenuCollapsed(broken);
      }}
    >
      <Menu
        theme="dark"
        mode="vertical"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item key="/">
          <Link to={'/'}>
            <HomeOutlined /> <span className="navText">Home</span>
          </Link>
        </Menu.Item>
        <SubMenu
          key="/income/:incomeType"
          icon={<UnorderedListOutlined />}
          title="Internados"
        >
          {floors?.map((floor) => (
            <Menu.Item key={`/income/${floor.id}`}>
              <Link to={`/income/${floor.id}`}>
                <span className="navText">{floor.name}</span>
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>
        <Menu.Item
          key="/incomes-history"
          disabled={role === ROLES.INFARMARY.value}
        >
          <Link to={'/incomes-history'}>
            <HistoryOutlined /> <span className="navText">Historial</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/calculator">
          <PermissionsAsigner requiredRole={ROLES.VIEW}>
            <Link to={'/calculator'}>
              <CalculatorOutlined />
              <span className="navText">Calculadora</span>
            </Link>
          </PermissionsAsigner>
        </Menu.Item>
        <Menu.Item key="/protocolo">
          <PermissionsAsigner requiredRole={ROLES.VIEW}>
            <Link to={'/protocolo'}>
              <WarningOutlined />
              <span className="navText">Protocolos</span>
            </Link>
          </PermissionsAsigner>
        </Menu.Item>
        <Menu.Item key="/phones">
          <PermissionsAsigner requiredRole={ROLES.VIEW}>
            <Link to={'/phones'}>
              <PhoneOutlined />
              <span className="navText">Telefonos Utiles</span>
            </Link>
          </PermissionsAsigner>
        </Menu.Item>
        <Menu.Item key="/calendar">
          <Link to={'/calendar'}>
            <CalendarOutlined />
            <span className="navText">Calendario Guardias</span>
          </Link>
        </Menu.Item>
        {drugStores && (
          <Menu.Item key="/pharmacy">
            <PermissionsAsigner requiredRole={ROLES.ADMININFARMARY}>
              <Link to={'/pharmacy'}>
                <ShopOutlined /> <span className="navText">Farmacia</span>
              </Link>
            </PermissionsAsigner>
          </Menu.Item>
        )}
        <Menu.Item key="/users">
          <PermissionsAsigner requiredRole={ROLES.ADMININFARMARY}>
            <Link to={'/users'}>
              <UserOutlined />
              <span className="navText">Usuarios</span>
            </Link>
          </PermissionsAsigner>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default withRouter(MenuSiderInfarmary);
