import {
  Collapse,
  Tooltip,
  Button,
  notification,
  Upload,
  message,
  Dropdown,
  Menu,
  Modal as ModalAntd,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { getProtocoloApi } from '../../api/protocolo';
import { getAccessTokenApi } from '../../api/auth';
import { deletePdfApi, getPdfApi, uploadPdfApi } from '../../api/cloudStorage';
import PdfViewer from '../../components/PdfViewer/PdfViewer';
import Spin from '../../components/Spin';
import {
  DeleteOutlined,
  EyeOutlined,
  MoreOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import '../Phones/Phones.scss';
import PermissionsAsigner from '../../components/PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import '../Phones/Phones.scss';

export default function Protocolo() {
  const [protocolos, setProtocolos] = useState([]);
  const [pdf, setPdf] = useState('');
  const [pdfName, setPdfName] = useState();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();

      getProtocoloApi(token)
        .then((response) => setProtocolos(response.protocolos))
        .catch((err) => {
          notification['error']({ message: 'Error del servidor' });
        });
      setReload(false);
    };
    getData();
  }, [reload]);

  const viewPdf = async (pdfName) => {
    let token = await getAccessTokenApi();
    setLoadingPdf(true);

    await getPdfApi(token, 'pdfs', pdfName)
      .then((response) => {
        setPdf(response.url);
        setPdfName(pdfName);
        setLoadingPdf(false);
      })
      .catch((err) => notification['error']({ message: 'Error del servidor' }));
  };

  const propsUpload = {
    name: 'file',
    accept: '.pdf',
    showUploadList: false,
    async beforeUpload(info) {
      const token = await getAccessTokenApi();
      if (!protocolos?.some((pdf) => info.name === pdf.pdfName)) {
        const isLt2M = info.size / 1024 / 1024 < 2000;
        if (!isLt2M) {
          return message.error('El archivo debe ser menor a 2 Gb.');
        }
        message.loading(`Subiendo ${info.name}`, 0);
        await uploadPdfApi(token, info, 'pdfs', 'Protocolo')
          .then((response) => {
            if (response?.code !== 200) {
              message.error(response.message).then(() => message.destroy());
            } else {
              message
                .success(`${info.name} se ha subido con exito.`, 1)
                .then(() => message.destroy());
              setPdfName();
              setPdf();
              setReload(true);
            }
          })
          .catch((err) => {
            console.log(err);
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            }).then(() => message.destroy());
          });
      } else {
        message
          .warning('Ya existe un archivo con ese nombre.', 1)
          .then(() => message.destroy());
        return;
      }
    },
  };

  const deleteProtocolo = () => {
    ModalAntd.confirm({
      title: 'Eliminar protocolo',
      content: `¿ Estas seguro que quieres eliminar el protocolo ${pdfName}?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        setReload(true);
        const token = await getAccessTokenApi();
        deletePdfApi(token, pdfName, 'pdfs', 'Protocolo')
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
              setReload(false);
            } else {
              notification['success']({ message: response.message });
              setPdfName();
              setPdf();
              setReload(true);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setReload(false);
          });
      },
    });
  };

  return (
    <div className="phones">
      <div className="phones-header">
        <div></div>
        <h1>Protocolos</h1>
        <PermissionsAsigner requiredRole={ROLES.VIEW}>
          <Upload {...propsUpload}>
            <Button type="primary">
              <UploadOutlined /> Subir Protocolo
            </Button>
          </Upload>
        </PermissionsAsigner>
      </div>
      {protocolos?.map((protocolo) => {
        return (
          <Collapse
            collapsible=""
            onChange={(e) => e.length !== 0 && viewPdf(protocolo.pdfName)}
          >
            <Collapse.Panel
              header={protocolo.name}
              extra={
                <>
                  <Tooltip title="Ver en pantalla completa">
                    <a href={pdf} target="_blank" referrerPolicy="origin">
                      <Button
                        type="link"
                        size="small"
                        disabled={pdfName !== protocolo.pdfName}
                      >
                        <EyeOutlined />
                      </Button>
                    </a>
                  </Tooltip>
                  <Dropdown
                    trigger="click"
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <Button type="link" onClick={() => deleteProtocolo()}>
                            <span style={{ color: 'red' }}>Eliminar</span>
                            <DeleteOutlined style={{ color: 'red' }} />
                          </Button>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button type="link">
                      <MoreOutlined style={{ color: '#1764ab' }} />
                    </Button>
                  </Dropdown>
                </>
              }
            >
              {loadingPdf ? (
                <Spin />
              ) : (
                <div style={{ textAlign: 'center', height: '100%' }}>
                  <PdfViewer pdf={pdf} />
                </div>
              )}
            </Collapse.Panel>
          </Collapse>
        );
      })}
    </div>
  );
}
