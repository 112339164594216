import React, { useState } from 'react';
import { Row, Col, Form, Input, Card, Button } from 'antd';

export function ToolsFarmacos() {
  const [dosis, setDosis] = useState();
  const [ritmo, setRitmo] = useState();
  const [gamasForm] = Form.useForm();
  const [ritmoForm] = Form.useForm();

  const calculateGamas = (values) => {
    setDosis(
      (values.mlInfusion * (values.mgPreparados / values.mlSolucion) * 1000) /
        (values.peso * 60)
    );
  };

  const calculateRitmo = (values) => {
    setRitmo(
      (values.dosisDeseada * values.peso * 60) /
        ((values.mgPreparados / values.mlSolucion) * 1000)
    );
  };
  return (
    <div>
      <Row gutter={24}>
        <Col lg={12}>
          <Form form={gamasForm} onFinish={calculateGamas}>
            <Card title="Calculo de gamas" headStyle={{ textAlign: 'center' }}>
              <Col span={24}>
                <Form.Item
                  name="mgPreparados"
                  label="Mg preparados"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar los mg preparados.',
                    },
                  ]}
                >
                  <Input type="number" suffix="mg" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="mlSolucion"
                  label="Ml de la solución"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar los ml de la solición.',
                    },
                  ]}
                >
                  <Input type="number" suffix="ml" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="peso"
                  label="Peso del paciente"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar el peso.',
                    },
                  ]}
                >
                  <Input type="number" suffix="kg" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="mlInfusion"
                  label="Ml/Hs infundiendo"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar los ml/hs infundiendo.',
                    },
                  ]}
                >
                  <Input type="number" suffix="ml/hs" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="default"
                    htmlType="submit"
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    Calcular
                  </Button>
                </Form.Item>
              </Col>
              {dosis && (
                <h1>Dosis infundiendo: {dosis.toFixed(2)} ug/kg/min</h1>
              )}
            </Card>
          </Form>
        </Col>
        <Col lg={12}>
          <Form form={ritmoForm} onFinish={calculateRitmo}>
            <Card
              title="Calculo ritmo de infusión"
              headStyle={{ textAlign: 'center' }}
            >
              <Col span={24}>
                <Form.Item
                  name="dosisDeseada"
                  label="Dosis deseada"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar la dosis deseada.',
                    },
                  ]}
                >
                  <Input type="number" suffix="ug/kg/hs" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="mgPreparados"
                  label="Cantidad de la droga preparada"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar los mg preparados.',
                    },
                  ]}
                >
                  <Input type="number" suffix="mg" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="mlSolucion"
                  label="Cantidad de solución"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar los ml de la solución.',
                    },
                  ]}
                >
                  <Input type="number" suffix="ml" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="peso"
                  label="Peso del paciente"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar el peso.',
                    },
                  ]}
                >
                  <Input type="number" suffix="kg" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="default"
                    htmlType="submit"
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    Calcular
                  </Button>
                </Form.Item>
              </Col>
              {ritmo && <h1>Ritmo de infusión: {ritmo.toFixed(2)} ml/hs</h1>}
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
