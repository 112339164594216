import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { signInApi } from '../../api/user';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../utils/constants';

import './LoginForm.scss';

export default function LoginForm() {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const login = async (values) => {
    setLoading(true);

    const result = await signInApi(values);

    if (result.message) {
      notification['error']({ message: result.message });
      setLoading(false);
    } else {
      const { accessToken, refreshToken } = result;
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);

      notification['success']({ message: 'Login correcto' });
      window.location.href = '/';
    }
  };
  return (
    <Form className="login-form" form={form} onFinish={login}>
      <Form.Item
        name="email"
        initialValue=""
        rules={[
          {
            required: true,
            message: 'Debe cargar un email valido. Sin espacios al final.',
            type: 'email',
          },
        ]}
      >
        <Input
          className="login-form__input"
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
          placeholder="Correo electronico"
          inputMode="email"
        />
      </Form.Item>
      <Form.Item
        className="login-form__input"
        name="password"
        initialValue=""
        rules={[
          {
            required: true,
            message: 'Debe ingresar una contraseña',
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
          placeholder="Contraseña"
        />
      </Form.Item>
      <Form.Item>
        <Button
          className="login-form__button"
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Entrar
        </Button>
      </Form.Item>
    </Form>
  );
}
