import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { notification } from 'antd';
import Spin from '../Spin';
import { getLineStatsApi, getTurnsStatsApi } from '../../api/stats';
import { getAccessTokenApi } from '../../api/auth';
import moment from 'moment';

export function BarChart({
  floor,
  height,
  start,
  end,
  type,
  userId,
  generate,
  status,
  statsType,
  studieType,
}) {
  const [dataChart, setDataChart] = useState();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      setLoading(true);
      statsType === 'amb'
        ? getTurnsStatsApi({
            token,
            type,
            start,
            end,
            id: userId,
            status,
            studieType,
          }).then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              setDataChart(response.dataChart);
              setTotal(response.total || null);
              setLoading(false);
            }
          })
        : getLineStatsApi({ token, type, start, end, floor }).then(
            (response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
              } else {
                setDataChart(response.dataChart);
                setLoading(false);
              }
            }
          );
    };

    getData();
  }, [generate, type]);

  if (loading)
    return (
      <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <span>Cargando...</span>
        <Spin />
      </div>
    );
  return (
    <Chart
      width="auto"
      height={height || '250px'}
      options={{
        chart: {
          title:
            total && `Total en el periodo: ${total.toLocaleString('es-AR')}`,
          bar: { groupWidth: '100px' },
        },
      }}
      chartType="Bar"
      loader={<Spin />}
      data={dataChart}
      rootProps={{ 'data-testid': '2' }}
    />
  );
}
