import React, { useState } from 'react';
import { Tag, Checkbox, notification, Tooltip } from 'antd';
import moment from 'moment';
import './ViewInfo.scss';
import { checkMedicineApi } from '../../api/medicine';
import { getAccessTokenApi } from '../../api/auth';

export function ViewMedicine(props) {
  const { data, report, setReload } = props;

  const checkMedicine = async (indicationId, status, position) => {
    let token = await getAccessTokenApi();
    checkMedicineApi(data.medicineId, indicationId, status, position, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({
            message: response.message,
          });
        } else {
          notification['success']({
            message: response.message,
          });
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
    setReload(true);
  };

  if (!data) {
    return null;
  }

  const checks = (indication) => {
    let q = 24 / (indication.time ? indication.time : 24);
    let data = [];
    for (let i = 0; i < q; i++) {
      data.push(
        <Checkbox
          disabled={
            indication.status[i] === true ||
            indication.suspend ||
            !moment()
              .startOf('day')
              .isSame(moment(data && data.date).startOf('day'), 'day')
          }
          onChange={(value) => {
            checkMedicine(indication._id, value.target.checked, i);
          }}
          defaultChecked={indication.status[i] === true}
        />
      );
    }
    return data;
  };

  return (
    <div className="view-info">
      <h4>{data && moment(data?.date).format('DD/MM/YYYY')}</h4>
      {data?.indication?.length > 0 && <h4>Medicamentos</h4>}
      <ol>
        {data?.indication?.map((indication, index) => (
          <li key={index}>
            {report ? (
              <span
                style={{
                  textDecoration: indication.suspend && 'line-through',
                }}
              >
                {indication.suspend && 'SUSPENDIDA - '}
                {indication.drug.name} - {indication.dosis} {indication.unity}
                {indication.extra !== 'Unica Dosis' &&
                  ` - Cada ${indication.time} hs`}
                {indication.hour && ` (${indication.hour})`}{' '}
                {indication.extra !== 'Habitual' && `- ${indication.extra}`}
                {indication.description && ` - ${indication.description}`}
              </span>
            ) : (
              <>
                <Tag
                  color={
                    indication.extra === 'Unica Dosis'
                      ? 'warning'
                      : indication.extra === 'SOS'
                      ? 'error'
                      : null
                  }
                >
                  <span
                    style={{
                      textDecoration: indication.suspend && 'line-through',
                    }}
                  >
                    {indication.suspend && 'SUSPENDIDA - '}
                    {indication.drug.name} - {indication.dosis}{' '}
                    {indication.unity}
                    {indication.extra !== 'Unica Dosis' &&
                      ` - Cada ${indication.time} hs`}
                    {indication.hour && ` (${indication.hour})`}{' '}
                    {indication.extra !== 'Habitual' && `- ${indication.extra}`}
                    {indication.description && ` - ${indication.description}`}
                  </span>
                </Tag>

                {checks(indication)}
              </>
            )}
          </li>
        ))}
      </ol>
      {data?.infusion?.length > 0 && <h4>Infusiones</h4>}
      <ol>
        {data?.infusion?.map((infusion, index) => (
          <li key={index}>
            <span
              style={{
                textDecoration: infusion.suspend && 'line-through',
              }}
            >
              {infusion.suspend && 'SUSPENDIDA - '}
              {infusion.infusion.name} - {infusion.quantity} ml -{' '}
              {infusion.ritmo} {infusion.unity}
              {infusion.drug && (
                <span>
                  ( {infusion.drug.name} {infusion.dosis && infusion.dosis}{' '}
                  {infusion.unityDrug && infusion.unityDrug})
                </span>
              )}
              {infusion.description && <span>. {infusion.description}</span>}
            </span>
          </li>
        ))}
      </ol>
      {data?.action?.length > 0 && <h4>Acciones Enfermería</h4>}
      <ol>
        {data?.action?.map((action, index) => (
          <li key={index}>
            <span
              style={{
                textDecoration: action.suspend && 'line-through',
              }}
            >
              {action.suspend && 'SUSPENDIDA - '}
              {action.action.name}
              {action.time && ` - Cada ${action.time} hs`}
              {action.hour && ` (${action.hour})`}{' '}
              {action.extra !== 'Habitual' && `- ${action.extra}`}
              {action.description && ` - ${action.description}`}
            </span>
          </li>
        ))}
      </ol>

      <div className="view-info__signature">
        <h4>
          {data?.user?.name} {data?.user?.lastname}
        </h4>
      </div>
    </div>
  );
}
