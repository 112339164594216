import { basePath, apiVersion } from './config';

export async function getNoteApi(id, order, token) {
  const url = `${basePath}/${apiVersion}/note/${id}&${order}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function addNoteApi(noteData, token) {
  const url = `${basePath}/${apiVersion}/note`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(noteData),
  };

  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err;
  }
}
