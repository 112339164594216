import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { onDiagnosticCreate } from '../../actions';

export default function DiagnosticForm(props) {
  const { setIsVisibleModal } = props;
  const dispatch = useDispatch();
  const [diagnosticForm] = Form.useForm();

  useEffect(() => {
    diagnosticForm.resetFields();
  });

  const onFinish = (values) => {
    dispatch(onDiagnosticCreate(values, setIsVisibleModal));
  };

  return (
    <div>
      <Form form={diagnosticForm} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              name="name"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: 'Debe cargar un diagnostico de alta.',
                },
              ]}
            >
              <Input placeholder="Diagnostico alta (*)" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
