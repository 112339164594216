import React, { useEffect, useState } from 'react';
import {
  Form,
  Col,
  Row,
  Input,
  Button,
  DatePicker,
  notification,
  Radio,
  Steps,
  Select,
} from 'antd';
import moment from 'moment';
import {
  addDyalisisControlApi,
  updateDyalisisControlApi,
} from '../../../api/control';
import { getAccessTokenApi } from '../../../api/auth';
import { useSelector } from 'react-redux';
import locale from 'antd/es/date-picker/locale/es_ES';
import { vascularAccess } from '../../../utils/constants';

export default function DyalisisControlForm(props) {
  const {
    incomeId,
    data,
    setIsVisibleModal,
    setReload,
    discharge,
    incomeType,
    preVascular,
  } = props;

  const { Step } = Steps;
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(0);
  const [present, setPresent] = useState(data ? data.present : true);
  const [controlForm] = Form.useForm();

  const id = useSelector((store) => store.userData?.user?._id);

  useEffect(() => {
    !data || !data?.present ? setView(0) : setView(data.params?.length + 1);
  }, [data]);

  useEffect(() => {
    controlForm.resetFields();
    controlForm.setFieldsValue(
      view === 0
        ? {
            present: data ? data?.present : present,
            hourStart: data
              ? moment(data?.initialParams?.hourStart)
              : moment().startOf('hour'),
            ufPre: data?.initialParams?.ufPre,
            tecResp: data?.initialParams?.tecResp,
            filter: data?.initialParams?.filter,
            temperature: data?.initialParams?.temperature,
            testDes: data?.initialParams?.testDes,
            uf: data?.initialParams?.uf,
            weightAnt: data?.initialParams?.weightAnt,
            weightPre: data?.initialParams?.weightPre,
            taIngreso: data?.initialParams?.taIngreso,
            incident: data ? data?.initialParams?.incident : false,
            controlBac: data ? data?.initialParams?.controlBac : true,
            vascularAccess: data ? data?.vascularAccess : preVascular,
          }
        : view === 5
        ? {
            taEgreso: data?.endParams?.taEgreso,
            temperature: data?.endParams?.temperature,
            uf: data?.endParams?.uf,
            hourEnd: data?.endParams
              ? moment(data?.endParams?.hourEnd)
              : moment(data?.initialParams?.hourStart)
                  .add(4, 'hours')
                  .startOf('hour'),
            ktv: data?.endParams?.ktv,
            weightPost:
              data?.endParams?.weightPost || data?.initialParams?.weightPost,
          }
        : data?.params?.length > 0
        ? {
            tas_tad: data?.params[view - 1]?.tas_tad,
            uf: data?.params[view - 1]?.uf,
            pArterial: data?.params[view - 1]?.pArterial,
            pVenosa: data?.params[view - 1]?.pVenosa,
            qbEfec: data?.params[view - 1]?.qbEfec,
          }
        : {}
    );
  }, [view, data]);

  const onFinish = async (values) => {
    if (moment(values.hourStart).isAfter(moment().endOf('day'))) {
      notification['warning']({
        message: 'La fecha no puede ser mayor a la actual.',
      });
      return;
    }
    setLoading(true);
    values.user = id;
    values.income = incomeId;
    values.incomeType = incomeType;
    if (!present) values.vascularAccess = preVascular;

    let token = await getAccessTokenApi();
    data
      ? updateDyalisisControlApi({
          controlData: values,
          token,
          id: data?._id,
          view,
          paramId: data?.params[view - 1]?._id,
        })
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
              setLoading(false);
            } else {
              notification['success']({ message: response.message });
              setReload(true);
              setLoading(false);

              setIsVisibleModal(false);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoading(false);
          })
      : addDyalisisControlApi(values, token)
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
              setLoading(false);
            } else {
              notification['success']({ message: response.message });
              setReload(true);
              setLoading(false);
              setIsVisibleModal(false);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoading(false);
          });
  };

  const initial = (
    <Form form={controlForm} onFinish={onFinish}>
      <Row>
        <Col xs={12} sm={6} md={6}>
          <Form.Item
            name="present"
            initialValue={present}
            label="Presencia"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: 'Debe completar el dato',
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setPresent(e.target.value);
              }}
            >
              <Radio value={true}>Presente</Radio>
              <Radio value={false}>Ausente</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        {present && (
          <>
            <Col xs={12} sm={6} md={6}>
              <Form.Item
                name="incident"
                initialValue={false}
                label="Reporta Incidente Grave"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Debe completar el dato',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Si</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item
                name="controlBac"
                initialValue={true}
                label="Control Bacteriologico"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Debe completar el dato',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Si</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item
                name="vascularAccess"
                rules={[
                  {
                    required: true,
                    message: 'Debe cargar el acceso vascular',
                  },
                ]}
                label="Acceso Vascular"
                labelCol={{ span: 24 }}
              >
                <Select
                  placeholder="Acceso Vascular"
                  optionFilterProp="children"
                  inputMode="none"
                >
                  {vascularAccess?.map((item, index) => (
                    <Select.Option key={index} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            name="hourStart"
            rules={[{ required: true, message: 'Debe cargar una fecha' }]}
            label="Fecha / Hora de incio"
            labelCol={{ span: 24 }}
            initialValue={moment().startOf('hour')}
          >
            <DatePicker
              placeholder="Seleccione fecha de control (*)"
              style={{ width: '100%' }}
              format="DD-MM HH:mm"
              locale={locale}
              showTime={{
                format: 'HH:mm',
              }}
            />
          </Form.Item>
        </Col>
        {present && (
          <>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="weightAnt"
                label="Peso HD Ant"
                labelCol={{ span: 24 }}
              >
                <Input
                  type="number"
                  inputMode="numeric"
                  max={250}
                  suffix="Kg"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="weightPre"
                label="Peso pre HD"
                labelCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Debe cargar el peso previo' },
                ]}
              >
                <Input
                  type="number"
                  inputMode="numeric"
                  max={250}
                  suffix="Kg"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="uf"
                label="UF Prescripta"
                labelCol={{ span: 24 }}
              >
                <Input type="number" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="taIngreso"
                label="TA ingreso"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: 'Debe cargar la tension' }]}
              >
                <Input
                  maxLength={7}
                  onChange={(e) => {
                    controlForm.setFieldsValue({
                      taIngreso: tranformText(e.target.value),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="testDes"
                label="Test Des."
                labelCol={{ span: 24 }}
              >
                <Radio.Group>
                  <Radio value={true}>Si</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item name="filter" label="Filtro" labelCol={{ span: 24 }}>
                <Input type="number" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="temperature"
                label="Temperatura"
                labelCol={{ span: 24 }}
              >
                <Input type="number" suffix="°C" step={0.1} min={32} max={45} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="tecResp"
                label="Tec Resp"
                labelCol={{ span: 24 }}
              >
                <Input maxLength={7} />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Button
              type="default"
              htmlType="submit"
              style={{ width: '100%' }}
              loading={loading}
              disabled={loading}
            >
              Guardar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const params = (
    <Form form={controlForm} onFinish={onFinish}>
      <Row gutter={24}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
          <Form.Item name="tas_tad" label="TAS/TAD" labelCol={{ span: 24 }}>
            <Input
              maxLength={7}
              onChange={(e) => {
                controlForm.setFieldsValue({
                  tas_tad: tranformText(e.target.value),
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={4}>
          <Form.Item name="uf" label="UF" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={4}>
          <Form.Item
            name="pArterial"
            label="Presión Arterial"
            labelCol={{ span: 24 }}
          >
            <Input type="number" inputMode="numeric" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={4}>
          <Form.Item
            name="pVenosa"
            label="Presión Venosa"
            labelCol={{ span: 24 }}
          >
            <Input type="number" inputMode="numeric" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={4}>
          <Form.Item name="qbEfec" label="Qb Efectivo" labelCol={{ span: 24 }}>
            <Input type="number" inputMode="numeric" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Button
              type="default"
              htmlType="submit"
              style={{ width: '100%' }}
              loading={loading}
              disabled={loading}
            >
              Guardar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  function tranformText(text) {
    const url = text?.replace(/[. ,]/g, '/');
    return url.toLowerCase();
  }

  const end = (
    <Form form={controlForm} onFinish={onFinish}>
      <Row gutter={24}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
          <Form.Item
            name="hourEnd"
            rules={[{ required: true, message: 'Debe cargar una fecha' }]}
            label="Hora de fin"
            labelCol={{ span: 24 }}
            initialValue={moment()}
          >
            <DatePicker.TimePicker
              placeholder="Seleccione fecha de control (*)"
              style={{ width: '100%' }}
              inputReadOnly
              format="HH:mm"
              minuteStep={5}
              showTime={{ format: 'HH:mm' }}
              locale={locale}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
          <Form.Item
            name="taEgreso"
            label="TA egreso"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: 'Debe la TA de egreso' }]}
          >
            <Input
              maxLength={7}
              onChange={(e) => {
                controlForm.setFieldsValue({
                  taEgreso: tranformText(e.target.value),
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
          <Form.Item
            name="weightPost"
            label="Peso post"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: 'Debe cargar el peso final' }]}
          >
            <Input type="number" inputMode="numeric" max={250} suffix="Kg" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
          <Form.Item
            name="temperature"
            label="Temperatura"
            labelCol={{ span: 24 }}
          >
            <Input type="number" suffix="°C" step={0.1} min={32} max={45} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
          <Form.Item name="uf" label="UF Final" labelCol={{ span: 24 }}>
            <Input type="number" inputMode="numeric" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
          <Form.Item name="ktv" label="KTV" labelCol={{ span: 24 }}>
            <Input type="number" inputMode="numeric" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Button
              type="default"
              htmlType="submit"
              style={{ width: '100%' }}
              loading={loading}
              disabled={loading}
            >
              Guardar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <div>
      <Steps
        type="navigation"
        current={view}
        size="small"
        onChange={(step) => setView(step)}
      >
        <Step title="Inicio" />
        {present && (
          <>
            <Step
              title="1ª hora"
              disabled={!data}
              status={
                data?.endParams
                  ? data?.params && data.params[0]
                    ? 'finish'
                    : 'error'
                  : 'wait'
              }
            />
            <Step
              title="2ª hora"
              disabled={!data || data?.params?.length < 1}
              status={
                data?.endParams
                  ? data?.params && data.params[1]
                    ? 'finish'
                    : 'error'
                  : 'wait'
              }
            />
            <Step
              title="3ª hora"
              disabled={!data || data?.params?.length < 2}
              status={
                data?.endParams
                  ? data?.params && data.params[2]
                    ? 'finish'
                    : 'error'
                  : 'wait'
              }
            />
            <Step
              title="4ª hora"
              disabled={!data || data?.params?.length < 3}
              status={
                data?.endParams
                  ? data?.params && data.params[3]
                    ? 'finish'
                    : 'error'
                  : 'wait'
              }
            />
            <Step
              title="Final"
              disabled={!data}
              status={data?.endParams ? 'finish' : 'wait'}
            />
          </>
        )}
      </Steps>
      {view === 0 && initial}
      {0 < view && view <= 4 && params}
      {view === 5 && end}
    </div>
  );
}
