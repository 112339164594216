import React from 'react';
import { Page, View, Text } from '@react-pdf/renderer';
import { styleBody, styleTable } from './styles';
import moment from 'moment';

export function PdfTurn({ turn, name, type, date, statsTurns }) {
  return (
    <Page style={styleBody.bodyWithoutHeader}>
      <View style={styleBody.data}>
        <Text style={styleBody.title}>
          {name} - {date}
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.text}>
          Atendidos {statsTurns?.quantityCheck} de {statsTurns?.quantity}
        </Text>
      </View>
      <View
        render={() =>
          type === 'studie' ? (
            ''
          ) : (
            <View>
              <View style={styleBody.data}>
                <Text style={styleBody.text}>
                  Liquidacion Diaria{' '}
                  <Text style={styleBody.textTitle}>Profesional Total: </Text>
                  {statsTurns.pricePercent.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                  })}{' '}
                </Text>
              </View>
              <View style={styleBody.data}>
                <Text style={styleBody.text}>
                  Liquidacion Diaria{' '}
                  <Text style={styleBody.textTitle}>
                    Profesional Atendidos:{' '}
                  </Text>
                  {statsTurns.pricePercentCheck.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                  })}
                </Text>
              </View>
            </View>
          )
        }
      />
      <View style={[styleTable.row, styleTable.header]} fixed>
        <Text style={styleTable.column3}>Hora</Text>
        <Text style={styleTable.column5}>Paciente</Text>
        <Text style={styleTable.column1}>Estado</Text>
        <Text style={styleTable.column6}>Obra Social</Text>
        <Text style={styleTable.column6}>Estudios</Text>
        <Text
          style={styleTable.column6}
          render={() => (type === 'studie' ? 'Efector' : 'Observaciones')}
        />
      </View>
      {turn?.map((shift) => (
        <View wrap={false}>
          <View style={styleTable.row}>
            <Text style={styleTable.column3}>
              {moment(shift.hourStart).format('HH:mm')}
            </Text>
            <Text style={[{ textTransform: 'capitalize' }, styleTable.column5]}>
              {shift.patient?.name}
            </Text>
            <Text style={styleTable.column1}>{shift.status}</Text>
            <Text style={styleTable.column6}>{shift.patient?.socialWork}</Text>

            <Text style={styleTable.column6}>
              {shift?.studies?.map(
                (studie, index) => `${index + 1}- ${studie?.name}\n`
              ) || ''}
            </Text>

            <Text
              style={styleTable.column6}
              render={() =>
                type === 'studie'
                  ? shift?.doctorDerivate
                    ? `${shift?.doctorDerivate?.name} ${shift?.doctorDerivate?.lastname}`
                    : ''
                  : `${((shift?.price * shift?.percent) / 100).toLocaleString(
                      'es-AR',
                      {
                        style: 'currency',
                        currency: 'ARS',
                        minimumFractionDigits: 0,
                      }
                    )}
              | ${shift.description}`
              }
            ></Text>
          </View>
        </View>
      ))}
    </Page>
  );
}
