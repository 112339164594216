import React from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
export default function BackButton({ back }) {
  return (
    <Button
      type="link"
      style={{
        paddingLeft: 0,
        paddingBottom: 25,
      }}
      size="middle"
      onClick={() => {
        return back
          ? back === true
            ? window.history.back()
            : (window.location.href = `/#income/${back}`)
          : window.history.back();
      }}
    >
      <ArrowLeftOutlined /> Atras
    </Button>
  );
}
