import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Button, Tooltip, Dropdown, Menu, Spin as SpinAntd } from 'antd';
import './IncomesList.scss';
import Spin from '../Spin';
import {
  InfoCircleOutlined,
  AuditOutlined,
  EditOutlined,
  StopOutlined,
  LoadingOutlined,
  FileDoneOutlined,
  MoreOutlined,
  FileTextOutlined,
  SnippetsOutlined,
  MedicineBoxOutlined,
  ControlOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import Modal from '../Modal';
import PermissionsAsigner from '../PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import RollbackIncomeForm from '../RollbackIncomeForm';
import HighMedicalForm from '../HighMedicalForm';
import { onIncomeHistoryLoadMore, onIncomeValueChange } from '../../actions';

export default function IncomesList(props) {
  const { path } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const {
    incomesHistory,
    loadingHistory,
    count,
    search,
    searchType,
    searchSocialWork,
    searchDateStart,
    searchDateEnd,
    loadingSearch,
    page,
  } = useSelector((store) => store.incomeData);

  const dispatch = useDispatch();

  const rollbackIncomeModal = (income) => {
    setIsVisibleModal(true);
    setModalTitle(`Volver a internar a ${income.name}`);
    setModalContent(
      <RollbackIncomeForm
        income={income}
        setIsVisibleModal={setIsVisibleModal}
      />
    );
  };

  const highMedicalIncomeModal = (income) => {
    setIsVisibleModal(true);
    setModalTitle(`Formulario alta`);
    setModalContent(
      <HighMedicalForm income={income} setIsVisibleModal={setIsVisibleModal} />
    );
  };

  if (path === '/incomes-history-patient/:dni/:name' && loadingSearch) {
    return <Spin />;
  }

  return (
    <div>
      {(loadingSearch || loadingHistory) && incomesHistory?.length > 0 && (
        <h4 style={{ textAlign: 'center', padding: 10 }}>
          Buscando... <SpinAntd indicator={<LoadingOutlined />} />
        </h4>
      )}
      <List
        className="incomes-list"
        itemLayout={window.screen.width > 573 ? 'horizontal' : 'vertical'}
        dataSource={incomesHistory}
        pagination={
          incomesHistory?.length > 50 && {
            pageSize: 50,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} pacientes`,
            current: page,
            onChange: (page) => {
              dispatch(onIncomeValueChange({ page }));
            },
            position: 'top',
          }
        }
        renderItem={(income) => (
          <IncomeHistory
            income={income}
            rollbackIncomeModal={rollbackIncomeModal}
            highMedicalIncomeModal={highMedicalIncomeModal}
            loadingHistory={loadingHistory}
          />
        )}
        loading={
          incomesHistory?.length === 0 &&
          loadingHistory && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
        locale={
          (search || searchType) && {
            emptyText: 'No se han encontrado coincidencias...',
          }
        }
      />
      {search ||
      searchType ||
      searchSocialWork ||
      searchDateStart ||
      searchDateEnd ||
      path === '/incomes-history-patient/:dni/:name' ? null : (
        <div style={{ textAlign: 'center', margin: 5 }}>
          <Button
            type="link"
            onClick={() => {
              dispatch(onIncomeHistoryLoadMore(incomesHistory, 10, count));
              dispatch(
                onIncomeValueChange({ page: Math.ceil((count + 10) / 50) })
              );
            }}
            loading={loadingHistory}
            disabled={loadingHistory}
          >
            Cargar Mas...
          </Button>
        </div>
      )}
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={modalTitle === `Formulario alta` ? '75%' : '50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function IncomeHistory(props) {
  const { income, rollbackIncomeModal, highMedicalIncomeModal } = props;

  const day = moment(income.dischargeDate).format('DD');
  const month = moment(income.dischargeDate).format('MMMM');
  const year = moment(income.dischargeDate).format('YYYY');

  const { modulesAccess, role } = useSelector((store) => store.userData?.user);

  return (
    <>
      <Tooltip title="Fecha de alta" className="incomes-list__date-cel">
        <div>
          <span style={{ marginRight: 2 }}>{day}</span>
          <span style={{ marginRight: 2 }}>{month}</span>
          <span>{year}</span>
        </div>
      </Tooltip>
      <List.Item
        actions={[
          moment(income.dischargeDate)
            .startOf('hours')
            .diff(moment().startOf('hours'), 'hours') > -8 && (
            <PermissionsAsigner requiredRole={ROLES.ADMIN}>
              <Tooltip title="Deshacer alta">
                <Button
                  type="danger"
                  onClick={() => rollbackIncomeModal(income)}
                >
                  <StopOutlined />
                </Button>
              </Tooltip>
            </PermissionsAsigner>
          ),
          <Tooltip title="Informe internación">
            <Link
              to={{
                pathname: `/report/${income?._id}`,
                search:
                  income.bed?.bedType === 'Dialisis' &&
                  `?${income.bed?.bedType}`,
              }}
            >
              <Button type="default">
                <FileDoneOutlined />
              </Button>
            </Link>
          </Tooltip>,
          <Tooltip title="Ver epicrisis">
            <Link to={`/view-income-history/${income._id}`}>
              <Button type="default">
                <InfoCircleOutlined />
              </Button>
            </Link>
          </Tooltip>,
          <PermissionsAsigner
            requiredRole={ROLES.ADMIN}
            visible={ROLES.SECRETARY}
          >
            <Dropdown
              trigger="click"
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="link"
                      style={{ color: '#002a52' }}
                      onClick={() => highMedicalIncomeModal(income)}
                    >
                      <FileTextOutlined /> Formulario alta
                    </Button>
                  </Menu.Item>

                  {(modulesAccess?.includes('Control Internación') ||
                    role === 2) && (
                    <Menu.ItemGroup>
                      <Menu.Item>
                        <PermissionsAsigner
                          requiredRole={ROLES.OWNER}
                          visible={ROLES.SECRETARY}
                        >
                          <Link to={`/evolution-history/${income._id}`}>
                            <Button type="link" style={{ color: '#002a52' }}>
                              <AuditOutlined /> Evoluciones
                            </Button>
                          </Link>
                        </PermissionsAsigner>
                      </Menu.Item>
                      <Menu.Item>
                        <PermissionsAsigner
                          requiredRole={ROLES.OWNER}
                          visible={ROLES.SECRETARY}
                        >
                          <Link to={`/edit-history-income/${income._id}`}>
                            <Button type="link" style={{ color: '#002a52' }}>
                              <EditOutlined /> Editar ingreso
                            </Button>
                          </Link>
                        </PermissionsAsigner>
                      </Menu.Item>
                      <Menu.Item>
                        <PermissionsAsigner
                          requiredRole={ROLES.OWNER}
                          visible={ROLES.SECRETARY}
                        >
                          <Link to={`/medicine-history/${income._id}`}>
                            <Button type="link" style={{ color: '#002a52' }}>
                              <MedicineBoxOutlined /> Indicaciones
                            </Button>
                          </Link>
                        </PermissionsAsigner>
                      </Menu.Item>
                      <Menu.Item>
                        <PermissionsAsigner
                          requiredRole={ROLES.OWNER}
                          visible={ROLES.SECRETARY}
                        >
                          <Link to={`/operating-history/${income._id}`}>
                            <Button type="link" style={{ color: '#002a52' }}>
                              <SnippetsOutlined /> Parte quirófano
                            </Button>
                          </Link>
                        </PermissionsAsigner>
                      </Menu.Item>
                      <Menu.Item>
                        <PermissionsAsigner
                          requiredRole={ROLES.OWNER}
                          visible={ROLES.SECRETARY}
                        >
                          <Link
                            to={
                              income.bed?.bedType !== 'Dialisis'
                                ? `/controls/${income._id}/${true}`
                                : `/dialisis-controls/${
                                    income._id
                                  }/view/${true}`
                            }
                          >
                            <Button type="link" style={{ color: '#002a52' }}>
                              <ControlOutlined />{' '}
                              {income.bed?.bedType !== 'Dialisis'
                                ? 'Controles Frecuentes'
                                : 'Sesiones Dialisis'}
                            </Button>
                          </Link>
                        </PermissionsAsigner>
                      </Menu.Item>
                      <Menu.Item>
                        <PermissionsAsigner
                          requiredRole={ROLES.OWNER}
                          visible={ROLES.SECRETARY}
                        >
                          <Link to={`/notes/${income._id}/${true}`}>
                            <Button type="link" style={{ color: '#002a52' }}>
                              <EditOutlined /> Notas Enfermeria
                            </Button>
                          </Link>
                        </PermissionsAsigner>
                      </Menu.Item>
                      <Menu.Item>
                        <PermissionsAsigner
                          requiredRole={ROLES.OWNER}
                          visible={ROLES.SECRETARY}
                        >
                          <Link to={`/add-epicrisis-history/${income._id}`}>
                            <Button type="link" style={{ color: '#002a52' }}>
                              <FileDoneOutlined /> Editar epicrisis
                            </Button>
                          </Link>
                        </PermissionsAsigner>
                      </Menu.Item>
                    </Menu.ItemGroup>
                  )}
                </Menu>
              }
            >
              <Button type="link">
                <MoreOutlined style={{ color: '#1764ab' }} />
              </Button>
            </Dropdown>
          </PermissionsAsigner>,
        ]}
      >
        <List.Item.Meta
          avatar={
            <Tooltip title="Fecha de alta">
              <div className="incomes-list__date">
                <span style={{ marginRight: 2 }}>{day}</span>
                <span style={{ marginRight: 2 }}>{month}</span>
                <span>{year}</span>
              </div>
            </Tooltip>
          }
          title={income.name}
          description={
            income.dischargeDiagnostic ? (
              <div>
                Lugar de Alta: {income.type} | Diagnostico de alta:{' '}
                {income.dischargeDiagnostic} | Motivo de alta:{' '}
                {income.dischargeReason}
                {income?.historyTypes?.length > 1 && (
                  <div>
                    Paso por:
                    <ul>
                      {income?.historyTypes.map((i) => (
                        <li>{i}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ) : (
              `Motivo de alta: ${income.dischargeReason}`
            )
          }
        />
      </List.Item>
    </>
  );
}
