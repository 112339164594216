import React from 'react';
import { List, Tooltip, Button } from 'antd';
import moment from 'moment';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import './ViewInfo.scss';

export function ListDates(props) {
  const { data, viewData } = props;

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(data) => <Item data={data} viewData={viewData} />}
        locale={{ emptyText: 'No se han encontrado datos ' }}
        loading={
          !data && {
            style: { width: '100%', padding: '200px 0' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
      />
    </div>
  );
}

function Item(props) {
  const { data, viewData } = props;
  return (
    <List.Item
      actions={[
        <Tooltip title="Ver Detalle">
          <Button type="default" onClick={() => viewData(data)}>
            <EyeOutlined />
          </Button>
        </Tooltip>,
      ]}
    >
      <List.Item.Meta
        title={
          <div>
            <span>{moment(data.date).format('DD/MM/YY HH:mm')}</span>
          </div>
        }
        description={
          <div>
            <p style={{ fontWeight: '800' }}>{data?.title}</p>
            <span>
              {data?.user?.name} {data?.user?.lastname}
            </span>
          </div>
        }
      />
    </List.Item>
  );
}
