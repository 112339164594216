import React, { useEffect, useState } from 'react';
import { Form, Button, Input, Modal, DatePicker, Col, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { onIncomeAddEpicrisis } from '../../actions';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import { getUsersTypeApi } from '../../api/user';
import { getAccessTokenApi } from '../../api/auth';

export default function EpicrisisForm(props) {
  const { income, path } = props;
  const dispatch = useDispatch();
  const [epicrisForm] = Form.useForm();
  const { _id, name, lastname } = useSelector((store) => store.userData?.user);
  const [users, setUsers] = useState();

  useEffect(() => {
    epicrisForm.resetFields();
  }, [income]);

  useEffect(() => {
    let token = getAccessTokenApi();

    path === '/add-epicrisis-history/:id' &&
      getUsersTypeApi(token, true, 'Medico').then((response) => {
        setUsers(response.users);
      });
  }, [path]);

  const onFinish = (values) => {
    values.userEpicrisis = values.userEpicrisis || _id;

    Modal.confirm({
      title: 'Agregar epicrisis',
      content:
        path === '/add-epicrisis-history/:id' ? (
          <span>¿ Esta seguro que quiere confirmar los cambios ?</span>
        ) : (
          <span>
            ¿ Esta seguro que quiere firmar epicrisis como{' '}
            <b>
              {name} {lastname}
            </b>
            ?
          </span>
        ),
      okText: 'Aceptar',
      okType: 'default',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onIncomeAddEpicrisis(values, income._id));
      },
    });
  };

  return (
    <div>
      <Form form={epicrisForm} onFinish={onFinish}>
        {path === '/add-epicrisis-history/:id' && (
          <Row gutter={24} style={{ marginBottom: 5 }}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                name="dischargeDate"
                initialValue={moment(income.dischargeDate)}
                rules={[
                  { required: true, message: 'Debe ingresar la epicrisis' },
                ]}
              >
                <DatePicker
                  placeholder="Seleccione fecha de alta"
                  style={{ width: '100%' }}
                  format="DD-MM-YYYY"
                  locale={locale}
                  showToday={false}
                  disabledDate={(current) =>
                    current &&
                    (moment(current).isBefore(income.date) ||
                      moment(current).isAfter(income.dischargeDate))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                name="userEpicrisis"
                initialValue={
                  income?.userEpicrisis.type === 'Medico' &&
                  income?.userEpicrisis._id
                }
                rules={[
                  { required: true, message: 'Debe ingresar la epicrisis' },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Seleccione un medico.. (*)"
                  optionFilterProp="children"
                >
                  {users?.map((user, index) => (
                    <Select.Option key={index} value={user._id}>
                      {user.name} {user.lastname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item
          name="epicrisis"
          initialValue={income?.epicrisis}
          rules={[{ required: true, message: 'Debe ingresar la epicrisis' }]}
        >
          <Input.TextArea
            placeholder="Recordar que lo que esta negrita ya aparece en la epicrisis. Solo debe agregar lo que considere importante de los titulos en rojo y realizar un resumen da la internación."
            style={{ maxWidth: '95%' }}
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="default" htmlType="submit" style={{ width: '95%' }}>
            {income.epicrisis ? 'Actualizar Epicrisis' : 'Agregar Epicrisis'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
