import React, { useState, useEffect } from 'react';
import {
  Button,
  Tooltip,
  PageHeader,
  notification,
  Modal as ModalAntd,
  Row,
  DatePicker,
} from 'antd';
import PermissionsAsigner from '../../../components/PermissionsAsigner';
import { ROLES } from '../../../utils/constants';
import {
  ControlOutlined,
  DeleteOutlined,
  MedicineBoxOutlined,
  PlusOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getAccessTokenApi } from '../../../api/auth';
import Modal from '../../../components/Modal';
import { ListDates, PageListAndView } from '../../../components/ListAndDetail';
import InsumoIndicationForm from '../../../components/Infarmary/InsumoIndicationForm';
import '../../Evolution/Evolution.scss';
import {
  deleteIndicationInsumoApi,
  getIdicationFilterApi,
  getIdicationInsumoApi,
  getIdicationInsumoDetailApi,
} from '../../../api/insumo';
import { ViewIndicationInsumos } from '../../../components/ListAndDetail/ViewIndicationInsumos';
import locale from 'antd/es/date-picker/locale/es_ES';
import Spin from '../../../components/Spin';
import { Link } from 'react-router-dom';

export default function Insumos(props) {
  const { id, history } = props.match.params;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [data, setData] = useState(null);
  const [month, setMonth] = useState(moment());
  const [insumosIndication, setInsumosIndication] = useState();

  const [indicationId, setIndicationId] = useState();
  const [reload, setReload] = useState(false);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState();

  const { incomesActive, incomesHistory } = useSelector(
    (store) => store.incomeData
  );

  const userId = useSelector((store) => store.userData?.user?._id);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getIdicationInsumoApi(id, token, month)
        .then((response) => {
          setInsumosIndication(response.insumosIndication);
          setFilter(
            response.lastInsumo
              ? response.lastInsumo[0]?.filter
              : response.insumosIndication[0]?.filter
          );

          response?.insumosIndication.length > 0 &&
            setDisable(
              moment()
                .startOf('day')
                .isSame(
                  moment(response.insumosIndication[0]?.date).startOf('day'),
                  'day'
                ) ||
                moment(month).isBefore(
                  moment(response.insumosIndication[0]?.date).startOf('day')
                )
            );
          return response;
        })
        .then((response) => {
          response?.insumosIndication?.length > 0 &&
            getIdicationInsumoDetailApi({
              id: response?.insumosIndication[0]?._id,
              token,
            }).then((result) => {
              setData(result.insumosIndicationDetail);
              setIndicationId(response.insumosIndication[0]?._id);
            });
        });
    };
    getData();

    setReload(false);
  }, [reload, month]);

  const viewIndicationInsumo = async (indication, type = undefined) => {
    setLoading(true);
    const token = await getAccessTokenApi();
    setIndicationId(type ? type : indication._id);

    let filter =
      type === 'total'
        ? await getIdicationFilterApi(id, token, month).then((result) => {
            return result.response;
          })
        : await insumosIndication?.find((i) => {
            return i._id === indication?._id;
          });

    setFilter(type === 'total' ? filter : filter.filter);

    getIdicationInsumoDetailApi({
      id: type === 'total' ? indication : indication?._id,
      token,
      type,
      month,
    }).then((result) => {
      setData(result.insumosIndicationDetail);
      setLoading(false);
    });

    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  var income = {};
  history
    ? incomesHistory.find((i) => {
        if (i._id === id) {
          income = i;
        }
      })
    : incomesActive.find((i) => {
        if (i._id === id) {
          income = i;
        }
      });

  const addInsumo = (indicationId = false) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregando nuevos insumos utilizados a ${income.name}`);
    setModalContent(
      <InsumoIndicationForm
        incomeId={id}
        userId={userId}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        indicationId={indicationId}
        isVisibleModal={isVisibleModal}
        filter={filter}
        incomeType={income?.type}
      />
    );
  };

  const repeatInsumos = (indicationId = false, data) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregando nuevos insumos utilizados a ${income.name}`);
    setModalContent(
      <InsumoIndicationForm
        incomeId={id}
        userId={userId}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        discharge={income.dischargeDate}
        indicationId={indicationId}
        data={data.map((d) => {
          return { insumo: d.insumo.id, quantity: d.insumo.quantity };
        })}
        filter={filter}
        incomeType={income?.type}
      />
    );
  };

  return (
    <div className="evolution">
      <div className="evolution-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`${income?.bed?.name}`}
          subTitle={
            <Tooltip
              title={`Cama: ${income?.bed?.name} ${income?.name} - ${
                income.age
              } años | Fecha Ingreso: ${moment(income?.date).format(
                'DD/MM/YYYY'
              )}`}
            >
              {income?.name} - {income?.age} años | Fecha Ingreso:{' '}
              {moment(income.date).format('DD/MM/YYYY')}
            </Tooltip>
          }
          extra={[
            <DatePicker
              picker="month"
              locale={locale}
              placeholder="Fecha"
              allowClear={false}
              onChange={(value) => {
                setIndicationId();
                setData(null);
                setMonth(moment(value));
              }}
              value={month}
            />,
            <Tooltip title="Controles">
              <Link
                to={
                  income.bed?.bedType !== 'Dialisis'
                    ? `/controls/${income._id}`
                    : `/dialisis-controls/${income._id}`
                }
              >
                <Button type="link">
                  <ControlOutlined />
                </Button>
              </Link>
            </Tooltip>,
            <Tooltip title="Indicaciones">
              <Link
                to={{
                  pathname: `/medicine/${income?._id}`,
                  hash: income.bed?.bedType,
                }}
              >
                <Button type="link">
                  <MedicineBoxOutlined />
                </Button>
              </Link>
            </Tooltip>,
            <Button
              disabled={disable || history}
              type="primary"
              onClick={() => addInsumo()}
            >
              Nuevo Consumo
            </Button>,
          ]}
        />
      </div>

      <PageListAndView
        datesTitle="Insumos"
        extraDate={
          <Button
            type="link"
            onClick={() => {
              viewIndicationInsumo(id, 'total');
            }}
          >
            Ver Total Mes
          </Button>
        }
        componentDate={
          <ListDates data={insumosIndication} viewData={viewIndicationInsumo} />
        }
        detailTitle="Detalle de Insumos"
        data={data}
        componentDetail={
          loading ? (
            <Spin />
          ) : (
            <div className="view-info">
              <ViewIndicationInsumos
                data={data}
                month={month}
                filter={filter}
                type={indicationId}
              />
            </div>
          )
        }
        extra={
          indicationId !== 'total' &&
          indicationId && (
            <Row>
              <PermissionsAsigner requiredRole={ROLES.OWNER}>
                <DeleteInsumo
                  indicationId={indicationId}
                  data={data}
                  setReload={setReload}
                />
              </PermissionsAsigner>
              <>
                {moment()
                  .startOf('day')
                  .isSame(
                    moment(data && data[0]?.insumo.date).startOf('day'),
                    'day'
                  ) && (
                  <Tooltip title="Agregar insumo utlizado">
                    <Button
                      type="link"
                      onClick={() => {
                        addInsumo(indicationId);
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  </Tooltip>
                )}
                {!disable && !history && (
                  <Tooltip title="Repetir insumos">
                    <Button
                      type="link"
                      onClick={() => {
                        repeatInsumos(false, data);
                      }}
                    >
                      <RetweetOutlined />
                    </Button>
                  </Tooltip>
                )}
              </>
            </Row>
          )
        }
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'90%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function DeleteInsumo(props) {
  const { indicationId, data, setReload } = props;

  const deleteInsumo = () => {
    ModalAntd.confirm({
      title: 'Eliminar insumo',
      content: `¿ Estas seguro que quieres eliminar el uso de insumos del ${moment(
        data[0].insumo.date
      ).format('DD-MM HH:mm')}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        const token = await getAccessTokenApi();
        deleteIndicationInsumoApi(indicationId, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setReload(true);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  return (
    <Tooltip title="Eliminar">
      <Button
        type="link"
        onClick={() => deleteInsumo()}
        disabled={!indicationId || indicationId === 'total'}
      >
        <DeleteOutlined style={indicationId && { color: 'red' }} />
      </Button>
    </Tooltip>
  );
}
