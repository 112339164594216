import { basePath, apiVersion } from './config';

export async function getMedicineApi({ id, order, token, month }) {
  const url = `${basePath}/${apiVersion}/medicine/${id}&${order}?month=${month}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function getMedicineDayApi(id, token) {
  const url = `${basePath}/${apiVersion}/medicineDay/${id}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function addMedicineApi(medicineData, token) {
  const url = `${basePath}/${apiVersion}/medicine`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(medicineData),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function updateMedicineApi(id, medicineData, token) {
  const url = `${basePath}/${apiVersion}/medicine/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(medicineData),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function checkMedicineApi(id, dataId, status, position, token) {
  const url = `${basePath}/${apiVersion}/check-medicine/${id}&${dataId}&${position}?status=${status}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function suspendIndicationApi(
  id,
  dataId,
  status,
  incomeId,
  token
) {
  const url = `${basePath}/${apiVersion}/suspend-indication/${id}&${dataId}&${incomeId}?status=${status}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function suspendInfusionApi(id, dataId, status, incomeId, token) {
  const url = `${basePath}/${apiVersion}/suspend-infusion/${id}&${dataId}&${incomeId}?status=${status}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function suspendActionApi(id, dataId, status, incomeId, token) {
  const url = `${basePath}/${apiVersion}/suspend-action/${id}&${dataId}&${incomeId}?status=${status}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function getAllMedicinesApi(token) {
  const url = `${basePath}/${apiVersion}/all-medicines`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function deleteMedicinenApi(id, token) {
  const url = `${basePath}/${apiVersion}/medicine/${id}`;

  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export async function getHeparinaMedicineDyalisisApi({ token, income, month }) {
  const url = `${basePath}/${apiVersion}/get-heparina-dyalisis/${income}?month=${month}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function checkDateMedicineApi(income, date, token) {
  const url = `${basePath}/${apiVersion}/check-medicine/${income}?date=${date}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}
