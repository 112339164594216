import React from 'react';
import AmpinaLogo from '../../assets/img/a1-8.png';
import ImageMaintenance from '../../assets/img/maintenance.png';
import './Maintenance.scss';

export default function Maintenance() {
  return (
    <div className="page">
      <div className="page-right">
        <h1>Pagina en mantenimiento</h1>
        <h2>Regrese en unos minutos...</h2>
        <h3>Disculpe las molestias</h3>
        <img src={AmpinaLogo} />
      </div>
      <div className="page-button">
        <button onClick={() => window.location.reload()}>Refrescar</button>
      </div>

      <div className="page-left">
        <img src={ImageMaintenance} />
      </div>
    </div>
  );
}
