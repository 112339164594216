import React, { useEffect, useState } from 'react';
import { PageHeader, Card } from 'antd';
import { useSelector } from 'react-redux';
import PatientDescription from '../../components/Ambulatory/PatientDescription';
import ConsultationForm from '../../components/Ambulatory/ConsultationForm';

export default function NewConsultation(props) {
  const [patient, setPatient] = useState([]);

  const { patientId } = props.match.params;

  const { patients } = useSelector((store) => store.patientData);

  useEffect(() => {
    setPatient(
      patients.find((patient) => {
        return patient._id === patientId;
      })
    );
  }, []);

  return (
    <div className="evolution">
      <div className="evolution-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={patient?.name}
          style={{ textTransform: 'capitalize' }}
        >
          <PatientDescription patient={patient} />
        </PageHeader>
      </div>

      <Card
        title="Nueva Consulta"
        headStyle={{
          textAlign: 'center',
          backgroundColor: '#002a52',
          color: '#fff',
        }}
      >
        <ConsultationForm patientId={patientId} />
      </Card>
    </div>
  );
}
