import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Select,
  notification,
  InputNumber,
  Tooltip,
  Steps,
  Tabs,
  Checkbox,
} from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  addIdicationInsumoApi,
  getInsumoApi,
  updateIdicationInsumoApi,
} from '../../../api/insumo';
import { useSelector } from 'react-redux';

export default function InsumoIndicationForm(props) {
  const {
    incomeId,
    userId,
    setIsVisibleModal,
    setReload,
    indicationId,
    data,
    filter,
    incomeType,
    getInsumos,
  } = props;
  const [insumos, setInsumos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changeFilter, setChangeFilter] = useState(false);

  const [insumoIndicationForm] = Form.useForm();
  const [filterIndicationForm] = Form.useForm();

  const { TabPane } = Tabs;

  const { role } = useSelector((store) => store.userData?.user);

  useEffect(() => {
    data && insumoIndicationForm.setFieldsValue({ insumos: data });

    filter &&
      filterIndicationForm.setFieldsValue({
        filter: filter.filter,
        use: indicationId ? filter.use : filter.use + 1,
        change: changeFilter,
        id: filter.id,
      });
  }, [data]);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getInsumoApi(token).then((response) => {
        setInsumos(response.insumos);
      });
    };
    getData();
  }, []);
  const onFinish = async (values) => {
    setLoading(true);
    let token = await getAccessTokenApi();
    values.user = userId;
    values.incomeType = incomeType || null;
    if (indicationId) {
      values.filter = changeFilter
        ? filterIndicationForm.getFieldValue()
        : false;
      updateIdicationInsumoApi({
        id: indicationId,
        indicationData: values,
        token,
      })
        .then((response) => {
          if (response?.code !== 200) {
            notification['error']({ message: response.message });
            getInsumoApi(token).then((response) => {
              setInsumos(response.insumos);
            });
            setLoading(false);
          } else {
            notification['success']({ message: response.message });
            setIsVisibleModal(false);
            setLoading(false);
            setReload(true);
            insumoIndicationForm.resetFields();
            filterIndicationForm.setFieldsValue(values.filter);
          }
        })
        .catch(() => {
          notification['error']({
            message: 'Error del servidor, intente mas tarde.',
          });
          setLoading(false);
        });
    } else {
      values.income = incomeId || null;
      values.filter = filterIndicationForm.getFieldValue();
      addIdicationInsumoApi({ indicationData: values, token })
        .then(async (response) => {
          if (response?.code !== 200) {
            notification['error']({ message: response.message });
            getInsumoApi(token).then((response) => {
              setInsumos(response.insumos);
            });
            setLoading(false);
          } else {
            notification['success']({ message: response.message });
            setIsVisibleModal(false);
            getInsumos ? getInsumos() : setReload(true);
            setLoading(false);
            insumoIndicationForm.resetFields();
            !getInsumos && filterIndicationForm.setFieldsValue(values.filter);
          }
        })
        .catch(() => {
          notification['error']({
            message: 'Error del servidor, intente mas tarde.',
          });
          setLoading(false);
        });
    }
  };

  const insumoForm = (
    <Form.List name="insumos">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <Row gutter={24} key={index}>
                <Col xs={22} md={14}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'insumo']}
                    fieldKey={[field.fieldKey, 'insumo']}
                    rules={[
                      {
                        required: true,
                        message: 'Debe elegir un insumo',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Seleccione un insumo.. (*)"
                      optionFilterProp="children"
                      onFocus={() => {
                        const getData = async () => {
                          const token = await getAccessTokenApi();
                          getInsumoApi(token).then((response) => {
                            setInsumos(response.insumos);
                          });
                        };
                        getData();
                      }}
                    >
                      {insumos.map(
                        (insumo, index) =>
                          !insumo?.filter && (
                            <Select.Option
                              disabled={
                                insumo.stock === 0 ||
                                insumoIndicationForm
                                  .getFieldsValue()
                                  .insumos?.find(
                                    (i) => i?.insumo === insumo._id
                                  )
                              }
                              key={index}
                              value={insumo._id}
                            >
                              {insumo.name} (Disponible: {insumo?.stock})
                            </Select.Option>
                          )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={22} md={7}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'quantity']}
                    rules={[
                      {
                        required: true,
                        message: 'Debe indicar cantidad',
                      },
                    ]}
                    fieldKey={[field.fieldKey, 'quantity']}
                    help="Para eliminar insumos, elegirlo y poner la cantidad que queremos eliminar en negativo"
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder="Cantidad a consumir"
                      step={1}
                      inputMode="numeric"
                    />
                  </Form.Item>
                </Col>

                <Col span={1} style={{ paddingLeft: '0' }}>
                  <Button
                    type="link"
                    size="large"
                    onClick={() => {
                      remove(field.name);
                    }}
                  >
                    <MinusCircleOutlined />
                  </Button>
                </Col>
              </Row>
            ))}
            <Form.Item style={{ textAlign: 'right' }}>
              <Tooltip title="Agregar insumo">
                <Button
                  type="default"
                  shape="circle"
                  onClick={() => {
                    add();
                  }}
                  size="large"
                >
                  <PlusOutlined />
                </Button>{' '}
              </Tooltip>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );

  const filterForm = (
    <Form form={filterIndicationForm} onFinish={onFinish}>
      <Row gutter={24}>
        <Col xs={22} md={4}>
          <Form.Item name="change">
            <Checkbox
              value={changeFilter}
              onChange={(e) => {
                setChangeFilter(e.target.checked);
                e.target.checked
                  ? filterIndicationForm.setFieldsValue({
                      filter: '',
                      use: 1,
                      change: e.target.checked,
                      id: filter ? filter?.id + 1 : 1,
                    })
                  : filterIndicationForm.setFieldsValue({
                      filter: filter.filter,
                      use: indicationId ? filter.use : filter.use + 1,
                      change: e.target.checked,
                      id: filter.id,
                    });
              }}
            >
              Cambiar Filtro
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={22} md={14}>
          <Form.Item
            name="filter"
            rules={[
              {
                required: true,
                message: 'Debe elegir un filtro',
              },
            ]}
          >
            <Select
              disabled={!changeFilter}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccione un filtro.. (*)"
              optionFilterProp="children"
              onFocus={() => {
                const getData = async () => {
                  const token = await getAccessTokenApi();
                  getInsumoApi(token).then((response) => {
                    setInsumos(response.insumos);
                  });
                };
                getData();
              }}
            >
              {insumos?.map(
                (insumo, index) =>
                  insumo?.filter && (
                    <Select.Option
                      key={index}
                      value={insumo._id}
                      disabled={insumo.stock === 0}
                    >
                      {insumo.name} (Disponible: {insumo?.stock})
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={22} md={4}>
          <Form.Item
            name="use"
            rules={[
              {
                required: true,
                message: 'Debe indicar nro de uso',
              },
            ]}
            help="Uso nro"
          >
            <InputNumber
              disabled={!changeFilter}
              style={{ width: '100%' }}
              placeholder="Uso nro"
              step={1}
              inputMode="numeric"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item></Form.Item>
    </Form>
  );

  return (
    <div>
      <div>
        <Form form={insumoIndicationForm} onFinish={onFinish}>
          <Tabs size="small" centered type="card" defaultActiveKey="1">
            <TabPane tab="Insumos" key="1">
              {insumoForm}
            </TabPane>
            {incomeId && (
              <TabPane tab="Filtros" key="2">
                {filterForm}
              </TabPane>
            )}
          </Tabs>
          <Form.Item>
            <Button
              type="default"
              htmlType="submit"
              style={{ width: '100%' }}
              disabled={loading}
              loading={loading}
            >
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
